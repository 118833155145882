import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  KeyboardAvoidingView,
  Platform,
  Dimensions,
} from "react-native";

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  webStyle: {
    alignSelf: "flex-start",
  },
  webStyleCenter: {
    paddingTop: 0,
  },
  image: {
    marginTop: 10,
    marginBottom: 30,
  },
});

export const AuthViewCustom = (props: any) => {
  const [viewStyle, setViewStyle] = useState(styles.webStyle);

  let style;
  const onResize = () => {
    if (Dimensions.get("window").width < 850) {
      style = styles.webStyleCenter;
      setViewStyle(style);
    } else {
      style = styles.webStyle;
      setViewStyle(style);
    }
  };

  useEffect(() => {
    Dimensions.addEventListener("change", onResize);
    onResize();
    return () => {
      Dimensions.removeEventListener("change", onResize);
    };
  }, []);

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={[styles.root, viewStyle]}
    >
      {props.children}
    </KeyboardAvoidingView>
  );
};
