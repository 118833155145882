import React from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import { MaterialIcons } from "@expo/vector-icons";
import { Text, View } from "react-native";
import { translate } from "./Localei18n/languagesFunctions";
import { MedimixTitle } from "./MedimixTitle";

export const ErrorView = () => (
  <SafeAreaView style={{ flex: 1 }}>
    <MedimixTitle style={{
      width: 150,
      height: 56,
      marginLeft: 20,
      marginTop: 20,
    }}
    />
    <View style={{
      flex: 1, justifyContent: "center", alignItems: "center", paddingHorizontal: 30, marginTop: -20,
    }}
    >
      <MaterialIcons name="warning" size={72} color="#cf4a44" />
      <Text style={{ color: "#000", marginTop: 10, fontSize: 24 }}>{translate("Oh no! Something went wrong.")}</Text>
      <Text style={{ marginTop: 10, lineHeight: 20, fontSize: 18 }}>
        {translate("We are informed automatically of the problem and will fix promptly the issue.")}
      </Text>
    </View>
  </SafeAreaView>
);
