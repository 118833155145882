
import React from "react";
import { Image, ImageStyle, StyleProp, View } from "react-native";

type Props = {
  style?: StyleProp<ImageStyle>;
}
export const MedimixTitle = (props: Props) => (
  <View style={{ display: "flex", height: 40, justifyContent: "center" }}>
    <Image
      source={require("../assets/medimix-evidera-2.png")}
      resizeMode="contain"
      style={[{
        height: 30,
        // marginVertical: 5,
      }, props.style]}
    />
  </View>
);
