import { API, graphqlOperation } from "aws-amplify";
import Auth from "@aws-amplify/auth";
// eslint-disable-next-line no-unused-vars
import { StackNavigationProp } from "@react-navigation/stack";
import gql from "graphql-tag";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { KeyboardAvoidingView, Platform, ScrollView, View } from "react-native";
import { Button, Snackbar, Subheading, Text, TextInput, Title, useTheme } from "react-native-paper";
import PhoneInput from "../../../components/PhoneInput";
import FormField from "../../../components/FormValidator/FormField";
import { translate } from "../../../components/Localei18n/languagesFunctions";
import { UserInfoContext } from "../../../context/userInfo";
import { CountryPicker } from "../../Auth/components/CountryPicker";
import { ProfileParamlist } from "../types"; // eslint-disable-line no-unused-vars
import { styles } from "./forms.style";
import VerifyNumber from "./profile.contacts.number.verify";

type Props = {
  // eslint-disable-next-line react/require-default-props
  navigation?: StackNavigationProp<ProfileParamlist>;
};

type InputData = {
  contact_id: string;
  panelist_id: number;
  number_type: number;
  number_type_desc: number;
  number: string;
  country_phone_code: string;
}

const AddContactsNumber = (props: Props) => {
  const theme = useTheme();
  const {
    control, register, getValues, setValue, trigger, watch, formState: { errors },
  } = useForm({ mode: "onSubmit", reValidateMode: "onSubmit" });
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [verificationMessage, setVerificationMessage] = useState("");
  const [Submitting, setSubmitting] = useState(false);
  const [openVerify, setOpenVerify] = useState(false);
  const [SendingCode, setSendingCode] = useState(false);
  const [number_id, setNumber_id] = useState(null);
  const [inputData, setInputData] = useState<InputData>({ ...props.route.params, number_type: 0, reload: undefined }); // eslint-disable-line react/prop-types

  const [countryPickerIsVisible, setCountryPickerIsVisible] = useState(false);
  const phoneInput = useRef<PhoneInput>(null);
  const { userInfo: { uic } } = useContext(UserInfoContext);

  const sendCode = async () => {
    setSendingCode(true);

    const completeNumber = `+${inputData.country_phone_code}${inputData.number}`;
    const formattedCompleteNumber = completeNumber.replace(/[\- ]+/g, "");

    setVerificationMessage(`${(translate("Sending Verification Code to"))} ${completeNumber}`);


    await Auth.currentAuthenticatedUser()
      .then(async (cognito) => {
        await Auth.updateUserAttributes(cognito, {
          phone_number: formattedCompleteNumber,
        });
      });


    // console.log(`sending code to ${getValues("phoneNumber")}`);

    await Auth.verifyCurrentUserAttribute("phone_number")
      .then((response) => {
        console.log([response]);
        setVerificationMessage((`${(translate("We sent a verification code to your number"))} ${completeNumber}`));
      })
      .catch((e) => {
        console.log(e);
        setVerificationMessage((`${translate("Failed to send verificaiton code.")}\n${e.message}`));
      });
    // setVerificationMessage((`${translate("We sent a verification code to your number")} ${getValues("phoneNumber")}`));
    setSendingCode(false);
    return true;
  };

  const onSubmit = async (data) => {
    try {
      const mutation = !number_id ? gql`
          mutation insert($formValue: FormContactsNumbersInput!, $panelist_id: Int!, $status: Int, $uic: String){
            insertIgnoreFormsContacts(panelist_id: $panelist_id)
            insertIgnoreForsmContactsNumbers(panelist_id: $panelist_id)
            updateVerificationStatus(status: $status, panelist_id: $panelist_id, uic: $uic)
            addFormNumber(formValue: $formValue){
              number_id panelist_id country_phone_code
            }
          }
        ` : gql`
          mutation insert($formValue: FormContactsNumbersInput!, $panelist_id: Int!, $status: Int, $uic: String){
            insertIgnoreFormsContacts(panelist_id: $panelist_id)
            insertIgnoreForsmContactsNumbers(panelist_id: $panelist_id)
            updateVerificationStatus(status: $status, panelist_id: $panelist_id, uic: $uic)
            updateFormNumber(formValue: $formValue)
          }
        `;
      setSubmitting(true);
      if (data?.phoneNumber) delete data?.phoneNumber;
      const response = await API.graphql(graphqlOperation(mutation, {
        formValue: {
          ...data,
          number_5last: (data.number).substr(data.number.length - 5, data.number.length),
          ...(number_id ? { number_id } : {}),
        },
        panelist_id: inputData.panelist_id,
        status: 2,
        uic,
      }));

      if (response.data.addFormNumber !== null || response.data.updateFormNumber === true) {
        if (response.data?.addFormNumber) setNumber_id(response.data.addFormNumber?.number_id);
        // await sendCode();
        // setOpenVerify(true);
        setMessage(translate("Number was saved."));
      } else {
        setMessage(translate("Failed to add number."));
      }
      setSnackbarVisible(true);
    } catch (err) {
      console.log(["failed to add contacts", err]);
      if (err.errors[0]?.message?.includes("Duplicate entry")) {
        setMessage(translate("The number you entered already exist in your account"));
      } else {
        setMessage(translate("Error adding number."));
      }
      setSnackbarVisible(true);
    }
    setSubmitting(false);
  };

  const handleSave = async () => {
    await trigger();
    if (Object.keys(errors).length === 0) {
      onSubmit(getValues());
    }
    return true;
  };

  useEffect(() => {
    // "panelist_id","number_type","primary_number","contact_id","number","country_phone_code"
    register("panelist_id", { value: inputData.panelist_id });
    register("number_type", { value: 2 });
    register("primary_number", { value: 0 });
    register("contact_id", { value: inputData.contact_id });
    register("number", { value: "" });
    register("country_phone_code", { value: "1" });
  }, []);

  const countryData = phoneInput.current?.getPickerData();

  const selectCountryHandler = (country) => {
    phoneInput.current?.selectCountry(country.iso2);
    setTimeout(() => {
      setCountryPickerIsVisible(false);
    }, 300);
  };

  const dismissCountryPicker = () => {
    setCountryPickerIsVisible(false);
  };

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={[
        styles.root,
        { backgroundColor: theme.colors.background },
      ]}
    >
      <ScrollView style={styles.scrollWrapper}>
        {!openVerify
          ? (
            <View style={[styles.scrollInnerResponsive]}>
              <View style={styles.titleWRapper}>
                <Title style={styles.title}>{translate("New Contact Number")}</Title>
                <Subheading style={styles.subheadingRegular}>
                  {translate("The contact numbers you add is subject for verification.")}
                </Subheading>
              </View>
              <Text style={styles.subheadingSmall}>{translate("Phone Number")}</Text>

              {(Platform.OS !== "ios" && countryPickerIsVisible)
                && (
                  <CountryPicker
                    countries={countryData}
                    dismissModal={dismissCountryPicker}
                    setCountry={selectCountryHandler}
                  />
                )}

              <FormField errors={errors?.phoneNumber}>
                <Controller
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <PhoneInput
                      ref={phoneInput}
                      defaultCode="US"
                      onChangePhoneNumber={(val) => {
                        const number = val.replace(/[^+,0-9]/g, "");
                        onChange(number);
                        setValue("number", number.replace(`+${phoneInput.current?.getCountryCode()}`, ""));
                        setValue("country_phone_code", phoneInput.current?.getCountryCode());
                        setInputData({
                          ...inputData,
                          number: number.replace(`+${phoneInput.current?.getCountryCode()}`, ""),
                          country_phone_code: phoneInput.current?.getCountryCode(),
                        });
                      }}
                      value={value}
                      autoFormat={false}
                      textComponent={TextInput}
                      textProps={{
                        mode: "flat", style: { height: 40 }, keyboardType: "numeric", value: `+${inputData?.country_phone_code || ""}${inputData?.number || ""}`,
                      }}
                      allowZeroAfterCountryCode
                      withDarkTheme
                      onSelectCountry={(iso2) => {
                      // phoneInput.current.selectCountry(iso2);
                        setValue("country_phone_code", phoneInput.current?.getCountryCode());
                        setValue("phoneNumber", `+${phoneInput.current?.getCountryCode()}${getValues("number")}`);
                        setInputData({
                          ...inputData,
                          number: inputData.number?.replace(`+${phoneInput.current?.getCountryCode()}`, ""),
                          country_phone_code: phoneInput.current?.getCountryCode(),
                        });
                      }}
                      {...(Platform.OS !== "ios" ? { onPressFlag: () => setCountryPickerIsVisible(true) } : {})}
                    />
                  )}
                  name="phoneNumber"
                  defaultValue={`+${inputData?.country_phone_code || ""}${inputData?.number || ""}`}
                  rules={{
                    required: { value: true, message: translate("Please enter your mobile number.") },
                    validate: () => {
                      if (!phoneInput.current?.isValidNumber()) {
                        return translate("Invalid mobile number");
                      }
                      return true;
                    },
                  }}
                />
              </FormField>

              <View style={styles.formActionField}>
                <Button loading={Submitting} disabled={Submitting} onPress={() => handleSave()} contentStyle={[styles.button]} dark mode="contained">
                  {Submitting ? translate("Saving") : !number_id ? translate("Add Number") : translate("Change")}
                </Button>
              </View>


            </View>
          ) : (
            <View style={[styles.scrollInnerResponsive]}>
              <VerifyNumber
                data={{ ...(getValues()), number_id }}
                // data={{
                //   contact_id: "718",
                //   country_phone_code: "63",
                //   number: "09216266523",
                //   number_type: 2,
                //   panelist_id: 2452006,
                //   primary_number: 0,
                //   number_id: 3391932,
                // }}
                SendingCode={SendingCode}
                goBack={() => props.navigation.goBack()}
                sendCode={sendCode}
                message={verificationMessage}
                setMessage={setMessage}
                setOpenVerify={setOpenVerify}
                openSnackbar={props.route.params.onOpenSnackbar}
              />
            </View>
          )}
      </ScrollView>

      <Snackbar
        visible={snackbarVisible}
        onDismiss={() => setSnackbarVisible(false)}
        duration={5000}
        action={{
          label: "×",
          onPress: () => {
            setSnackbarVisible(false);
          },
        }}
        style={{
          maxWidth: 500,
          width: "100%",
          padding: 10,
        }}
        wrapperStyle={{ alignItems: "center" }}
      >
        {message}
      </Snackbar>
    </KeyboardAvoidingView>
  );
};

export default AddContactsNumber;
