/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
import React, { useState, useEffect } from "react";
import { Platform, StyleSheet, View, TextInput, TouchableHighlight, Text, TouchableOpacity, TouchableWithoutFeedback, Keyboard, ScrollView } from "react-native";
import { API, graphqlOperation } from "aws-amplify";
import Auth from "@aws-amplify/auth";
import { I18n } from "@aws-amplify/core";

import gql from "graphql-tag";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import {
  HCPPortalTheme,
} from "../../components";
import { scaleDisplay } from "../../helpers/image/applyScale";
import { translate } from "../../components/Localei18n/languagesFunctions";

const styles = StyleSheet.create({
  container: {
    marginTop: 20,
    width: scaleDisplay(460),
    padding: 35,
    borderRadius: 6,
    shadowColor: "black",
    shadowOpacity: 0.15,
    shadowOffset: { width: 0, height: 3 },
    shadowRadius: 6,
  },
});

type Props = {
  user: any; // PropTypes.elementType.isRequired,
  handleSignout: () => void; // PropTypes.func.isRequired,
  updateUserData: () => void; // PropTypes.func.isRequired,
}

export const ConfirmNumber = (props) => {
  console.log(props.user.attributes);

  const [number, setNumber] = useState(props.user.attributes.phone_number);
  const [stage, setStage] = useState(0);
  const [code, setCode] = useState("");
  const [error, setError] = useState("");

  const onChangeText = (numberInputText) => {
    console.log(numberInputText);
    setNumber(numberInputText);
  };

  const onChangeTextCode = (codeInputText) => {
    setCode(codeInputText);
  };

  const setNumberByTmp = async () => {
    Auth.updateUserAttributes(props.user, { phone_number: props.user.attributes.phone_number });
    setNumber(props.user.attributes.phone_number);
    setStage(1);
  };

  const skipPhoneConfirmation = async () => {
    Auth.updateUserAttributes(props.user, { phone_number: null });
  };

  // const getContactPanlistID = async () => {
  //   if (!props.user.attributes["custom:panelist_id"]) {
  //     const qry = gql`
  //     query getContactsInfo_by_email($email: String!){
  //       getContactsInfo_by_email(email: $email){
  //         panelist_id
  //       }
  //     }
  //     `;

  //     const response: any = await API.graphql(graphqlOperation(qry, {
  //       email: props.user.attributes.email,
  //     }));

  //     const userInfo = response.data.getContactsInfo_by_email;

  //     if (userInfo) {
  //       if (!userInfo.panelist_id) {
  //         Auth.updateUserAttributes(props.user, { "custom:panelist_id": userInfo.panelist_id.toString() });
  //       }
  //     }
  //   }
  // };

  const sendCode = async () => {
    if (number !== "") {
      await Auth.currentAuthenticatedUser()
        .then((user) => {
          if (user.attributes.phone_number === number) {
            Auth.verifyCurrentUserAttribute("phone_number")
              .then(() => { setStage(1); })
              .catch((err) => { setError(err.message); });
          } else {
            Auth.updateUserAttributes(props.user, { phone_number: number })
              .then(() => { setStage(1); })
              .catch((err) => { setError(err.message); });
          }
        });
    } else {
      setError(translate("Phone number cannot be empty"));
    }
  };

  const verifyCode = async () => {
    if (code !== "") {
      Auth.verifyCurrentUserAttributeSubmit("phone_number", code)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          setError(err.message);
        });
    } else {
      setError(translate("Code cannot be empty"));
    }
  };

  const reSendCode = async () => {
    Auth.verifyCurrentUserAttribute("phone_number");
  };

  const backToPhone = () => {
    setStage(0);
  };

  const ViewPlatform = (innerProps) => {
    if (Platform.OS === "web") {
      return <View style={styles.container}>{innerProps.children}</View>;
    }
    return (
      <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
        <ScrollView style={HCPPortalTheme.section}>
          {innerProps.children}
        </ScrollView>
      </TouchableWithoutFeedback>
    );
  };

  const ErrorComponent = () => {
    if (error) {
      return (
        <View style={[HCPPortalTheme.errorRow, { marginTop: 32 }]}>
          <MaterialCommunityIcons name="alert" size={20} />
          <Text style={HCPPortalTheme.errorRowText}>{error}</Text>
        </View>
      );
    }
    return null;
  };

  useEffect(() => {
    setNumberByTmp();
    // getContactPanlistID();
  }, []);

  return (
    <ViewPlatform>
      {
				stage === Number(0) && (
				  <>
  <View style={HCPPortalTheme.sectionHeader}>
    <Text style={HCPPortalTheme.sectionHeaderText}>{translate("Enter Your Phone Number")}</Text>
  </View>
  <Text style={{ marginBottom: 10 }}>{`${translate("Phone Number")} *`}</Text>
  <TextInput
    style={[HCPPortalTheme.input, { marginBottom: 16 }]}
    placeholder={translate("Enter Your Phone Number")}
    autoCapitalize='none'
    autoCorrect={false}
    onChangeText={onChangeText}
    value={number}
    autoFocus
  />

  <TouchableOpacity onPress={sendCode} style={HCPPortalTheme.button}>
    <Text style={HCPPortalTheme.buttonText}>{translate("Send code")}</Text>
  </TouchableOpacity>

  <View style={[{ marginTop: 16 }]}>
    <View style={HCPPortalTheme.sectionFooter}>
      <View style={[HCPPortalTheme.cell]}>
        <TouchableHighlight
          onPress={props.handleSignout}
        >
          <Text style={HCPPortalTheme.sectionFooterLink}>{translate("Logout")}</Text>
        </TouchableHighlight>
      </View>

      <View style={[{ marginTop: 16 }]}>
        <TouchableHighlight onPress={skipPhoneConfirmation}>
          <Text style={HCPPortalTheme.sectionFooterLink}>{translate("Skip")}</Text>
        </TouchableHighlight>
      </View>
    </View>
  </View>
				  </>
				)
			}
      {
				stage === Number(1) && (
				  <>
  <View style={HCPPortalTheme.sectionHeader}>
    <Text style={HCPPortalTheme.sectionHeaderText}>{translate("Confirmation code")}</Text>
  </View>
  <Text style={{ marginBottom: 10 }}>{`${translate("Confirm code")} *`}</Text>
  <TextInput
    style={[HCPPortalTheme.input, { marginBottom: 16 }]}
    placeholder={translate("Confirmation code")}
    autoCapitalize='none'
    autoCorrect={false}
    onChangeText={onChangeTextCode}
    value={code}
    autoFocus
  />

  <TouchableOpacity onPress={verifyCode} style={HCPPortalTheme.button}>
    <Text style={HCPPortalTheme.buttonText}>{translate("Confirm")}</Text>
  </TouchableOpacity>

  <View style={[{ marginTop: 16 }]}>
    <View style={HCPPortalTheme.sectionFooter}>
      <View style={HCPPortalTheme.cell}>
        <TouchableHighlight
          onPress={backToPhone}
        >
          <Text style={HCPPortalTheme.sectionFooterLink}>{translate("Enter Your Phone Number")}</Text>
        </TouchableHighlight>
      </View>
      <View style={HCPPortalTheme.cell}>
        <TouchableHighlight
          onPress={reSendCode}
        >
          <Text style={HCPPortalTheme.sectionFooterLink}>{translate("Resend code")}</Text>
        </TouchableHighlight>
      </View>
    </View>
  </View>

  <View style={[{ marginTop: 16 }]}>
    <TouchableHighlight onPress={skipPhoneConfirmation}>
      <Text style={HCPPortalTheme.sectionFooterLink}>{translate("Skip")}</Text>
    </TouchableHighlight>
  </View>
				  </>
				)
			}
      <ErrorComponent />
    </ViewPlatform>
  );
};

export default ConfirmNumber;
