const primaryOpaque = (opacity) => `rgba(18, 25, 50, ${opacity})`;

const colors = {
  primaryLightBlueColor: "#27AAE1",
  primaryBlueGreyColor: "#d6e0f5",
  primaryBlueColor: "#25327a",
  primaryGreenColor: "#8DC63F",
  primaryVioletColor: "#662D91",
  primaryGreyColor: "#545861",
  secondaryLightBlueColor: "#67D0F4",
  secondaryBlueColor: "#35368D",
  secondaryGreenColor: "#B3D44F",
  secondaryVioletColor: "#7E52A1",
  secondaryGreyColor: "#B5B5B6",
  additionalSecondaryOrangeColor: "#B3D44F",
  additionalSecondaryGreenColor: "#13A89E",
  additionalSecondaryRedColor: "#D91B5C",
  primaryOpaque,
};

const ColorsEvidera = {
  primary: "#25327a", // navy blue
  secondary: "#67bd4a", // green
  additionalSecondary: "#662D91", // violet
  accent: "#009bd9", // light blue
  danger: "#d91b5c",
  warning: "#f7941d",
};

export {
  colors, ColorsEvidera,
};
