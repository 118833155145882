/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
/* ---------------------------------
      Create Account
      email,password,repeat password
--------------------------------- */
import React, { useState, useEffect, useContext } from "react";
import {
  View,
  TouchableHighlight,
  Text,
  StyleSheet,
  Platform,
  TouchableWithoutFeedback,
} from "react-native";
import {
  TextInput,
  Button,
  useTheme,
  IconButton,
} from "react-native-paper";
import PropTypes from "prop-types";
import Auth from "@aws-amplify/auth";
import * as Localization from "expo-localization";
import { useForm, Controller } from "react-hook-form";
import { ScrollView } from "react-native-gesture-handler";
import FormField from "../../../components/FormValidator/FormField";
import BinaryAlert from "../../../components/BinaryAlert/BinaryAlert";
import { HCPPortalTheme } from "../../../components";
import { ReferenceDataContext } from "./ReferenceDataContext";
import { translate } from "../../../components/Localei18n/languagesFunctions";
import AlertBox from "../../../components/Alert";
import CognitoPasswordChecker, { passwordPolicyCheck } from "../../../components/CognitoPasswordChecker";
import SignUpHeading from "./SignUpHeading";
import { ColorsEvidera } from "../../../theme/variables";
import TextInputPassword from "../../../components/TextInputPassword";

const styles = StyleSheet.create({
  input: {
    height: 37,
    width: "100%",
    backgroundColor: "rgb(239, 239, 239)",
    padding: 5,
    borderColor: "rgb(220, 218, 218)",
    borderRadius: 3,
  },
  title: {
    textAlign: "center",
    // color: "rgb(130, 130, 130)",
    color: "#4e4e4e",
    fontSize: 16,
  },
  button: {
    backgroundColor: "rgb(26, 158, 168)",
    height: 40,
    color: "white",
    borderRadius: 3,
    padding: 10,
  },
});

export const Stage0 = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  let unmounted = false;

  const uTheme = useTheme();

  const {
    control, watch, trigger, getValues, setValue, formState: { errors },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      email: "",
      password: "",
    },
  });

  React.useEffect(() => {
    if (props.email !== "" && props.pageTrigger === "userCheck") {
      setValue("email", props.email);
    } else {
      setValue("email", "");
    }
  }, [props.pageTrigger]);

  const { authenticatorData, setAuthenticatorData } = useContext(
    ReferenceDataContext
  );

  const onSubmit = async () => {
    await trigger();

    if (Object.keys(errors).length === 0) {
      if (!passwordPolicyCheck(getValues("password"), 8)) {
        return;
      }
      const { email, password } = getValues();
      if (!unmounted) {
        setLoading(true);
        const locale = Localization.locale.substring(0, 2);

        try {
          await Auth.signUp({
            username: `${email.toLowerCase()}`,
            password,
            attributes: {
              email: email.toLowerCase(),
              locale,
            },
          });

          const datas = {
            email,
            password,
          };
          setAuthenticatorData(datas);

          props.goToStage(1);
        } catch (er) {
          setLoading(false);
          console.log("SIGN IN", er);
          if (er.code === "UsernameExistsException") {
            BinaryAlert(
              translate("Email already exists"),
              translate("An account with the given email already exists."),
              () => props.changeState("signIn"), () => console.log("Cancel Pressed")
            );
          } else {
            setError(translate(er.message));
          }
        }
      }
    }
  };

  const handleGotoSignin = () => {
    props.changeState("signIn");
  };

  const goToVerify = () => {
    props.goToStage(2);
  };

  useEffect(() => () => {
    unmounted = true;
  }, []);

  return (
    <ScrollView
      keyboardShouldPersistTaps="never"
      style={[{ flex: 1, width: "100%" }]}
      contentContainerStyle={[{
        padding: 30,
        paddingTop: 10,
        paddingBottom: 20,
        minHeight: "100%",
      }]}
    >

      <View style={{ flexGrow: 1 }}>
        <SignUpHeading
          style={[{ justifyContent: "center", alignItems: "center" },
            (Platform.OS === "web") ? {}
              : {
                marginBottom: 20,
                marginTop: 20,
              }]}
          title={translate("Create account")}
          subheading={translate("Sign up to get started")}
        />
        <FormField errors={errors?.email}>
          <Controller
            control={control}
            defaultValue=""
            render={({ field: { onChange, onBlur, value } }) => (
              <TextInput
                returnKeyType="next"
                mode="flat"
                keyboardType="email-address"
                autoCapitalize="none"
                label={translate("Email")}
                placeholder={translate("Enter your email")}
                textContentType="emailAddress"
                onBlur={onBlur}
                onChangeText={(ev) => {
                  onChange(ev);
                  props.setEmail(ev);
                }}
                error={errors.email?.message}
                value={value}
              />
            )}
            name="email"
            rules={{
              required: {
                value: true,
                message: translate("Email is mandatory."),
              },
              pattern: {
                value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: translate("Entered value does not match email format"),
              },
            }}
          />
        </FormField>

        <FormField errors={errors?.password}>
          <Controller
            control={control}
            defaultValue=""
            render={({ field: { onChange, onBlur, value } }) => (
              <TextInputPassword
                returnKeyType="next"
                mode="flat"
                label={translate("Password")}
                style={{ marginTop: 6 }}
                onBlur={onBlur}
                onChangeText={(e) => {
                  onChange(e);

                  if (getValues("repeatPassword") !== "") trigger("repeatPassword");
                }}
                value={value}
                textContentType="password"
                placeholder={translate("Enter your password")}
                secureTextEntry
              />
            )}
            name="password"
            rules={{
              required: {
                value: true,
                message: translate("You must specify a password"),
              },
              minLength: {
                value: 8,
                message: translate("Minimum 8 characters required"),
              },
            }}
          />
        </FormField>

        <FormField errors={errors?.repeatPassword}>
          <Controller
            control={control}
            defaultValue=""
            render={({ field: { onChange, onBlur, value } }) => (
              <TextInputPassword
                mode="flat"
                label={translate("Repeat Password")}
                style={{ marginTop: 6 }}
                onBlur={onBlur}
                onChangeText={onChange}
                value={value}
                placeholder={translate("Repeat Password")}
                textContentType="password"
                secureTextEntry
              />
            )}
            name="repeatPassword"
            rules={{
              required: {
                value: true,
                message: translate("Please retype your password"),
              },

              validate: (value) => value === watch("password") || translate("Password did not match"),
            }}
          />
        </FormField>

        <CognitoPasswordChecker style={{ marginBottom: 18, marginTop: 10 }} min={8} password={watch("password")} />

        <AlertBox type="error">{error}</AlertBox>

        <View
          style={{
            marginTop: 10,
            flexGrow: 1,
          }}
        >
          <Button
            compact
            loading={loading}
            disabled={loading}
            mode="contained"
            onPress={onSubmit}
            color={uTheme.colors.primary}
            contentStyle={{ paddingVertical: 8 }}
          >
            {translate("Create account")}
          </Button>
        </View>


      </View>

      <View style={[{
        flexDirection: "row", alignItems: "center", justifyContent: "center", paddingVertical: 15,
      }]}
      >
        <Text style={{ fontSize: 14 }}>
          {translate("Have an account?  ")}
        </Text>
        <TouchableWithoutFeedback onPress={handleGotoSignin}>
          <Text style={{
            color: ColorsEvidera.additionalSecondary,
            fontWeight: "800",
            fontSize: 14,
            ...Platform.OS === "web" && { cursor: "pointer" },
          }}
          >
            {translate("Sign in")}
          </Text>
        </TouchableWithoutFeedback>
      </View>
    </ScrollView>
  );
};

Stage0.propTypes = {
  goToStage: PropTypes.func.isRequired,
  changeState: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  setEmail: PropTypes.func.isRequired,
  pageTrigger: PropTypes.string,
};

Stage0.defaultProps = {};

export default Stage0;
