import React, { useEffect } from "react";
// eslint-disable-next-line no-unused-vars
import { StackNavigationProp } from "@react-navigation/stack";
import { ScrollView, View } from "react-native";
import { Helmet } from "react-helmet";
// eslint-disable-next-line no-unused-vars
import { StackNavigatorParamlist } from "../../navigation/types";
import SmartBanner from "../../components/SmartBanner/SmartBanner";
import LandingLogin from "../landing/landing.login.page";

type Props = {
  // eslint-disable-next-line react/no-unused-prop-types
  // eslint-disable-next-line react/require-default-props
  refreshCurrentUser: () => Promise<void>;
  user: object;
  userStatus: boolean;
  initialRoute: string;
  navigation?: StackNavigationProp;
  setShowMessage: () => {};
  showMessage: string;
};

const Authenticate = (props: Props) => {
  let unmounted = false;
  const getOS = () => {
    const { userAgent } = window.navigator;
    const { platform } = window.navigator;
    const macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"];
    const windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"];
    const iosPlatforms = ["iPhone", "iPad", "iPod"];
    let os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = "Mac OS";
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = "iOS";
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = "Windows";
    } else if (/Android/.test(userAgent)) {
      os = "Android";
    } else if (!os && /Linux/.test(platform)) {
      os = "Linux";
    }

    return os;
  };

  let bannerHidden = false;

  // this is the logic for the app banner
  const appleID = "1504950824";
  let appUrl = "";
  let storeName = "";
  bannerHidden = false;
  if (getOS() === "Android") {
    appUrl = "https://play.google.com/store/apps/details?id=com.ppd.medimix.hcpportal&hl=en";
    storeName = "Google Play";
  } else if (getOS() === "iOS") {
    appUrl = `itms-apps://itunes.apple.com/us/app/id${appleID}`;
    storeName = "App Store";
  } else {
    bannerHidden = true;
  }

  const appInfo = {
    imageUrl: require("../../assets/icon.png"),
    name: "Medimix HCP Portal",
    publisher: "Evidera PPD",
    infoText: `Free - In ${storeName}`,
    linkButtonText: "VIEW",
    linkUrl: appUrl,
  };

  useEffect(() => {
    if (!unmounted) {
      if (props.userStatus === true && props.initialRoute !== "Authenticate") {
        props.navigation.navigate(props.initialRoute);
      }
    }

    return () => {
      unmounted = true;
    };
  }, [props.userStatus, props.user]);


  return (
    <ScrollView>
      <View>
        {!bannerHidden && (
          <View>
            <Helmet>
              <meta name="apple-itunes-app" content="app-id=1504950824" />
              <meta
                name="google-play-app"
                content="app-id=com.ppd.medimix.hcpportal"
              />
              <link rel="apple-touch-icon" href="icon.png" />
              <link rel="android-touch-icon" href="icon.png" />
              <link rel="windows-touch-icon" href="icon.png" />
            </Helmet>
            <SmartBanner
              appInfo={appInfo}
              position="top"
              onClose={() => {
                console.log("onClose called");
              }}
              hidden={false}
              style={{
                zIndex: 10,
              }}
            />
          </View>
        )}
        <LandingLogin
          setShowMessage={props.setShowMessage}
          showMessage={props.showMessage}
          bannerHidden={bannerHidden}
          navigation={props.navigation}
          refreshCurrentUser={props.refreshCurrentUser} />
      </View>
    </ScrollView>
  );
};

export default Authenticate;
