import React, { useEffect } from "react";
import { View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { Button, Title, useTheme } from "react-native-paper";
import Auth from "@aws-amplify/auth";
import { translate } from "../../components/Localei18n/languagesFunctions";

const ReAuth = (props) => {
  const uTheme = useTheme();

  const signout = () => {
    Auth.signOut();
  };
  console.log("authenticator props", props);

  useEffect(() => {
    console.log("reauth");
  }, []);

  return (
    <ScrollView
      style={[{ flex: 1, width: "100%" }]}
      contentContainerStyle={[{
        padding: 30,
        paddingTop: 10,
        paddingBottom: 20,
        minHeight: "100%",
      }]}
      keyboardShouldPersistTaps="never"
    >
      <View style={{ flexGrow: 1, justifyContent: "center" }}>
        <Title style={{ paddingBottom: 20 }}>You were signout. Please signin again</Title>
        <Button
          compact
          mode="contained"
          contentStyle={{ paddingVertical: 8 }}
          onPress={() => signout()}
          color={uTheme.colors.primary}
        >
          {translate("Sign in")}
        </Button>
      </View>

    </ScrollView>
  );
};

export default ReAuth;
