/* eslint-disable camelcase */
import React, { useState, useCallback, useEffect, useContext } from "react";
import { View, KeyboardAvoidingView, Platform, ScrollView, TouchableOpacity, TouchableWithoutFeedback } from "react-native";
import { PickerIOS, Picker } from "@react-native-picker/picker";
import { useForm, Controller } from "react-hook-form";
import { TextInput, useTheme, Title, Subheading, Portal, Text, Button, Snackbar, Switch, ActivityIndicator } from "react-native-paper";
import debounce from "lodash/debounce";
import { API, graphqlOperation } from "aws-amplify";
import gql from "graphql-tag";
import { StackNavigationProp } from "@react-navigation/stack";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { styles } from "./forms.style";
import { ProfileParamlist } from "../types"; // eslint-disable-line no-unused-vars
import { translate } from "../../../components/Localei18n/languagesFunctions";
import FormField from "../../../components/FormValidator/FormField";
import { PickerCustom } from "../../../components/picker.custom";
import { UserInfoContext } from "../../../context/userInfo";

type Props = {
  // eslint-disable-next-line react/require-default-props
  navigation?: StackNavigationProp<ProfileParamlist>;
};

type InputData = {
  contact_id: string;
  panelist_id: number;
  address_line1: string;
  address_line2: string;
  primary_address: number;
  address_center_name: string;
  address_country_code_desc: string;
  address_country_code: string;
  address_city: string;
  address_postal_code: string;
  address_state_or_province: String;
}

const AddContactsAddress = (props: Props) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);

  const { userInfo: { country_code } } = useContext(UserInfoContext);
  const { userInfo: { uic } } = useContext(UserInfoContext);
  console.log(country_code);

  const {
    control, handleSubmit, register, setValue, getValues, trigger, formState: { errors },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [SubmitLoading, setSubmitLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [inputData, setInputData] = useState<InputData>(props.route.params); // eslint-disable-line react/prop-types

  const [Options, setOptions] = useState({ countries: [] });
  const [selectOpen, setSelectOpen] = useState(false);

  const onSubmit = useCallback(debounce(async (data: object) => {
    try {
      const mutation = gql`
      mutation insert($formValue: FormContactsAddressInput, $panelist_id: Int!, $status: Int, $uic: String){
        insertIgnoreFormsContacts(panelist_id: $panelist_id)
        insertIgnoreForsmContactsAddress(panelist_id: $panelist_id)
        updateVerificationStatus(status: $status, panelist_id: $panelist_id, uic: $uic)
        addFormAddress(formValue: $formValue)
      }`;

      const response = await API.graphql(graphqlOperation(mutation, {
        formValue: { ...data, primary_address: data.primary_address === true ? 1 : 0 },
        panelist_id: inputData.panelist_id,
        status: 2,
        uic,
      }));

      if (response.data.addFormAddress === true) {
        props.navigation.goBack();
      } else {
        setSnackbarVisible(true);
        setMessage(translate("Failed to save address."));

        setSubmitLoading(false);
      }
    } catch (err) {
      setSubmitLoading(false);
      console.log(["failed to save address", err, data]);

      if (err.errors[0]?.message?.includes("Duplicate entry")) {
        setMessage(translate("The address you entered already exist in your account"));
      } else {
        setMessage(translate("Failed to add address."));
      }
      setSnackbarVisible(true);
    }
  },
  1000, { leading: false }), []);

  const getOptions = () => new Promise((resolve, reject) => {
    const qry = gql`
    {
      getCountries {
        country_code country_name
      }
    }
    `;

    try {
      const response: any = API.graphql(graphqlOperation(qry, {}));
      resolve(response);
    } catch (err) {
      reject(err);
    }
  });

  const triggerSave = async () => {
    await trigger();
    if (Object.keys(errors).length === 0) {
      setSubmitLoading(true);
      onSubmit(getValues());
      // Analytics.logEvent("AddressUpdated");
    }
  };

  useEffect(() => {
    let unmount = false;
    register("panelist_id", { value: inputData.panelist_id });
    register("contact_id", { value: inputData.contact_id });
    register("region_code", { value: "" });
    register("address_type", { value: 0 });
    setLoading(true);
    getOptions().then((response) => {
      if (unmount) return;
      const newOptions = Options;
      newOptions.countries = response.data.getCountries;

      setOptions(newOptions);
      setLoading(false);
    }).catch((e) => {
      setLoading(false);
      console.log(["error fetching list numbertype", e]);
      throw e;
    });

    return () => {
      unmount = true;
    };
  }, []);

  if (loading) {
    return (
      <View style={{ paddingVertical: 20, flex: 1, alignItems: "center" }}>
        <ActivityIndicator size={40} />
      </View>
    );
  }

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={[
        styles.root,
        { backgroundColor: theme.colors.background },
      ]}
    >
      <ScrollView style={styles.scrollWrapper}>
        <View style={[styles.scrollInnerResponsive, { flex: 1 }]}>
          <View style={styles.titleWRapper}>
            <Title style={styles.title}>{translate("New Address")}</Title>
            <Subheading style={styles.subheadingRegular}>
              {translate("Any changes to address is subject for verification.")}
            </Subheading>
          </View>

          <FormField
            bottomSpace
            hint={translate("Street Address, Building Name")}
            errors={errors?.address_line1}
          >
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  returnKeyType="next"
                  mode="outlined"
                  style={[styles.formInput, { textAlign: "left" }]}
                  onBlur={onBlur}
                  onChangeText={(ev) => {
                    onChange(ev);
                  }}
                  value={value}
                  label={`${translate("Address Line 1")} *`}
                />
              )}
              name="address_line1"
              defaultValue=""
              rules={{
                required: {
                  value: true, message: translate("Please enter your address"),
                },
              }}
            />
          </FormField>

          <FormField
            bottomSpace
            hint={translate("Department name or unit/space #")}
          >
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  returnKeyType="next"
                  mode="outlined"
                  style={[styles.formInput, { textAlign: "left" }]}
                  onBlur={onBlur}
                  onChangeText={(ev) => {
                    onChange(ev);
                  }}
                  value={value}
                  placeholder=""
                  label={translate("Address Line 2 (OPTIONAL)")}
                />
              )}
              name="address_line2"
              defaultValue=""
            />
          </FormField>

          <FormField bottomSpace>
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  returnKeyType="next"
                  mode="outlined"
                  style={[styles.formInput, { textAlign: "left" }]}
                  onBlur={onBlur}
                  onChangeText={(ev) => {
                    onChange(ev);
                  }}
                  value={value}
                  placeholder=""
                  label={translate("Hospital / Health Center Name")}
                />
              )}
              name="address_center_name"
              defaultValue=""
            />
          </FormField>

          <FormField errors={errors?.address_country_code}>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <PickerCustom
                  style={{ flex: 1 }}
                  value={value}
                  mode="outlined"
                  required
                  onChange={(itemValue) => {
                    onChange(itemValue);
                  }}
                  label={translate("Country")}
                >
                  <Picker.Item label={translate("Select Country")} value="" />
                  {Options.countries && Options.countries.map((e, index) => (
                    <Picker.Item
                      key={index.toString()}
                      label={translate(e.country_name)}
                      value={e.country_code}
                    />
                  ))}
                </PickerCustom>
              )}
              name="address_country_code"
              defaultValue={country_code}
              rules={{
                required: {
                  value: true, message: translate("Please select country"),
                },
              }}
            />
          </FormField>

          <FormField errors={errors?.address_state_or_province}>
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  multiline
                  returnKeyType="next"
                  mode="outlined"
                  style={[styles.formInput, { textAlign: "left" }]}
                  onBlur={onBlur}
                  onChangeText={(ev) => {
                    onChange(ev);
                  }}
                  value={value}
                  placeholder=""
                  label={`${translate("State/Province")} *`}
                />
              )}
              name="address_state_or_province"
              defaultValue=""
              rules={{
                required: {
                  value: true, message: translate("Please select state or province"),
                },
              }}
            />
          </FormField>

          <FormField errors={errors?.address_city}>
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  returnKeyType="next"
                  mode="outlined"
                  style={[styles.formInput, { textAlign: "left" }]}
                  onBlur={onBlur}
                  onChangeText={(ev) => {
                    onChange(ev);
                  }}
                  placeholder={translate("e.g. New York")}
                  value={value}
                  label={`${translate("City")} *`}
                />
              )}
              name="address_city"
              defaultValue=""
              rules={{
                required: {
                  value: true, message: translate("Please enter city"),
                },
              }}
            />
          </FormField>

          <FormField errors={errors?.address_postal_code} style={{ marginBottom: 20 }}>
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  returnKeyType="next"
                  mode="outlined"
                  style={[styles.formInput, { textAlign: "left" }]}
                  onBlur={onBlur}
                  onChangeText={(ev) => {
                    onChange(ev);
                  }}
                  value={value}
                  placeholder="e.g. 10001"
                  label={`${translate("Postal Code")} *`}
                />
              )}
              name="address_postal_code"
              defaultValue=""
              rules={{
                required: {
                  value: true, message: translate("Please enter postal code"),
                },
              }}
            />
          </FormField>

          <View style={styles.formField}>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <Controller
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    {inputData.primary_address
                      ? (
                        <View style={{ flexDirection: "row", alignItems: "center" }}>
                          <MaterialCommunityIcons name="check-bold" size={24} color={theme.colors.primary} />
                          <Text style={{ fontSize: 18, fontWeight: "700", color: theme.colors.primary }}>
                            {translate("Primary Address")}
                          </Text>
                        </View>
                      )
                      : (
                        <>
                          <Switch
                            color={theme.colors.primary}
                            value={value}
                            onValueChange={() => {
                              onChange(!value);
                            }}
                          />

                          <TouchableWithoutFeedback onPress={() => {
                            onChange(!value);
                          }}
                          >

                            <Text style={{ marginLeft: 12, fontSize: 17, fontWeight: "500" }}>{translate("Set as Primary")}</Text>

                          </TouchableWithoutFeedback>
                        </>
                      )}
                  </>

                )}
                name="primary_address"
                defaultValue={false}
              />

            </View>
          </View>


          <View style={styles.formActionField}>
            <Button onPress={() => props.navigation.goBack()} contentStyle={[styles.button]} style={{ marginRight: 16 }} color="grey">
              {translate("Cancel")}
            </Button>
            <Button loading={SubmitLoading} onPress={triggerSave} contentStyle={[styles.button]} dark mode="contained">
              {translate("Save")}
            </Button>
          </View>

        </View>
        <Portal>
          {Platform.OS === "ios" && selectOpen
            && (
              <View style={{ flex: 1, backgroundColor: "rgba(0,0,0,0.31)", justifyContent: "flex-end" }}>
                <View style={styles.pickerWrapper}>
                  <View style={styles.pickerLabelWrapper}>
                    <View style={{ flex: 1 }}>
                      <Text style={styles.pickerLabel}>{translate("Select")}</Text>
                    </View>
                    <TouchableOpacity style={styles.pickerActionClose} onPress={() => setSelectOpen(!selectOpen)}>
                      <Text style={styles.pickerActionText}>{translate("Done")}</Text>
                    </TouchableOpacity>
                  </View>
                  <View style={{ height: 200 }}>

                    <PickerIOS
                      selectedValue={inputData.address_country_code}
                      style={{ width: "100%" }}
                      onValueChange={(itemValue) => {
                        const selected = Options.countries.filter((e) => e.country_code === itemValue);
                        setInputData({ ...inputData, address_country_code: itemValue, address_country_code_desc: selected.length >= 1 ? selected[0].country_name : "" });
                        setValue("address_country_code", itemValue);
                      }}
                    >
                      <Picker.Item
                        label="N/A"
                        value="0"
                      />
                      {Options.countries && Options.countries.map((e, index) => <Picker.Item key={index.toString()} label={translate(e.country_name)} value={e.country_code} />)}
                    </PickerIOS>
                  </View>
                </View>
              </View>
            )}
        </Portal>
      </ScrollView>
      <Snackbar
        visible={snackbarVisible}
        onDismiss={() => setSnackbarVisible(false)}
        duration={5000}
        action={{
          label: "×",
          onPress: () => {
            setSnackbarVisible(false);
          },
        }}
        style={{
          maxWidth: 500,
          width: "100%",
          padding: 10,
        }}
        wrapperStyle={{ alignItems: "center" }}
      >
        {message}
      </Snackbar>
    </KeyboardAvoidingView>
  );
};

export default AddContactsAddress;
