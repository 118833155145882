/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
import React, { useState, useEffect, useRef, useContext } from "react";
import { View, TouchableWithoutFeedback, Keyboard, Platform } from "react-native";
import { ActivityIndicator, DefaultTheme, Text } from "react-native-paper";
import PropTypes from "prop-types";
import { I18n } from "@aws-amplify/core";
import { HCPPortalTheme } from "../../components";
import { Stage0 } from "./PersonalInfo/Stage0";
import { Stage1 } from "./PersonalInfo/Stage1";
import { Stage2 } from "./PersonalInfo/Stage2";
import { Stage3 } from "./PersonalInfo/Stage3";
import { ReferenceDataContext } from "./PersonalInfo/ReferenceDataContext";
import UserRegistrationService from "../../helpers/user.registration.service";
import { AuthContext } from "./AuthContext";

// const logger = new Logger("PersonalInfo");

// const ViewPlatform = (innerProps) => (
//   <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
//     {innerProps.children}
//   </TouchableWithoutFeedback>
// );

type Props = {
  updateUserAttributes: (user: any, attributes: any) => Promise<void>;
  verifyCurrentUserAttributeSubmit: (attribute: any, code: any) => Promise<void>;
  user: object;
  initialRoute?: string;
  handleSignout: () => Promise<any>;
  userStatus: boolean;
  navigation?: StackNavigationProp;
  startStage: number;
};

export const PersonalInfo = (props: Props) => {
  const {
    stage2DataCollection,
  } = UserRegistrationService();
  const {
    stage, setStage, personalInfoData, setPersonalInfoData,
  } = useContext(ReferenceDataContext);
  const { AuthLanguage } = useContext(AuthContext);
  // if (theme === "dark") setTheme("light");
  const [loading, setLoading] = useState(true);
  let unmounted = false;

  const goTo = async () => {
    if (unmounted) return;
    if (stage === 0) {
      setStage(1);
    } else if (stage === 1) {
      setStage(2);
    } else if (stage === 2) {
      setStage(3);
    }
  };

  const backTo = () => {
    setStage(stage - 1);
  };

  const onSignout = () => {
    props.handleSignout().then((e) => {
      setPersonalInfoData({});
      if (Platform.OS === "web" && e) {
        props.navigation.navigate("Authenticate");
      }
    });
  };

  useEffect(() => {
    if (props.user) {
      if (props.user.attributes?.["custom:contact_id"] && props.startStage === 2) {
        stage2DataCollection(props.user.attributes?.["custom:contact_id"])
          .then((response) => {
            setPersonalInfoData({ ...personalInfoData, ...response });
            setLoading(false);
          });
      } else if (props.user.attributes?.["custom:contact_id"] && props.startStage === 0) {
        setLoading(false);
      }

      if (Platform.OS === "web" && props.userStatus === true && props.initialRoute !== "PersonalInfo" && !unmounted) {
        props.navigation?.navigate(props.initialRoute);
      }
    } else {
      props.navigation?.navigate("Authenticate");
    }
    return () => {
      unmounted = true;
    };
  }, [props.user, AuthLanguage]);


  if (loading) {
    return (
      <View style={{ flex: 1, justifyContent: "center" }}>
        <ActivityIndicator size={40} />
      </View>
    );
  }

  return (
    <View style={{ flex: 1, backgroundColor: "rgb(246, 246, 246)" }}>

      {stage === 0 && (
      <Stage0
        goTo={goTo}
        user={props.user}
        userStatus={props.userStatus}
        handleSignout={onSignout}
        updateUserAttributes={props.updateUserAttributes}
        navigation={props.navigation}
      />
      )}
      {stage === 1 && (
      <Stage1
        goTo={goTo}
        backTo={() => backTo()}
        user={props.user}
        userStatus={props.userStatus}
      />
      )}
      {stage === 2 && (
      <Stage2
        startStage={props.startStage}
        goTo={goTo}
        backTo={() => backTo()}
        handleSignout={onSignout}
        user={props.user}
        userStatus={props.userStatus}
        updateUserAttributes={props.updateUserAttributes}
        verifyCurrentUserAttributeSubmit={
              props.verifyCurrentUserAttributeSubmit
            }
      />
      )}
      {stage === 3 && (
      <Stage3
        backTo={() => backTo()}
        user={props.user}
        updateUserAttributes={props.updateUserAttributes}
      />
      )}

    </View>
  );
};

export default PersonalInfo;
