/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from "react";
import { View, KeyboardAvoidingView, Platform, ScrollView } from "react-native";
import { useTheme, Title, Subheading, TextInput, Button, Text } from "react-native-paper";
import { Controller, useForm } from "react-hook-form";
// eslint-disable-next-line no-unused-vars
import { StackNavigationProp } from "@react-navigation/stack";
import { Picker } from "@react-native-picker/picker";
import { API, graphqlOperation } from "aws-amplify";
import gql from "graphql-tag";
import ContentLoader from "react-native-easy-content-loader";
import Auth from "@aws-amplify/auth";
import axios from "axios";
import { styles } from "../../profile/profile.contacts/forms.style";
import { translate } from "../../../components/Localei18n/languagesFunctions";
// eslint-disable-next-line no-unused-vars
import { StackNavigatorParamlist } from "../../../navigation/types";
import FormField from "../../../components/FormValidator/FormField";
import { PickerCustom } from "../../../components/picker.custom";
import { UserInfoContext } from "../../../context/userInfo";
import ExpoReleaseEnvironments from "../../../ExpoReleaseEnvironments";


type Props = {
  // eslint-disable-next-line react/no-unused-prop-types
  navigation?: StackNavigationProp<StackNavigatorParamlist>; // eslint-disable-line react/require-default-props
  // setSnackbar: () => void;
  route: { params: any};
};

const SendMessage = (props: Props) => {
  const { setSnackbar } = props.route.params;
  const theme = useTheme();
  let unmounted = false;
  const [submitLoading, setSubmitLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const {
    userInfo: {
      email, first_name, last_name, uic, panelist_id,
    },
  } = useContext(UserInfoContext);
  const [reasons, setReasons] = useState([]);
  const [contactCenterEmail, setContactCenterEmail] = useState({});

  const {
    control,
    formState: { errors },
    getValues,
    trigger,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const getSupportSendEmailInfo = async () => {
    setLoading(true);

    const qry = gql`
        query ($language: String!) {
          getSupportContactCenterEmail(language: $language) {
            from_name
            email
          }
          getSupportSendEmailReasons {
            desc
          }
        }
      `;

    const response: any = await API.graphql(graphqlOperation(qry, {
      // eslint-disable-next-line react/prop-types
      language: props.route.params.lang,
    }));

    if (unmounted) return;

    setContactCenterEmail(response.data.getSupportContactCenterEmail);
    setReasons(response.data.getSupportSendEmailReasons);
    setLoading(false);
  };

  const sendEmail = async () => {
    const data = getValues();
    await trigger();

    const token = (await Auth.currentSession()).getAccessToken().getJwtToken();
    if (Object.keys(errors).length === 0) {
      const formattedMessage = `Panelist: ${first_name} ${last_name}(${email})<br/><br/> ${data?.message}`;

      setSubmitLoading(true);

      axios.post(`${ExpoReleaseEnvironments().backofficeUrl}api/hcp/contactus/sendemail`,
        {
          // email: "sample@gmail.com", // <-- this is for test
          // name: "Sample", // <-- this is for test
          email: contactCenterEmail?.email, // <-- uncomment for prod
          name: contactCenterEmail?.from_name, // <--  uncomment for prod
          fromAddress: "surveyen@medimix.net",
          fromName: "Panel Management",
          subject: `Offline Message from ${uic}/${panelist_id} - ${data.reason}`,
          replyTo: email,
          message: formattedMessage,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((response) => {
        if (response.status === 200) {
          if (setSnackbar) setSnackbar(true, translate("Your email was sent."));
          props.navigation.goBack();
        }

        setSubmitLoading(false);
      }).catch((error) => {
        console.log("error: ", error);
        setSubmitLoading(false);
      });
    }
  };

  useEffect(() => {
    getSupportSendEmailInfo();
    return () => { unmounted = true; };
  }, []);

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={[
        styles.root,
        { backgroundColor: theme.colors.background },
      ]}
    >
      <ScrollView style={styles.scrollWrapper}>
        {
          !loading ? (

            <View style={[styles.scrollInnerResponsive]}>
              <View style={styles.titleWRapper}>
                <Title style={styles.title}>{translate("Send us a message")}</Title>
                <Subheading style={styles.subheadingRegular}>
                  <Text style={{ fontWeight: "600" }}>
                    {props.route.params.noAgentOnQueue && `${translate("We're sorry, but no agent available for now.")}${" "}`}
                    {props.route.params.liveChatError && `${translate("We're sorry, but Live Chat encountered problem.")}${" "}`}
                  </Text>
                  {translate("Please leave us a message we’ll come back to you shortly.")}
                </Subheading>
              </View>
              {/* insert here */}

              <FormField errors={errors?.reason} style={{ marginTop: 15 }}>
                <Controller
                  control={control}
                  render={({ field: { onChange, value } }) =>
                  // const newValue = categoryList.find(
                  //   (e) => e.target_code === Number(value)
                  // );
                    (
                      <PickerCustom
                        style={{ flex: 1 }}
                      // textCaption={
                      //   newValue?.target_name
                      //     ? translate(newValue?.target_name)
                      //     : ""
                      // }
                        value={value}
                        mode="outlined"
                        required
                        onChange={(itemValue) => {
                          onChange(itemValue);
                        }}
                        label={translate("Reason")}
                        display="default"
                      >
                        <Picker.Item label={translate("Please specify your reason")} value="" />
                        {reasons
                        && reasons.map((e, index) => (
                          <Picker.Item
                            key={index.toString()}
                            label={translate(e.desc)}
                            value={e.desc.toString()}
                          />
                        ))}
                      </PickerCustom>
                    )}
                  name="reason"
                  defaultValue=""
                  rules={{
                    required: {
                      value: true,
                      message: translate("Please specify a reason."),
                    },
                  }}
                />
              </FormField>

              <FormField errors={errors?.message} style={{ marginTop: 8 }}>
                <Controller
                  control={control}
                  render={({ field: { onChange, value } }) =>
                  // const newValue = categoryList.find(
                  //   (e) => e.target_code === Number(value)
                  // );
                    (
                      <TextInput
                        returnKeyType="next"
                        mode="outlined"
                        onChangeText={(ev) => {
                          onChange(ev);
                        }}
                        value={value}
                        label={translate("Message")}
                        multiline
                        numberOfLines={5}
                      />
                    )}
                  name="message"
                  defaultValue=""
                  rules={{
                    required: {
                      value: true,
                      message: translate("Please state your message."),
                    },
                  }}
                />
              </FormField>

              <View style={{ flexDirection: "row", paddingTop: 25 }}>
                <Button
                  loading={submitLoading}
                  disabled={submitLoading}
                  style={{ flex: 1 }}
                  onPress={() => sendEmail()}
                  mode="contained"
                  contentStyle={{ height: 50 }}
                  icon="send"
                >
                  {translate("Send")}
                </Button>
              </View>
            </View>

          ) : (
            <View style={{ paddingTop: 30, paddingHorizontal: 25 }}>
              <ContentLoader
                animationDuration={500}
                active
                pRows={2}
                listSize={1}
                tHeight={20}
                pHeight={12}
                pWidth={["100%", 200]}
                paragraphStyles={{ marginTop: 8 }}
                titleStyles={{ marginBottom: 18 }}

              />
            </View>
          )
        }

      </ScrollView>

    </KeyboardAvoidingView>
  );
};

export default SendMessage;
