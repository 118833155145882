import React, { useContext, useEffect } from "react";
import color from "color";
import { createMaterialBottomTabNavigator } from "@react-navigation/material-bottom-tabs";
import { useTheme } from "react-native-paper";

import { MaterialCommunityIcons } from "@expo/vector-icons";

import overlay from "../helpers/overlay";
import { Support } from "../screens/support";
import Account from "../screens/account/account";
// import Activity from "../screens/activity/activity";
import { ProfileCategory } from "../screens/profile/profileCategory/profile.category";
import { NotificationsStack } from "../screens/notifications/notifications";
import { UserInfoContext } from "../context/userInfo";
import { translate } from "../components/Localei18n/languagesFunctions";

const Tab = createMaterialBottomTabNavigator();

// type Props = {
//   route: RouteProp<StackNavigatorParamlist, "FeedList">;
// };

export const BottomTabs = () => {
  // const routeName = props.route.state
  //   ? props.route.state.routes[props.route.state.index].name
  //   : "Feed";
  const { language } = useContext(UserInfoContext);

  const theme = useTheme();

  const tabBarColor = theme.dark
    ? (overlay(6, theme.colors.surface) as string)
    : theme.colors.surface;

  useEffect(() => {}, [language]);
  return (
    <>

      <Tab.Navigator
        initialRouteName="Notifications"
        backBehavior="initialRoute"
        shifting={false}
        activeColor={theme.colors.primary}
        inactiveColor={color(theme.colors.text)
          .alpha(0.6)
          .rgb()
          .string()}
        sceneAnimationEnabled={false}
        barStyle={{ backgroundColor: tabBarColor }}
      >
        {/* <Tab.Screen
        name="Home"
        component={Activity}
        options={{
          tabBarIcon: (tabProps) => (
            <Entypo name="home" color={tabProps.color} size={24} />
          ),
          tabBarColor,

        }}
      /> */}
        <Tab.Screen
          name="Notifications"
          component={NotificationsStack}
          options={{
            tabBarLabel: translate("Notifications"),
            tabBarIcon: (tabProps) => (
              <MaterialCommunityIcons name="bell" color={tabProps.color} size={24} />
            ),
            tabBarColor,
          }}
        />
        <Tab.Screen
          name="Profile"
          component={ProfileCategory}
          options={{

            tabBarLabel: translate("Profile"),
            tabBarIcon: (tabProps) => ( // eslint-disable-line react/prop-types
              <MaterialCommunityIcons
                name="account"
                color={tabProps.color}
                size={24}
              />
            ),
            tabBarColor,
          }}
        />
        <Tab.Screen
          name="Account"
          component={Account}
          options={{
            tabBarLabel: translate("Account"),
            tabBarIcon: (tabProps) => (
              <MaterialCommunityIcons name="bank" color={tabProps.color} size={24} />
            ),
            tabBarColor,
          }}
        />
        <Tab.Screen
          name="Support"
          component={Support}
          options={{
            tabBarLabel: translate("Support"),
            tabBarIcon: (tabProps) => (
              <MaterialCommunityIcons name="chat" color={tabProps.color} size={24} />
            ),
            tabBarColor,
          }}
        />
      </Tab.Navigator>
    </>
  );
};
