import React from "react";

type PreferencesContextType = {
  theme: "light" | "dark";
  rtl?: "left" | "right";
  keepLogin: boolean;
  toggleTheme: () => void;
  toggleRTL?: () => void;
  toggleKeepLogin: React.Dispatch<React.SetStateAction<boolean>>;
};

export const PreferencesContext = React.createContext<PreferencesContextType>({
  rtl: "left",
  theme: "light",
  keepLogin: false,
  toggleTheme: () => {},
  toggleRTL: () => {},
  toggleKeepLogin: () => {},
});
