import React, { useState, useEffect, useContext } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  ScrollView,
} from "react-native";
import PropTypes from "prop-types";
import { propStyle } from "aws-amplify-react";
import { styles } from "./landing.style";
import { AuthViewCustom, Authenticator } from "../Auth";
import useAmplifyAuth from "../../helpers/useAmplifyAuth";
import LanguageSessionSelector from "../../components/languageSessionSelector";
import AuthContextProvider, { AuthContext } from "../Auth/AuthContext";
import { translate } from "../../components/Localei18n/languagesFunctions";

const LandingLogin = ({ bannerHidden, navigation, refreshCurrentUser, showMessage, setShowMessage }) => {
  let unmounted = false;
  const [cardStyle, setCardStyle] = useState(styles.card);
  const [phoneVisible, setPhoneVisible] = useState(true);
  const { AuthLanguage } = useContext(AuthContext);
  const { handleSignout } = useAmplifyAuth();

  const onResize = () => {
    if (Dimensions.get("window").width < 850) {
      if (!unmounted) setCardStyle(styles.cardFull);
      if (!unmounted) setPhoneVisible(false);
    } else {
      if (!unmounted) setCardStyle(styles.card);
      if (!unmounted) setPhoneVisible(true);
    }
  };

  useEffect(() => {
    Dimensions.addEventListener("change", onResize);
    onResize();
    return () => {
      unmounted = true;
      Dimensions.removeEventListener("change", onResize);
    };
  }, [AuthLanguage]);

  return (
    /* Page */
    <ScrollView>
      <View style={[styles.pageGray, Dimensions.get("window").width < 850 ? { backgroundColor: "white" } : {}]}>
        {/* Container */}
        <View style={[styles.container, Dimensions.get("window").width < 850 ? { borderColor: "white" } : {}]}>
          {/* Section 1 */}
          <View style={[styles.section, Dimensions.get("window").width < 850 ? { width: "100%" } : { marginTop: 50 }]}>
            {/* Card 1 */}
            {(phoneVisible && (
              <View style={cardStyle}>
                <Image
                  source={require("../../assets/phone_gif.gif")}
                  resizeMode="cover"
                  style={{
                    alignSelf: "flex-end",
                    width: 400,
                    height: 600,
                  }}
                />
              </View>
            ))}
            {/* Card 2 */}
            <View style={cardStyle}>
              <AuthViewCustom>
                <Authenticator
                  showMessage={showMessage}
                  setShowMessage={setShowMessage}
                  handleSignout={handleSignout}
                  bannerHidden={bannerHidden}
                  navigation={navigation}
                  refreshCurrentUser={refreshCurrentUser} />

              </AuthViewCustom>
            </View>
          </View>
          {/* Footer */}
          <View style={[styles.hero, { paddingBottom: 0 }]}>
            <Text style={[styles.textFooter, { color: "black" }]}>
              {translate("Copyright ©")}
              {" "}
              {(new Date().getFullYear())}
              {" "}
              {translate("Medimix International. All rights reserved.")}
            </Text>
          </View>
          <View style={{ paddingBottom: 20 }}>
            <LanguageSessionSelector />
          </View>
        </View>
      </View>
    </ScrollView>
  );
};

LandingLogin.propTypes = {
  bannerHidden: PropTypes.bool.isRequired,
  showMessage: PropTypes.string,
  setShowMessage: PropTypes.func,
};

LandingLogin.defaultProps = {};

export default LandingLogin;
