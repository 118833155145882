/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */

/* ---------------------------------
      enter verification code
      email,password,repeat password
--------------------------------- */
import React, { useState, useContext, useEffect } from "react";
import {
  View,
  TouchableHighlight,
  TouchableOpacity,
  Text,
  StyleSheet,
  Platform,
} from "react-native";
import PropTypes from "prop-types";
import Auth from "@aws-amplify/auth";
import { Controller, useForm } from "react-hook-form";
import { TextInput, Button, useTheme, IconButton } from "react-native-paper";
import { ScrollView } from "react-native-gesture-handler";
import { HCPPortalTheme } from "../../../components";
import { ReferenceDataContext } from "./ReferenceDataContext";
import { translate } from "../../../components/Localei18n/languagesFunctions";
import FormField from "../../../components/FormValidator/FormField";
import AuthMessage from "../AuthMessage";
import AlertBox from "../../../components/Alert";
import { AuthContext } from "../AuthContext";
import SignUpHeading from "./SignUpHeading";

const styles = StyleSheet.create({
  input: {
    height: 37, width: "100%", backgroundColor: "rgb(239, 239, 239)", padding: 5, borderColor: "rgb(220, 218, 218)", borderRadius: 3,
  },
  title: {
    textAlign: "center",
    // color: "rgb(130, 130, 130)",
    color: "#4e4e4e",
    fontSize: 16,
  },
  button: {
    backgroundColor: "rgb(26, 158, 168)",
    height: 40,
    color: "white",
    borderRadius: 3,
    padding: 10,
  },
});

type Props = {
  goToStage: (stage) => void;
  changeState: ($nextStage, data) => void;
  refreshCurrentUser: () => void;
}

export const Stage1 = (props: Props) => {
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [confirming, setConfirming] = useState(false);
  const [resendStatus, setResendStatus] = useState({ loading: false, message: null });

  const { authenticatorData, setAuthenticatorData } = useContext(
    ReferenceDataContext
  );

  const [btnLabel, setBtnLabel] = useState("Verify");

  const theme = useTheme();

  const {
    control,
    trigger,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const isEmpty = (obj) => {
    if (obj === void 0) {
      obj = {};
    }
    return Object.keys(obj).length === 0;
  };

  const confirmSignUp = async () => {
    setError("");
    await trigger();

    if (Object.keys(errors).length <= 0) {
      setConfirming(true);
      try {
        const result = await Auth.confirmSignUp(authenticatorData.email.toLowerCase(), code);

        if (result === "SUCCESS") {
          console.log("confirmSignUp: SUCCESS");
        }

        if (authenticatorData.password) {
          setBtnLabel("Signing in");
          await Auth.signIn(authenticatorData.email.toLowerCase(), authenticatorData.password)
            .then(async (user) => {
              const requireMFA = user.Session !== null;
              if (user.challengeName === "SMS_MFA") {
                props.changeState("confirmSignIn", user);
              } else if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
                props.changeState("requireNewPassword", user);
              } else {
                await Auth.verifiedContact(user).then(async (data) => {
                  if (!isEmpty(data.verified)) {
                    props.refreshCurrentUser();
                    props.changeState("signedIn", user);
                    console.log("signedIn");
                  }
                  const nUser = Object.assign(user, data);
                  props.changeState("verifyContact", nUser);
                  console.log("verifyContact");
                  props.refreshCurrentUser();
                });
              }
            })
            .catch((err) => {
              if (err.code === "PasswordResetRequiredException") {
                console.log("the user requires a new password");
                props.changeState("forgotPassword", authenticatorData.email);
              } else {
                setError(err);
              }
            });
        } else {
          props.changeState("signIn");
          setConfirming(false);
        }
      } catch (er) {
        setConfirming(false);
        if (typeof er === "object") {
          setError(translate(er?.message));
        } else {
          setError(translate(`Error Confirming Sign up: ${er}`));
        }
      }
    }
  };

  const showResendStatus = () => {
    setResendStatus({
      loading: false, message: translate("A new verification code was sent to you email ") + authenticatorData.email,
    });
    setTimeout(() => {
      setResendStatus({
        loading: false, message: null,
      });
    }, 7000);
  };

  const resendEmailCode = async () => {
    setError("");
    try {
      setResendStatus({
        loading: true, message: null,
      });

      await Auth.resendSignUp(`${authenticatorData.email.toLowerCase()}`);
      showResendStatus();
    } catch (er) {
      console.log(er);
      setError(AuthMessage(er.code, er.message));
    }
  };

  const goToForm = () => {
    props.goToStage(0);
    setError("");
  };

  useEffect(() => {
    if (props.message) setError(AuthMessage(props.message.code, props.message.message));
  }, [props.message]);

  return (
    <>
      <ScrollView
        keyboardShouldPersistTaps="never"
        style={[{ flex: 1, width: "100%" }]}
        contentContainerStyle={[{
          padding: 30,
          paddingTop: 10,
          paddingBottom: 20,
          minHeight: "100%",
        }]}
      >

        <View style={{
          height: "35%",
        }}
        >
          <View style={{
            position: "relative",
            marginLeft: -20,
            zIndex: 1000,
          }}
          >
            <IconButton
              icon="arrow-left"
              color={theme.colors.primary}
              size={30}
              onPress={goToForm}
              style={{ }}
            />

          </View>

          <SignUpHeading
            style={{ flex: 1, justifyContent: "center" }}
            title={translate("Verify Email")}
            subheading={`${translate("Please enter the verification code that was sent to your email:")} ${authenticatorData.email}`}
          />

        </View>


        <FormField errors={errors?.code}>
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextInput
                placeholder={translate("Enter Verification Code")}
                onChangeText={(val) => {
                  onChange(val);
                  setCode(val);
                }}
                value={value}
                style={{
                  textAlign: "center",
                }}
              />
            )}
            name="code"
            defaultValue={code || ""}
            rules={{
              required: {
                value: true,
                message: translate("Verification code required."),
              },
            }}
          />
        </FormField>

        <Button
          mode="contained"
          color={theme.colors.primary}
          style={{ marginTop: 8, marginBottom: 20 }}
          onPress={() => confirmSignUp()}
          contentStyle={{ paddingVertical: 8 }}
          loading={confirming}
          disabled={confirming}
        >
          {translate(btnLabel)}
        </Button>

        <View style={[{ marginTop: 16 }]}>

          <Button
            mode="text"
            onPress={resendEmailCode}
            contentStyle={{ paddingVertical: 4 }}
            loading={resendStatus.loading}
          >
            {translate("Resend code")}
          </Button>
          {/*
          <View style={[HCPPortalTheme.sectionFooter, { padding: 0, marginTop: 0, marginBottom: 0 }]}>
           <View style={HCPPortalTheme.cell}>
              <TouchableHighlight onPress={goToForm}>
                <Text style={[HCPPortalTheme.sectionFooterLink, { backgroundColor: "white" }]}>
                  {translate("Back to sign up form")}
                </Text>
              </TouchableHighlight>
            </View>
            <View style={HCPPortalTheme.cell}>
              <TouchableHighlight onPress={resendEmailCode}>
                <Text style={[HCPPortalTheme.sectionFooterLink, { backgroundColor: "white" }]}>
                  {translate("Resend code")}
                </Text>
              </TouchableHighlight>
            </View>
          </View> */}
        </View>

        <View style={[error && { marginTop: 25 }]}>
          <AlertBox type="error">{error}</AlertBox>


          <AlertBox style={{ marginTop: 20 }} type="information">{resendStatus.message}</AlertBox>

          {/* <Text style={{ color: "red", textAlign: "center" }} /> */}
        </View>
      </ScrollView>
    </>
  );
};


export default Stage1;
