import React, { useState, useContext, useEffect } from "react";
import { StyleSheet, View, ScrollView } from "react-native";
import {
  useTheme,
  Text,
} from "react-native-paper";
import color from "color";

import { API, graphqlOperation } from "aws-amplify";
import gql from "graphql-tag";
import { FontAwesome5 } from "@expo/vector-icons";
import { StackNavigationProp } from "@react-navigation/stack"; // eslint-disable-line no-unused-vars
// import FormField from "../../helpers/FormField";
import ContentLoader from "react-native-easy-content-loader";
import { StackNavigatorParamlist } from "../../../navigation/types"; // eslint-disable-line no-unused-vars

import { CardIT, CardITDetails } from "../../../components/details-icon-text-card";
import { UserInfoContext } from "../../../context/userInfo";
import { HCP } from "../types";
import { translate } from "../../../components/Localei18n/languagesFunctions";

type Props = {
  // eslint-disable-next-line react/require-default-props
  navigation?: StackNavigationProp<StackNavigatorParamlist>;
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  messageWarning: {
    backgroundColor: "orange",
    paddingVertical: 10,
    paddingHorizontal: 15,
  },
});

export const ProfilePayment = (props: Props) => {
  // const ma: any = useContext(UserContext);
  const { managedUserInfo, isProductionCheck } = useContext(UserInfoContext);
  const [IsAmazon, setIsAmazon] = useState(false);
  const theme = useTheme();

  const [loading, setLoading] = useState(true);

  const [data, setData] = useState<any>({});

  const mutedColor = color(theme.colors.text)
    .alpha(0.67)
    .rgb()
    .string();

  const getContacts = async () => {
    const qry = gql`
      query ($email: String!) {
        getContactsInfo_by_email(email: $email){
          amazon_market paypal_id paypal_active panelist_id country_code uic amazon_market_country_name
        }
        getFormsContactsInfo_by_email(email: $email){
          amazon_market paypal_id paypal_active panelist_id country_code uic amazon_market_country_name verification_status
        }
      }
    `;
    try {
      const response: any = await API.graphql(graphqlOperation(qry, {
        email: managedUserInfo.email,
      }));
      setIsAmazon(response.data.getContactsInfo_by_email?.paypal_active.toString() === "2");

      if (response.data.getFormsContactsInfo_by_email
        && response.data.getFormsContactsInfo_by_email.verification_status
        && response.data.getFormsContactsInfo_by_email.verification_status !== null
        && [2].includes(response.data.getFormsContactsInfo_by_email.verification_status)) {
        setData({ ...response.data.getContactsInfo_by_email, ...response.data.getFormsContactsInfo_by_email });
      } else {
        setData(response.data.getContactsInfo_by_email);
      }
      setLoading(false);
    } catch (e) {
      console.log(["payment->error", e]);

      setLoading(false);
    }
  };

  useEffect(() => {
    getContacts();

    const unsubscribe = props.navigation.addListener("focus", () => {
      getContacts();
    });

    return unsubscribe;
  }, []);
  // Analytics.logEvent("PaymentInfoOpened");
  return (
    <ScrollView style={styles.root}>

      <View>
        { data.verification_status && (
        <View style={[styles.messageWarning]}>
          <Text style={{ color: "white", fontWeight: "600" }}>
            {translate("Pending Verification")}
          </Text>
        </View>
        )}
        <CardIT
          title={translate("Rewards")}
          onPress={() => isProductionCheck() && props.navigation
            && props.navigation.push("EditPayment", { ...data, IsAmazon })}
        >
          {!loading
            ? (
              <CardITDetails
                // eslint-disable-next-line no-nested-ternary
                label={data.paypal_active === 1 ? "Paypal" : data.paypal_active === 2
                  ? `Amazon${data.amazon_market_country_name !== null ? ` - ${data.amazon_market_country_name.replace("LNG_Country_", "").replace("_", " ")}` : ""}`
                  : translate("Not set")}
                icon={(
                  <FontAwesome5
                    // eslint-disable-next-line no-nested-ternary
                    name={data.paypal_active === 1 ? "paypal"
                      : (data.paypal_active === 2 ? "amazon" : "alert-circle-outline")}
                    size={24}
                    color={mutedColor}
                  />
                )}
              >
                <Text style={{ color: mutedColor }}>{data.paypal_id && data.paypal_id !== "" ? data.paypal_id : "-" }</Text>

                {(data.paypal_active !== 2 && data.paypal_active !== 1)
                  && (<Text style={{ color: "red" }}>{translate("Please update mode of payment")}</Text>)}
              </CardITDetails>
            )
            : (
              <View style={{ paddingTop: 5, marginBottom: 1 }}>
                <ContentLoader
                  aSize={25}
                  animationDuration={500}
                  avatar
                  avatarStyles={{ marginLeft: 10 }}
                  active
                  pRows={2}
                  listSize={1}
                  tHeight={14}
                  titleStyles={{ marginLeft: 10, marginBottom: 4 }}
                  pHeight={10}
                  paragraphStyles={{ marginLeft: 10, marginBottom: 0 }}
                />
              </View>
            )}
        </CardIT>
      </View>


    </ScrollView>
  );
};
