import React, { useState, useEffect, useCallback, useContext } from "react";
import { FlatList, View, RefreshControl, Text, Platform, Animated, Image } from "react-native";
import { useTheme, Surface, Subheading, Title, ActivityIndicator, Snackbar } from "react-native-paper";
import { API, graphqlOperation } from "aws-amplify";
import Auth from "@aws-amplify/auth";
import { I18n } from "@aws-amplify/core";

import gql from "graphql-tag";

import { StackNavigationProp } from "@react-navigation/stack";// eslint-disable-line no-unused-vars
import ContentLoader from "react-native-easy-content-loader";
import { timezone } from "expo-localization";
import { StackNavigatorParamlist } from "../../navigation/types";// eslint-disable-line no-unused-vars
import { AccountItem } from "./account.item/account.item";
import ZeroRequestState from "../../components/zero.request.states";
import { UserInfoContext } from "../../context/userInfo";
import { ColorsEvidera } from "../../theme/variables";
import { translate } from "../../components/Localei18n/languagesFunctions";
import AppHeader from "../../components/app.header";
import UserService from "../../helpers/user.service";
import { SessionContext } from "../../context/SessionContext";
import { sendAnalyticsEvent } from "../../components/Analytics/analytics";

const AnimatedFlatList = Animated.createAnimatedComponent(FlatList);
type AccountProps = React.ComponentProps<typeof AccountItem>;

type Props = {
  navigation?: StackNavigationProp<StackNavigatorParamlist>;
};

function renderItem({ item }: { item: AccountProps }) {
  return <AccountItem {...item} />;
}

function keyExtractor(item: AccountProps) {
  return item.id ? `${item.id.toString()}-${(item.transaction_id && item.transaction_id.toString())}` : null;
}
const HEADER_MAX_HEIGHT = 300;
const HEADER_MIN_HEIGHT = Platform.OS === "ios" ? 60 : 70;
const HEADER_SCROLL_DISTANCE = HEADER_MAX_HEIGHT - HEADER_MIN_HEIGHT;

export const Account = (props: Props) => {
  const { handleSessionDeactivation } = useContext(SessionContext);
  const { checkUser } = UserService();
  const { managedUserInfo } = useContext(UserInfoContext);
  const theme = useTheme();
  const [AccountData, setAccountData] = useState<Omit<AccountProps, "onPress">[]>([]);
  const [balance, setBalance] = useState({ availableBalance: "", unit: "" });
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState(-1);

  const [snackbarVisible, setSnackbarVisible] = useState(false);

  const [refreshing, setRefreshing] = React.useState(false);

  const fetchRewards = async (user) => {
    if (await checkUser() !== true) {
      handleSessionDeactivation(0);
    }

    setLoading(true);
    const qry = gql`
    query ($email: String!, $timezone: String!){
      getRewardsBalance(email: $email){
        availableBalance unit
      }
      getRewards(email: $email, timezone: $timezone ){
        mode_of_payment_id country_code amazon_market id panelist_id unit amount created paypal_id
        mailed_date transaction_id mode_of_payment_id mode_of_payment status_id
        donation_pct donation_amt donation_anonymous original_reward donation_acct
      }
    }`;
    try {
      const response: any = await API.graphql(graphqlOperation(qry, { email: user.email, timezone }));
      setBalance(response.data.getRewardsBalance);
      setAccountData(response.data.getRewards);
    } catch (e) {
      setBalance({ availableBalance: "0", unit: "" });
      setAccountData([]);
    }
    setTimeout(() => setLoading(false), 500);
  };

  const updateBalance = async () => {
    // setLoading(true);
    const qry = gql`
    query ($email: String!){
      getRewardsBalance(email: $email){
        availableBalance unit
      }

    }`;

    const response: any = await API.graphql(graphqlOperation(qry, { email: managedUserInfo.email }));
    setBalance(response.data.getRewardsBalance);
  };

  useEffect(() => {
    fetchRewards(managedUserInfo);
  }, [managedUserInfo]);

  useEffect(() => {
    sendAnalyticsEvent("Account");
  }, []);

  const data = AccountData.map((accountProps, index) => ({
    ...accountProps,
    selected,
    index,
    onPress: () => props.navigation
    && props.navigation.push("Donate", {
      ...accountProps,
    }),
    openDonationForm: (status) => setSelected(status),
    setSnackbarVisible,
    updateBalance,
  }));

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    fetchRewards(managedUserInfo);
    setRefreshing(false);
  }, [loading, managedUserInfo]);


  const renderEmptyComponent = () => (
    <View style={{ paddingHorizontal: 10, paddingTop: 30 }}>
      {loading ? (
        <ContentLoader avatar active pRows={3} listSize={4} />
      ) : (
        <ZeroRequestState
          title={translate("No payment record to show")}
        />
      )}
    </View>
  );

  const [animatedValue] = useState(new Animated.Value(Platform.OS === "ios" ? -HEADER_MAX_HEIGHT : 0));

  const titleScale = animatedValue.interpolate({
    inputRange: [0, HEADER_SCROLL_DISTANCE / 2, HEADER_SCROLL_DISTANCE],
    outputRange: [1, 1, 0.7],
    extrapolate: "clamp",
  });

  const titleTranslate = animatedValue.interpolate({
    inputRange: [0, HEADER_SCROLL_DISTANCE / 2, HEADER_SCROLL_DISTANCE],
    outputRange: [0, 0, 22],
    extrapolate: "clamp",
  });

  const headerTranslate = animatedValue.interpolate({
    inputRange: [0, HEADER_SCROLL_DISTANCE / 2, HEADER_SCROLL_DISTANCE],
    outputRange: [0, 0, -80],
    extrapolate: "clamp",
  });
  // Analytics.logEvent("Account");
  return (
    <>
      <AppHeader>
        <Image
          source={require("../../assets/medimix-evidera-2.png")}
          resizeMode="contain"
          style={{
            height: 30,
            width: 200,
          }}
        />

      </AppHeader>
      <Surface style={{ flex: 1, backgroundColor: "#662D91" }}>
        <AnimatedFlatList
          contentContainerStyle={{ backgroundColor: theme.colors.background }}
          style={{ backgroundColor: theme.colors.background }}
          data={data}
          scrollEventThrottle={16}
          onScroll={Animated.event(
            [{ nativeEvent: { contentOffset: { y: animatedValue } } }],
            { useNativeDriver: true } // <-- Add this
          )}
          renderItem={renderItem}
          keyExtractor={keyExtractor}
          refreshing={loading}
          refreshControl={(
            <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        )}
          ListHeaderComponent={() => (
            <Animated.View
              style={[
                {
                  backgroundColor: ColorsEvidera.primary,
                  height: 132,
                  paddingVertical: 25,
                  paddingHorizontal: 15,
                  marginBottom: 14,
                  transform: [
                    { translateY: headerTranslate },
                  ],
                },
              ]}
            >
              <View>
                <Subheading style={{
                  textAlign: "center", color: "rgba(255,255,255,0.6)", marginBottom: 12, textTransform: "capitalize",
                }}
                >
                  {translate("available balance")}
                </Subheading>
                <Animated.View style={{
                  transform: [{ translateY: titleTranslate }],
                }}
                >
                  <Animated.View style={{
                    transform: [{ scale: titleScale }],
                  }}
                  >
                    {!loading
                      ? (
                        <View style={{
                          alignItems: "flex-start",
                          justifyContent: "center",
                          flexDirection: "row",
                        }}
                        >
                          <Title style={{
                            textAlign: "center",
                            fontSize: 40,
                            lineHeight: 42,
                            color: "white",
                          }}
                          >
                            {balance.availableBalance ? balance.availableBalance : "0" }
                          </Title>
                          <Text style={{ fontSize: 18, color: "rgba(255,255,255,0.7)", lineHeight: 18 }}>{balance.unit}</Text>
                        </View>
                      ) : (
                        <ActivityIndicator color="white" size={40} />
                      )}
                  </Animated.View>
                </Animated.View>
              </View>
            </Animated.View>
          )}
          stickyHeaderIndices={[0]}
          ListEmptyComponent={renderEmptyComponent}
        />

        <Snackbar
          visible={snackbarVisible}
          onDismiss={() => setSnackbarVisible(false)}
          duration={3000}
          action={{
            label: "Hide",
            onPress: () => {
              setSnackbarVisible(false);
            },
          }}
        >
          {translate("Donation saved!")}
        </Snackbar>
      </Surface>
    </>
  );
};

export default Account;
