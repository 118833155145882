import Constants from "expo-constants";
import * as Notifications from "expo-notifications";
import { Platform } from "react-native";
// import * as Permissions from "expo-permissions";
import { API, graphqlOperation } from "aws-amplify";
import gql from "graphql-tag";
// import { useNavigation } from "@react-navigation/core";
import { translate } from "../components/Localei18n/languagesFunctions";

const PushNotificationService = () => {
  const registerExpoPushToken = async (panelistId) => {
    if (Constants.isDevice) {
      const { status: existingStatus } = await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;
      if (existingStatus !== "granted") {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }
      if (finalStatus !== "granted") {
        alert(translate("Failed to get push token for push notification!"));
        return;
      }

      try {
        // Get the token that identifies this device
        const token = await Notifications.getExpoPushTokenAsync();

        // POST the token to your backend server frocm where you can retrieve it to send push notifications.
        const mutation = gql`
        mutation updateContacts_expo_tokens($updateContactsExpoTokensInput: UpdateContactsExpoTokensInput!){
          updateContacts_expo_tokens(updateContactsExpoTokensInput: $updateContactsExpoTokensInput){
            panelist_id expo_token
          }
        }`;
        // const response = await API.graphql(graphqlOperation(mutation, {

        await API.graphql(graphqlOperation(mutation, {
          updateContactsExpoTokensInput: { panelist_id: panelistId, expo_token: token },
        }));

        if (Platform.OS === "android") {
          Notifications.setNotificationChannelAsync("default", {
            name: "default",
            // deprecated parameters
            // sound: true,
            // priority: "max",
            vibrationPattern: [0, 250, 250, 250],
            importance: Notifications.AndroidImportance.HIGH,
          });
        }
      } catch (err) {
        console.log("failed to register expo token", err);
      }
    } else {
      alert(translate("Must use physical device for Push Notifications"));
    }
  };

  const handlePushNotification = (notification) => {
    // console.log()
    // handle push notification when the user taps the nodification
    console.log("handlePushNotification", notification);
  };

  return {
    registerExpoPushToken,
    handlePushNotification,
  };
};

export default PushNotificationService;
