import React, { useState, useEffect, useContext } from "react";
import { StyleSheet, View, ScrollView, Text, Alert } from "react-native";
import {
  useTheme,
  ActivityIndicator, TouchableRipple, Snackbar,
} from "react-native-paper";
import color from "color";

import { API, graphqlOperation } from "aws-amplify";
import gql from "graphql-tag";
import { I18n } from "@aws-amplify/core";
import analytics from "@react-native-firebase/analytics";

import { StackNavigationProp } from "@react-navigation/stack"; // eslint-disable-line no-unused-vars
// import FormField from "../../helpers/FormField";
import { StackNavigatorParamlist } from "../../../navigation/types"; // eslint-disable-line no-unused-vars
import { CardITListItem, CardITList, CardITListItemAction } from "../../../components/details-icon-text-card";
import { translate } from "../../../components/Localei18n/languagesFunctions";
import { UserInfoContext } from "../../../context/userInfo";

type Props = {
  // eslint-disable-next-line react/require-default-props
  navigation?: StackNavigationProp<StackNavigatorParamlist>; // eslint-disable-line react/no-unused-prop-types

};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    flexDirection: "column",
  },
  inlineCardDetails: {
    flexDirection: "row",
  },
});

export const ProfileContactsAddressList = (props: Props) => {
  // eslint-disable-next-line react/prop-types
  const managedUserInfo = props.route.params;

  const { isProductionCheck } = useContext(UserInfoContext);
  const theme = useTheme();

  const [loading, setLoading] = useState(false);
  const [Address, setAddress] = useState([]);

  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [message, setMessage] = useState("");

  const mutedColor = color(theme.colors.text)
    .alpha(0.67)
    .rgb()
    .string();

  const { userInfo: { uic } } = useContext(UserInfoContext);

  const getContacts = async () => {
    try {
      const qry = gql`
        query ($email: String!) {
          getFormsContactsInfo_by_email(email: $email){
            verification_status
          }
          listAddressByEmail(email: $email){
            address_id address_center_name address_city address_country_code address_line1 address_line2 address_name address_postal_code address_state_or_province address_type address_type_name panelist_id primary_address region_code
          }
          listFormAddressByEmail(email: $email){
            address_id address_center_name address_city address_country_code address_line1 address_line2 address_name address_postal_code address_state_or_province address_type address_type_name panelist_id primary_address region_code
          }
        }
      `;
      const response: any = await API.graphql(graphqlOperation(qry, {
        // eslint-disable-next-line react/prop-types
        email: managedUserInfo.email,
      }));

      if (response.data.getFormsContactsInfo_by_email?.verification_status // eslint-disable-line react/prop-types
        && response.data.getFormsContactsInfo_by_email?.verification_status !== null // eslint-disable-line react/prop-types
        && [2].includes(response.data.getFormsContactsInfo_by_email?.verification_status)) { // eslint-disable-line react/prop-types
        setAddress(response.data.listFormAddressByEmail.length > 0
          && response.data.listAddressByEmail !== response.data.listFormAddressByEmail
          ? response.data.listFormAddressByEmail : response.data.listAddressByEmail);
      } else {
        setAddress(response.data.listAddressByEmail);
      }
      setLoading(false);
    } catch (e) {
      console.log(["profilecontects->getContacts->error", e]);
      setLoading(false);
    }
  };

  const insertIgnoreForsmContactsAddress = async (panelist_id) => {
    const mutation = gql`
    mutation deleteFormAddress($panelist_id: Int!){
      insertIgnoreForsmContactsAddress(panelist_id: $panelist_id)
    }
  `;
    try {
      const response = await API.graphql(graphqlOperation(mutation, {
        panelist_id,
      }));

      if (response.data.insertIgnoreForsmContactsAddress) {
        return true; // new Promise((resolve) => resolve(true));
      }
      return false; // new Promise((resolve) => resolve(false));
    } catch (err) {
      console.log(err);
      return false; // new Promise((resolve) => resolve(false));
    }
  };

  const onDelete = async (id) => {
    if (Address.length === 1) {
      Alert.alert(
        translate("Failed to delete"),
        translate("You must have atleast 1 address."),
        [
          {
            text: translate("Done"),
            onPress: () => null,
          },

        ],
        { cancelable: false }
      );
      return;
    }
    // Analytics.logEvent("AddressUpdated");
    if (await insertIgnoreForsmContactsAddress(managedUserInfo.panelist_id) !== true) {
      return false;
      // return new Promise((resolve) => resolve(false));
    }

    const mutation = gql`
        mutation deleteFormAddress($address_id: Int!, $panelist_id: Int!, $status: Int, $uic: String){
          updateVerificationStatus(status: $status, panelist_id: $panelist_id, uic: $uic)
          deleteFormAddress(address_id: $address_id)
        }
      `;
    try {
      const response = await API.graphql(graphqlOperation(mutation, {
        address_id: id,
        panelist_id: managedUserInfo.panelist_id,
        status: 2,
        uic,
      }));

      if (response.data.deleteFormAddress) {
        const newAddress = Address.filter((e) => e.address_id !== id);
        setAddress(newAddress);
        setMessage(translate("Address deleted."));
        setSnackbarVisible(true);
      }
      return true;
      // return new Promise((resolve) => resolve(true));
      // setTimeout(() => { resolve(response.data.deleteFormAddress); }, 500);
    } catch (err) {
      console.log(["error", err]);
      // return new Promise((resolve) => resolve(false));
      return false;
      // setMessage("Failed to delete address.");
      // setSnackbarVisible(true);
      // resolve(err);
    }
  };

  useEffect(() => {
    getContacts();

    const unsubscribe = props.navigation.addListener("focus", () => {
      getContacts();
    });

    return unsubscribe;
  }, []);

  return (
    <View style={{ flex: 1 }}>
      <ScrollView style={styles.root}>
        {!loading
          ? (
            <View>
              <CardITList
                title={translate("Address")}
              >

                {Address && Address.length > 0 && Address.map((e, index) => {
                  const line1 = e.address_line1 && e.address_line1 !== "" && e.address_line1;
                  const line2 = e.address_line2 && e.address_line2 !== "" && `${e.address_line2}`;

                  const provincialAddress = (e.address_state_or_province !== "" ? `${e.address_state_or_province}` : "")
                    + (e.address_state_or_province !== "" && e.address_country_code !== "" ? ", " : "")
                    + (e.address_country_code !== "" ? `${e.address_country_code.toUpperCase()}` : "");

                  const line3 = `${e.address_postal_code}${e.address_city && e.address_city !== "" && ` ${e.address_city}`}, ${provincialAddress}`;

                  const line4 = e.address_center_name;

                  const final = line1.trim()
                  + (line1.trim() !== "" && line2.trim() !== "" ? "\n" : "") + line2.trim()
                  + (line2.trim() !== "" && line3.trim() !== "" ? "\n" : "") + line3.trim()
                  + (line4.trim() !== "" && line3.trim() !== "" ? "\n" : "") + line4.trim();
                  return (
                    <View
                      key={index.toString()}
                    >
                      {index !== 0 && (<View style={{ height: StyleSheet.hairlineWidth, backgroundColor: "rgba(0,0,0,0.4)", marginLeft: 10 }} />)}
                      <CardITListItem
                        label={(
                          <Text>
                            {final.trim() !== "" ? final.trim() : "[Empty]"}
                          </Text>
                    )}
                        onPress={() => isProductionCheck() && props.navigation.push("EditContactsAddress", {
                          ...e,
                          email: managedUserInfo.email,
                          onDelete: () => onDelete(e.address_id),
                        })}
                      >
                        {e.primary_address === 1
                    && <Text style={{ color: mutedColor }}>{translate("Primary")}</Text>}
                      </CardITListItem>
                    </View>
                  );
                })}
                <View style={{ height: StyleSheet.hairlineWidth, backgroundColor: "rgba(0,0,0,0.4)", marginLeft: 10 }} />
                <CardITListItemAction>
                  <TouchableRipple
                    onPress={() => isProductionCheck() && props.navigation.push("AddContactsAddress", {
                      contact_id: managedUserInfo.contact_id,
                      panelist_id: managedUserInfo.panelist_id,
                    })}
                    style={{ paddingHorizontal: 8, paddingVertical: 10 }}
                  >
                    <Text style={{ color: theme.colors.primary }}>{translate("New Address")}</Text>
                  </TouchableRipple>
                </CardITListItemAction>


              </CardITList>
            </View>


          )
          : (
            <View style={{ paddingTop: 30 }}>
              <ActivityIndicator size={40} color={theme.colors.primary} />
            </View>
          )}

      </ScrollView>
      <Snackbar
        visible={snackbarVisible}
        onDismiss={() => setSnackbarVisible(false)}
        duration={5000}
        action={{
          label: "×",
          onPress: () => {
            setSnackbarVisible(false);
          },
        }}
      >
        {message}
      </Snackbar>
    </View>
  );
};
