import React, { useEffect, useState } from "react";
import { Platform } from "react-native";
import { TextInput } from "react-native-paper";
import { TextInputProps } from "react-native-paper/lib/typescript/components/TextInput/TextInput";

const TextInputPassword = (props: TextInputProps) => {
  const [enableShowPassword, setEnableShowPassword] = useState(false);
  useEffect(() => {
    // console.log(Platform.OS);
  }, []);
  return (
    <TextInput
      {...props}
      enablesReturnKeyAutomatically
      secureTextEntry={!enableShowPassword}
      right={(
        <TextInput.Icon
          size={20}
          icon={enableShowPassword ? "eye-off-outline" : "eye-outline"}
          onPress={() => {
            setEnableShowPassword(() => !enableShowPassword);
          }}
        />
        )}
    />
  );
};

export default TextInputPassword;
