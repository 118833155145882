/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { I18n } from "@aws-amplify/core";
import gql from "graphql-tag";
import { View, Linking } from "react-native";
import {
  Title,
  Text,
  Avatar,
  TouchableRipple,
  useTheme,
  overlay,
} from "react-native-paper";
import color from "color";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import capitalize from "lodash/capitalize";
import Moment from "../../../helpers/moment";
import { Donate } from "../donate/donate";
import styles from "./account.item.styles";
import { ColorsEvidera } from "../../../theme/variables";
import { translate } from "../../../components/Localei18n/languagesFunctions";

type Props = {
  id: number;
  selected: number;
  index: number;
  panelist_id: number;
  unit: string;
  amount: number;
  created: string;
  paypal_id: string;
  mailed_date: string;
  transaction_id: string;
  mode_of_payment: string;
  mode_of_payment_id: number;
  country_code: string;
  amazon_market: string;
  status_id: number;
  donation_amt: number; // eslint-disable-line camelcase
  donation_pct: number; // eslint-disable-line camelcase
  donation_acct: string; // eslint-disable-line camelcase
  donation_anonymous: boolean; // eslint-disable-line camelcase
  original_reward: number; // eslint-disable-line camelcase
  onPress: () => void;
  openDonationForm: (status: number) => void;
  setSnackbarVisible: (status: boolean) => void;
  updateBalance: () => void;
};

type FormData = {
  id: number;
  donation_amt: number; // eslint-disable-line camelcase
  donation_pct: number; // eslint-disable-line camelcase
  donation_acct: string; // eslint-disable-line camelcase
  donation_anonymous: number; // eslint-disable-line camelcase
  original_reward: number; // eslint-disable-line camelcase
  amount: number;
};

export const AccountItem = (props: Props) => {
  const theme = useTheme();
  // const [loading, setLoading] = useState(false);
  const [donation, setDonation] = useState<FormData>({
    amount: props.amount,
    donation_amt: props.donation_amt,
    donation_pct: props.donation_pct,
    donation_acct: props.donation_acct,
    donation_anonymous: props.donation_anonymous === true ? 1 : 0,
    original_reward: props.original_reward,
    id: props.id,
  });


  const contentColor = color(theme.colors.text)
    .alpha(0.8)
    .rgb()
    .string();

  const mutedContentColor = color(theme.colors.text)
    .alpha(0.4)
    .rgb()
    .string();

  const surfaceOnDark = theme.dark
    ? (overlay(6, theme.colors.surface) as string)
    : theme.colors.surface;

  const separatorColor = color(theme.colors.text)
    .alpha(0.11)
    .rgb()
    .string();

  useEffect(() => {}, []);

  const getFormat = () => {
    if (window.currentLanguage === "es") {
      return (" d [de] MMM. [del] YYYY");
    } if (window.currentLanguage === "pt") {
      return (" d [de] MMM. [de] YYYY");
    }
    return " MMM D, YYYY";
  };

  const saveDonation = async (inputDonationData, rollbackData) => {
    const mutation = gql`
      mutation updateReward($rewardInput: RewardInput!) {
        updateReward(rewardInput: $rewardInput) {
          donation_acct
          donation_amt
          id
          original_reward
          donation_pct
          donation_anonymous
          amount
        }
      }
    `;

    try {
      // const response = await API.graphql(graphqlOperation(mutation, {
      await API.graphql(
        graphqlOperation(mutation, {
          rewardInput: inputDonationData,
        })
      );
      props.setSnackbarVisible(true);
      props.updateBalance();
    } catch (err) {
      setDonation(rollbackData);
    }
  };

  const isDonateButtonVisible = (mailed_date, status_id) => {
    if ([1, 8, 13, 24].includes(status_id)) {
      return true;
    }
    return false;
  };

  const displayRewardStatusMessage = () => {
    if (props.status_id === 5) {
      return (
        <Text>
          <Text
            style={[
              styles.caption,
              { textTransform: "uppercase", color: "#68bd47" },
            ]}
          >
            {translate("paid")}
          </Text>
          {` ${translate("on")}`}
          <Moment format={getFormat()} date={props.mailed_date} />
        </Text>
      );
    } if ([1, 8, 13].includes(props.status_id)) {
      return (
        <Text
          style={[
            styles.caption,
            { textTransform: "uppercase", color: "orange" },
          ]}
        >
          {translate("processing")}
        </Text>
      );
    } if (props.status_id === 22) {
      return (
        <Text
          style={[
            styles.caption,
            { textTransform: "uppercase", color: "gray" },
          ]}
        >
          {translate("expired")}
        </Text>
      );
    }
    return (
      <Text
        style={[
          styles.caption,
          { textTransform: "uppercase", color: "orange" },
        ]}
      >
        {translate("payment in progress")}
      </Text>
    );
  };

  return (
    <View
      style={[
        styles.root,
        { backgroundColor: theme.dark ? surfaceOnDark : theme.colors.surface },
        props.selected === props.index && { marginTop: 20, marginBottom: 28 },
      ]}
    >
      <View style={styles.container}>
        <View style={styles.leftColumn}>
          <View style={{ padding: 3, ...!theme.dark && { backgroundColor: "#fff" }, borderRadius: 18 }}>
            <Avatar.Image
              style={{ backgroundColor: "transparent" }}
              source={theme.dark ? require("../../../assets/icon_transparent.png") : require("../../../assets/icon.png")}
              size={28}
            />
          </View>
        </View>
        <View style={[styles.rightColumn]}>
          <View style={{ marginTop: -2 }}>
            <Text style={{ color: contentColor, lineHeight: 21 }}>
              {capitalize(translate("completed on"))}
              <Moment format={getFormat()} date={props.created} />
            </Text>
            <Text
              style={{ color: mutedContentColor, lineHeight: 21, fontSize: 13 }}
            >
              {displayRewardStatusMessage()}
            </Text>
          </View>
        </View>
        <View>
          <Title style={{ flex: 1, lineHeight: 22 }}>
            {Number(
              props.original_reward > 0 ? props.original_reward : props.amount
            ).toFixed(2)}
          </Title>
        </View>
      </View>

      <View style={styles.content}>
        {// Ref Transaction row *************************************************************************
          props.status_id !== 22 && (
            <View>
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{
                    color: contentColor,
                    lineHeight: 21,
                    fontSize: 14,
                    flex: 1,
                  }}
                >
                  {props.mode_of_payment_id === 6
                    ? props.amazon_market
                      && `Amazon ${props.country_code} website: ${props.amazon_market}`
                    : props.paypal_id && `Paypal: ${props.paypal_id}`}
                </Text>
                {donation.donation_amt > 0 && (
                  <Text style={[styles.contentAmount, { color: contentColor }]}>
                    {Number(donation.amount).toFixed(2)}
                  </Text>
                )}
              </View>
              {props.mailed_date !== "" && props.transaction_id && (
                <Text
                  style={{ color: contentColor, lineHeight: 21, fontSize: 14 }}
                >
                  {`Ref: ${props.transaction_id}`}
                </Text>
              )}
            </View>
          )
          }
        {// Donation row *************************************************************************
          Number(donation.donation_amt) > 0 && (
            <View style={{ flexDirection: "row" }}>
              <Text style={[{ color: contentColor, flex: 1 }]}>
                {`${translate("Donated")} ${donation.donation_pct}% ${translate(
                  "to"
                )} `}
                <Text
                  accessibilityRole="link"
                  style={{
                    color: ColorsEvidera.secondary,
                    textTransform: "lowercase",
                  }}
                  onPress={() => Linking.openURL(`http://${props.donation_acct}`)}
                >
                  {donation.donation_acct}
                </Text>
              </Text>
              <Text style={[styles.contentAmount, { color: contentColor }]}>
                {donation.donation_amt.toFixed(2)}
              </Text>
            </View>
          )
        }
      </View>

      {/* <View>
        {isDonateButtonVisible(props.mailed_date, props.status_id) && (
          <View
            style={[
              styles.actionContainer,
              { borderTopWidth: 1, borderTopColor: separatorColor },
            ]}
          >
            {props.selected !== props.index ? (
              <TouchableRipple
                onPress={() => props.openDonationForm(
                  props.selected !== props.index ? props.index : -1
                )}
              >
                <View style={[styles.actionButton]}>
                  <MaterialCommunityIcons name="share" size={14} color={contentColor} />
                  <Text style={styles.actionButtonText}>
                    {translate("donate")}
                  </Text>
                </View>
              </TouchableRipple>
            ) : (
              <Donate
                saveDonation={saveDonation}
                setDonation={setDonation}
                donation={donation}
                openDonationForm={props.openDonationForm}
              />
            )}
          </View>
        )}
      </View> */}
    </View>
  );
};
