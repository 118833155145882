import React, { useEffect, useState } from "react";
import { useWindowDimensions, View, Platform } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { useTheme } from "react-native-paper";
// import HTML_SOURCE from "../../public/en/termsAndCondition.html";
import HTML from "react-native-render-html";
import color from "color";
import applyScale from "../../helpers/image/applyScale";
import { TermsEN } from "../../public/terms/en";
import { TermsES } from "../../public/terms/es";
import { TermsFR } from "../../public/terms/fr";
import { TermsDE } from "../../public/terms/de";
import { TermsPT } from "../../public/terms/pt";
import { TermsIT } from "../../public/terms/it";

type Props = {
  language: string;
};


export const TermsConditions = (props: Props) => {
  const [terms, setTerms] = useState(TermsEN);
  const contentWidth = applyScale(useWindowDimensions().width);
  const theme = useTheme();
  const classesStyles: any = {
    agreement: {
      marginBottom: 30,
    },
    flex: {
      display: "flex",
    },
    "flex-row": {
      flexDirection: "row",
      ...Platform.OS !== "web" && { width: "85%" },
    },
    order: {
      marginRight: 8,
    },
    "remove-vertical-margin": {
      marginTop: 0,
      marginBottom: 0,
    },
    sub: {
      marginLeft: applyScale(13),
    },
    sub2: {
      marginLeft: applyScale(31),
    },
    justify: {
      textAlign: "justify",
    },
    flexAuto: {
      flex: 1,
    },
  };

  const tagStyle: any = {
    p: {
      margin: 0,
      color: color(theme.colors.text)
        .alpha(0.8)
        .rgb()
        .string(),
    },
    span: {
      padding: 0,
    },
    h3: {
      color: color(theme.colors.text)
        .alpha(0.8)
        .rgb()
        .string(),
    },
    h4: {
      color: color(theme.colors.text)
        .alpha(0.8)
        .rgb()
        .string(),
    },
  };


  useEffect(() => {
    let unmounted = false;

    if (props.language === "fr") {
      setTerms(TermsFR);
    } else if (props.language === "pt") {
      setTerms(TermsPT);
    } else if (props.language === "de") {
      setTerms(TermsDE);
    } else if (props.language === "es") {
      setTerms(TermsES);
    } else if (props.language === "it") {
      setTerms(TermsIT);
    } else {
      setTerms(TermsEN);
    }
    return () => {
      unmounted = true;
    };
  }, [props.language]);

  return (
    <>
      <ScrollView style={{ flex: 1 }}>
        <View style={{ padding: 10, ...Platform.OS === "web" && { padding: 20 } }}>
          <HTML
            classesStyles={classesStyles}
            source={{ html: terms }}
            contentWidth={contentWidth}
            tagsStyles={tagStyle}
          />
        </View>
      </ScrollView>
    </>
  );
};
