import React, { useState, useEffect, useContext } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  TouchableOpacity,
  Linking,
  ImageBackground,
  ScrollView,
  Animated,
  Platform,
  Easing,
} from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { I18n } from "@aws-amplify/core";
import PropTypes from "prop-types";
import { StackNavigationProp } from "@react-navigation/stack";
import { Button } from "react-native-paper";
import { styles } from "./landing.style";
import applyScale from "../../helpers/image/applyScale";
import { translate } from "../../components/Localei18n/languagesFunctions";
import LanguageSessionSelector from "../../components/languageSessionSelector";
import { AuthContext } from "../Auth/AuthContext";

type Props = {
  user?: any | undefined;
  userStatus: boolean;
  initialRoute?: string;
  navigation?: StackNavigationProp;
};


const LandingDownload = (props: Props) => {
  let unmounted = false;

  const { AuthLanguage } = useContext(AuthContext);
  const [imageWidth, setImageWidth] = useState(220);
  const [imageHeight, setImageHeight] = useState(100);
  const [cardStyle, setCardStyle] = useState(styles.card);

  const initialValue = 0;
  const translateValue = new Animated.Value(initialValue);

  const appleID = "1504950824";
  const googlePlay = "https://play.google.com/store/apps/details?id=com.ppd.medimix.hcpportal&hl=en";
  const appleStore = `https://itunes.apple.com/us/app/id${appleID}`;

  const onResize = () => {
    if (Dimensions.get("window").width < 850) {
      if (!unmounted) setCardStyle(styles.cardFull);
    } else if (!unmounted) setCardStyle(styles.card);
  };

  const translation = () => {
    if (!unmounted) translateValue.setValue(initialValue);
    if (!unmounted) {
      Animated.timing(translateValue, {
        toValue: -100,
        duration: 10000,
        easing: Easing.linear,
        useNativeDriver: true,
      }).start(() => translation());
    }
  };

  const openUrl = (url) => {
    if (Platform.OS === "web") {
      window.open(url, "_blank").focus();
    } else {
      Linking.openURL(url);
    }
  };

  useEffect(() => {
    setImageWidth(applyScale(220));
    setImageHeight(applyScale(100));
    Dimensions.addEventListener("change", onResize);
    onResize();
    translation();
    return () => {
      unmounted = true;
      Dimensions.removeEventListener("change", onResize);
    };
  }, [translateValue, AuthLanguage]);

  const translateAnimation = translateValue.interpolate({
    inputRange: [0, 60],
    outputRange: [0, 60],
  });

  const translateAnimation2 = translateValue.interpolate({
    inputRange: [0, 100],
    outputRange: [0, 100],
  });

  const AnimatedImage = Animated.createAnimatedComponent(ImageBackground);

  return (
    /* Page */
    <ScrollView>
      <View style={styles.pageGray}>
        {/* Animated BG */}
        <AnimatedImage
          source={require("../../assets/tech_bg.png")}
          resizeMode="cover"
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            transform: [
              { translateX: translateAnimation2 },
              { translateY: 0 },
            ],
          }}
        />
        {/* Container */}
        <View style={styles.container}>
          {/* Header */}
          <TouchableOpacity onPress={() => props.navigation.push("Home")}>
            <Image
              source={require("../../assets/medimix-evidera-2.png")}
              resizeMode="contain"
              style={{
                ...styles.image,
                width: imageWidth,
                height: imageHeight,
                alignSelf: "center",
              }}
            />
          </TouchableOpacity>
          <View style={[styles.header, { marginTop: 10 }]}>
            <AnimatedImage
              source={require("../../assets/lines3.png")}
              resizeMode="cover"
              style={{
                width: "100%",
                height: "500px",
                position: "absolute",
                transform: [
                  { translateX: 0 },
                  { translateY: translateAnimation },
                ],
              }}
            />
            <View style={{
              width: "100%", flex: 1, flexDirection: "row", justifyContent: "center",
            }}
            >
              <Image
                source={require("../../assets/feature/feature.gif")}
                resizeMode="contain"
                style={{ height: "400px", width: "25%" }}
              />
            </View>
          </View>
          {/* Section 1 */}
          <View style={[styles.section, { paddingTop: 50 }]}>
            {/* Card 1 */}
            <View style={cardStyle}>
              <Text style={[styles.textBullet, { fontSize: 30, marginBottom: 20, fontWeight: "400" }]}>
                <MaterialCommunityIcons style={[styles.icon, { marginRight: 11 }]} name="star" size={30} color="#d0d0d0" />
                {translate("Key features")}
              </Text>
              {/* Bullet 1 */}
              <Text style={styles.textBullet}>
                <MaterialCommunityIcons style={[styles.icon, { marginRight: 11 }]} name="check" size={24} color="#26d234" />
                {translate("Notifications")}
              </Text>
              <Text style={[styles.textBulletItem, { marginLeft: 35 }]}>{translate("Receive notification as soon as a new study is available for you")}</Text>
              {/* Bullet 2 */}
              <Text style={styles.textBullet}>
                <MaterialCommunityIcons style={[styles.icon, { marginRight: 11 }]} name="check" size={24} color="#26d234" />
                {translate("Participations")}
              </Text>
              <Text style={[styles.textBulletItem, { marginLeft: 35 }]}>{translate("Review new and pending participations")}</Text>
              {/* Bullet 3 */}
              <Text style={styles.textBullet}>
                <MaterialCommunityIcons style={[styles.icon, { marginRight: 11 }]} name="check" size={24} color="#26d234" />
                {translate("Start Survey")}
              </Text>
              <Text style={[styles.textBulletItem, { marginLeft: 35 }]}>{translate("Start or resume your participations")}</Text>
              {/* Bullet 4 */}
              <Text style={styles.textBullet}>
                <MaterialCommunityIcons style={[styles.icon, { marginRight: 11 }]} name="check" size={24} color="#26d234" />
                {translate("Personal Data")}
              </Text>
              <Text style={[styles.textBulletItem, { marginLeft: 35 }]}>{translate("Review your personal data and submit changes")}</Text>
              {/* Bullet 5 */}
              <Text style={styles.textBullet}>
                <MaterialCommunityIcons style={[styles.icon, { marginRight: 11 }]} name="check" size={24} color="#26d234" />
                {translate("Payments")}
              </Text>
              <Text style={[styles.textBulletItem, { marginLeft: 35 }]}>{translate("Review your past completions and follow your payments")}</Text>
              {/* Bullet 6 */}
              <Text style={styles.textBullet}>
                <MaterialCommunityIcons style={[styles.icon, { marginRight: 11 }]} name="check" size={24} color="#26d234" />
                {translate("Have a question or an issue?")}
              </Text>
              <Text style={[styles.textBulletItem, { marginLeft: 35 }]}>
                {translate("Get support through live chat, phone or with our most frequently asked questions section.")}
              </Text>
            </View>
            {/* Card 2 */}
            <View style={[cardStyle]}>
              <Text style={[styles.textBullet, { fontSize: 30, fontWeight: "400" }]}>
                <MaterialCommunityIcons style={[styles.icon, { marginRight: 11 }]} name="file-download" size={30} color="#d0d0d0" />
                {translate("Download our app today")}
              </Text>
              <Text style={[styles.textBulletItem, { marginTop: 20, fontSize: 20, marginLeft: 35 }]}>{translate("Simply select the link below on your phone and follow the on-screen instructions.")}</Text>

              <View>
                <View style={{ flexDirection: "row", marginVertical: 10 }}>
                  <TouchableOpacity onPress={() => openUrl(appleStore)}>
                    <Image
                      source={require("../../assets/apple_store_croped.png")}
                      resizeMode="contain"
                      style={styles.imageButton}
                    />
                  </TouchableOpacity>
                </View>
                <View style={{ flexDirection: "row", marginVertical: 10 }}>
                  <TouchableOpacity onPress={() => openUrl(googlePlay)}>
                    <Image
                      source={require("../../assets/google_play_croped.png")}
                      resizeMode="contain"
                      style={styles.imageButton}
                    />
                  </TouchableOpacity>
                </View>
                <View style={{ flexDirection: "row", marginVertical: 10 }}>
                  <Button
                    compact
                    mode="contained"
                    style={styles.imageButton}
                    onPress={() => props.navigation.push("Authenticate")}
                  >
                    <Text style={{ fontSize: 12 }}>
                      {translate("Go to web app")}
                    </Text>
                  </Button>
                </View>

              </View>
            </View>
          </View>
          {/* Footer */}
          <View style={{}}>
            <Text style={styles.textFooterDark}>
              {translate("Apple and the App Store logo are trademarks of Apple Inc.")}
            </Text>
            <Text style={styles.textFooterDark}>
              {translate("Google Play and the Google Play logo are trademarks of Google LLC.")}
            </Text>
            <Text style={styles.textFooterDark}>
              {translate("Copyright ©")}
              {" "}
              {(new Date().getFullYear())}
              {" "}
              {translate("Medimix International. All rights reserved.")}
            </Text>
          </View>
          <LanguageSessionSelector />
        </View>
      </View>
    </ScrollView>
  );
};

// LandingDownload.propTypes = {
//   user: PropTypes.shape({}).isRequired,
// };

LandingDownload.defaultProps = {};

export default LandingDownload;
