/* eslint-disable no-console */
import { useReducer, useState, useEffect } from "react";
import { Hub, Logger } from "@aws-amplify/core";
import Auth from "@aws-amplify/auth";
import AsyncStorage from "@react-native-async-storage/async-storage";
import UserService from "./user.service";
import axios from "axios";
import ExpoReleaseEnvironments from "../ExpoReleaseEnvironments";

const logger = new Logger("amplifyAuthReducer");

// see https://www.rockyourcode.com/custom-react-hook-use-aws-amplify-auth/

const amplifyAuthReducer = (state, action) => {
  switch (action.type) {
    case "FETCH_USER_DATA_INIT":
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case "FETCH_USER_DATA_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isError: false,
        user: action.payload.user,
      };
    case "FETCH_USER_DATA_FAILURE":
      return { ...state, isLoading: false, isError: true };
    case "RESET_USER_DATA":
      return { ...state, user: null };
    default:
      throw new Error();
  }
};

const useAmplifyAuth = () => {
  let isMounted = true;
  const initialState = {
    isLoading: true,
    isError: false,
    user: null,
  };

  const [state, dispatch] = useReducer(amplifyAuthReducer, initialState);
  const [triggerFetch, setTriggerFetch] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      if (isMounted) {
        dispatch({ type: "FETCH_USER_DATA_INIT" });
      }
      try {
        if (isMounted) {
          const data = await Auth.currentAuthenticatedUser({
            bypassCache: true,
          });
          if (data) {
            dispatch({
              type: "FETCH_USER_DATA_SUCCESS",
              payload: { user: data },
            });
          }
        }
      } catch (error) {
        if (isMounted) {
          logger.debug("fetchUserData FETCH_USER_DATA_FAILURE: ", error);
          dispatch({ type: "FETCH_USER_DATA_FAILURE" });
        }
      }
    };

    const onAuthEvent = (payload) => {
      switch (payload.event) {
        case "signIn":
          if (isMounted) {
            setTriggerFetch(true);
            logger.debug("signed in", "");
          }
          break;
        default:
      }
    };

    const HubListener = () => {
      Hub.listen("auth", (data) => {
        logger.debug("HubListener", data);
        const { payload } = data;
        onAuthEvent(payload);
      });
    };

    HubListener();
    fetchUserData();

    return () => {
      Hub.remove("auth", null);
      isMounted = false;
    };
  }, [triggerFetch]);

  const removeHcpDeviceOnSignOut = async() => {
    const {
      accessToken, panelist_id, clientId, event_id, token,
    } = await Auth.currentSession()
      .then(({
        accessToken: {
          jwtToken = "",
          payload: { client_id = "", event_id = "" },
        }, idToken: {
          payload = {},
        }, refreshToken: {
          token = "",
        },
      }: any) => ({
        event_id,
        clientId: client_id,
        accessToken: jwtToken,
        token,
        panelist_id: payload?.["custom:panelist_id"],
      }));

    try {
      const { data } = await axios.post(
        `${ExpoReleaseEnvironments().backofficeUrl}api/hcp/removeHcpDeviceOnSignOut`,
        { panelist_id, uniqueId: event_id},
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
    } catch (e) {
      console.log(e);
    }
  }

  const handleSignout = async () => {
    await removeHcpDeviceOnSignOut();

    try {
      await AsyncStorage.clear();
      await Auth.signOut();
      setTriggerFetch(false);
      dispatch({ type: "RESET_USER_DATA" });
      window.environment = "";
      logger.debug("signed out", "");
    } catch (error) {
      logger.debug("handleSignout: Error signing out user ", error);
      return false;
    }
    return true;
  };

  const updateUserAttributes = async (user, attributes) => {
    if (isMounted) {
      dispatch({ type: "FETCH_USER_DATA_INIT" });
    }
    try {
      await Auth.updateUserAttributes(user, attributes);
      try {
        if (isMounted) {
          const data = await Auth.currentAuthenticatedUser({
            bypassCache: true,
          });
          if (data) {
            dispatch({
              type: "FETCH_USER_DATA_SUCCESS",
              payload: { user: data },
            });
          }
        }
      } catch (error) {
        logger.debug("fetchUserData FETCH_USER_DATA_FAILURE: ", error);
        dispatch({ type: "FETCH_USER_DATA_FAILURE" });
      }
    } catch (error) {
      logger.debug("updateUserAttributes error:", error);
    }
  };

  const verifyCurrentUserAttributeSubmit = async (attribute, code) => {
    if (isMounted) {
      dispatch({ type: "FETCH_USER_DATA_INIT" });
    }
    try {
      if (isMounted) {
        await Auth.verifyCurrentUserAttributeSubmit(attribute, code);
        const data = await Auth.currentAuthenticatedUser({ bypassCache: true });
        if (data) {
          dispatch({
            type: "FETCH_USER_DATA_SUCCESS",
            payload: { user: data },
          });
        }
        return data;
      }
    } catch (error) {
      logger.debug("verifyCurrentUserAttributeSubmit error:", error);
      return error;
    }
  };

  const refreshCurrentUser = async () => {
    if (isMounted) {
      dispatch({ type: "FETCH_USER_DATA_INIT" });
    }
    try {
      if (isMounted) {
        const data = await Auth.currentAuthenticatedUser({ bypassCache: true });
        if (data) {
          dispatch({
            type: "FETCH_USER_DATA_SUCCESS",
            payload: { user: data },
          });
        }
      }
    } catch (error) {
      logger.debug("refreshCurrentUser error:", error);
    }
  };

  return {
    state,
    handleSignout,
    updateUserAttributes,
    verifyCurrentUserAttributeSubmit,
    refreshCurrentUser,
  };
};

export default useAmplifyAuth;
