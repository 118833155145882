import { API, graphqlOperation } from "aws-amplify";
import gql from "graphql-tag";

const UserRegistrationService = () => {
  const createContact = async (user) => {
    try {
      const qry = gql`
        query ($email: String!) {
          getTesterPrivileges(email: $email){ id first_name last_name }
        }
      `;
      const response2: any = await API.graphql(graphqlOperation(qry, {
        email: user?.attributes.email,
      }));

      if (response2.data.getTesterPrivileges?.id) {
        const responseData = { tester: response2.data.getTesterPrivileges };
        return new Promise((resolve) => resolve(responseData));
      }

      const mutation = gql`
      mutation createContactFromPanelWithEmail($formValue: createContactFromPanelWithEmailInput!) {
        createContactFromPanelWithEmail(formValue: $formValue) {
          contact_id panelist_id contact_status_id uic
        }
      }
    `;
      /* if existing contact:
      1. add to form contacts + identity_id
      2. update cognito attributes panelist_id = contact.panelist_id and review_status = contact.status
      3. get contact id from form_contacts.
      */
      const response: any = await API.graphql(
        graphqlOperation(mutation, {
          formValue: {
            contact_id: user?.attributes["custom:contact_id"],
            identity_id: user?.attributes.sub,
            email: user?.attributes.email,
            panelist_id: user?.attributes["custom:panelist_id"],
          },
        })
      );
      const responseData = { contact: response.data.createContactFromPanelWithEmail };
      return new Promise((resolve) => resolve(responseData));
    } catch (e) {
      return new Promise((resolve) => resolve({ error: e }));
    }
  };

  // eslint-disable-next-line camelcase
  const countParticipation = async (panelist_id) => {
    const qry = gql`
        query ($panelist_id: Int!) {
          getContacts(panelist_id: $panelist_id) { uic }
        }
      `;
    const response: any = await API.graphql(graphqlOperation(qry, {
      panelist_id,
    }));

    const qry2 = gql`
        query ($uic: String!) {
          getCountCompleteParticipation(uic: $uic)
        }
      `;
    const response2: any = await API.graphql(graphqlOperation(qry2, {
      uic: response.data.getContacts.uic,
    }));

    return response2.data.getCountCompleteParticipation;
  };

  // eslint-disable-next-line camelcase
  const getTargetData = async (codeSelected, country_code) => {
    // setValue("salutation_code", "");
    if (codeSelected !== "") {
      const qry = gql`
        query($code: Int!,$country_code: String!) {
          getListSalutations(code: $code) {
            salutation_code
            salutation_name
          }
          getSpecialty(code: $code) {
            code
            name
          }
          getSpecialty2(code: $code) {
            code
            name
          }
          listDocumentType(country_code: $country_code) {
            country_code
            description
            document_type_id
            document_type_name
            organization
          }
        }
      `;

      const response: any = await API.graphql(
        graphqlOperation(qry, {
          code: codeSelected,
          country_code,
        })
      );

      return (response.data);
    }
    return ([]);
  };

  // eslint-disable-next-line camelcase
  const stage2DataCollection = async (contact_id) => {
    try {
      const qry = gql`
          query($contact_id: Int!) {
            getContactsStage0(contact_id: $contact_id) {
              first_name
              last_name
              country: country_code
            }
            getCountries {
              country_code
              country_name
            }
            getContactsStage1(contact_id: $contact_id) {
              target_code
              salutation_code
              level1_specialty_code
              level2_specialty_code
            }
            getTargets {
              target_code
              target_name
            }

            listFormContactsDocuments(contact_id: $contact_id) {
              document_id
              contact_id
              panelist_id
              document_number
              document_type_id
              filename
              format
            }
          }
        `;
      const response: any = await API.graphql(
        graphqlOperation(qry, {
          contact_id,
        })
      );
      const targetData = await getTargetData(response.data.getContactsStage1[0]?.target_code, response.data.getContactsStage0[0]?.country);

      return {
        ...response.data.getContactsStage0[0],
        selected_country: response.data.getCountries.find((e) => e.country_code === response.data.getContactsStage0[0]?.country),
        contact_id,
        ...response.data.getContactsStage1[0],
        ...response.data.listFormContactsDocuments[0],
        categoryList: response.data.getTargets,
        salutationList: targetData.getListSalutations,
        specialtyList: targetData.getSpecialty,
        secondSpecialtyList: targetData.getSpecialty2,
        documentTypeList: targetData.listDocumentType,
      };
    } catch (e) {
      console.log(e);
      return {};
    }
  };

  // eslint-disable-next-line camelcase
  const updateRegistrationStatus = async (contact_id, status, approver) => {
    try {
      const mutation = gql`
        mutation (
          $contact_id: Int!
          $status: Int!,
          $approver: String
        ) {
          createVerificationControl(contact_id: $contact_id,status: $status, approver: $approver)
        }
      `;

      const response: any = await API.graphql(
        graphqlOperation(mutation, {
          contact_id,
          status,
          approver,
        })
      );
      return { status: "_SUCCESS", data: response.data.createVerificationControl };
    } catch (e) {
      return { status: "_FAILED", data: e.data.createVerificationControl };
    }
  };

  return {
    createContact,
    countParticipation,
    stage2DataCollection,
    updateRegistrationStatus,
  };
};

export default UserRegistrationService;
