/* eslint-disable camelcase */
import React, { useState, useContext, useEffect } from "react";
import { StyleSheet, View, ScrollView, Text, Image, Platform } from "react-native";
import {
  useTheme,
  TouchableRipple,
  Snackbar,
} from "react-native-paper";
import color from "color";

import { API, graphqlOperation } from "aws-amplify";
import gql from "graphql-tag";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { StackNavigationProp } from "@react-navigation/stack"; // eslint-disable-line no-unused-vars
// import FormField from "../../helpers/FormField";
import ContentLoader from "react-native-easy-content-loader";
import { StackNavigatorParamlist } from "../../../navigation/types"; // eslint-disable-line no-unused-vars

import { CardIT, CardITDetails, CardITList, CardITListItem, CardITListItemAction } from "../../../components/details-icon-text-card";
import { UserInfoContext } from "../../../context/userInfo";
import HCP from "../types";
import { translate } from "../../../components/Localei18n/languagesFunctions";
import { sendAnalyticsEvent } from "../../../components/Analytics/analytics";

type Props = {
  // eslint-disable-next-line react/require-default-props
  navigation?: StackNavigationProp<StackNavigatorParamlist>;
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  messageWarning: {
    backgroundColor: "orange",
    paddingVertical: 10,
    paddingHorizontal: 15,
  },
});

export const ProfileHcp = (props: Props) => {
  // const ma: any = useContext(UserContext);
  const { managedUserInfo, isProductionCheck } = useContext(UserInfoContext);

  const theme = useTheme();

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<HCP>({});
  const [Docs, setDocs] = useState([]);

  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [countryCode, setCountryCode] = useState("");


  const mutedColor = color(theme.colors.text)
    .alpha(0.67)
    .rgb()
    .string();

  const getContacts = async (showLoading = true) => {
    if (showLoading) setLoading(true);
    const qry = gql`
      query ($email: String!) {
        getContactsInfo_by_email(email: $email){
          uic country_name country_code panelist_id level1_specialty_name level2_specialty_name
          level1_specialty_code level2_specialty_code target_code salutation_code salutation
        }
        getFormsContactsInfo_by_email(email: $email){
          verification_status uic country_name country_code panelist_id contact_id level1_specialty_name
          level2_specialty_name level1_specialty_code level2_specialty_code target_code salutation_code salutation
        }
        listContactDocuments_by_email(email: $email){
          document_number document_type_id document_type_name filename organization specified_organization specified_country_code specified_type_name document_id
        }
        listFormContactDocuments_by_email(email: $email){
          document_number document_type_id document_type_name filename organization specified_organization specified_country_code specified_type_name document_id
        }
      }
    `;
    try {
      const response: any = await API.graphql(graphqlOperation(qry, {
        email: managedUserInfo.email,
      }));

      setCountryCode(response.data.getFormsContactsInfo_by_email?.country_code);

      if ([2].includes(response.data.getFormsContactsInfo_by_email?.verification_status)) {
        setData({ ...response.data.getContactsInfo_by_email, ...response.data.getFormsContactsInfo_by_email });
        setDocs(response.data.listFormContactDocuments_by_email);
      } else {
        setData(response.data.getContactsInfo_by_email);
        setDocs(response.data.listContactDocuments_by_email);
      }

      setLoading(false);
    } catch (e) {
      console.log("hcp->error", e);
      setLoading(false);
    }
  };

  const onDelete = async (id) => {
    const mutation = gql`
        mutation deleteFormAddress($document_id: Int!, $panelist_id: Int!){
          insertIgnoreFormsContacts(panelist_id: $panelist_id)
          insertIgnoreForsmContactsDocuments(panelist_id: $panelist_id)
          deleteFormDocument(document_id: $document_id)
        }
      `;
    try {
      const response = await API.graphql(graphqlOperation(mutation, {
        document_id: id,
        panelist_id: managedUserInfo.panelist_id,
      }));

      if (response.data.deleteFormDocument) {
        const newAddress = Docs.filter((e) => e.document_id !== id);
        setDocs(newAddress);
        setMessage("License deleted.");
        setSnackbarVisible(true);
      }
      // eslint-disable-next-line consistent-return
      return new Promise((resolve) => resolve(true));
    } catch (err) {
      console.log(["error", err]);
      // eslint-disable-next-line consistent-return
      return new Promise((resolve) => resolve(false));
    }
  };

  useEffect(() => {
    getContacts();

    const unsubscribe = props.navigation.addListener("focus", () => {
      getContacts(false);
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    sendAnalyticsEvent("ProfileHCPIdentification");
  }, []);
  // Analytics.logEvent("ProfileHcpOpened");
  return (
    <>
      <ScrollView style={styles.root}>
        { data.verification_status && (
        <View style={[styles.messageWarning]}>
          <Text style={{ color: "white", fontWeight: "600" }}>
            {translate("Pending Verification")}
          </Text>
        </View>
        )}

        <View>
          <CardIT
            title={translate("HCP Identification")}
            onPress={() => isProductionCheck() && props.navigation
              && props.navigation.push("EditHcpDetails", { ...data, reload: getContacts })}
          >
            {!loading
              ? (
                <>
                  <CardITDetails
                    label={translate("Primary Specialty")}
                    icon={(
                      <MaterialCommunityIcons
                        name="medical-bag"
                        size={24}
                        color={mutedColor}
                      />
                    )}
                  >
                    <Text style={{ color: mutedColor, marginVertical: 4 }}>{translate(data.level1_specialty_name)}</Text>
                  </CardITDetails>
                  <CardITDetails
                    style={{ marginTop: 8 }}
                    label={translate("Secondary Specialty")}
                  >
                    <Text style={{ color: mutedColor, marginVertical: 4 }}>{data?.level2_specialty_name ? translate(data.level2_specialty_name) : "-"}</Text>
                  </CardITDetails>

                  <CardITDetails
                    style={{ marginTop: 15 }}
                    label={translate("Country")}
                    icon={(
                      <MaterialCommunityIcons
                        name="earth"
                        size={24}
                        color={mutedColor}
                      />
                    )}
                  >
                    <Text style={{ color: mutedColor, marginVertical: 4 }}>{translate(data?.country_name)}</Text>
                  </CardITDetails>
                </>
              )
              : (
                <View style={{ paddingTop: 5, marginBottom: 1 }}>
                  <ContentLoader
                    aSize={25}
                    animationDuration={500}
                    avatar
                    avatarStyles={{ marginLeft: 10 }}
                    active
                    pRows={1}
                    listSize={1}
                    tHeight={14}
                    titleStyles={{ marginLeft: 10, marginBottom: 4 }}
                    pHeight={10}
                    paragraphStyles={{ marginLeft: 10, marginBottom: 0 }}
                  />
                </View>
              )}
          </CardIT>
        </View>
      </ScrollView>
      <Snackbar
        visible={snackbarVisible}
        onDismiss={() => setSnackbarVisible(false)}
        duration={5000}
        action={{
          label: "×",
          onPress: () => {
            setSnackbarVisible(false);
          },
        }}
      >
        {message}
      </Snackbar>
    </>
  );
};
