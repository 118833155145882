// eslint-disable-next-line no-unused-vars
import React, { useContext, useState, useEffect, useCallback } from "react";
import { FlatList, View, RefreshControl, Linking, ImageBackground, Platform, Image, StyleSheet } from "react-native";
import {
  Surface, Text,
  useTheme,
  Title,
  TouchableRipple,
} from "react-native-paper";
import color from "color";
// eslint-disable-next-line no-unused-vars
import { StackNavigationProp } from "@react-navigation/stack";
import gql from "graphql-tag";
import { API, graphqlOperation } from "aws-amplify";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import ContentLoader from "react-native-easy-content-loader";
import { StackNavigatorParamlist } from "../../../navigation/types";
import { ListData } from "./profile.category.data";
import { styles } from "./profile.category.styles";
import { UserInfoContext } from "../../../context/userInfo";
import ZeroRequestState from "../../../components/zero.request.states";
import { ColorsEvidera } from "../../../theme/variables";
import { translate } from "../../../components/Localei18n/languagesFunctions";
import AppHeader from "../../../components/app.header";
import UserService from "../../../helpers/user.service";
import { SessionContext } from "../../../context/SessionContext";
import { sendAnalyticsEvent } from "../../../components/Analytics/analytics";

type ListProps = {
  id: number;
  title: string;
  description?: string;
  icon?: object;
  navigateTo?: string;
  onPress: (id: any) => void;
  link?: string;
  status?: any;
  hidden: boolean;
}

type Props = {
  navigation?: StackNavigationProp<StackNavigatorParamlist>;
};


const components = {
  MaterialCommunityIcons,
};

function keyExtractor(item: ListProps) {
  return item.id ? item.id.toString() : null;
}

const imageUri = { uri: "https://www.evidera.com/wp-content/uploads/2019/04/shutterstock_631425161-cropped-1600x600.jpg" };

export const ProfileCategory = (props: Props) => {
  const { handleSessionDeactivation } = useContext(SessionContext);
  const { checkUser } = UserService();

  const theme = useTheme();
  const { managedUserInfo } = useContext(UserInfoContext);
  // const [userInfo, setUserInfo] = useState<any>();
  const [loading, setLoading] = useState(true);
  // const [data, setData] = useState();
  const [profileList, setProfileList] = useState<Omit<ListProps, "onPress">[]>([]);

  const [refreshing, setRefreshing] = React.useState(false);

  const initializeCatList = (response) => {
    const newList: any = ListData;

    const formData = response.data?.getFormsContactsInfo_by_email;
    const contactData = response.data?.getContactsInfo_by_email;

    // ITEM ID === 3
    if ((formData?.paypal_active === 1 || formData?.paypal_active === 2)
      && (managedUserInfo.paypal_id !== formData?.paypal_id)
      && formData?.verification_status) {
      newList[3].status = { type: 2, text: "Pending Verification" };
    } else if (contactData?.paypal_active !== 1 && contactData?.paypal_active !== 2) {
      newList[3].status = { type: 3, text: "INCOMPLETE" };
    }

    setProfileList(newList);
  };

  const getUserInfo = async () => {
    setLoading(true);

    if (await checkUser() !== true) {
      handleSessionDeactivation(0);
    }

    setProfileList([]);

    const qry = gql`
      query getContactsInfo_by_email($email: String!){
        getPrfStatus(email: $email){
          submitdate status link
        }
        getFormsContactsInfo_by_email(email: $email){
          paypal_id paypal_active verification_status
        }
        getContactsInfo_by_email(email: $email){
          paypal_id paypal_active
        }
      }`;

    try {
      const response: any = await API.graphql(graphqlOperation(qry, {
        email: managedUserInfo.email,
      }));

      initializeCatList(response);
    } catch (error) {
      console.log("profileCategory->getuser->error", error);
      initializeCatList(error);
    }


    setLoading(false);
  };

  async function updateSurveyStatus() {
    props.navigation.removeListener("focus", () => {});
    setLoading(true);
    try {
      const newList = profileList;

      // setProfileList([]);
      const qry = gql`
      query qry($panelist_id: Int!, $email: String!){
        getMiniSurvey(panelist_id: $panelist_id){
          question_id question response
        }
        getFormsContactsInfo_by_email(email: $email){
          paypal_id paypal_active verification_status
        }
        getContactsInfo_by_email(email: $email){
          paypal_id paypal_active
        }
      }`;
      const response: any = await API.graphql(graphqlOperation(qry, {
        panelist_id: managedUserInfo.panelist_id,
        email: managedUserInfo.email,
      }));

      const formData = response.data?.getFormsContactsInfo_by_email;
      const contactData = response.data?.getContactsInfo_by_email;


      if ((formData?.paypal_active === 1 || formData?.paypal_active === 2)
      // && (managedUserInfo.paypal_id !== formData.paypal_id)
        && formData?.verification_status) {
        newList[3].status = { type: 2, text: "Pending Verification" };
      } else if (contactData?.paypal_active !== 1 && contactData?.paypal_active !== 2) {
        newList[3].status = { type: 3, text: "INCOMPLETE" };
      } else {
        newList[3].status = { type: 0, text: "" };
      }

      setProfileList(newList);
    } catch (e) {
      console.log(["failed update survey status", e]);
    }
    setLoading(false);
  }

  const data = profileList.map((listProps: any) => ({
    ...listProps,
    onPress: () => {
      if (props.navigation
        && listProps.navigateTo) {
        props.navigation.addListener("focus", () => {
          updateSurveyStatus();
        });

        props.navigation.push(listProps.navigateTo, {
          ...listProps,
        });
      } else if (listProps.link) {
        if (Platform.OS === "web") {
          window.open(listProps.link, "profile").focus();
        } else {
          Linking.openURL(listProps.link);
        }
      } else {
        return false;
      }
    },
  }));

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    getUserInfo();
    setRefreshing(false);
  }, [loading, managedUserInfo, props.navigation]);

  useEffect(() => {
    getUserInfo();

    return () => {

    };
  }, [managedUserInfo, props.navigation]);

  useEffect(() => {
    sendAnalyticsEvent("Profile");
  }, []);

  const iconColor = color(theme.colors.text)
    .alpha(0.54)
    .rgb()
    .string();

  const mutedContentColor = color(theme.colors.text)
    .alpha(0.67)
    .rgb()
    .string();

  const separatorColor = color(theme.colors.text)
    .alpha(0.1)
    .rgb()
    .string();

  // const surfaceBackground = theme.dark
  //   ? (overlay(6, theme.colors.surface) as string)
  //   : "white";

  const surfaceBackground = theme.dark
    ? theme.colors.background
    : "white";

  const RenderItem = ({ item }: { item: ListProps }) => (!item.hidden ? (
    <View>
      {
        item.id === 0 ? (
          <View style={[styles.wrapperListItemSubheader, { backgroundColor: theme.colors.background }]}>
            <Text style={styles.listItemSubheader}>
              {translate(item.title)}
            </Text>
          </View>
        )
          : (
            <TouchableRipple
              style={{ flexDirection: "row" }}
              onPress={() => item.onPress && item.onPress(item.id)}
            >
              <View style={[styles.listItem, { backgroundColor: surfaceBackground }]}>
                <IconComponent {...item.icon} color={iconColor} size={24} />
                <View style={styles.listItemContent}>
                  <Text style={[styles.listItemTitle, { color: theme.colors.text }]}>{translate(item.title)}</Text>
                  <Text style={[styles.listItemSubtitle, { color: mutedContentColor }]}>{translate(item.description)}</Text>
                  <View style={{ flexDirection: "row", paddingTop: 4 }}>
                    {
                    item.status && item.status.type !== 0
                    && (
                    <Text style={{
                      // eslint-disable-next-line no-nested-ternary
                      color: item.status.type === 1
                        ? ColorsEvidera.secondary
                        : item.status.type === 2 ? ColorsEvidera.warning
                          : ColorsEvidera.danger,
                      fontWeight: "bold",
                    }}
                    >
                      {`${translate(item.status.text)}`}

                    </Text>
                    )
                  }

                  </View>
                </View>
                {item.status && item.link && item.link !== ""
              && (
                <View style={{
                  alignSelf: "stretch", flexDirection: "row", alignItems: "center",
                }}
                >
                  <MaterialIcons name="play-circle-outline" color={theme.colors.text} size={36} />
                </View>
              )}
              </View>

            </TouchableRipple>
          )
      }
    </View>
  ) : (
    <></>
  ));

  const renderEmptyComponent = () => (
    <View style={{ paddingHorizontal: 8, paddingTop: 30 }}>
      {loading ? (
        <ContentLoader animationDuration={500} avatar active pRows={2} pWidth={["90%", "40%"]} listSize={3} />
      ) : (
        <ZeroRequestState
          title={translate("Profile not found")}
        />
      )}
    </View>
  );

  const IconComponent = (iProps: any) => {
    // eslint-disable-next-line react/prop-types
    const MyComponent = components[iProps.type];
    return <MyComponent {...iProps} />;
  };
  // Analytics.logEvent("Profile");
  return (
    <>
      <AppHeader>
        <Image
          source={require("../../../assets/medimix-evidera-2.png")}
          resizeMode="contain"
          style={{
            height: 30,
            width: 200,
          }}
        />

      </AppHeader>
      <Surface style={[styles.container, { backgroundColor: ColorsEvidera.primary }]}>

        <FlatList
          contentContainerStyle={{ backgroundColor: theme.colors.background, paddingBottom: 20 }}
          style={{ backgroundColor: theme.colors.background }}
          data={data}
          renderItem={RenderItem}
          keyExtractor={keyExtractor}
          refreshing={loading}
          stickyHeaderIndices={(profileList.length > 0 ? [1] : [0])}
          refreshControl={
            <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }
          ListHeaderComponent={
          (managedUserInfo.panelist_id && (
            <ImageBackground
              source={imageUri}
              style={[styles.headerImage,
                { backgroundColor: ColorsEvidera.primary }]}
              resizeMode="cover"
            >
              <View style={[styles.headerWrapper, { backgroundColor: "rgba(0,0,0,0.50)" }]}>
                {loading
                  ? (
                    <View style={{ flex: 1, alignItems: "center" }}>
                      <ContentLoader
                        animationDuration={500}
                        active
                        pRows={1}
                        listSize={1}
                        tHeight={20}
                        tWidth={220}
                        titleStyles={{ marginLeft: 7, marginTop: 7, marginBottom: 4 }}
                        pHeight={12}
                        pWidth={120}
                        paragraphStyles={{ marginLeft: 7, marginBottom: 0 }}
                      />
                    </View>
                  ) : (
                    <View style={styles.userInfoSection}>

                      <Title style={styles.title}>{`${managedUserInfo.first_name} ${managedUserInfo.last_name}`}</Title>
                      <Text style={[styles.subtitle, { color: "white", fontWeight: "bold" }]}>{managedUserInfo.uic}</Text>
                    </View>
                  )}
              </View>
            </ImageBackground>
          ))
        }
          ListEmptyComponent={renderEmptyComponent}
          ItemSeparatorComponent={() => (<View style={{ backgroundColor: separatorColor, height: StyleSheet.hairlineWidth }} />)}
        />
      </Surface>

    </>
  );
};
