/* eslint-disable camelcase */
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import {
  DarkTheme as nDarkTheme, DefaultTheme as nDefaultTheme, LinkingOptions, NavigationContainer,
} from "@react-navigation/native";
import {
  createStackNavigator,
  StackNavigationProp,
} from "@react-navigation/stack";
import * as Linking from "expo-linking";
import React, { useEffect, useState } from "react";
import { View } from "react-native";
import {
  ActivityIndicator, DarkTheme, DefaultTheme, Provider as PaperProvider,
} from "react-native-paper";
import { runQueryTranslation } from "../components";
import CookieConsent from "../components/CookieConsent";
import { ErrorView } from "../components/ErrorView";
import { translate } from "../components/Localei18n/languagesFunctions";
import SessionManager from "../components/SessionManager";
import { PreferencesContext } from "../context/preferencesContext";
import { UserProvider } from "../context/UserContext";
import UserInfoContextProvider from "../context/userInfo";
import UnsecureStorage from "../data/UnsecureStorage";
import ExpoReleaseEnvironments from "../ExpoReleaseEnvironments";
import useAmplifyAuth from "../helpers/useAmplifyAuth";
import UserRegistrationService from "../helpers/user.registration.service";
import { RootNavigator } from "../navigation/root.navigator";
import { RouteConfig } from "../navigation/RouteConfig";
// eslint-disable-next-line no-unused-vars
import { StackNavigatorParamlist } from "../navigation/types";
import { ColorsEvidera } from "../theme/variables";
import { PersonalInfo, Review } from "./Auth";
import AuthContextProvider from "./Auth/AuthContext";
import Authenticate from "./Auth/Authenticate";
import { ReferenceDataContext } from "./Auth/PersonalInfo/ReferenceDataContext";
import LandingDownload from "./landing/landing.download.page";
import LandingMain from "./landing/landing.main.page";
import { TermsConditions } from "./preferences/terms.conditions";
import Inactive from "./welcome/inactive";

Bugsnag.start({
  apiKey: ExpoReleaseEnvironments().bugsnagKey,
  plugins: [new BugsnagPluginReact()],
});

type NavProps = {
  // eslint-disable-next-line react/require-default-props
  navigation?: StackNavigationProp<StackNavigatorParamlist>;
};
const prefix = Linking.createURL("/");

const RootStack = createStackNavigator<StackNavigatorParamlist>();
const PERSISTENCE_KEY = "NAVIGATION_STATE";

const routesProfileMapping = {
  EditHcpDetails: "Profile",
  EditContactsAddress: "Profile",
  AddContactsAddress: "Profile",
  EditContactsEmail: "Profile",
  AddContactsEmail: "Profile",
  EditContactsNumber: "Profile",
  AddContactsNumber: "Profile",
  ProfileContactsContacts: "Profile",
  EditContactsPersonal: "Profile",
  ProfileContactsAddressList: "Profile",
  EditPayment: "Profile",
  ProfileContacts: "Profile",
};

export const Main = () => {
  const {
    createContact,
    countParticipation,
    updateRegistrationStatus,
  } = UserRegistrationService();
  const [participation, setParticipation] = useState(0);
  const [signOutInactiveFromWeb, setSignOutInactiveFromWeb] = useState(false);

  const [loading, setLoading] = useState(true);
  const [isReady, setIsReady] = React.useState(false);
  const [initialState, setInitialState] = React.useState();
  const [showMessage, setShowMessage] = useState("");

  const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);
  const routeNameRef = React.useRef();
  const navigationRef: any = React.useRef();
  let unmounted = false;
  const {
    state: { user, isLoading },
    handleSignout,
    updateUserAttributes,
    refreshCurrentUser,
  } = useAmplifyAuth();

  // Managing Theme
  const [theme, setTheme] = React.useState<"light" | "dark">("light");

  const navigationTheme = theme === "dark" ? nDarkTheme : nDefaultTheme;
  const [keepLogin, toggleKeepLogin] = useState(false);

  const getDefaultThemeAsync = async () => {
    try {
      if (user && user?.attributes?.["custom:review_status"].toString() === "2") {
        const xTheme = UnsecureStorage.getItemAsync("defaultTheme");
        if (xTheme !== null && !unmounted) {
          setTheme(xTheme === "dark" ? "dark" : "light");
        }
      } else {
        setTheme("light");
      }
    } catch (error) {
      // Error retrieving data
      if (!unmounted) {
        console.log(error.message);
      }
    }
  };

  function toggleTheme() {
    setTheme(() => (theme === "light" ? "dark" : "light"));
    UnsecureStorage.setItemAsync(
      "defaultTheme",
      theme === "light" ? "dark" : "light"
    );
  }

  const getThemeValue = () => {
    if (theme !== "dark" || user?.attributes?.["custom:review_status"] !== "2") {
      return {
        ...DefaultTheme,
        colors: {
          ...DefaultTheme.colors,
          primary: ColorsEvidera.primary,
          accent: ColorsEvidera.secondary,
          warn: ColorsEvidera.accent,
        },
      };
    }

    return {
      ...DarkTheme,
      colors: {
        ...DarkTheme.colors,
        primary: ColorsEvidera.accent,
        accent: ColorsEvidera.secondary,
        warn: ColorsEvidera.accent,
      },
    };
  };

  const preferences = React.useMemo(
    () => ({
      theme,
      keepLogin,
      toggleTheme,
      toggleKeepLogin,
    }),
    [theme, keepLogin]
  );

  const linking: LinkingOptions = {
    prefixes: [prefix],
    config: RouteConfig(),
  };

  const pageAccessedByReload = (
    (window.PerformanceNavigationTiming && PerformanceNavigationTiming.type === "reload")
  );

  const getInitialRouteName = (cognitoUser) => {
    if (!cognitoUser) return "Authenticate";
    // Check how to verify the phone numebr is verified if not cognitoUser need to complete PersonalInfo
    // eslint-disable-next-line camelcase
    if (user?.attributes?.["custom:tester"] !== "1"
      && (user?.attributes?.["custom:review_status"] === "3"
        || user?.attributes?.["custom:review_status"] === "1"
        || (!user?.attributes?.["custom:panelist_id"]
          && (!user?.attributes?.["custom:review_status"])))
    ) {
      return "PersonalInfo";
    }
    // Redirect cognitoUser to review if status is Inactive, Permanent Inactive or in review (panel.contact_status)
    if (user?.attributes?.["custom:tester"] !== "1"
      && user?.attributes?.["custom:review_status"] === "6"
    ) {
      return "Review";
    }
    if (user?.attributes?.["custom:tester"] !== "1"
      && cognitoUser.attributes?.["custom:review_status"] === "4") {
      return "Inactive";
    }
    if (pageAccessedByReload) {
      const currentLastPathName = window.location.pathname.substring(window.location.pathname.lastIndexOf("/") + 1);

      if (currentLastPathName in routesProfileMapping) {
        return routesProfileMapping[currentLastPathName];
      }

      if (currentLastPathName.includes("account")) {
        return "Account";
      }

      if (currentLastPathName.includes("support")) {
        return "Support";
      }

      // in instance of last path name is still authenticate, when storage, caches & cookies is cleared
      // sometime issue occurs
      if (currentLastPathName.includes("authenticate")) {
        return "Dashboard";
      }

      return "notifications";
    }
    return "Dashboard";
  };
  const handleNavigationSignout = async () => {
    handleSignout().then(() => {
      try {
        navigationRef.current?.navigate("Home");
      } catch (error) {
        console.log(error);

        return new Promise((resolve) => resolve(false));
      }
      return new Promise((resolve) => resolve(true));
    });
  };

  const RootNavigatorScreenHandler = (props: NavProps) => (
    <UserProvider value={user}>
      <UserInfoContextProvider>
        <RootNavigator
          {...props}
          user={user}
          handleSignout={handleNavigationSignout}
          userStatus={!isLoading}
          initialRoute={getInitialRouteName(user)}
        />
      </UserInfoContextProvider>
    </UserProvider>
  );
  const AuthScreenHandler = (props: NavProps) => (
    <UserProvider value={user}>
      <AuthContextProvider>
        <Authenticate
          {...props}
          user={user}
          userStatus={!isLoading}
          initialRoute={getInitialRouteName(user)}
          refreshCurrentUser={refreshCurrentUser}
          setShowMessage={setShowMessage}
          showMessage={showMessage}
        />
      </AuthContextProvider>
    </UserProvider>
  );
  const LandingScreenHandler = (props: NavProps) => (
    <UserProvider value={user}>
      <AuthContextProvider>
        <LandingMain
          {...props}
          user={user}
          userStatus={!isLoading}
          initialRoute={getInitialRouteName(user)}
          signOutInactiveFromWeb={signOutInactiveFromWeb}
          setSignOutInactiveFromWeb={setSignOutInactiveFromWeb}
        />
      </AuthContextProvider>
    </UserProvider>
  );
  const DownloadScreenHandler = (props: NavProps) => (
    <AuthContextProvider>
      <LandingDownload {...props} />
    </AuthContextProvider>
  );
  const TermsAndConditionsScreenHandler = (props: NavProps) => (
    <TermsConditions
      language="en"
      {...props}
    />
  );
  const TermsAndConditionsScreenHandlerFR = (props: NavProps) => (
    <TermsConditions
      language="fr"
      {...props}
    />
  );
  const TermsAndConditionsScreenHandlerPT = (props: NavProps) => (
    <TermsConditions
      language="pt"
      {...props}
    />
  );
  const TermsAndConditionsScreenHandlerGR = (props: NavProps) => (
    <TermsConditions
      language="gr"
      {...props}
    />
  );
  const TermsAndConditionsScreenHandlerES = (props: NavProps) => (
    <TermsConditions
      language="es"
      {...props}
    />
  );
  const TermsAndConditionsScreenHandlerIT = (props: NavProps) => (
    <TermsConditions
      language="it"
      {...props}
    />
  );

  const PersonalInfoScreenHandler = (props: NavProps) => {
    const Auth = useAmplifyAuth();
    const [personalInfoData, updatePersonalInfoData] = useState({});
    const [stage, setStage] = useState(user?.attributes?.["custom:review_status"] === "3" && participation > 0 ? 2 : 0);

    const handlePersonalInfoData = (data) => {
      updatePersonalInfoData(data);
    };
    const handleUpdateStage = (stg: number) => {
      setStage(stg);
    };

    const personalInfo = React.useMemo(
      () => ({
        personalInfoData,
        stage,
        setPersonalInfoData: handlePersonalInfoData,
        setStage: handleUpdateStage,
      }),
      [personalInfoData, stage]
    );
    return (
      <ReferenceDataContext.Provider value={personalInfo}>
        <UserProvider value={user}>
          <AuthContextProvider>
            <PersonalInfo
              {...props}
              startStage={participation > 0 && user?.attributes?.["custom:review_status"] === "3" ? 2 : 0}
              user={user}
              handleSignout={handleNavigationSignout}
              updateUserAttributes={Auth.updateUserAttributes}
              verifyCurrentUserAttributeSubmit={
              Auth.verifyCurrentUserAttributeSubmit
            }
              userStatus={!isLoading}
              initialRoute={getInitialRouteName(user)}
            />
          </AuthContextProvider>
        </UserProvider>
      </ReferenceDataContext.Provider>
    );
  };

  const ReviewScreenHandler = (props: NavProps) => (
    <UserProvider value={user}>
      <Review
        {...props}
        user={user}
        userStatus={!isLoading}
        handleSignout={handleSignout}
        initialRoute={getInitialRouteName(user)}
      />
    </UserProvider>
  );

  const InactiveScreenHandler = (props: NavProps) => (
    <UserProvider value={user}>
      <Inactive
        {...props}
        user={user}
        userStatus={!isLoading}
        handleSignout={handleSignout}
        initialRoute={getInitialRouteName(user)}
      />
    </UserProvider>
  );

  const onInit = async () => {
    if (unmounted) return;
    if (user?.attributes?.["custom:panelist_id"]) {
      setParticipation(await countParticipation(user?.attributes?.["custom:panelist_id"]));
    }
    if (user?.attributes && !user?.attributes?.["custom:tester"]) {
      await createContact(user)
        .then(async (contact: any) => {
          if (contact.tester?.id) {
            await updateUserAttributes(user, { "custom:tester": "1" });
          } else {
            if (contact.contact?.contact_id) {
              const count = contact.contact?.panelist_id ? await countParticipation(contact.contact?.panelist_id) : 0;

              if (count > 0 && contact.contact?.contact_status_id.toString() === "2") {
                updateRegistrationStatus(contact.contact?.contact_id, 7, "System");
              }

              await updateUserAttributes(user, {
                ...{ "custom:contact_id": contact.contact?.contact_id.toString() },
                "custom:tester": "0",
                ...(contact.contact?.panelist_id ? { "custom:panelist_id": contact.contact?.panelist_id.toString() } : {}),
                ...(contact.contact?.contact_status_id ? { "custom:review_status": (count <= 0 && contact.contact?.contact_status_id.toString() === "2" ? "1" : contact.contact?.contact_status_id.toString()) } : {}),
              });
            }

            // get number of completet Participations
            if (contact.contact?.panelist_id) {
              const count = await countParticipation(contact.contact?.panelist_id);
              setParticipation(count);
            }
          }
        })
        .catch((e) => {
          console.error("failed to initialize, root ", e);
        });
    }

    if (!user) {
      await runQueryTranslation();
    }

    if (!unmounted) setLoading(false);
  };

  useEffect(() => {
    // executed when component mounted
    getDefaultThemeAsync();
    onInit();

    const savedStateString = UnsecureStorage.getItemAsync(PERSISTENCE_KEY);
    const state = savedStateString ? JSON.parse(savedStateString) : undefined;
    if (state !== undefined) {
      setInitialState(state);
    }

    return () => {
      // executed when unmount
      unmounted = true;
    };
  }, [user]);

  return (
    <>
      <style type="text/css">
        {
          `input {
              outline: none !important;
           }`
        }
      </style>

      {loading || (isLoading && !user) ? (
        <View style={{ flex: 1, justifyContent: "center" }}>
          <ActivityIndicator size={40} />
        </View>
      ) : (
        <ErrorBoundary FallbackComponent={ErrorView}>
          <PreferencesContext.Provider value={preferences}>
            <PaperProvider theme={getThemeValue()}>
              <SessionManager
                userInfo={{ ...user?.attributes }}
                user={!!user}
                handleSignout={handleSignout}
                showMessage={showMessage}
                setShowMessage={setShowMessage}
                setSignOutInactiveFromWeb={setSignOutInactiveFromWeb}
              >
                <NavigationContainer
                  linking={linking}
                  independent
                  ref={navigationRef}
                // initialState={initialState}
                  onStateChange={(state) => {
                    const previousRouteName = routeNameRef.current;
                    const currentRouteName = navigationRef.current.getCurrentRoute().name;

                    if (previousRouteName !== currentRouteName) {
                      // The line below uses the expo-firebase-analytics tracker
                      // https://docs.expo.io/versions/latest/sdk/firebase-analytics/
                      // Change this line to use another Mobile analytics SDK
                      // Analytics.logEvent(currentRouteName);
                    }

                    // Save the current route name for later comparision
                    routeNameRef.current = currentRouteName;
                    UnsecureStorage.setItemAsync(
                      PERSISTENCE_KEY,
                      JSON.stringify(state)
                    );
                  }}
                  theme={navigationTheme}
                >
                  <RootStack.Navigator
                    initialRouteName="Home"
                    headerMode="none"
                  >

                    <RootStack.Screen
                      name="Home"
                      component={LandingScreenHandler}
                      options={{ title: translate("Home") }}
                    />

                    <RootStack.Screen
                      name="Download"
                      component={DownloadScreenHandler}
                      options={{ title: translate("Download") }}
                    />

                    <RootStack.Screen
                      name="Dashboard"
                      component={RootNavigatorScreenHandler}
                    />

                    <RootStack.Screen
                      name="Authenticate"
                      component={AuthScreenHandler}
                      options={{ title: translate("Authenticate") }}
                    />

                    <RootStack.Screen
                      name="PersonalInfo"
                      component={PersonalInfoScreenHandler}
                      options={{ title: translate("Personal Information") }}
                    />

                    <RootStack.Screen
                      name="Review"
                      component={ReviewScreenHandler}
                      options={{ title: translate("Review") }}
                    />

                    <RootStack.Screen
                      name="TermsAndConditions"
                      component={TermsAndConditionsScreenHandler}
                      options={{ title: translate("Terms and conditions") }}
                    />

                    <RootStack.Screen
                      name="TermsAndConditionsPT"
                      component={TermsAndConditionsScreenHandlerPT}
                      options={{ title: translate("Terms and conditions") }}
                    />

                    <RootStack.Screen
                      name="TermsAndConditionsFR"
                      component={TermsAndConditionsScreenHandlerFR}
                      options={{ title: translate("Terms and conditions") }}
                    />

                    <RootStack.Screen
                      name="TermsAndConditionsGR"
                      component={TermsAndConditionsScreenHandlerGR}
                      options={{ title: translate("Terms and conditions") }}
                    />

                    <RootStack.Screen
                      name="TermsAndConditionsES"
                      component={TermsAndConditionsScreenHandlerES}
                      options={{ title: translate("Terms and conditions") }}
                    />

                    <RootStack.Screen
                      name="TermsAndConditionsEIT"
                      component={TermsAndConditionsScreenHandlerIT}
                      options={{ title: translate("Terms and conditions") }}
                    />

                    <RootStack.Screen
                      name="Inactive"
                      component={InactiveScreenHandler}
                      options={{ title: translate("Review") }}
                    />
                  </RootStack.Navigator>
                </NavigationContainer>

                <CookieConsent theme={theme} />
              </SessionManager>
            </PaperProvider>
          </PreferencesContext.Provider>
        </ErrorBoundary>
      )}
    </>
  );
};
