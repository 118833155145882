import * as Localization from "expo-localization";
import { I18n } from "@aws-amplify/core";
// import API, { graphqlOperation } from "@aws-amplify/api";
import { API, graphqlOperation } from "aws-amplify";
import AsyncStorage from "@react-native-async-storage/async-storage";
import gql from "graphql-tag";
import { Platform } from "react-native";
import * as SecureStore from "expo-secure-store";
import _ from "lodash";
import secureStoreOptionsKeychain from "../../data/secure-store-keychain";

const secureStoreOptions = {
  keychainService: secureStoreOptionsKeychain,
  keychainAccessible: SecureStore.ALWAYS, // iOS only
};

let systemLanguage = Localization.locale.substring(0, 2);

let language = { language: "en" };

const Localei18nFn = () => {
  const getlang = async () => {
    const xLang = await AsyncStorage.getItem("language");
    return xLang == "" ? JSON.stringify({ language: "en", preferredLanguage: "en", language_name: "English" }) : xLang;
  };

  const errorsMapEntries = [
    ["Username cannot be empty", /Username.*cannot.*be.*empty/i],
    ["User does not exist", /user.*not.*exist/i],
    ["User already exists", /user.*already.*exist/i],
    ["Incorrect username or password", /incorrect.*username.*password/i],
    ["Invalid password format", /validation.*password/i],
    [
      "Invalid phone number format",
      /invalid.*phone/i,
      "Invalid phone number format. Please use a phone number format of +12345678900",
    ],
  ];

  const errorMap = (message) => {
    const match = errorsMapEntries.filter(([, entry]) => entry.test(message));
    if (match.length === 0) {
      return message;
    }

    const entry = match[0];
    const msg = entry.length > 2 ? entry[2] : entry[0];

    return I18n.get(entry[0], msg);
  };

  const getDataListLangages = _.memoize(async () => {
    const query = gql`
  query getListLanguages {
    getListLanguages {
      language_code language
    }
  }
  `;

    try {
      const result = await API.graphql(graphqlOperation(query));
      const JSONListLanguages = JSON.stringify(result.data.getListLanguages);
      return JSON.parse(JSONListLanguages);
    } catch (err) {
      console.log(err);
      return null;
    }
  });

  const runQueryTranslation = async () => {
    try {
      if (Platform.OS === "web") {
        const xLang = await getlang();
        language = JSON.parse(await xLang);
      } else {
        language = JSON.parse(await SecureStore.getItemAsync("language", secureStoreOptions));
      }

      let name = "";
      if (language) {
        systemLanguage = language.language;
      } else {
        const list = await getDataListLangages();
        const simpleList = [];
        name = list[list.findIndex((x) => x.language_code === systemLanguage)].language;

        if (list.length > 0) {
          list.forEach((lng) => {
            simpleList.push(lng.language_code);
          });
        }

        if (simpleList.indexOf(systemLanguage) === -1) {
          systemLanguage = "en";
        }
      }

      const qryListTranslation = gql`
      query listTranslations {
        listTranslations(language_code: "${systemLanguage}") {
          label
          translation
        }
      }
      `;

      const translations = {};
      const result = await API.graphql(graphqlOperation(qryListTranslation));
      translations[systemLanguage] = {};
      result.data.listTranslations.forEach((item) => {
        translations[systemLanguage][item.label] = item.translation;
      });
      I18n.putVocabularies(translations);
      window.currentLanguage = systemLanguage;
      if (Platform.OS === "web") {
        AsyncStorage.setItem("currentLanguage", systemLanguage);
        AsyncStorage.setItem("language", JSON.stringify({ language: systemLanguage, preferredLanguage: systemLanguage, language_name: name }));
      } else {
        SecureStore.setItemAsync("currentLanguage", systemLanguage, secureStoreOptions);
        SecureStore.setItemAsync("language", JSON.stringify({ language: systemLanguage, preferredLanguage: systemLanguage, language_name: name }), secureStoreOptions);
      }
      I18n.setLanguage(systemLanguage);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log("error: ", err);
    }
  };

  return {
    runQueryTranslation, getDataListLangages, errorMap,
  };
};

export const { runQueryTranslation, getDataListLangages, errorMap } = Localei18nFn();

