import React, { useEffect } from "react";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { StyleProp, StyleSheet, Text, View, ViewStyle } from "react-native";
import { translate } from "./Localei18n/languagesFunctions";

type Props = {
  password: String;
  min: number;
  style?: StyleProp<ViewStyle>;
}

const styles = StyleSheet.create({
  error: { color: "red" },
  ok: { color: "green" },
});

const CognitoPasswordChecker = (props: Props) => {
  const policies = [
    { code: "acceptableCharacter", message: translate("Password must be a combination of alphanumeric, special characters and no spaces") },
    { code: "mustHaveLowercase", message: translate("Password must have lowercase") },
    { code: "mustHaveUppercase", message: translate("Password must have uppercase") },
    { code: "mustContainNumber", message: translate("Password must contain a number") },
    { code: "minCharacter", message: translate(`Password must be atleast ${props.min} characters in length`) },
    { code: "mustContainSpecialCharacter", message: translate("Password must contain atleast 1 special character") },
  ];

  const policyChecker = (policy, password = "") => {
    if (policy.code === "acceptableCharacter") {
      // return password.length > 0 && !/\s/g.test(password);
      return password?.length > 0 && !/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]$/g.test(password) && !/\s/g.test(password);
    }
    if (policy.code === "mustHaveLowercase") {
      return password?.length > 0 && /[a-z]/.test(password);
    }
    if (policy.code === "mustHaveUppercase") {
      return password?.length > 0 && /[A-Z]/.test(password);
    }
    if (policy.code === "mustContainNumber") {
      return /[0-9]/.test(password);
    }
    if (policy.code === "minCharacter") {
      return password?.length >= props.min;
    }
    if (policy.code === "mustContainSpecialCharacter") {
      return /[,!@#$%^&*()_+{}\[\]\\|:;'"<.>/?-]{1}/g.test(password);
    }

    return false;
  };

  useEffect(() => {

  }, [props.password]);

  return (
    <View style={props.style}>
      {
        policies.map((e, i) => {
          const policyStyle = policyChecker(e, props.password) ? styles.ok : styles.error;
          return (
            <View
              key={`cognitoPass-${i}`}
              style={[
                { flexDirection: "row", marginBottom: 3 }]}
            >
              <View style={{ marginRight: 5, marginTop: 2 }}>
                <MaterialCommunityIcons name={policyChecker(e, props.password) ? "check-circle" : "checkbox-blank-circle-outline"} size={14} {...(policyStyle)} />
              </View>
              <View style={{ flex: 1 }}>
                <Text style={policyStyle}>{e.message}</Text>
              </View>
            </View>
          );
        })
      }

    </View>
  );
};

export const passwordPolicyCheck = (password = "", min) => {
  if (!(password?.length > 0 && !/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]$/g.test(password) && !/\s/g.test(password))) {
    return false;
  }

  if (!(password?.length > 0 && /[a-z]/.test(password))) {
    return false;
  }

  if (!(password?.length > 0 && /[A-Z]/.test(password))) {
    return false;
  }

  if (!(/[0-9]/.test(password))) {
    return false;
  }

  if (!(password?.length >= min)) {
    return false;
  }

  if (!(/[,!@#$%^&*()_+{}\[\]\\|:;'"<.>/?-]{1}/g.test(password))) {
    return false;
  }

  return true;
};

export default CognitoPasswordChecker;
