/* eslint-disable camelcase */
import React, { useState, useCallback, useEffect } from "react";
import { View, KeyboardAvoidingView, Platform, ScrollView } from "react-native";
import { Picker } from "@react-native-picker/picker";
import { useForm, Controller } from "react-hook-form";
import { TextInput, useTheme, Title, Subheading, Text, ActivityIndicator } from "react-native-paper";
import debounce from "lodash/debounce";
import { API, graphqlOperation } from "aws-amplify";
import gql from "graphql-tag";
import { styles } from "./forms.style";
import { HcpIdentification } from "../types"; // eslint-disable-line no-unused-vars

import { translate } from "../../../components/Localei18n/languagesFunctions";
import FormField from "../../../components/FormValidator/FormField";
import { PickerCustom } from "../../../components/picker.custom";

const EditHcpDetails = (props) => {
  const theme = useTheme();
  const [unmounted, setUnmounted] = useState(false);
  const {
    control, getValues, setValue, register, trigger, watch, formState: { errors },
  } = useForm({ mode: "onChange", reValidateMode: "onChange" });

  const [Countries, setCountries] = useState([]);
  const [Targets, setTargets] = useState([]);
  const [Specialty, setSpecialty] = useState([]);
  const [Specialty2, setSpecialty2] = useState([]);
  const [Salutations, setSalutations] = useState([]);

  const [renderedPrimarySpecialty, setRenderedPrimarySpecialty] = useState(null);
  const [renderedSecondarySpecialty, setRenderedSecondarySpecialty] = useState(null);

  // const [inputData, setInputData] = useState<HcpIdentification>(props.route.params); // eslint-disable-line react/prop-types
  const [inputData, setInputData] = useState<HcpIdentification>(props.route.params); // eslint-disable-line react/prop-types

  const [loading, setLoading] = useState(true);

  const update = useCallback(debounce(async (data: object) => {
    const mutation = gql`
      mutation updateFormsContacts($formsContactsInput: FormsContactsInput! , $panelist_id: Int!){
        insertIgnoreFormsContacts(panelist_id: $panelist_id)
        updateFormsContacts(formsContactsInput: $formsContactsInput)
      }
    `;

    try {
      // const response = await API.graphql(graphqlOperation(mutation, {
      const response = await API.graphql(graphqlOperation(mutation, {
        formsContactsInput: { ...data, panelist_id: inputData.panelist_id },
        panelist_id: inputData.panelist_id,
      }));

      // props.route.params.reload();
      // if (response.data.insertIgnoreFormsContacts && response.data.updateFormsContacts) {
      //   updateManageUser({ ...managedUserInfo, paypal_active: inputData.paypal_active });
      // }
    } catch (err) {
      console.log(["failed to update contacts", err, data]);
    }
  },
  1000), []);

  const autoSave = async () => {
    await trigger();
    if (Object.keys(errors).length === 0) {
      update(getValues());
      // Analytics.logEvent("PaymentInfoUpdated");
    }
  };


  const onInit = async () => {
    try {
      const qry = gql`
      query getoptions($code: String!){
          getTargets {
            target_code target_name
          }
          getCountries {
            country_code country_name
          }
          getSpecialty(code: $code) {
            code name target_code
          }
          getSpecialty2(code: $code) {
            code name level1
          }
          getListSalutations(code: $code) {
            salutation_code salutation_name target_code
          }
      }
    `;

      const response: any = await API.graphql(graphqlOperation(qry, {
        code: "%",
      }));

      if (unmounted) return false;
      setSpecialty(response.data.getSpecialty);
      setSpecialty2(response.data.getSpecialty2);
      setTargets(response.data.getTargets);
      setCountries(response.data.getCountries);
      setSalutations(response.data.getSpecialty);
    } catch (e) {
      console.log("error:", e?.errors);
      if (unmounted) return false;
    }

    console.log("stop loading");
    setLoading(false);
    return true;
  };

  useEffect(() => {
    register("panelist_id", { value: props.route.params.panelist_id });

    onInit();

    return () => {
      setUnmounted(true);
    };
  }, []);

  useEffect(() => {
    trigger();
    console.log(inputData);

    setRenderedPrimarySpecialty(inputData?.level1_specialty_code);
    setRenderedSecondarySpecialty(inputData?.level2_specialty_code);
  }, []);

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={[
        styles.root,
        { backgroundColor: theme.colors.background },
      ]}
    >
      <ScrollView style={styles.scrollWrapper}>
        <View style={styles.scrollInnerResponsive}>
          <View style={styles.titleWRapper}>
            <Title style={styles.title}>{translate("HCP Identification")}</Title>
            <Subheading style={styles.subheadingRegular}>
              {translate("Remember that changes to your professional information is subject for verification.")}
            </Subheading>
          </View>
          {
            !loading

              ? (
                <>
                  <FormField
                    style={{ marginTop: 10 }}
                    errors={errors?.target_code}
                  >
                    <Controller
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <PickerCustom
                          style={{ flex: 1 }}
                          value={value}
                          label={translate("Health Category")}
                          mode="outlined"
                          display="default"
                          required
                          onChange={(itemValue) => {
                            onChange(itemValue);
                            // setValue("target_code", itemValue);
                            setInputData({ ...inputData, target_code: itemValue });
                            // console.log(itemValue.toString());
                            // console.log(Specialty);

                            // console.log(Specialty.filter((e) => e.target_code === itemValue.toString()));

                            autoSave();
                          }}
                        >
                          <Picker.Item label={translate("Select Health Category")} value="" />
                          {Targets
                            && Targets.map((e, index) => (
                              <Picker.Item
                                key={index.toString()}
                                label={`${translate(e.target_name)}`}
                                value={e.target_code.toString()}
                              />
                            ))}

                        </PickerCustom>
                      )}
                      name="target_code"
                      defaultValue={inputData?.target_code.toString()}
                    />
                  </FormField>

                  <FormField
                    style={{ marginTop: 10 }}
                    errors={errors?.level1_specialty_code}
                  >
                    <Controller
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <PickerCustom
                          style={{ flex: 1 }}
                          value={value}
                          label={translate("Primary Specialty")}
                          mode="outlined"
                          display="default"
                          required
                          onChange={(itemValue) => {
                            onChange(itemValue);
                            // const newInputData = inputData;

                            let secondarySpecCode = "";
                            if (itemValue === renderedPrimarySpecialty) {
                              secondarySpecCode = renderedSecondarySpecialty;
                            }

                            setInputData({
                              ...inputData,
                              level2_specialty_code: secondarySpecCode,
                              level1_specialty_code: itemValue,
                            });
                            setValue("level2_specialty_code", secondarySpecCode);
                            autoSave();
                          }}
                        >
                          <Picker.Item label={translate("Select Primary Specialty")} value="" />
                          {Specialty
                            && Specialty.filter((e) => ((inputData.target_code
                              && inputData.target_code !== "")
                              ? e.target_code?.toString() === inputData.target_code : true))
                              .sort((current, next) => {
                                if ((current.name).toLowerCase().includes("other")) return 1;
                                if ((next.name).toLowerCase().includes("other")) return -1;

                                if (translate(current.name) < translate(next.name)) return -1;
                                if (translate(current.name) > translate(next.name)) return 1;
                                return 0;
                              })
                              .map((e, index) => (
                                <Picker.Item
                                  key={index.toString()}
                                  label={translate(e.name)}
                                  value={e.code.toString()}
                                />
                              ))}

                        </PickerCustom>
                      )}
                      name="level1_specialty_code"
                      defaultValue={inputData?.level1_specialty_code.toString()}
                    />
                  </FormField>

                  <FormField
                    style={{ marginTop: 10 }}
                    errors={errors?.level2_specialty_code}
                  >
                    <Controller
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <PickerCustom
                          style={{ flex: 1 }}
                          value={value}
                          label={translate("Secondary Specialty")}
                          mode="outlined"
                          display="default"
                          required
                          onChange={(itemValue) => {
                            onChange(itemValue);
                            // const newInputData = inputData;
                            setInputData({ ...inputData, level2_specialty_code: itemValue });
                            // setInputData({ ...newInputData, level2_specialty_code: itemValue });
                            setValue("level2_specialty_code", itemValue);
                            autoSave();
                          }}
                        >
                          <Picker.Item label={translate("Select Secondary Specialty")} value="" />
                          {Specialty2
                            && Specialty2.filter((e) => ((inputData.level1_specialty_code
                              && inputData.level1_specialty_code !== "")
                              ? e.level1?.toString() === inputData.level1_specialty_code : true))
                              .sort((current, next) => {
                                if ((current.name).toLowerCase().includes("other")) return 1;
                                if ((next.name).toLowerCase().includes("other")) return -1;

                                if (translate(current.name) < translate(next.name)) return -1;
                                if (translate(current.name) > translate(next.name)) return 1;
                                return 0;
                              })
                              .map((e, index) => (
                                <Picker.Item
                                  key={index.toString()}
                                  label={translate(e.name)}
                                  value={e.code}
                                />
                              ))}

                        </PickerCustom>
                      )}
                      name="level2_specialty_code"
                      defaultValue={inputData?.level2_specialty_code}
                    />
                  </FormField>

                  <FormField errors={errors?.country_code}>
                    <Controller
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <PickerCustom
                          style={{ flex: 1 }}
                          value={value}
                          label={translate("Country")}
                          mode="outlined"
                          required
                          display="default"
                          onChange={(itemValue) => {
                            onChange(itemValue);
                            setInputData({ ...inputData, country_code: itemValue });
                            autoSave();
                          }}
                          withTheme={false}
                        >
                          <Picker.Item label={translate("Select Country")} value="" />
                          {Countries
                            && Countries
                              .sort((current, next) => {
                                if ((current.country_name).toLowerCase().includes("other")) return 1;
                                if ((next.country_name).toLowerCase().includes("other")) return -1;

                                if (translate(current.country_name) < translate(next.country_name)) return -1;
                                if (translate(current.country_name) > translate(next.country_name)) return 1;
                                return 0;
                              })
                              .map((e, index) => (
                                <Picker.Item
                                  key={index.toString()}
                                  label={translate(e.country_name)}
                                  value={e.country_code}
                                />
                              ))}
                        </PickerCustom>
                      )}
                      name="country_code"
                      defaultValue={inputData?.country_code || ""}
                      rules={{
                        required: {
                          value: true,
                          message: translate("Please select country"),
                        },
                      }}
                    />
                  </FormField>

                </>
              ) : (
                <View style={{ paddingTop: 30 }}>
                  <ActivityIndicator size={40} color={theme.colors.primary} />
                </View>
              )
      }
          <View style={styles.formActionField}>
            {/* <Button onPress={handleSubmit(onSubmit)} contentStyle={[styles.button]} dark mode="contained">
              Update
            </Button> */}
          </View>
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

export default EditHcpDetails;
