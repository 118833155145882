
import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  root: {
    flex: 1,
    marginTop: 25,
    marginBottom: 15,
    paddingBottom: 10,
    // paddingTop: 10,
    shadowOffset: {
      height: 2,
      width: 0,
    },
    shadowRadius: 3,
    shadowColor: "rgba(0,0,0,.25)",
    backgroundColor: "white",
    borderRadius: 10,
    paddingHorizontal: 12,
  },
  rootWeb: {
    paddingHorizontal: 30,
    width: "100%",
    minWidth: 360,
    maxWidth: 500,
    marginHorizontal: "auto",
    marginTop: 30,
    alignSelf: "center",
  },

  rootLangSelector: {
    marginBottom: 30,
    width: "100%",
    minWidth: 360,
    maxWidth: 500,
    alignSelf: "center",
  },

  contentWrapper: {
    flex: 1,
    position: "relative",
    zIndex: 1,
    backgroundColor: "transparent",
  },
  contentWrapperWeb: {
    flex: 1,
    // paddingVertical: 30,
    width: "100%",
  },
  mask: {
    flex: 1,
    backgroundColor: "rgba(255,255,255,0.4)",
    zIndex: 1,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  title: {
    fontWeight: "700",
    paddingTop: 16,
    paddingBottom: 12,
    paddingHorizontal: 10,
  },
  subheadingRegular: {
    marginTop: 10,
  },
  subheadingSmall: {
    fontSize: 16,
    letterSpacing: 1,
    marginVertical: 8,
  },
  formField: {
    flexDirection: "row",
    marginBottom: 20,
  },
  formFieldError: {
    color: "red", marginLeft: 10, marginTop: -18, height: 18, fontSize: 14,
  },
  formRow: {
    flexDirection: "column",
  },
  fluid: {
    width: "100%",
    position: "relative",
    zIndex: 1,
  },
  scrollWeb: {
    paddingHorizontal: 30,
    marginTop: "1.3rem",
    width: "100%",
  },
  formFieldImageUpload: {
    height: 144,
    backgroundColor: "rgb(167, 185, 201)",
    alignItems: "center",
    justifyContent: "center",
  },
  formActionSection: {
    paddingHorizontal: 0,
    marginHorizontal: -5,
    paddingTop: 10,
    paddingBottom: 10,
    flexDirection: "row-reverse",
  },
  formButtonDisabled: {
    backgroundColor: "rgba(100,100,100,.06)",
  },
  formButton: {
    backgroundColor: "rgba(100,100,100,.06)",
    padding: 12,
    marginHorizontal: 5,
    alignItems: "center",
    flex: 1,
  },
  extra: {
    fontWeight: "700",
  },
  boxShadow: {
    shadowColor: "rgba(60,64,67,0.302)",
    shadowOffset: {
      width: 2,
      height: 2,
    },
    shadowOpacity: 0.8,
    shadowRadius: 5,
  },
  formInput: {
    width: "100%",
  },
});
