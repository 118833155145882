import React, { useEffect, useState } from "react";
import { View, KeyboardAvoidingView, Platform, ScrollView } from "react-native";
import { useForm, Controller } from "react-hook-form";
import { Button, TextInput, useTheme, Title, Subheading, Text } from "react-native-paper";
import color from "color";
import { I18n } from "@aws-amplify/core"; // eslint-disable-line import/no-extraneous-dependencies
import Auth from "@aws-amplify/auth"; // eslint-disable-line import/no-extraneous-dependencies
import analytics from "@react-native-firebase/analytics";
import { styles } from "./changepass.style";
import FormField, { Message } from "../../components/FormValidator/FormField";
import { translate } from "../../components/Localei18n/languagesFunctions";
import TextInputPassword from "../../components/TextInputPassword";
import CognitoPasswordChecker, { passwordPolicyCheck } from "../../components/CognitoPasswordChecker";

const ChangePasswordComponent = () => {
  const theme = useTheme();
  const {
    control, getValues, formState, watch, reset, trigger, formState: { errors },
  } = useForm({ reValidateMode: "onChange", mode: "onChange" });

  const [message, setMessage] = useState("");
  const [type, setType] = useState("default");
  const [Submitting, setSubmitting] = useState(false);


  const onSubmit = (data) => {
    if (Object.keys(errors).length >= 1) return;
    if (data.newPassword !== data.retypePassword) return;

    if (!passwordPolicyCheck(getValues("newPassword"), 8)) {
      return;
    }

    setSubmitting(true);
    // return;
    Auth.currentAuthenticatedUser()
      .then((user) => {
        Auth.changePassword(user, data.password, data.newPassword)
          .then((response) => {
            setType("info");
            if (response === "SUCCESS") {
              setMessage(translate("Password was changed"));
              reset();
            }
            setSubmitting(false);
          })
          .catch((err) => {
            setType("danger");
            if (err.message === "1 validation error detected: Value at 'previousPassword' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\\S]+.*[\\S]+$") {
              setMessage(translate("You need to enter your current password"));
            } else {
              setMessage(err.message);
            }
            setSubmitting(false);
          });
      });
  };

  const passwordChangeTrackingHandler = () => {
    // Analytics.logEvent("passwordChanged");
    onSubmit(getValues());
  };


  useEffect(() => {

  }, []);

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={[
        styles.root,
        { backgroundColor: theme.colors.background },
      ]}
    >
      <ScrollView style={styles.scrollWrapper}>
        <View style={styles.scrollInner}>
          <View style={styles.titleWRapper}>
            <Subheading>{translate("Protect your account by changing your password regularly")}</Subheading>
          </View>

          <Message type={type} style={{ marginBottom: 20 }}>
            {message}
          </Message>

          <FormField errors={errors.password} style={{ marginBottom: 15 }}>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextInputPassword
                  returnKeyType="next"
                  mode="outlined"
                  secureTextEntry
                  style={styles.formInput}
                  onBlur={() => {
                    trigger("newPassword");
                  }}
                  onChangeText={onChange}
                  value={value}
                  label={translate("Current Password")}
                  placeholder={translate("Enter your current password")}
                />
              )}
              name="password"
              defaultValue={getValues("password") || ""}
              rules={{
                required: {
                  value: true,
                  message: translate("Please enter your current password"),
                },
              }}
            />
          </FormField>

          <FormField errors={errors.newPassword} style={{ marginBottom: 15 }}>
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInputPassword
                  returnKeyType="next"
                  mode="outlined"
                  secureTextEntry
                  style={styles.formInput}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  value={value}
                  label={translate("New Password")}
                  placeholder={translate("Enter your new password")}
                  required
                />
              )}
              name="newPassword"
              defaultValue={getValues("newPassword") || ""}
              rules={{
                required: {
                  value: true,
                  message: translate("Please enter your new password"),
                },
                minLength: {
                  value: 7,
                  message: translate("Minimum 7 characters required"),
                },
                validate: () => {
                  if (watch("newPassword") === watch("password")) {
                    return translate("New password must be different from old password");
                  }
                  return true;
                },
              }}
            />
          </FormField>

          <FormField
            // eslint-disable-next-line no-nested-ternary
            errors={errors.retypePassword ? errors.retypePassword
              : (formState.dirtyFields.retypePassword
                && (watch("newPassword") !== watch("retypePassword"))
                ? { message: translate("Password did not match") }
                : { message: undefined })}
            style={{ marginBottom: 15 }}
          >
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInputPassword
                  returnKeyType="next"
                  mode="outlined"
                  secureTextEntry
                  style={styles.formInput}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  value={value}
                  label={translate("Retype Password")}
                  placeholder={translate("Enter your password again")}
                />
              )}
              name="retypePassword"
              defaultValue={getValues("retypePassword") || ""}
              rules={{
                required: {
                  value: true,
                  message: translate("Please retype your new password"),
                },
                minLength: {
                  value: 7,
                  message: translate("Minimum 7 characters required"),
                },
              }}
            />
          </FormField>

          <CognitoPasswordChecker style={{ marginBottom: 18, marginTop: 10 }} min={8} password={watch("newPassword")} />


          <View style={styles.formActionField}>
            <Button
              disabled={Submitting}
              loading={Submitting}
              onPress={passwordChangeTrackingHandler}
              contentStyle={styles.button}
              mode="contained"
            >
              {translate("Change Password")}
            </Button>
          </View>
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

export default ChangePasswordComponent;
