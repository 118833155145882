import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  scrollWrapper: {
    flex: 1,
    // paddingHorizontal: 24,
    // paddingTop: 24,
    // paddingBottom: 18,
  },
  scrollInner: {
    flex: 1,
    paddingHorizontal: 24,
    paddingTop: 24,
    paddingBottom: 18,
  },
  formField: {
    flexDirection: "column",
    marginBottom: 24,
  },

  formActionField: {
    flexDirection: "row",

    paddingVertical: 24,
  },
  formInput: {
    width: "100%",
  },
  formMessageWrapper: {
    fontSize: 12,
  },
  button: {
    paddingVertical: 8,
  },
  titleWRapper: {
    paddingBottom: 22,
  },
  title: {
    fontWeight: "900",
    marginBottom: 10,
  },
});
