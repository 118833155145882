export const ListData = [
  { title: "Profile and Account ", id: 0 },
  {
    id: 1,
    title: "Contacts",
    description: "Emails, phone numbers and personal info",
    icon: { name: "notebook-multiple", type: "MaterialCommunityIcons" },
    navigateTo: "ProfileContacts",
    hidden: false,
  },
  {
    id: 2,
    title: "HCP Identification",
    description: "Professional Information",
    icon: { name: "doctor", type: "MaterialCommunityIcons" },
    navigateTo: "HCPIdentification",
    hidden: false,
  },
  {
    id: 3,
    title: "Payment Information",
    description: "Paypal Details",
    icon: { name: "credit-card-outline", type: "MaterialCommunityIcons" },
    navigateTo: "ProfilePayment",
    hidden: false,
  },
  {
    id: 4,
    title: "Profiling Information",
    description: "Open's Link to Profiling",
    icon: { name: "account", type: "MaterialCommunityIcons" },
    hidden: true,
  },
];
