/* eslint-disable camelcase */
import React, { useEffect, useState, useContext } from "react";
import { StyleSheet, View, Linking, TouchableOpacity, PlatformColor, Platform, TouchableWithoutFeedback, TextInputBase, TouchableHighlight } from "react-native";
import {
  Text,
  useTheme,
  Switch,
  overlay,
  Colors,
  Button,
  Portal,
  Dialog,
  Paragraph,
  TextInput,
  Modal,
  Snackbar,
  ActivityIndicator,
  Banner,
  Checkbox,
} from "react-native-paper";
import color from "color";
import { FontAwesome5, MaterialCommunityIcons } from "@expo/vector-icons";

import Auth from "@aws-amplify/auth";
import { ScrollView } from "react-native-gesture-handler";
import * as SecureStore from "expo-secure-store";
import { API, graphqlOperation } from "aws-amplify";
import { StackNavigationProp } from "@react-navigation/stack"; // eslint-disable-line no-unused-vars
import gql from "graphql-tag";
import { useForm, Controller } from "react-hook-form";
import { Picker } from "@react-native-picker/picker";
import axios from "axios";
import moment from "moment";
import { PreferencesContext } from "../../context/preferencesContext";
import secureStoreOptionsKeychain from "../../data/secure-store-keychain";
import { UserInfoContext } from "../../context/userInfo";
import { StackNavigatorParamlist } from "../../navigation/types"; // eslint-disable-line no-unused-vars
import { getTranslations, translate } from "../../components/Localei18n/languagesFunctions";
import { getDataListLangages } from "../../components/Localei18n";
import FormField from "../../components/FormValidator/FormField";
import { PickerCustom } from "../../components/picker.custom";
import UserService from "../../helpers/user.service";
import ExpoReleaseEnvironments from "../../ExpoReleaseEnvironments";
import { sendAnalyticsEvent } from "../../components/Analytics/analytics";

const secureStoreOptions = {
  keychainService: secureStoreOptionsKeychain,
  keychainAccessible: SecureStore.ALWAYS, // iOS only
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingVertical: 12,
  },
  title: {
    fontSize: 14,
    paddingHorizontal: 16,
    paddingBottom: 10,
  },
  caption: { fontSize: 14 },

  row: {
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 0.5,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 8,
    paddingHorizontal: 16,
    height: 40,
  },
});

type Props = {
  navigation?: StackNavigationProp<StackNavigatorParamlist>;
};

export interface FwTeamScope {
  id: number;
  livechat_department: string;
  livechat_online: string;
  livechat_theme: string;
}

export const Preferences = (props: Props) => {
  const { deleteAccountRequest } = UserService();
  const { userInfo, language, updateLanguage } = useContext(UserInfoContext);
  const [listOpened, setListOpened] = useState(false);
  const [listLanguage, setListLanguage] = useState([]);
  const [viewTag, setViewTag] = useState(window.viewTag);
  const [deletionReasons, setDeletionReasons] = useState([]);
  // const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const [dialogIsVisible, setDialogIsVisible] = useState(false);
  const [snackbarConfig, setSnackbarConfig] = useState({
    visible: false,
    message: "",
    label: "",
    action: () => {},
  });
  const [deletionRequestCount, setDeletionRequestCount] = useState(0);
  const [accountDeletionReady, setAccountDeletionReady] = useState(false);
  const [fwTeamScope, setFwTeamScope] = useState<FwTeamScope>();
  const [isResendingNotif, setIsResendingNotif] = useState(false);
  window.viewTag = viewTag;
  const [lastResendDate, setLastResendDate] = useState(null);

  // console.log("Preferences", userInfo);

  const labelColor = color(theme.colors.text)
    .alpha(0.67)
    .rgb()
    .string();

  const ShadowColor = color(theme.colors.text)
    .alpha(1)
    .rgb()
    .string();

  const check = color(Colors.green500)
    .alpha(1)
    .rgb()
    .string();

  const { toggleTheme } = React.useContext(
    PreferencesContext
  );

  const toggleViewTag = () => {
    setViewTag(!viewTag);
  };

  const surfaceBackground = theme.dark
    ? (overlay(6, theme.colors.surface) as string)
    : "white";

  const itemBackground = theme.dark ? Colors.grey700 : Colors.grey50;

  const setLanguage = async (lang, name) => {
    const newLanguage = { language: lang, preferredLanguage: lang, language_name: name };
    await getTranslations(newLanguage);
    updateLanguage(newLanguage);

    const mutation = gql`
      mutation updateContactLanguage($panelist_id: Int, $language: String){
        updateContactLanguage(panelist_id: $panelist_id, language: $language)
      }
    `;

    try {
      API.graphql(graphqlOperation(mutation, { panelist_id: userInfo.panelist_id, language: lang }));

      const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
      Auth.updateUserAttributes(user, { locale: lang });
    } catch (err) {
      console.log(err);
    }
  };

  const getListLanguages = async () => {
    const listLanguages = await getDataListLangages();
    setListLanguage(listLanguages);
  };

  const initData = async () => {
    const qry = gql`
    query($countryCode: String, $languageCode: String) {
      getHcpDeletionReasons(languageCode: $languageCode) {
        reason_id
        reason_name
      }
      getFW_team_scope(countrycode: $countryCode) {
        id
        livechat_department
        livechat_online
        livechat_theme
      }
    }`;

    const response: any = await API.graphql(graphqlOperation(qry, {
      countryCode: userInfo?.country_code,
      languageCode: language?.language,
    }));
    setFwTeamScope(response.data.getFW_team_scope);
    setDeletionReasons(response.data.getHcpDeletionReasons);
  };

  const sendEmail = async (resend: number = 0, reasons: String = "", other_reason: String = "") => {
    const teamScope = !fwTeamScope?.livechat_department ? "en" : (fwTeamScope?.livechat_department).replace("fieldwork-", "");
    const teamScopeEmail = `survey${teamScope}@medimix.net`;
    const token = (await Auth.currentSession()).getAccessToken().getJwtToken();
    const templateName = resend === 1 ? `hcp-portal-account-delete-followup_${teamScope}` : `hcp-portal-account-delete-request_${teamScope}`;

    axios.post(`${ExpoReleaseEnvironments().backofficeUrl}api/hcp/send_account_deletion_email`, {
      firstName: userInfo?.first_name,
      lastName: userInfo?.last_name,
      email: teamScopeEmail,
      uic: userInfo?.uic,
      templateName,
      reason: reasons,
      other_reason,
      panelist_id: userInfo?.panelist_id,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(({ data }) => {
      if (data) { // api returns true or false
        if (resend === 1) {
          setSnackbarConfig((props) => ({
            ...props,
            visible: true,
            message: !data ? "Failed to send request." : translate("The team has been notified again."),
            label: "Ok",
            action: () => setSnackbarConfig((props) => ({ ...props, visible: false })),
          }));
        }
      }

      setIsResendingNotif(false);
    }).catch((error) => {
      console.log("error: ", error);
    });
  };

  const checkHcpDeletionRequestCount = async () => {
    const qry = gql`
      query getHcpDeletionRequestCount($panelist_id: Int, $status: Int){
        getHcpDeletionRequestCount(panelist_id: $panelist_id, status: $status)
        getHcpDelReqResendDate(panelist_id: $panelist_id, status: 1)
      }`;

    const response = await API.graphql(graphqlOperation(qry, {
      panelist_id: userInfo?.panelist_id,
      status: 1,
    }));

    setLastResendDate(response?.data?.getHcpDelReqResendDate);
    setDeletionRequestCount(response?.data?.getHcpDeletionRequestCount);
    setAccountDeletionReady(true);
  };

  useEffect(() => {
    getListLanguages();
    initData();
  }, [language]);

  useEffect(() => {
    sendAnalyticsEvent("Settings");
    initData();
    setAccountDeletionReady(false);

    setTimeout(() => {
      setDialogIsVisible(false);
    }, 300000);
  }, []);

  useEffect(() => {
    checkHcpDeletionRequestCount();
  }, [userInfo]);

  const ConfirmDeleteAccountDialog = ({ visible, closeDialog, reasons }) => {
    const disabled = false;
    const [selectOpen, setSelectOpen] = useState(false);
    const [textCaption, setTextCaption] = useState("");
    const label = "Select Reason";
    const required = false;
    const [requireOtherReason, setRequireOtherReason] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const staticOtherReasonId = 4;
    const [selectedReasons, setSelectedReasons] = useState([]);
    const [otherReasonSelected, setOtherReasonSelected] = useState(false);

    const {
      control, errors, register, getValues, setValue, trigger, handleSubmit, formState,
    } = useForm({
      mode: "onChange",
      reValidateMode: "onChange",
      defaultValues: {
        reason_ids: [],
        other_reason: "",
      },
    });

    const submitRequest = async (data: {other_reason?: String}) => {
      setIsSubmitting(true);

      deleteAccountRequest({
        ...data,
        reason_id: selectedReasons.map(({ reason_id }) => (reason_id)).join(","),
        email: userInfo?.email,
      })
        .then((response: boolean) => {
          setIsSubmitting(false);
          closeDialog();

          setSnackbarConfig((props) => ({
            ...props,
            visible: true,
            message: !response ? "Failed to send request." : "Account deletion request sent.",
            label: "Ok",
            action: () => setSnackbarConfig((props) => ({ ...props, visible: false })),
          }));

          setAccountDeletionReady(false);
          checkHcpDeletionRequestCount();

          const formattedReasons = selectedReasons.length > 0
            ? selectedReasons.map(({ reason_name }) => (`<li>${reason_name}</li>`)).join("")
            : "";
          const reasons = formattedReasons?.length > 0
            ? `<p>Due to the following reason(s):</p><ul>${formattedReasons}</ul>` : "";

          if (response) {
            sendEmail(0,
              ((data.other_reason?.length <= 0 && data.other_reason !== undefined)
                && reasons?.length <= 0 ? "- No reason(s) specified. -" : reasons),
              data.other_reason?.length > 0 && data.other_reason !== undefined
                ? `Other reason: ${data.other_reason}` : "");
          }

          setOtherReasonSelected(false);
          setSelectedReasons([]);
        });
    };

    const OtherCheckbox = () => {
      if (Platform.OS === "ios") {
        return (
          <TouchableHighlight
            underlayColor="#eeeeee"
            onPress={() => setOtherReasonSelected(!otherReasonSelected)}
          >
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              {otherReasonSelected && <Checkbox.IOS status={otherReasonSelected ? "checked" : "unchecked"} />}
              {!otherReasonSelected && (
              <FontAwesome5
                style={{ padding: 8 }}
                name="check"
                size={20}
                color="#E0E0E0"
              />
              )}
              <Text>{translate("Other")}</Text>
            </View>
          </TouchableHighlight>
        );
      }
      return (
        <Checkbox.Item
          status={otherReasonSelected ? "checked" : "unchecked"}
          onPress={() => setOtherReasonSelected(!otherReasonSelected)}
          style={{ paddingHorizontal: 0 }}
          labelStyle={{ textAlign: "left", paddingLeft: 8 }}
          position="leading"
          label={translate("Other")}
        />
      );
    };

    const populateReasonIds = (id: number) => {
      const _selectedReasons = [...selectedReasons];
      const itemIndex = _selectedReasons.findIndex((i) => (i === id));

      if (itemIndex === -1) {
        _selectedReasons.push(id);
      } else {
        _selectedReasons.splice(itemIndex, 1);
      }
      setSelectedReasons(_selectedReasons);
    };

    const component = (
      <Dialog visible={visible} onDismiss={closeDialog}>
        <Dialog.Title>
          {translate("Confirm Account Deletion")}
        </Dialog.Title>
        <Dialog.Content>
          <Paragraph>
            {translate("Please confirm that you want to delete your account and all of your data.")}
          </Paragraph>

          <Paragraph style={{ marginTop: 16 }}>
            {translate("Please, let us know the reason.")}
          </Paragraph>
          <View style={{ marginTop: 8, display: "flex" }}>
            {reasons && reasons.map((e: any, index: number) => {
              const isSelected = selectedReasons.findIndex((i) => i?.reason_id === e.reason_id) > -1;
              if (Platform.OS === "ios") {
                return (
                  <TouchableHighlight
                    underlayColor="#eeeeee"
                    onPress={() => {
                      populateReasonIds(e);
                    }}
                  >
                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                      {isSelected && (
                      <Checkbox.IOS
                        status={isSelected ? "checked" : "unchecked"}
                        key={index}
                      />
                      )}
                      {!isSelected && (
                      <FontAwesome5
                        style={{ padding: 8 }}
                        name="check"
                        size={20}
                        color="#E0E0E0"
                      />
                      )}
                      <Text>{translate(e.reason_name)}</Text>
                    </View>
                  </TouchableHighlight>
                );
              }
              return (
                <Checkbox.Item
                  status={isSelected ? "checked" : "unchecked"}
                  style={{ paddingHorizontal: 0 }}
                  onPress={() => {
                    populateReasonIds(e);
                  }}
                  labelStyle={{ textAlign: "left", paddingLeft: 8 }}
                  position="leading"
                  key={index}
                  label={translate(e.reason_name)}
                />
              );
            })}
            <OtherCheckbox />

            {otherReasonSelected && (
            <FormField errors={errors?.other_reason}>
              <Controller
                control={control}
                render={({ onChange, value }) => (
                  <TextInput
                    style={{ marginTop: 8 }}
                    returnKeyType="next"
                    mode={Platform.OS !== "android" ? "outlined" : "flat"}
                    onChangeText={(value) => {
                      onChange(value);
                      setValue("other_reason", value);
                    }}
                    value={value}
                    label={`${translate("Other")}, ${translate("Please specify")}`}
                    multiline
                    numberOfLines={2}
                  />
                )}
                name="other_reason"
                defaultValue=""
              />
            </FormField>
            )}
          </View>
        </Dialog.Content>
        <Dialog.Actions>
          <Button
            loading={isSubmitting}
            uppercase={false}
            onPress={handleSubmit(submitRequest)}
            color={theme.colors.error}
            disabled={isSubmitting}
          >
            {translate("Yes, Delete my account")}
          </Button>
          <Button uppercase={false} onPress={closeDialog}>
            {translate("Cancel")}
          </Button>
        </Dialog.Actions>
      </Dialog>
    );

    return Platform.OS !== "web" ? <Portal>{component}</Portal> : component;
  };

  // Analytics.logEvent("PreferencesOpened");
  // Analytics.logEvent("Preferences");
  return (
    <>
      <ScrollView style={[styles.container, { backgroundColor: theme.colors.background }]}>
        <Text style={[styles.title, { color: labelColor }]}>{translate("Theme")}</Text>

        <View style={[styles.row, { backgroundColor: surfaceBackground, shadowColor: ShadowColor }]}>
          <Text style={styles.caption}>{translate("Dark Theme")}</Text>

          <View>
            <Switch
              onValueChange={toggleTheme}
              value={theme.dark === true}
              trackColor={{ false: "#cccccc", true: color(theme.colors.primary).alpha(0.2).rgb().string() }}
              thumbColor={theme.dark === true ? theme.colors.primary : "whitesmoke"}
              ios_backgroundColor="whitesmoke"
            />
          </View>

        </View>

        { window.environment === "MAINTENANCE"
            && (
            <View style={[styles.row, { backgroundColor: surfaceBackground, shadowColor: ShadowColor }]}>
              <Text style={styles.caption}>{translate("See Language Tag")}</Text>

              <View>
                <Switch
                  onValueChange={toggleViewTag}
                  value={viewTag}
                  trackColor={{ false: "#cccccc", true: color(theme.colors.primary).alpha(0.2).rgb().string() }}
                  thumbColor={viewTag ? theme.colors.primary : "whitesmoke"}
                  ios_backgroundColor="whitesmoke"
                />
              </View>

            </View>
            )}

        <Text style={[styles.title, { marginTop: 18, color: labelColor }]}>{translate("Language")}</Text>

        <TouchableOpacity onPress={() => setListOpened(!listOpened)}>
          <View style={[styles.row, { backgroundColor: surfaceBackground, shadowColor: ShadowColor }]}>
            <Text style={styles.caption}>
              {translate("Preferred Language")}
              {": "}
              {language.language_name ? translate(language.language_name).toString().toUpperCase() : translate("ENGLISH")}
            </Text>
            <View pointerEvents="none">
              <MaterialCommunityIcons name={!listOpened ? "chevron-down" : "chevron-up"} size={18} color={labelColor} />
            </View>
          </View>
        </TouchableOpacity>

        <View style={!listOpened ? { display: "none" } : {}}>
          {listLanguage && listLanguage.map((e) => (
            <TouchableOpacity key={e.language_code} onPress={() => setLanguage(e.language_code, e.language)}>
              <View style={[styles.row, { backgroundColor: itemBackground, shadowColor: ShadowColor }]}>
                <Text style={styles.caption}>{translate(e.language)}</Text>
                <View pointerEvents="none" style={language.preferredLanguage !== e.language_code ? { display: "none" } : {}}>
                  <MaterialCommunityIcons name="check-circle" size={25} color={check} />
                </View>
              </View>
            </TouchableOpacity>
          ))}
        </View>

        <Portal>
          <ConfirmDeleteAccountDialog
            visible={dialogIsVisible}
            closeDialog={() => setDialogIsVisible(false)}
            reasons={deletionReasons}
          />
        </Portal>

        {!accountDeletionReady ? (
          <View
            style={{
              marginTop: 24,
              display: "flex",
              flexDirection: "row",
              paddingHorizontal: 16,
            }}
          >
            <ActivityIndicator size="small" animating />
          </View>
        ) : deletionRequestCount === 0 ? (
          <View style={{
            paddingHorizontal: 4,
            marginTop: 16,
            display: "flex",
            flexDirection: "row",
          }}
          >

            <Button
              color={theme.colors.notification}
              disabled={dialogIsVisible}
            // icon='delete'
              uppercase={false}
              onPress={() => setDialogIsVisible(true)}
            >
              {translate("Delete my Account")}
            </Button>

          </View>
        ) : (
          <View style={{ paddingHorizontal: 16, marginTop: 24 }}>
            <Banner
              {...Platform.OS === "web" && {
                contentStyle: {
                  maxWidth: "100%",
                },
              }}
              {...(moment(lastResendDate).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) && {
                style: {
                  paddingBottom: 16,
                },
              }}
              visible
              {...(moment(lastResendDate).format("YYYY-MM-DD") !== moment().format("YYYY-MM-DD")) ? {
                actions: [{
                  label: translate("Resend"),
                  uppercase: false,
                  disabled: isResendingNotif,
                  loading: isResendingNotif,
                  onPress: async () => {
                    setIsResendingNotif(true);
                    sendEmail(1);
                    // call insert function again to trigger
                    // duplicate key update
                    deleteAccountRequest({
                      email: userInfo?.email,
                    }).then((response) => {
                      if (response) {
                        checkHcpDeletionRequestCount();
                      }
                    });
                  },
                }],
              } : {
                actions: [],
              }}
            >
              {translate("You have sumbitted a deletion request. \nOur team will notify you by email when your request is approved.\n\nThis usually takes less than 48 hours.")}
            </Banner>
          </View>
        )}
      </ScrollView>

      <Snackbar
        visible={snackbarConfig.visible}
        onDismiss={() => setSnackbarConfig((props) => ({ ...props, visible: false }))}
        {...(snackbarConfig.label !== "") && {
          action: {
            label: snackbarConfig.label,
            onPress: snackbarConfig.action,
          },
        }}
      >
        {snackbarConfig.message}
      </Snackbar>
    </>
  );
};
