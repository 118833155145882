/* eslint-disable no-console */
import React, { useContext, useState, useEffect } from "react";
import { Linking, Platform, View, Dimensions, Text } from "react-native";
import { FAB, List, Snackbar, ActivityIndicator } from "react-native-paper";
import { API, graphqlOperation } from "aws-amplify";
import gql from "graphql-tag";
import { useTheme } from "@react-navigation/native";
import { colors } from "../../../theme/variables";
import { translate } from "../../../components/Localei18n/languagesFunctions";
import { UserInfoContext } from "../../../context/userInfo";
import ExpoReleaseEnvironments from "../../../ExpoReleaseEnvironments";
import UserContext from "../../../context/UserContext";
import { SessionContext } from "../../../context/SessionContext";
import UserService from "../../../helpers/user.service";

type Props = {
  navigation: any;
}

export const Chat = (props: Props) => {
  const theme = useTheme();
  let unmounted = false;
  const [ChatEnabled, setChatEnabled] = useState(false);
  const { userInfo, language } = useContext(UserInfoContext);
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [containChatWidget, setContainChatWidget] = useState(false);
  const user = useContext(UserContext);
  const [chatUrlParams, setChatUrlParams] = useState("");
  const [webChatLoading, setWebChatLoading] = useState(false);
  const [chatStarted, setChatStarted] = useState(false);
  const [cxBusLoaded, setCxBusLoaded] = useState(false);
  const uInfo = useContext(UserInfoContext);
  const [agentOnQueue, setAgentOnQueue] = useState(false);
  const { handleSessionDeactivation } = useContext(SessionContext);
  const { checkUser } = UserService();

  const isProd = ExpoReleaseEnvironments().envName === "PRODUCTION";
  const widgetSetting = {
    deploymentKey: isProd ? "7186e035-7ba7-4e80-8e2d-3692efabbe29" : "467c65c2-67fe-4360-9f9e-843731d51e7f",
    queue: isProd ? "Support" : "Dev Queue",
    queueId: isProd ? "190e2ac4-0190-4fd9-b122-616cb858803a" : "21e12dea-6234-4c4c-a94e-fdbd4faf1bde",
  };

  // const [preferredLanguage, setPreferredLanguage] = useState("en");
  // const [department, setDepartment] = useState(2);

  let preferredLanguage = "en";
  let department = 2;
  let chatThemeLanguageId = 2;

  // console.log("CHAT", userInfo);
  // console.log("CHAT", language.preferredLanguage);

  const { managedUserInfo } = useContext(UserInfoContext);

  const token = `${user?.signInUserSession?.accessToken?.jwtToken}`;

  const getContactsData = async () => {
    if (await checkUser() !== true) {
      handleSessionDeactivation(0);
    }

    const qry = gql`
        query ($email: String!, $countrycode: String!) {
          getContactsInfo_by_email(email: $email){
            panelist_id uic level1_specialty_name default_language_code country_name country_code
          }
          getContacts_numbers(email: $email){
            number country_phone_code primary_number number_type number_id number_type_name number_status_id
          }
          getFW_team_scope(countrycode:$countrycode){
            id livechat_department livechat_theme livechat_online
          }
        }
      `;
    try {
      const response: any = await API.graphql(
        graphqlOperation(qry, {
          email: managedUserInfo.email,
          countrycode: managedUserInfo.country_code,
        })
      );

      const countryAndSpecialty = response.data.getContactsInfo_by_email;
      const country = countryAndSpecialty.country_name.length > 0 ? (countryAndSpecialty.country_name.replace("LNG_Country_", "")).replace(/_/g, "") : "";
      const specialty = countryAndSpecialty.level1_specialty_name.length > 0 ? (countryAndSpecialty.level1_specialty_name.replace("LNG_Level1_", "")).replace(/_/g, "") : "";
      const liveChatDepartment = Object.keys(response.data.getFW_team_scope).length > 0 ? response.data.getFW_team_scope.livechat_department : "fieldwork-en";

      const langSkillArray = {
        "": "English - Written",
        en: "English - Written",
        es: "Spanish - Written",
        pt: "Portuguese - Written",
        de: "German - Written",
      };

      let params = {
        country_code: countryAndSpecialty.country_code,
        country,
        panelistid: countryAndSpecialty.panelist_id,
        uic: countryAndSpecialty.uic,
        specialty,
        preferred_language: (uInfo.language.preferredLanguage).substr(0, 2) || "en",
        email: user.attributes?.email,
        phone: "",
        firstname: uInfo.userInfo.first_name,
        lastname: uInfo.userInfo.last_name,
        langSkill: langSkillArray[liveChatDepartment.substr(10)],
        liveChatDepartment,
      };

      if (langSkillArray[params.preferred_language.substr(0, 2)] !== undefined) {
        params = {
          ...params,
          langSkill: langSkillArray[params.preferred_language],
          liveChatDepartment: `fieldwork-${params.preferred_language}`,
        };
      } else {
        params = {
          ...params,
          preferred_language: liveChatDepartment.substr(10),
        };
      }

      const contactNumber = response.data.getContacts_numbers?.find((e) => e.primary_number === true);
      if (user.attributes?.phone_number === undefined) {
        params = { ...params, phone: `${contactNumber.country_phone_code}${contactNumber.number}` };
      } else {
        params = { ...params, phone: (user.attributes?.phone_number).replace("+", "") };
      }

      return params;
    } catch (e) {
      // console.log("error fetching contacts info: ", e);
      return e;
    }
  };

  const getAdvancedConfig = (fn, ln, email) => {
    CXBus.command("WebChat.open", {
      form: {
        autoSubmit: true,
        firstname: fn,
        lastname: ln,
        email,
      },
      formJSON: {
        wrapper: "<table></table>",
        inputs: [
          {
            id: "cx_webchat_form_firstname",
            type: "hidden",
            name: "firstname",
            maxlength: "100",
            placeholder: "@i18n:webchat.ChatFormPlaceholderFirstName",
            label: "@i18n:webchat.ChatFormFirstName",
          },
          {
            id: "cx_webchat_form_lastname",
            type: "hidden",
            name: "lastname",
            maxlength: "100",
            placeholder: "@i18n:webchat.ChatFormPlaceholderLastName",
            label: "@i18n:webchat.ChatFormLastName",
          },
          {
            id: "cx_webchat_form_email",
            type: "hidden",
            name: "email",
            maxlength: "100",
            placeholder: "@i18n:webchat.ChatFormPlaceholderEmail",
            label: "@i18n:webchat.ChatFormEmail",
          },
        ],
      },
    }).done((e) => {
      console.log("done ", e);
    }).fail((e) => {
      console.log("fail ", e);
    });
  };

  const fnRenderizarGenesys = (fn, ln, email) => {
    const rendercxbusGenesys = new Promise(((resolve, reject) => {
      const scriptCxbus = document.createElement("script");
      scriptCxbus.src = "https://apps.usw2.pure.cloud/widgets/9.0/cxbus.min.js";
      scriptCxbus.async = true;
      scriptCxbus.onload = function () { resolve(scriptCxbus); };
      scriptCxbus.onerror = function () { reject(new Error("Error loading cxbus")); };
      document.head.append(scriptCxbus);
    }));

    rendercxbusGenesys.then((result) => {
      CXBus.configure({ debug: false, pluginsPath: "https://apps.usw2.pure.cloud/widgets/9.0/plugins/" });
      CXBus.loadPlugin("widgets-core").done(() => {
        // Create a plugin (or reuse your own if you have one already)
        // var oMyPlugin = CXBus.registerPlugin("myPlugin");
        // oMyPlugin.command('WebChatService.getAgents');
        // oMyPlugin.command('WebChatService.startChat').done(function(e){
        //    console.log("WebChatService started a chat successfully");
        //    document.getElementById("chat-button-simple").style.display = "block";
        // }).fail(function(e){
        //    console.log("WebChatService failed to start chat");
        // });
        // document.getElementById("chat-button-simple").style.display = "block";
        setCxBusLoaded(true);

        CXBus.subscribe("WebChat.opened", (e) => {
          setWebChatLoading(false);
          setChatStarted(true);
        });

        CXBus.subscribe("WebChat.closed", (e) => {
          setChatStarted(false);
        });

        CXBus.subscribe("WebChatService.ended", (e) => {
          CXBus.command("WebChat.close");
        });

        CXBus.subscribe("WebChatService.started", (e) => {
          setWebChatLoading(false);
          setChatStarted(true);
        });
      });
    }).catch((err) => {
      console.error(err);
    });
  };

  switch (language.preferredLanguage) {
    case "pt":
      // setPreferredLanguage("por");
      // setDepartment(4);
      preferredLanguage = "por";
      chatThemeLanguageId = 4;
      department = 4;
      break;
    case "es":
      // setPreferredLanguage("esp");
      // setDepartment(5);
      preferredLanguage = "esp";
      chatThemeLanguageId = 5;
      department = 5;
      break;
    case "de":
      // setPreferredLanguage("ger");
      // setDepartment(6);
      preferredLanguage = "ger";
      chatThemeLanguageId = 6;
      department = 6;
      break;
    case "it":
      // setPreferredLanguage("ger");
      // setDepartment(6);
      preferredLanguage = "ita";
      chatThemeLanguageId = 7;
      department = 7;
      break;
    case "fr":
      // setPreferredLanguage("ger");
      // setDepartment(6);
      preferredLanguage = "fre";
      chatThemeLanguageId = 8;
      department = 8;
      break;
    default:
      break;
  }

  const onResize = () => {
    if (Dimensions.get("window").width > 600) {
      if (!unmounted) setContainChatWidget(false);
    } else if (!unmounted) setContainChatWidget(true);
  };

  const setSnackbar = (visible, message) => {
    setSnackbarVisible(visible);
    setSnackbarMessage(message);
  };

  const handleOpenSendEmail = () => {
    props.navigation.push("SendMessage", { lang: language.preferredLanguage, setSnackbar });
  };

  const checkAgentOnQueue = () => fetch(
    `${ExpoReleaseEnvironments().backofficeUrl}api/hcp/genesys/checkAgentsOnQueue`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token,
        countryCode: userInfo.country_code,
        preferredLanguage: language.preferredLanguage,
        queueId: widgetSetting.queueId,
      }),
      mode: "cors",
    }
  ).then((response) => response.json());

  const handleCheckagentQueue = () => {
    setWebChatLoading(true);
    checkAgentOnQueue()
      .then((data) => {
        if (parseInt(data, 10) > 0) { // meaning an agent is on queue noAgentOnQueue should be false
          setChatEnabled(true);
          console.log(true);
        } else {
          console.log(false);
          setChatEnabled(false);
        }

        setWebChatLoading(false);
      });
  };

  useEffect(() => {
    Dimensions.addEventListener("change", onResize);
    onResize();

    handleCheckagentQueue();

    getContactsData().then((params) => {
      setChatUrlParams(`panelistid=${params.panelistid}&uic=${params.uic}&specialty=${params.specialty}&email=${params.email}&country=${params.country}&phone=${params.phone}&country_code=${params.country_code}&preferred_language=${params.preferred_language}`);
      // "7186e035-7ba7-4e80-8e2d-3692efabbe29" -> Prod
      // '467c65c2-67fe-4360-9f9e-843731d51e7f' -> Dev
      // 'fcd73d5d-6bec-46f3-b117-89c26c19b8f2' -> V1.0

      if (Platform.OS === "web") {
        if (!window._genesys) window._genesys = {};
        if (!window._gt) window._gt = [];

        (function () {
          window._genesys = {
            widgets: {
              main: {
                theme: "light",
                lang: params.preferred_language,
                i18n: `https://apps.usw2.pure.cloud/widgets/9.0/i18n/widgets-${params.preferred_language}.i18n.json`,
                mobileMode: "auto",
                mobileModeBreakpoint: 600,
              },
              webchat: {
                transport: {
                  type: "purecloud-v2-sockets",
                  dataURL: "https://api.usw2.pure.cloud",
                  deploymentKey: widgetSetting.deploymentKey,
                  orgGuid: "8abe9156-7441-47ec-8f0d-786103b0455a",
                  // uploadsEnabled: true,
                  interactionData: {
                    routing: {
                      targetType: "QUEUE",
                      targetAddress: widgetSetting.queue,
                      priority: 0,
                    },
                  },
                },
                skills: ["Live Chat"],
                language: params.langSkill,
                confirmFormClosedEnabled: false,
                actionsMenu: true,
                userData: {
                  PanelistID: params.preferred_language,
                  UIC: params.uic,
                  Specialty: params.specialty,
                  phoneNumber: params.phone,
                  Email: params.email,
                  Country: params.country,
                  lang: params.preferred_language,
                  customField1Label: "panelist_id",
                  customField1: params.panelistid,
                  customField2Label: "country_code",
                  customField2: params.country_code,
                  customField3Label: "uic",
                  customField3: params.uic,
                  livechatDepartment: params.liveChatDepartment,
                  langSkill: params.langSkill,
                },
                autoInvite: {
                  enabled: true,
                  timeToInviteSeconds: 1,
                  inviteTimeoutSeconds: 30,
                },
                async: {
                  enabled: true,
                  newMessageRestoreState: "minimized",
                },
              },
            },
          };

          fnRenderizarGenesys(params.firstname, params.lastname, params.email);
        }());
      }
    }).catch((e) => console.log(e));

    return () => {
      unmounted = true;
      Dimensions.removeEventListener("change", onResize);
    };
  }, []);

  return (
    <View>
      <List.Item
        disabled={!ChatEnabled && chatStarted}
        title={(
          <Text style={{ color: ChatEnabled ? theme.colors.text : "grey", fontSize: 16 }}>
            {translate("Chat with us")}
            {!ChatEnabled && ` - ${translate("Offline")}`}
          </Text>
          )}
        left={() => {
          if (webChatLoading) {
            return (<ActivityIndicator size={24} style={{ margin: 8, width: 40, height: 40 }} />);
          }
          return (<List.Icon color={ChatEnabled ? colors.primaryVioletColor : "grey"} icon="chat" />);
        }}
        onPress={() => {
          if (!ChatEnabled) return false;
          setWebChatLoading(true);
          checkAgentOnQueue()
            .then((data) => {
              if (parseInt(data, 10) > 0) { // meaning an agent is on queue noAgentOnQueue should be false
                if (Platform.OS !== "web") {
                  props.navigation.push("ChatRoom", {
                    lang: language.preferredLanguage,
                    fallbackProp: { lang: language.preferredLanguage, setSnackbar, noAgentOnQueue: false },
                  });
                } else {
                  setChatStarted(true);
                  if (cxBusLoaded) {
                    getContactsData()
                      .then((params) => {
                        getAdvancedConfig(params.firstname, params.lastname, params.emal);
                      });
                  }
                }
              } else {
                // disable chat here
                props.navigation.push("SendMessage", { lang: language.preferredLanguage, setSnackbar, noAgentOnQueue: true });
              }

              setWebChatLoading(false);
            });
          // openChat();
        }}
      />
      <List.Item
        title={translate("Write us an eMail")}
        left={() => <List.Icon color={colors.primaryVioletColor} icon="email" />}
        onPress={() => handleOpenSendEmail()}
      />

      <Snackbar
        visible={snackbarVisible}
        onDismiss={() => setSnackbarVisible(false)}
        duration={5000}
        action={{
          label: "×",
          onPress: () => {
            setSnackbarVisible(false);
          },
        }}
      >
        {snackbarMessage}
      </Snackbar>

    </View>
  );
};
