/* eslint-disable camelcase */
import React, { useContext } from "react";
import { StyleSheet, View } from "react-native";
import {
  useTheme,
} from "react-native-paper";
import color from "color";

import { ScrollView } from "react-native-gesture-handler";
import * as SecureStore from "expo-secure-store";
import secureStoreOptionsKeychain from "../../data/secure-store-keychain";
import { UserInfoContext } from "../../context/userInfo";
import { PrivacyPolicy } from "./privacy.policy";
import { TermsConditions } from "./terms.conditions";
import { translate } from "../../components/Localei18n/languagesFunctions";

const secureStoreOptions = {
  keychainService: secureStoreOptionsKeychain,
  keychainAccessible: SecureStore.ALWAYS, // iOS only
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  container: {
    flex: 1,
    paddingVertical: 12,
  },
  title: {
    textTransform: "capitalize",
    fontSize: 14,
    paddingHorizontal: 16,
    paddingBottom: 10,
  },
  caption: { fontSize: 14, textTransform: "capitalize" },
});

export const Information = (props: any) => {
  const { language } = useContext(UserInfoContext);

  const theme = useTheme();

  const labelColor = color(theme.colors.text)
    .alpha(0.67)
    .rgb()
    .string();

  const ShadowColor = color(theme.colors.text)
    .alpha(1)
    .rgb()
    .string();

  return (
    <ScrollView style={styles.root}>
      {props.route.params.title === translate("Privacy Policy")
        && (<PrivacyPolicy {...props.route.params} />)}

      {props.route.params.title === translate("Terms and Conditions")
        && (<TermsConditions {...props.route.params} />)}
    </ScrollView>
  );
};
