/* eslint-disable no-shadow */
import { MaterialCommunityIcons } from "@expo/vector-icons";
import {
  // eslint-disable-next-line no-unused-vars
  DrawerContentComponentProps,
  DrawerContentScrollView,
  DrawerItem,
  DrawerNavigationProp, // eslint-disable-line no-unused-vars
} from "@react-navigation/drawer";
import React, { useContext, useState, useEffect } from "react";
import { StyleSheet, TouchableOpacity, View, Alert, Platform } from "react-native";
import {
  Drawer,
  Text,
  Title,
  useTheme,
  ActivityIndicator,
  Dialog,
  Button,
  Portal,
  Paragraph,
} from "react-native-paper";
import Animated from "react-native-reanimated";
import color from "color";
import Constants from "expo-constants";
import applyScale from "../helpers/image/applyScale";
import { UserInfoContext } from "../context/userInfo";
import { translate } from "../components/Localei18n/languagesFunctions";
import DrawerMiniProfile from "../components/drawer.menu.mini.profile";

type Props = DrawerContentComponentProps<DrawerNavigationProp<{}>> &
{
  loading?: boolean;
  handleSignout: () => Promise;
  userInfo?: any;
  environment?: "MAINTENANCE" | "PRODUCTION";
};

const styles = StyleSheet.create({
  drawerContent: {
    flex: 1,
  },
  drawerBackHolder: {
    paddingHorizontal: 15,
    paddingVertical: 10,
  },
  userInfoSection: {
    paddingTop: 10,
    paddingLeft: 24,
    paddingRight: 15,
  },
  title: {
    textTransform: "capitalize",
    marginTop: 12,
    fontSize: 24,
    lineHeight: 24,
    fontWeight: "800",
  },
  subtitle: {
    fontSize: 16,
    lineHeight: 16,
    color: "rgba(255,255,255,0.35)",
  },
  caption: {
    fontSize: 14,
    lineHeight: 14,
  },
  row: {
    marginTop: 20,
    flexDirection: "row",
    alignItems: "center",
  },
  section: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: 15,
  },
  paragraph: {
    marginRight: 3,
  },
  drawerSection: {
    marginTop: 15,
  },
  drawerItem: {
    paddingHorizontal: 14,
  },
});

export function DrawerContent(props: Props) {
  const theme = useTheme();
  const sizeIcon = applyScale(30);
  const { managedUserInfo } = useContext(UserInfoContext);

  const paperTheme = useTheme();

  const [confirmVisible, setConfirmVisible] = useState(false);

  const translateX = Animated.interpolateNode(props.progress, {
    inputRange: [0, 0.5, 0.7, 0.8, 1],
    outputRange: [0, -85, -70, -45, 0],
  });
  const mutedContentColor = color(theme.colors.text)
    .alpha(0.6)
    .rgb()
    .string();

  const confirmSignout = () => {
    if (Platform.OS !== "web") {
      Alert.alert(
        translate("Sign out from this device?"),
        translate("You won't be able to receive new updates on Livetracker."),
        [
          {
            text: translate("Cancel"),
            onPress: () => { },
            style: "cancel",
          },
          {
            text: translate("Sign out"),
            onPress: () => props.handleSignout(),
          },
        ],
        { cancelable: false }
      );
    } else {
      setConfirmVisible(true);
    }
  };

  useEffect(() => () => {
    if (managedUserInfo.email === undefined && props.environment === "MAINTENANCE") {
      props.navigation.navigate("SearchUIC");
    }
  }, [managedUserInfo]);

  const AnimatedWrapper = ({ children }) => (Platform.OS === "android" ? children : (
    <Animated.View
      style={
                [styles.drawerContent,
                  {
                    backgroundColor: paperTheme.colors.surface,
                    transform: [{ translateX }],
                  }]
              }
    >
      {children}
    </Animated.View>
  ));

  return (
    <>
      <DrawerContentScrollView {...props}>
        <AnimatedWrapper>
          <View style={styles.drawerBackHolder}>
            <TouchableOpacity
              style={{ marginLeft: 10 }}
              onPress={() => {
                props.navigation.toggleDrawer();
              }}
            >
              <MaterialCommunityIcons
                name="arrow-left"
                color={theme.colors.primary}
                size={sizeIcon}
              />
            </TouchableOpacity>
          </View>
          <View style={styles.userInfoSection}>

            {props.loading && (
              <View style={{ alignItems: "center" }}>
                <ActivityIndicator size={20} color={theme.colors.text} />
              </View>
            )}

            {(!props.loading && props.userInfo.panelist_id) && (
              <View>
                <Title style={[styles.title, { textTransform: "capitalize", color: theme.colors.text }]}>{`${props.userInfo.first_name} \n${props.userInfo.last_name}`}</Title>
                <Title style={[styles.subtitle, { color: mutedContentColor }]}>{props.userInfo.uic}</Title>
              </View>
            )}

            {(!props.loading && props.userInfo.id && props.environment === "MAINTENANCE") && (
              <DrawerMiniProfile selectedUic={managedUserInfo} currentUser={props.userInfo} />
              // <View>
              //   <Title style={[styles.title, { textTransform: "none", color: theme.colors.text }]}>
              //     {`${props.userInfo.first_name} ${props.userInfo.last_name}`}
              //   </Title>
              //   <Title style={[styles.subtitle, { color: mutedContentColor }]}>{translate("MAINTENANCE")}</Title>
              // </View>
            )}

            {(!props.loading && !props.userInfo.panelist_id && props.environment === "PRODUCTION") && (
              <View style={{ alignItems: "center" }}>
                <MaterialCommunityIcons name="account-question" size={30} />
                <Text>{translate("Unknow User")}</Text>
              </View>
            )}

          </View>

          <Portal>
            <Dialog
              visible={confirmVisible}
              style={{ width: 400, marginLeft: "auto", marginRight: "auto" }}
              onDismiss={() => setConfirmVisible(false)}
            >
              <Dialog.Title>{translate("Sign out from this device?")}</Dialog.Title>
              <Dialog.Content>
                <Paragraph>{translate("You won't be able to receive new updates on Livetracker.")}</Paragraph>
              </Dialog.Content>
              <Dialog.Actions>
                <Button onPress={() => setConfirmVisible(false)} style={{ marginRight: 15 }}>{translate("Cancel")}</Button>

                <Button onPress={() => props.handleSignout().then((e) => {
                  if (e === true) window.open("/authenticate", "_self").focus();
                })}
                >
                  {translate("Ok")}
                </Button>

              </Dialog.Actions>
            </Dialog>
          </Portal>

          <Drawer.Section style={styles.drawerSection}>

            {/* <DrawerItem
            style={styles.drawerItem}
            icon={({ color, size }) => ( // eslint-disable-line no-shadow
              <MaterialIcons name="security" color={color} size={size} />
            )}
            label="Authentication"
            // onPress={() => { props.navigation.navigate("Authentication"); }}
            onPress={() => {}}
          /> */}

            {(props.environment === "MAINTENANCE" && Platform.OS === "web")
              && (
                <DrawerItem
                  style={styles.drawerItem}
                  icon={({ color, size }) => ( // eslint-disable-line no-shadow
                    <MaterialCommunityIcons name="account-group" color={color} size={size} />
                  )}
                  label={translate("Change User")}
                  onPress={() => { props.navigation.navigate("SearchUIC"); }}
                />
              )}
            <DrawerItem
              style={styles.drawerItem}
              icon={({ color, size }) => ( // eslint-disable-line no-shadow
                <MaterialCommunityIcons name="tune" color={color} size={size} />
              )}
              label={translate("Settings")}
              onPress={() => { props.navigation.navigate("Preferences"); }}
            />
            <DrawerItem
              style={styles.drawerItem}
              icon={({ color, size }) => ( // eslint-disable-line no-shadow
                <MaterialCommunityIcons name="shield-account" color={color} size={size} />
              )}
              label={translate("Change Password")}
              onPress={() => { props.navigation.navigate("ChangePassword"); }}
            />
            <DrawerItem
              style={styles.drawerItem}
              icon={({ color, size }) => ( // eslint-disable-line no-shadow
                <MaterialCommunityIcons name="gavel" color={color} size={size} />
              )}
              label={translate("Legal Information")}
              onPress={() => { props.navigation.navigate("Legalinfo"); }}
            />
            <DrawerItem
              style={styles.drawerItem}
              icon={({ color, size }) => ( // eslint-disable-line no-shadow
                <MaterialCommunityIcons
                  name="account-off-outline"
                  color={color}
                  size={size}
                />
              )}
              label={translate("Sign out")}
              onPress={confirmSignout}
            />
          </Drawer.Section>

          <View style={{
            flex: 1, alignItems: "center", marginTop: 30, marginBottom: 20,
          }}
          >
            <Text>
              {`v.${Constants?.manifest?.version}`}
            </Text>
          </View>
        </AnimatedWrapper>

      </DrawerContentScrollView>
    </>
  );
}
