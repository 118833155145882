import React, { useContext } from "react";
import { TouchableOpacity, Image, Text, Platform } from "react-native";
import { createStackNavigator } from "@react-navigation/stack";
import { Appbar, useTheme } from "react-native-paper";
import { MaterialCommunityIcons } from "@expo/vector-icons";

// eslint-disable-next-line no-unused-vars
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { getFocusedRouteNameFromRoute, NavigationContext, useLinkTo, useNavigationState, useRoute } from "@react-navigation/native";

import applyScale from "../helpers/image/applyScale";
import { BottomTabs } from "./bottomTabs";
import { ProfileCategory } from "../screens/profile/profileCategory/profile.category";

// eslint-disable-next-line no-unused-vars
import { StackNavigatorParamlist } from "./types";
import { Preferences } from "../screens/preferences/preferences";
import { SearchUIC } from "../screens/searchuic/search.uic.component";
import { Information } from "../screens/preferences/information";
import ChangePasswordComponent from "../screens/changepass/changepass";
import { ProfileContacts } from "../screens/profile/profile.contacts/profile.contacts";
import EditContactsPersonal from "../screens/profile/profile.contacts/profile.contacts.personal.edit";
import { ProfilePayment } from "../screens/profile/profile.payment/profile.payment";
import EditPayment from "../screens/profile/profile.payment/profile.payment.edit";
import { ProfileHcp } from "../screens/profile/profile.hcp/profile.hcp";
import { ProfileContactsContactsEdit } from "../screens/profile/profile.contacts/profile.contacts.contacts.edit";
import { ProfileContactsAddressList } from "../screens/profile/profile.contacts/profile.contacts.address.list";
import EditContactsEmail from "../screens/profile/profile.contacts/profile.contacts.email.edit";
import AddContactsEmail from "../screens/profile/profile.contacts/profile.contacts.email.add";
import EditContactsNumber from "../screens/profile/profile.contacts/profile.contacts.number.edit";
import AddContactsNumber from "../screens/profile/profile.contacts/profile.contacts.number.add";
import EditContactsAddress from "../screens/profile/profile.contacts/profile.contacts.address.edit";
import AddContactsAddress from "../screens/profile/profile.contacts/profile.contacts.address.add";
import { ProfileInterest } from "../screens/profile/profile.interests/profile.interests";
import EditHcpDetails from "../screens/profile/profile.hcp/profile.hcp.edit";
// import { EditHcpLicenseList } from "../screens/profile/profile.hcp/profile.hcp.license.list";
import EditHcpLicense from "../screens/profile/profile.hcp/profile.hcp.license.edit";
import AddHcpLicense from "../screens/profile/profile.hcp/profile.hcp.license.add";
// import AddHcpLicenseSingle from "../screens/profile/profile.hcp/profile.hcp.license.single.add";
import { translate } from "../components/Localei18n/languagesFunctions";
import { Legalinfo } from "../screens/legal/legalinfo";
import SendMessage from "../screens/support/components/SendMessage";
import { ChatRoom } from "../screens/support/components/ChatRoom";
import { UserInfoContext } from "../context/userInfo";
// import { NotificationDetails } from "../screens/notifications/notification.details";

const Stack = createStackNavigator<StackNavigatorParamlist>();

export const StackNavigator = () => {
  const theme = useTheme();
  const sizeIcon = applyScale(30);
  const linkTo = useLinkTo();

  const { environment } = useContext(UserInfoContext);
  return (
    <Stack.Navigator
      initialRouteName="FeedList"
      headerMode="screen"
      mode="screen"
      screenOptions={{
        // eslint-disable-next-line react/prop-types
        header: ({ back, options, route, navigation }) => {
          const previous = back;

          // eslint-disable-next-line no-nested-ternary
          const title = options.headerTitle !== undefined // eslint-disable-line react/prop-types
            ? options.headerTitle // eslint-disable-line react/prop-types
            : options.title !== undefined // eslint-disable-line react/prop-types
              ? options.title // eslint-disable-line react/prop-types
              : (
                <Image
                  source={require("../assets/medimix-evidera-2.png")}
                  resizeMode="contain"
                  style={{
                    height: 30,
                    width: 200,
                  }}
                />
              );
          // const showBackButton = (state?.routes?.[0].params?.screen === "Notification" || state?.routes?.[0].state?.routes?.[0].name === "Notification");
          const showBackButton = route.name === 'Notifications' || route.params?.screen === 'Notifications';

          return (
            <Appbar.Header
              theme={{ colors: { primary: theme.colors.surface } }}
            >
              {(options.headerTitle || options.title) ? (
                <Appbar.BackAction
                  onPress={() => {
                    if (Platform.OS !== "web") {
                      navigation.goBack();
                      return;
                    }

                    if (showBackButton || !previous) {
                      linkTo("/dashboard/notifications");
                    } else {
                      navigation.goBack();
                    }
                  }} // eslint-disable-line react/prop-types
                  color={theme.colors.primary}
                />
              ) : (
                <TouchableOpacity
                  style={{ marginLeft: 10 }}
                  onPress={() => {
                    ((navigation as any) as DrawerNavigationProp<{}>).openDrawer();
                  }}
                >
                  <MaterialCommunityIcons
                    name="menu"
                    color={theme.colors.primary}
                    size={sizeIcon}
                  />
                </TouchableOpacity>
              )}

              <Appbar.Content
                title={(
                  <Text numberOfLines={1}>{title}</Text>
                )}
                titleStyle={{
                  fontSize: 18,
                  height: (typeof (title) === "string" ? 30 : 40),
                  lineHeight: 30,
                  overflow: "visible",
                  textAlign: "center",
                  color: theme.colors.primary,
                  marginLeft: Platform.OS === "ios" ? -15 : -20,
                  marginTop: (Platform.OS !== "android") && (typeof title !== "string") ? 15 : 0,
                }}
              />
            </Appbar.Header>
          );
        },
      }}
    >
      <Stack.Screen name="FeedList" component={BottomTabs} options={{ headerShown: false }} />
      <Stack.Screen name="Profile" component={ProfileCategory} options={{ headerTitle: translate("Profile") }} />
      {
        environment === "PRODUCTION" && (
          <>
            <Stack.Screen name="EditContactsEmail" component={EditContactsEmail} options={{ title: translate("Edit Email") }} />
            <Stack.Screen name="AddContactsEmail" component={AddContactsEmail} options={{ title: translate("New Email") }} />
            <Stack.Screen name="ProfileContactsContacts" component={ProfileContactsContactsEdit} options={{ title: translate("Emails & Number") }} />
            <Stack.Screen name="EditPayment" component={EditPayment} options={{ title: translate("Edit") }} />
            <Stack.Screen name="EditContactsPersonal" component={EditContactsPersonal} options={{ title: translate("Edit Info") }} />
            <Stack.Screen name="EditContactsNumber" component={EditContactsNumber} options={{ title: translate("Edit Number") }} />
            <Stack.Screen name="AddContactsNumber" component={AddContactsNumber} options={{ title: translate("New Number") }} />
            <Stack.Screen name="EditContactsAddress" component={EditContactsAddress} options={{ title: translate("Edit Address") }} />
            <Stack.Screen name="AddContactsAddress" component={AddContactsAddress} options={{ title: translate("New Address") }} />
            <Stack.Screen name="EditHcpDetails" component={EditHcpDetails} options={{ title: translate("Edit") }} />
          </>
        )
      }
      {Platform.OS === "web" && (
        <Stack.Screen name="SearchUIC" component={SearchUIC} options={{ title: translate("Search UIC"), headerShown: false }} />
      )}

      <Stack.Screen name="ProfileContacts" component={ProfileContacts} options={{ title: translate("Contact Details") }} />
      <Stack.Screen name="ProfileContactsAddressList" component={ProfileContactsAddressList} options={{ title: translate("Address") }} />
      <Stack.Screen name="ProfilePayment" component={ProfilePayment} options={{ title: translate("Payment Information") }} />
      <Stack.Screen name="ProfileInterest" component={ProfileInterest} options={{ title: translate("Opportunities") }} />
      <Stack.Screen name="HCPIdentification" component={ProfileHcp} options={{ title: translate("HCP Identification") }} />
      {/* <Stack.Screen name="EditHcpLicenseList" component={EditHcpLicenseList} options={{ title: translate("Licenses") }} /> */}
      {/* <Stack.Screen name="AddHcpLicenseSingle" component={AddHcpLicenseSingle} options={{ title: translate("License") }} /> */}
      {/* <Stack.Screen name="Donate" component={Donate} options={{ headerTitle: "Donate" }} /> */}
      {/* <Stack.Screen name="NotificationDetails" component={NotificationDetails} options={{ title: translate("Details") }} /> */}
      <Stack.Screen name="Preferences" component={Preferences} options={{ title: translate("Settings") }} />
      <Stack.Screen name="Information" component={Information} options={{ title: translate("Legal Information") }} />

      <Stack.Screen name="ChangePassword" component={ChangePasswordComponent} options={{ title: translate("Change Password") }} />
      <Stack.Screen name="Legalinfo" component={Legalinfo} options={{ title: translate("Legal Information") }} />

      <Stack.Screen name="SendMessage" component={SendMessage} options={{ title: translate("Send us a message") }} />
      <Stack.Screen name="ChatRoom" component={ChatRoom} options={{ title: translate("Chat") }} />
    </Stack.Navigator>
  );
};
