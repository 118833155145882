/* eslint-disable camelcase */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  forwardRef,
} from "react";
import { View, RefreshControl, Platform, ImageBackground } from "react-native";
import {
  Surface,
  ActivityIndicator,
  DefaultTheme,
  ProgressBar,
  Text,
  TextInput,
  TouchableRipple,
  useTheme,
  Title,
  Snackbar,
} from "react-native-paper";
import * as ImagePicker from "expo-image-picker";
import { API, graphqlOperation } from "aws-amplify";
import gql from "graphql-tag";
import * as mime from "react-native-mime-types";
import {
  ScrollView,
  TouchableWithoutFeedback,
} from "react-native-gesture-handler";
import { Controller, useForm } from "react-hook-form";
import { Picker } from "@react-native-picker/picker";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Buffer } from "buffer";
import { ReferenceDataContext } from "./ReferenceDataContext";
import { PickerCustom } from "../../../components/picker.custom";
import { styles } from "./styles";
import { ColorsEvidera } from "../../../theme/variables";
import { PersonalInfoTitle } from "./StageTitle";
import FormField from "../../../components/FormValidator/FormField";
import { StageLoader } from "./StageLoader";
import { translate } from "../../../components/Localei18n/languagesFunctions";
import LanguageSessionSelector from "../../../components/languageSessionSelector";

type Props = {
  ref: any;
  goTo: () => void;
  user: any;
  userStatus: any;
  backTo: () => void;
};

async function getFormContactsDocuments(documentId: any, contactId: any) {
  const qry = gql`
    query($contact_id: Int!) {
      listFormContactsDocuments(contact_id: $contact_id) {
        document_id
        contact_id
        panelist_id
        document_number
        document_type_id
        filename
        format
        specified_type_name
        specified_organization
        specified_country_code
      }
    }
  `;

  const response: any = await API.graphql(
    graphqlOperation(qry, {
      document_id: documentId,
      contact_id: contactId,
    })
  );

  return response;
}

export const Stage1 = forwardRef((props: Props, ref) => {
  let unmounted = false;
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const theme = useTheme();
  const [urlImage, setUrlImage] = useState(null);

  const [ImageLoaded, setImageLoaded] = useState(false);
  const [uploadDocumentProgress, setUploadDocumentProgress] = useState(0);
  const [categoryList, setCategoryList] = useState([]);
  const [salutationList, setSalutationList] = useState([]);
  const [specialtyList, setSpecialtyList] = useState([]);
  const [secondSpecialtyList, setSecondSpecialtyList] = useState([]);
  const [documentTypeList, setDocumentTypeList] = useState([]);
  const [DefaultFormDocument, setDefaultFormDocument] = useState<any>({});
  const [alertDialog, setAlertDialog] = useState({ message: "", show: false, style: {} });

  const [selectOtherAsDocType, setSelectOtherAsDocType] = useState(false);
  const snackbarErrorColorConfig = { backgroundColor: "#D32F2F", color: "#ffffff" };

  const [currentLanguage, setCurrentLanguage] = useState("");
  const [docTypeId, setDocTypeId] = useState("");

  const {
    control, getValues, register, trigger, setValue, formState: { errors },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const { personalInfoData, setPersonalInfoData } = useContext(
    ReferenceDataContext
  );


  const getUrlExtension = (url) => url
    .split(/[#?]/)[0]
    .split(".")
    .pop()
    .trim();

  const getUrlImage = async (filename) => {
    const apiName = "hcps3docs";
    const path = "/hcps3docs/download";
    const myInit = {
      queryStringParameters: {
        filename,
      },
    };
    setImageLoaded(false);
    setUploadDocumentProgress(100);
    API.get(apiName, path, myInit)
      .then((response) => {
        setUrlImage(response.linkImg);
      })
      .catch((err) => {
        setAlertDialog("Failed to load image");
        console.log(err);
      });
  };

  const loadContactsDocument = async () => {
    const response: any = await getFormContactsDocuments(
      personalInfoData?.document_id,
      personalInfoData?.contact_id
    );

    if (
      !(
        response.data.listFormContactsDocuments
        && response.data.listFormContactsDocuments[0]
      )
    ) {
      // if document id we need to create an empty record
      const mutationreplaceContactsDocument = gql`
        mutation replaceContactsDocument(
          $formValue: replaceContactsDocumentInput!
        ) {
          replaceContactsDocument(formValue: $formValue)
        }
      `;

      await API.graphql(
        graphqlOperation(mutationreplaceContactsDocument, {
          formValue: {
            document_id: personalInfoData?.document_id,
            contact_id: personalInfoData?.contact_id,
            panelist_id: personalInfoData?.panelist_id,
          },
        })
      );

      const response2: any = await getFormContactsDocuments(
        personalInfoData?.document_id,
        personalInfoData?.contact_id
      );
      if (
        response2.data.listFormContactsDocuments
        && response2.data.listFormContactsDocuments[0]
      ) {
        setPersonalInfoData({
          ...personalInfoData,
          ...response2.data.listFormContactsDocuments[0],
        });

        if (
          response2.data.listFormContactsDocuments[0].filename
          && response2.data.listFormContactsDocuments[0]?.filename !== ""
        ) {
          getUrlImage(response2.data.listFormContactsDocuments[0]?.filename);
        }

        return new Promise((resolve) => resolve(response2.data.listFormContactsDocuments[0]));
      }
    } else {
      setPersonalInfoData({
        ...personalInfoData,
        ...response.data.listFormContactsDocuments[0],
      });

      if (
        response.data.listFormContactsDocuments[0].filename
        && response.data.listFormContactsDocuments[0]?.filename !== ""
      ) {
        getUrlImage(response.data.listFormContactsDocuments[0]?.filename);
      }
      return new Promise((resolve) => resolve(response.data.listFormContactsDocuments[0]));
    }
  };

  const pickFile = async () => {
    const document_type_id = getValues("document_type_id");
    const document_id = getValues("document_id");
    if (!document_type_id || document_type_id === "") {
      alert(translate("Select first a type of document."));
      return;
    }
    const permissionResult = await ImagePicker.requestMediaLibraryPermissionsAsync();

    if (permissionResult.granted === false) {
      alert(translate("Permission to access camera roll is required!"));
      return;
    }

    const pickerResult = await ImagePicker.launchImageLibraryAsync(
      {
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
      }
    );

    if (pickerResult.cancelled === true) {
      return;
    }
    setImageLoaded(false);
    setUrlImage(pickerResult.uri);
    setUploadDocumentProgress(10);
    let format = getUrlExtension(pickerResult.uri);
    let newfilename = `unverified/${props.user.username}/${document_type_id}_${document_id}.${format}`;
    let filetype = mime.lookup(newfilename) || "application/octet-stream";
    if (Platform.OS === "web") {
      const regex = /data:([\w\/]+);base64/gm;
      const str = pickerResult.uri;
      let m;
      while ((m = regex.exec(str)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
          regex.lastIndex++;
        }
        // The result can be accessed through the `m`-variable.
        m.forEach((match, groupIndex) => {
          console.log(`Found match, group ${groupIndex}: ${match}`);
          if (groupIndex == 1) {
            filetype = match;
            console.log(filetype);
          }
        });
      }
      format = mime.extension(filetype);
      newfilename = `unverified/${props.user.username}/${document_type_id}_${document_id}.${format}`;
    }
    setValue("filename", newfilename);
    setValue("format", format);

    trigger("filename");

    const apiName = "hcps3docs";
    const path = "/hcps3docs/presignedurl";
    const myInit = {
      queryStringParameters: {
        filename: newfilename,
        filetype,
      },
    };

    API.get(apiName, path, myInit).then((response) => {
      const url = response.fileUploadURL;

      // Creating a new Request
      const xhr = new XMLHttpRequest();
      xhr.open("PUT", url);
      xhr.setRequestHeader("Content-Type", filetype);
      // Adding a listener to track the progress
      xhr.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          const percentComplete = event.loaded / event.total;
          setUploadDocumentProgress(
            Math.floor((percentComplete < 0.1 ? 0.1 : percentComplete) * 100)
          );
        }
      });
      // Adding a listener to check the status of the request
      xhr.onreadystatechange = (e) => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            console.log("Image successfully uploaded to S3");
            if (document_id) {
              setValue("filename", newfilename);
              // getUrlImage(newfilename);
            }
          } else {
            console.log("Error while sending the image to S3");
            setAlertDialog({ style: snackbarErrorColorConfig, message: "Failed to upload image", show: true });
          }
        }
      };
      if (Platform.OS === "web") {
        xhr.setRequestHeader("Content-Encoding", "base64");
        const buf = Buffer.from(pickerResult.uri.replace(/^data:image\/\w+;base64,/, ""), "base64");
        xhr.send(buf);
      } else {
        xhr.send({ uri: pickerResult.uri, type: filetype, name: newfilename });
      }
    });
  };

  const getSalutations = async (codeSelected) => {
    // setValue("salutation_code", "");
    if (codeSelected !== "") {
      const qry = gql`
        query($code: String!) {
          getListSalutations(code: $code) {
            salutation_code
            salutation_name
          }
        }
      `;

      const response: any = await API.graphql(
        graphqlOperation(qry, {
          code: codeSelected,
        })
      );

      if (response.data.getListSalutations.length === 0) {
        setValue("salutation_code", 0);
      }

      setSalutationList(response.data.getListSalutations);
    } else {
      setSalutationList([]);
    }
  };

  const getSpecialty = async (codeSelected) => {
    // setValue("level1_specialty_code", "");
    if (codeSelected !== "") {
      const qry = gql`
        query($code: String!) {
          getSpecialty(code: $code) {
            code
            name
          }
        }
      `;

      const response: any = await API.graphql(
        graphqlOperation(qry, {
          code: codeSelected,
        })
      );

      setSpecialtyList(response.data.getSpecialty);
    } else {
      setSpecialtyList([]);
    }
  };

  const getSpecialty2 = async (codeSelected) => {
    // setValue("level2_specialty_code", "");
    if (codeSelected !== "") {
      const qry = gql`
        query($code: String!) {
          getSpecialty2(code: $code) {
            code
            name
          }
        }
      `;

      const response: any = await API.graphql(
        graphqlOperation(qry, {
          code: codeSelected,
        })
      );

      if (response.data.getSpecialty2.length === 0) {
        setValue("level2_specialty_code", 0);
      }

      setSecondSpecialtyList(response.data.getSpecialty2);
    } else {
      setSecondSpecialtyList([]);
    }
  };

  const loadContactsStage1 = async () => {
    try {
      const qry = gql`
        query($contact_id: Int!, $country_code: String!) {
          getContactsStage1(contact_id: $contact_id) {
            target_code
            salutation_code
            level1_specialty_code
            level2_specialty_code
          }
          listDocumentType(country_code: $country_code) {
            country_code
            description
            document_type_id
            document_type_name
            organization
          }
          getTargets {
            target_code
            target_name
          }
        }
      `;

      const response: any = await API.graphql(
        graphqlOperation(qry, {
          contact_id: personalInfoData?.contact_id,
          country_code: personalInfoData?.selected_country.country_code,
        })
      );


      await setDocumentTypeList(response.data.listDocumentType);


      let docTypes = [];
      let selectedCountryDocTypes = [];
      if (response.data.listDocumentType.length >= 1) {
        docTypes = response.data.listDocumentType;
        selectedCountryDocTypes = docTypes.filter(({ country_code }) => country_code === personalInfoData?.selected_country.country_code);

        if (selectedCountryDocTypes.length <= 0) {
          setSelectOtherAsDocType(true);
        } else {
          setSelectOtherAsDocType(false);
        }
      }

      const res: any = await loadContactsDocument();

      // register({ name: "filename", value: res?.filename });
      // register({ name: "document_type_id", value: res?.document_type_id });
      // register({ name: "specified_type_name", value: res?.specified_type_name });
      // register({ name: "specified_organization", value: res?.specified_organization });
      // setValue("document_id", res?.document_id);
      // setValue("format", res?.format);
      setDefaultFormDocument(res);
      register("format", { value: res?.format });
      register("document_id", { value: res?.document_id });
      setValue("filename", res?.filename);
      setValue("document_type_id", res?.document_type_id);
      setValue("specified_type_name", res?.specified_type_name);
      setValue("specified_organization", res?.specified_organization);

      if (res?.document_type_id === 1) {
        setSelectOtherAsDocType(true);
      }

      await setCategoryList(response.data.getTargets);

      if (
        response.data.getContactsStage1
        && response.data.getContactsStage1[0]
      ) {
        if (response.data.getContactsStage1[0]?.target_code) {
          // eslint-disable-line camelcase
          await getSalutations(response.data.getContactsStage1[0]?.target_code); // eslint-disable-line camelcase
          await getSpecialty(response.data.getContactsStage1[0]?.target_code); // eslint-disable-line camelcase
        }
        if (
          response.data.getContactsStage1[0].level1_specialty_code?.length > 0
        ) {
          await getSpecialty2(
            response.data.getContactsStage1[0].level1_specialty_code
          );
        }
      }

      await setPersonalInfoData({
        ...personalInfoData,
        ...response.data.getContactsStage1[0],
      });
    } catch (e) {
      return new Promise((reject) => reject(e));
    }

    return new Promise((resolve) => resolve(true));
  };

  const updateContactsStage1 = async (data) => {
    setSubmitting(true);
    try {
      const mutationreplaceContactsStage1 = gql`
        mutation replaceContactsStage1(
          $formValue: replaceContactsStage1Input!
        ) {
          replaceContactsStage1(formValue: $formValue)
        }
      `;

      const dataMutationreplaceContactsStage1 = (({
        contact_id,
        target_code,
        salutation_code,
        level1_specialty_code,
        level2_specialty_code,
      }) => ({
        contact_id,
        target_code,
        salutation_code,
        level1_specialty_code,
        level2_specialty_code,
      }))(data);

      await API.graphql(
        graphqlOperation(mutationreplaceContactsStage1, {
          formValue: dataMutationreplaceContactsStage1,
        })
      );
      const mutationreplaceContactsDocument = gql`
        mutation replaceContactsDocument(
          $formValue: replaceContactsDocumentInput!
        ) {
          replaceContactsDocument(formValue: $formValue)
        }
      `;

      const newData = parseInt(data?.document_type_id, 10) !== 1 ? { ...data, specified_type_name: "", specified_organization: "" } : data; // eslint-disable-line eqeqeq

      const dataMutationreplaceContactsDocument = (({
        document_id,
        contact_id,
        panelist_id,
        document_number,
        document_type_id,
        filename,
        format,
        specified_type_name,
        specified_organization,
      }) => ({
        document_id,
        contact_id,
        panelist_id,
        document_number,
        document_type_id,
        filename,
        format,
        specified_type_name,
        specified_organization,
      }))(newData);

      await API.graphql(
        graphqlOperation(mutationreplaceContactsDocument, {
          formValue: dataMutationreplaceContactsDocument,
        })
      );

      setPersonalInfoData({
        ...personalInfoData,
        ...newData,
        urlImage,
        categoryList: [categoryList.find((e) => e.target_code == getValues("target_code"))], // eslint-disable-line eqeqeq
        salutationList: [salutationList.find((e) => e.salutation_code == getValues("salutation_code"))], // eslint-disable-line eqeqeq
        specialtyList: [specialtyList.find((e) => e.code == getValues("level1_specialty_code"))], // eslint-disable-line eqeqeq
        secondSpecialtyList: [secondSpecialtyList.find((e) => e.code == getValues("level2_specialty_code"))], // eslint-disable-line eqeqeq
        documentTypeList: [documentTypeList.find((e) => e.code == getValues("level2_specialty_code"))], // eslint-disable-line eqeqeq
      });
      props.goTo();
    } catch (e) {
      setSubmitting(false);
      console.log("error saveing in stage 1", e);

      return false;
    }
    return true;
  };

  const onSubmit = async () => {
    await trigger();
    // && urlImage !== null --> add this line to condition if license image is required
    if (Object.keys(errors).length === 0) {
      const data = getValues();
      if (!unmounted) await updateContactsStage1(data);
    }
  };

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    loadContactsStage1().then(() => {
      setRefreshing(false);
    });
  }, []);

  useEffect(() => {
    register("contact_id", { value: personalInfoData?.contact_id });
    setLoading(true);
    loadContactsStage1().then(() => {
      if (!unmounted) {
        setLoading(false);
      }
    });

    return () => {
      unmounted = true;
    };
  }, [props.userStatus, props.user, currentLanguage]);

  const defaultDocumentTypeId = (DefaultFormDocument?.document_type_id === null || DefaultFormDocument?.document_type_id === undefined) ? "" : DefaultFormDocument?.document_type_id;

  return (
    <>
      <PersonalInfoTitle style={styles.fluid} />
      <ScrollView
        style={[
          styles.contentWrapper,
          Platform.OS === "web" && styles.contentWrapperWeb,
        ]}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }
        contentContainerStyle={{ paddingHorizontal: 10 }}
      >

        <Surface
          style={[
            styles.root,
            styles.boxShadow,
            Platform.OS === "web" && styles.rootWeb,
          ]}
        >
          {submitting && (
            <View style={styles.mask}>
              <ProgressBar indeterminate color={theme.colors.accent} />
            </View>
          )}

          <Title style={[styles.title, { color: theme.colors.primary }]}>
            {translate("Professional Information")}
          </Title>
          {loading && <StageLoader style={{ paddingTop: 10 }} />}
          {!loading && (
            <View>
              <FormField errors={errors?.target_code}>
                <Controller
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    const newValue = categoryList.find(
                      (e) => e.target_code === Number(value)
                    );
                    return (
                      <PickerCustom
                        style={{ flex: 1 }}
                        textCaption={
                          newValue?.target_name
                            ? translate(newValue?.target_name)
                            : ""
                        }
                        value={value}
                        mode="flat"
                        required
                        onChange={(itemValue) => {
                          onChange(itemValue);
                          setValue("salutation_code", "");
                          getSalutations(itemValue);
                          setValue("level1_specialty_code", "");
                          getSpecialty(itemValue);
                          setValue("level2_specialty_code", "");
                          setSecondSpecialtyList([]);
                        }}
                        label={translate("HCP Category")}
                        display="default"
                        withTheme={false}
                      >
                        <Picker.Item label={translate("Select HCP Category")} value="" />
                        {categoryList
                          && categoryList.map((e, index) => (
                            <Picker.Item
                              key={index.toString()}
                              label={translate(e.target_name)}
                              value={e.target_code}
                            />
                          ))}
                      </PickerCustom>
                    );
                  }}
                  name="target_code"
                  defaultValue={Number(personalInfoData?.target_code) === 0 ? "" : Number(personalInfoData?.target_code)}
                  rules={{
                    required: {
                      value: true,
                      message: translate("Please specify HCP Category"),
                    },
                  }}
                />
              </FormField>

              <FormField errors={errors?.salutation_code}>
                <Controller
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    const newValue = salutationList.find(
                      (e) => e.salutation_code === Number(value)
                    );
                    return (
                      <PickerCustom
                        style={{ flex: 1 }}
                        textCaption={
                          newValue?.salutation_name
                            ? translate(newValue?.salutation_name)
                            : ""
                        }
                        value={value}
                        mode="flat"
                        required={salutationList?.length > 0}
                        disabled={salutationList?.length === 0}
                        onChange={(itemValue) => {
                          onChange(itemValue);
                        }}
                        label={translate("Salutation")}
                        withTheme={false}
                      >
                        <Picker.Item label={salutationList?.length !== 0 ? translate("Select Salutation") : ""} value="" />
                        {salutationList
                          && salutationList.map((e, index) => (
                            <Picker.Item
                              key={index.toString()}
                              label={translate(e.salutation_name)}
                              value={e.salutation_code}
                            />
                          ))}
                      </PickerCustom>
                    );
                  }}
                  name="salutation_code"
                  defaultValue={personalInfoData?.salutation_code || 0}
                  rules={{
                    required: {
                      value: salutationList?.length > 0,
                      message: translate("Please specify salutation"),
                    },
                  }}
                />
              </FormField>

              <FormField errors={errors?.level1_specialty_code}>
                <Controller
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <PickerCustom
                      style={{ flex: 1 }}
                      value={value || ""}
                      mode="flat"
                      required
                      onChange={(itemValue) => {
                        onChange(itemValue);
                        getSpecialty2(itemValue);
                      }}
                      label={translate("Primary Specialty")}
                      withTheme={false}
                      display="default"
                    >
                      <Picker.Item label={translate("Select Primary Specialty")} value="" />
                      {specialtyList
                        && specialtyList
                          .sort((current, next) => {
                            if ((current.name).toLowerCase().includes("other")) return 1;
                            if ((next.name).toLowerCase().includes("other")) return -1;

                            if (translate(current.name) < translate(next.name)) return -1;
                            if (translate(current.name) > translate(next.name)) return 1;
                            return 0;
                          })
                          .map((e, index) => (
                            <Picker.Item
                              key={index.toString()}
                              label={translate(e.name)}
                              value={e.code}
                            />
                          ))}
                    </PickerCustom>
                  )}
                  name="level1_specialty_code"
                  defaultValue={personalInfoData?.level1_specialty_code || ""}
                  rules={{
                    required: {
                      value: true,
                      message: translate("Please specify Primary Specialty"),
                    },
                  }}
                />
              </FormField>

              <FormField>
                <Controller
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <PickerCustom
                      style={{ flex: 1 }}
                      value={value || ""}
                      mode="flat"
                      onChange={(itemValue) => {
                        onChange(itemValue);
                      }}
                      disabled={secondSpecialtyList.length === 0}
                      label={translate("Secondary Specialty")}
                      withTheme={false}
                      display="default"
                    >
                      <Picker.Item
                        label={secondSpecialtyList.length !== 0 ? translate("Select Secondary Specialty") : ""}
                        value=""
                      />
                      {secondSpecialtyList
                        && secondSpecialtyList
                          .sort((current, next) => {
                            if (translate(current.name) < translate(next.name)) return -1;
                            if (translate(current.name) > translate(next.name)) return 1;
                            return 0;
                          })
                          .map((e, index) => (
                            <Picker.Item
                              key={index.toString()}
                              label={translate(e.name)}
                              value={e.code}
                            />
                          ))}
                    </PickerCustom>
                  )}
                  name="level2_specialty_code"
                  defaultValue={personalInfoData?.level2_specialty_code || 0}
                />
              </FormField>

              {/* { License } */}
              <Text
                style={[
                  styles.subheadingSmall,
                  { paddingHorizontal: 10, color: theme.colors.placeholder },
                ]}
              >
                {translate("License").toUpperCase()}
              </Text>

              <FormField errors={errors?.document_number}>
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextInput
                      returnKeyType="next"
                      style={{
                        flex: 1,
                        backgroundColor: "transparent",
                      }}
                      onBlur={onBlur}
                      onChangeText={(ev) => {
                        onChange(ev);
                      }}
                      placeholder={translate("Enter License No.")}
                      value={value}
                      label={`${translate("License Number")} *`}
                    />
                  )}
                  name="document_number"
                  defaultValue={DefaultFormDocument?.document_number || ""}
                  rules={{
                    required: {
                      value: true,
                      message: translate("Please enter License Number"),
                    },
                    validate: (value) => {
                      if (!/^\d+$/.test(value)) {
                        return translate("Please enter a valid license number");
                      }
                      return true;
                    },
                  }}
                />
              </FormField>

              <FormField errors={errors?.document_type_id}>
                <Controller
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <PickerCustom
                      style={{ flex: 1 }}
                      value={value}
                      mode="flat"
                      onChange={(itemValue) => {
                        const documentTypeId = parseInt(itemValue, 10);
                        setSelectOtherAsDocType(documentTypeId === 1);

                        onChange(itemValue);
                        setDocTypeId(itemValue);
                      }}
                      label={translate("Document Type")}
                      withTheme={false}
                    >
                      <Picker.Item label={translate("Select Document Type")} value="" />
                      {documentTypeList
                        && documentTypeList.map((e, index) => (
                          <Picker.Item
                            key={index.toString()}
                            label={translate(e.document_type_name)}
                            value={e.document_type_id}
                          />
                        ))}
                    </PickerCustom>
                  )}
                  name="document_type_id"
                  defaultValue={selectOtherAsDocType ? 1 : defaultDocumentTypeId}
                  rules={{
                    required: {
                      value: true,
                      message: translate("Please specify Document Type"),
                    },
                  }}
                />
              </FormField>

              <View style={{ paddingTop: 10, marginBottom: 10, display: (selectOtherAsDocType || parseInt(docTypeId, 10) === 1) ? "flex" : "none" }}>
                <Text
                  style={[
                    styles.subheadingSmall,
                    { paddingHorizontal: 10, color: theme.colors.placeholder },
                  ]}
                >
                  {translate("IF OTHERS, PLEASE SPECIFY")}
                </Text>

                <FormField errors={errors?.specified_type_name}>
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextInput
                        returnKeyType="next"
                        mode="flat"
                        style={[styles.formInput, { backgroundColor: "transparent" }]}
                        onBlur={onBlur}
                        onChangeText={(ev) => {
                          onChange(ev);
                        }}
                        placeholder={translate("e.g. ME NUMBER ")}
                        value={value}
                        label={`${translate("License Type")} *`}
                      />
                    )}
                    name="specified_type_name"
                    rules={{
                      required: {
                        value: true,
                        message: translate("License type is required"),
                      },
                    }}
                    defaultValue={DefaultFormDocument?.specified_type_name || ""}
                  />
                </FormField>

                <FormField
                  errors={errors?.specified_organization}
                  style={{ marginTop: 16 }}
                >
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextInput
                        returnKeyType="next"
                        mode="flat"
                        style={[styles.formInput, { backgroundColor: "transparent" }]}
                        onBlur={onBlur}
                        onChangeText={(ev) => {
                          onChange(ev);
                        }}
                        placeholder={translate("e.g. American Medical Association")}
                        value={value}
                        label={`${translate("Organization")} *`}
                      />
                    )}
                    name="specified_organization"
                    rules={{
                      required: {
                        value: true,
                        message: translate("Organization is required"),
                      },
                    }}
                    defaultValue={DefaultFormDocument?.specified_organization || ""}
                  />
                </FormField>
              </View>

              <Text
                style={[
                  styles.subheadingSmall,
                  { paddingHorizontal: 10, color: theme.colors.placeholder },
                ]}
              >
                {translate("UPLOAD PICTURE OF LICENSE")}
              </Text>

              <FormField style={{ paddingHorizontal: 10 }} errors={errors.filename}>
                <Controller
                  control={control}
                  name="filename"
                  render={() => (
                    <>
                      {
                    urlImage ? (
                      <View style={{ flex: 1, borderRadius: 5, overflow: "hidden" }}>
                        <ImageBackground
                          onLoadEnd={() => setImageLoaded(true)}
                          style={{
                            height: 144,
                            backgroundColor: "rgb(10, 10, 10)",
                            justifyContent: "flex-start",
                            alignItems: "flex-end",
                          }}
                          source={{ uri: urlImage }}
                          resizeMode="contain"
                        >

                          {!ImageLoaded ? (
                            <View
                              style={{
                                flex: 1,
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <ActivityIndicator size={40} color="#fff" />
                            </View>
                          ) : (
                            <TouchableWithoutFeedback
                              onPress={() => {
                                setUrlImage(null);
                                setValue("format", null);
                                setValue("filename", null);
                              }}
                            >
                              <MaterialCommunityIcons name="close" size={36} color="white" />
                            </TouchableWithoutFeedback>
                          )}
                        </ImageBackground>
                        {uploadDocumentProgress < 100 && ( // eslint-disable-line eqeqeq
                          <ProgressBar
                            progress={uploadDocumentProgress / 100}
                            color={ColorsEvidera.additionalSecondary}
                          />
                        )}
                        {/* <Image source={{ uri: file?.uri }} style={{ height: 200 }} /> */}
                      </View>
                    ) : (
                      <View style={{ flex: 1 }}>
                        <TouchableWithoutFeedback onPress={() => { pickFile(); }}>
                          <View
                            pointerEvents="box-only"
                            style={styles.formFieldImageUpload}
                          >
                            <MaterialCommunityIcons name="camera-image" size={72} color="white" />
                          </View>

                        </TouchableWithoutFeedback>
                      </View>
                    )
                  }
                    </>
                  )}
                  rules={{
                    required: {
                      value: false, // TODO: confirmation if required or not
                      message: translate("Please upload a picture of license"),
                    },
                  }}
                  defaultValue={DefaultFormDocument?.filename || ""}
                />
              </FormField>

              {/* action button ************************************* */}
              <View style={styles.formActionSection}>
                <TouchableRipple
                  onPress={() => {
                    onSubmit();
                  }}
                  style={[
                    styles.formButton,
                    {
                      backgroundColor: theme.colors.accent,
                    },
                  ]}
                >
                  <View style={{ flexDirection: "row" }}>
                    {submitting && (
                      <ActivityIndicator
                        size={14}
                        color="white"
                        style={{ marginRight: 10 }}
                      />
                    )}
                    <Text style={{ color: "white" }}>{translate("Next")}</Text>
                  </View>
                </TouchableRipple>

                <TouchableRipple
                  disabled={submitting}
                  onPress={() => props.backTo()}
                  style={[styles.formButton, styles.formButtonDisabled]}
                >
                  <Text
                    theme={DefaultTheme}
                    style={{ color: theme.colors.primary }}
                  >
                    {translate("Back")}
                  </Text>
                </TouchableRipple>
              </View>
            </View>
          )}
        </Surface>
        <View style={[
          Platform.OS === "web" && styles.rootLangSelector,
          { marginTop: Platform.OS === "web" ? -10 : 0, marginLeft: Platform.OS === "web" ? 0 : -20, paddingBottom: 30 }]}
        >
          <LanguageSessionSelector style={{ margin: 0 }} />
        </View>
      </ScrollView>

      <Snackbar
        visible={alertDialog.show}
        onDismiss={() => setAlertDialog({ message: alertDialog.message, show: false, style: {} })}
        duration={5000}
        action={{
          label: <MaterialCommunityIcons name="close" size={24} color="#fff" />,
          onPress: () => {
            setAlertDialog({ message: alertDialog.message, show: false, style: {} });
          },
        }}
        wrapperStyle={{ alignItems: "center" }}
        style={[alertDialog.style, {
          maxWidth: 500,
          width: "100%",
          padding: 10,
        }]}
      >
        {alertDialog.message}
      </Snackbar>

    </>
  );
});

export default Stage1;

export const getBlob = async (fileUri) => {
  const resp = await fetch(fileUri);
  const imageBody = await resp.blob();
  return imageBody;
};
