export const TermsFR =  `
<h3 style="text-align: center; margin-top: 25px; margin-bottom: 0;">CONDITIONS RELATIVES À L’UTILISATEUR FINAL</h3>

<div class="agreement">
  <p class="justify">
    Le présent Accord (ci-après, l’ « Accord ») est passé par et entre Evidera, Ltd., société du Royaume Uni, dont les bureaux sont sis à The Ark 201 Talgarth Rd. Hammersmith, Londres W6 8BJ (ci-après, « Evidera ») et l’entité convenant aux conditions des présentes (ci-après, l’ « Utilisateur Final »). Le présent Accord régit l’accès aux Services de l’Utilisateur Final, ainsi que leur usage, et deviendra effectif à la Date d’Entrée en Vigueur. Cet Accord deviendra effectif à la date à laquelle l’Utilisateur Final clique sur le bouton « J’accepte » qui suit (ci-après, la « Date d’Entrée en Vigueur »). Si vous acceptez au nom de l’Utilisateur Final, vous déclarez et garantissez que : (i) vous disposez de la pleine autorité juridique aux effets d’engager votre employeur ou l’entité correspondante à respecter ces conditions ; (ii) vous avez lu et compris cet Accord ; et (iii) vous convenez, au nom de la partie que vous représentez, du présent Accord. Si vous n’êtes pas investi de l’autorité juridique aux effets d’engager l’Utilisateur Final, veuillez ne pas cliquer sur le bouton « J’accepte » ci-après. Le présent Accord régit l’accès de l’Utilisateur Final aux Services, ainsi que leur usage par ce dernier.
  </p>
</div>

<div class="flex flex-row">
  <p class="order remove-vertical-margin">1.</p>
  <p class="remove-vertical-margin"><strong>Services.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">1.1</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Installations et Transfert des Données.</strong>
    Toutes les installations utilisées afin de stocker et de traiter les Données de l’Utilisateur Final seront conformes à toutes les normes raisonnables de sécurité et respecteront toutes les lois et réglementations applicables en matière de confidentialité des données, y compris sans pour autant s’y limiter, les lois nationales appliquant le Règlement Général sur la Protection des Données (ci-après, le « Règlement ») de l’Union Européenne (ci-après, l’ « UE »), la Loi  relative à la Portabilité et Responsabilité de l’Assurance Médicale (ci-après, « HIPAA ») et les lois relatives à la violation de la sécurité de l’État aux États-Unis, la législation relative à la protection des données adoptée par un nombre croissant d’autres ressorts à échelle globale, ainsi que les prérequis en matière de privacité et confidentialité exigés par les Bonnes Pratiques Cliniques (ci-après, « GCP ») de l’ICH (Conseil international d'harmonisation des exigences techniques pour l'enregistrement des médicaments à usage humain).
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">1.2</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Modifications.</strong>
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub2">a.</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Portées aux Services.</strong>
    Evidera aura le droit de modifier, suspendre ou interrompre tout aspect de tout Service à tout moment, sans préavis. Dans le cas où Evidera porte une modification matérielle aux Services, elle devra en informer l’Utilisateur Final.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub2">b.</p>
  <p class="remove-vertical-margin justify break-word">
    <strong>Portées au présent Accord.</strong>
    Evidera se réserve le droit de changer ou de modifier toute condition comprise au présent Accord ou toute politique régissant le Service, à tout moment, en publiant le nouvel accord sur https://medimix.net/termsandconditions/ ou toute URL susceptible d’être fournie par Evidera. Evidera notifiera toute mise à jour du présent Accord à l’Utilisateur Final. Tout changement ou toute modification portée au présent Accord deviendra contraignante (i) lors de l’acceptation en ligne des conditions actualisées par l’Utilisateur Final, ou (ii) suite à un usage continu du Service par l’Utilisateur Final une fois ces conditions mises à jour par Evidera.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">2.</p>
  <p class="remove-vertical-margin"><strong>Obligations de l’Utilisateur Final. </strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">2.1</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Conformité.</strong>
    Le Client utilisera les Services conformément à la Politique relative à un Usage Convenable. Evidera pourra développer de nouvelles applications, caractéristiques ou fonctionnalités ayant trait aux Services disponibles, de manière ponctuelle, dont l’utilisation pourra être assujettie à l’acceptation des conditions additionnelles par le Client.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">2.2</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Restrictions d’Usage.</strong>
    Sous réserve du consentement spécifique et par écrit d’Evidera, le Client ne pourra pas et s’efforcera commercialement et raisonnablement afin que les tiers : (a) ne puissent pas vendre, ni revendre, louer ou équivalent fonctionnel, les Services à un tiers (sous réserve d’y avoir été expressément autorisés au présent Accord) ; (b) ne tentent pas de rétro concevoir le Service ou l’une quelconque de ses composantes ; (c) ne tentent pas de créer un service similaire ou de substitution au moyen de l’usage ou de l’accès aux Services ; (d) n’utilisent pas les Services dans le cadre d’Activités à Haut Risque ; ou (e) utilisent les Services afin de stocker ou de transférer toute Donnée de l’Utilisateur Final dont l’export est contrôlé en vertu des Lois relatives au Contrôle des Exports. L’Utilisateur Final sera exclusivement responsable de toute conformité applicable à l’HIPAA.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">2.3</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Politiques et Limites d’Usage.</strong>
    L’Utilisateur Final aura le droit à 1 Compte d’Utilisateur Final. L’Utilisateur Final devra respecter toute politique et limite additionnelle d’usage ayant trait à l’utilisation du Service, tel que requis par Evidera de manière ponctuelle.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">2.4</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Confidentialité.</strong>
    L’Utilisateur Final rendra exclusivement compte des informations individuelles et désidentifiées du patient provenant des dossiers médicaux. Les informations dont l’Utilisateur Final devra rendre compte seront stockées et conservées de manière sécurisée par Evidera, ces informations et résultats associés pourront être agréés ou autrement commercialisés par Evidera et ses sociétés affiliées à des parties externes et des fins commerciales (y compris des fins de recherche commerciale), dans un format anonyme regroupé ; et, en outre, afin d’éviter toute ambigüité, de telles informations et résultats associés regroupés de manière anonyme appartiendront à Evidera qui en sera le propriétaire.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">3.</p>
  <p class="remove-vertical-margin justify flexAuto">
    Services d ’Assistance Technique. Ils sont fournis par téléphone, e-mail et LifeChat, de lundi à vendredi, sous réserve des jours fériés dans les pays offrant cette assistance. L’assistance sera fournie par les équipes sur le terrain (Brésil et Philippines), ainsi que les Agents résidant en Irlande, Espagne et Allemagne. L’horaire consistera en les heures ouvrables des équipes parlant les langues suivantes : espagnol afin de couvrir l’Amérique Latine et l’Espagne (de l’Espagne et du Brésil), portugais afin de couvrir le Brésil et le Portugal (du Brésil), allemand pour et de l’Allemagne et anglais pour le reste du monde, des Philippines et de l’Irlande.
    <br/>
    L’horaire consistera en les heures ouvrables des équipes parlant les langues suivantes : espagnol afin de couvrir l’Amérique Latine et l’Espagne (de l’Espagne et du Brésil), portugais afin de couvrir le Brésil et le Portugal (du Brésil), allemand pour et de l’Allemagne et anglais pour le reste du monde, des Philippines et de l’Irlande.
    </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">4.</p>
  <p class="remove-vertical-margin"><strong>Suspension.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">4.1</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Des Comptes de l’Utilisateur Final par Evidera.</strong>
    Si Evidera prend connaissance de la violation de l’Accord par un Utilisateur Final, elle pourra Suspendre le Compte correspondant à cet Utilisateur Final. Le délai de toute Suspension s’achèvera lorsque ledit Utilisateur Final ait corrigé la violation à l’origine de la Suspension.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">4.2</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Problèmes Prioritaires de Sécurité.</strong>
    Nonobstant ce qui précède, en cas de Problème Prioritaire de Sécurité, Evidera pourra automatiquement Suspendre toute utilisation contrevenante. L’étendue de la portée et la durée de la Suspension sera réduite au minimum exigé afin de prévenir ou de mettre fin au Problème Prioritaire de Sécurité.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">5.</p>
  <p class="remove-vertical-margin"><strong>Informations Confidentielles.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">5.1</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Obligations.</strong>
    Chaque partie devra : (a) protéger les Informations Confidentielles de l’autre partie dans la même mesure où elle protège ses propres Informations Confidentielles ; et (b) ne pas divulguer ces Informations Confidentielles, sous réserve des Affiliés, employés et agents ayant besoin d’en prendre connaissance et se sont engagés par écrit à les maintenir comme confidentielles. Chaque partie (ainsi que tout Affilié, employé ou agent à qui ces Informations Confidentielles ont été divulguées) peut faire usage de ces Informations Confidentielles aux effets exclusifs d’exercer ses droits et de respecter ses obligations conformément au présent Accord, tout en prenant les mesures nécessaires à les protéger. Chaque partie est responsable de toute action de ses Affiliés, employés et agents, susceptible de violer cette Section.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">5.2</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Exceptions.</strong>
    Les Informations Confidentielles ne comprennent pas celles qui : (a) sont déjà connues par leur destinataire ; (b) deviennent du domaine public en l’absence de faute du destinataire ; (c) sont développées par le destinataire de manière indépendante ; ou (d) ont été mises de plein droit à la disposition du destinataire par une autre partie.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">5.3</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Divulgation Requise.</strong>
    Chaque partie peut divulguer les Informations Confidentielles de l’autre partie si elle y est requise par la loi et sous réserve, si juridiquement loisible : (a) elle s’efforce commercialement d’en notifier l’autre partie ; et (b) donne à l’autre partie la possibilité de faire objection à cette divulgation.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">6.</p>
  <p class="remove-vertical-margin"><strong>Droits de Propriété Intellectuelle ; Caractéristiques de la Marque.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">6.1</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Droits de Propriété Intellectuelle.</strong>
    Sous réserve d’être expressément stipulé aux présentes, cet Accord n’octroie aucun droit aux parties, qu’il soit implicite ou autre, sur le contenu ou la propriété intellectuelle de l’autre partie.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">7.</p>
  <p class="remove-vertical-margin"><strong>Déclarations, Garanties et Clauses de Non Responsabilité.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">7.1</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Déclarations et Garanties.</strong>
    Chaque partie atteste disposer des pleins pouvoirs, ainsi que de l’autorité, aux effets de passer le présent Accord. Chaque partie garantit qu’elle respectera toute loi et réglementation applicable à la mise à disposition ou à l’usage des Services, le cas échéant (y compris la loi applicable aux notifications de violation de la sécurité).
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">7.2</p>
  <p class="remove-vertical-margin justify flexAuto">
    L’Utilisateur Final s’abstiendra de distribuer tout contenu passible de poursuites pénales, ainsi que de violer tout autre droit appartenant aux tiers (droits de propriété intellectuelle en particulier), la loi sur la concurrence ou les droits des personnes de manière générale, ainsi que la règlementation relative à la protection des données. L’Utilisateur Final est responsable de respecter toute loi et règlementation applicable en matière de commerce électronique.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">7.3</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Clauses de Non Responsabilité.</strong>
    DANS TOUTE LA MESURE PERMISE PAR LA LOI, SOUS RÉSERVE DES DISPOSITIONS EXPRESSES COMPRISES AUX PRÉSENTES, AUCUNE PARTIE NE CONSTITUERA DE GARANTIE DE QUELLE QUE NATURE QUE CE SOIT EN FAVEUR DE L’AUTRE PARTIE, QU’ELLE SOIT EXPRESSE, IMPLICITE, STATUTAIRE OU AUTRE, Y COMPRIS SANS LIMITATION, TOUTE GARANTIE DE CONFORMITÉ, APTITUDE À UN USAGE PARTICULIER ET NON VIOLATION.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">8.</p>
  <p class="remove-vertical-margin"><strong>Résiliation.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">8.1</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Par l’Utilisateur Final.</strong>
    L’Utilisateur Final pourra interrompre l’usage du Service à tout moment.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">8.2</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Par Evidera.</strong>
    L’Utilisateur Final convient qu’Evidera pourra, à tout moment et pour quelle que raison que ce soit, résilier le présent Accord et/ou résilier la mise à disposition totale ou partielle du Service. Nonobstant ce qui précède, Evidera notifiera le Client de la résiliation ou interruption du Service dans un délai préalable d’au moins trente (30) jours ; sous réserve que le Service puisse être immédiatement résilié si (i) le Client a violé le présent Accord ou (ii) Evidera détermine, de manière raisonnable, qu’il est commercialement impossible de poursuivre la prestation du Service au vu des lois applicables.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">8.3</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Effets de la Résiliation.</strong>
    En cas de résiliation du présent Accord, les droits octroyés par une partie à l’autre s’éteindront immédiatement.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">9.</p>
  <p class="remove-vertical-margin"><strong>Indemnisation.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">9.1</p>
  <p class="remove-vertical-margin justify flexAuto">
    L’Utilisateur Final indemnisera, défendra et exonèrera Evidera de toute responsabilité, tout dommage et frais (y compris les dépens et les honoraires raisonnables des avocats) dérivés de toute réclamation provenant d’un tiers ou relative à l’usage des Services par l’Utilisateur Final en violation de la Politique relative à un Usage Convenable. La partie sollicitant une indemnisation notifiera la réclamation à l’autre partie dans les plus brefs délais et coopérera avec cette dernière afin de défendre une telle réclamation. La partie compensatrice disposera du plein contrôle et de la pleine autorité sur la défense, sous réserve : (a) de tout accord qui requière la partie sollicitant l’indemnisation d’admettre la responsabilité ou de régler tout montant, ce qui exigera son consentement préalable et par écrit, consentement qui ne pourra pas être déraisonnablement refusé ou retardé ; et (b) l’autre partie pourra intervenir à la défense accompagnée de son propre conseil et à ses frais.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">10.</p>
  <p class="remove-vertical-margin"><strong>Limitation de Responsabilité.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">10.1</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Limitation de Responsabilité Indirecte.</strong>
    AUCUNE PARTIE NE SERA RESPONSABLE, EN VERTU DU PRÉSENT ACCORD, DES PERTES À GAGNER OU DE DOMMAGES INDIRECTS, SPÉCIAUX, ACCIDENTELS, CONSÉCUTIFS, EXEMPLAIRES OU PUNITIFS, VOIRE SI LA PARTIE SAVAIT OU AURAIT DU SAVOIR QUE DE TELS DOMMAGES ÉTAIENT POSSIBLES ET MÊME SI LES DOMMAGES DIRECTS NE RÉSOLVENT PAS UN RECOURS.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">10.2</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Limitation relative au Plafond de la Responsabilité.</strong>
    AUCUNE PARTIE NE SERA RESPONSABLE, CONFORMÉMENT AU PRÉSENT CONTRAT, AU-DELÀ D’UN PLAFOND S’ÉLEVANT À MILLE DOLLARS (1.000 $).
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">10.3</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Exceptions aux Limitations.</strong>
    Les limitations de responsabilité s’appliquent dans toute la mesure permise par la loi applicable, mais ne s’appliquent pas aux violations des obligations de confidentialité, violations des Droits de Propriété Intellectuelle d’une partie par l’autre partie, ou aux obligations d‘indemniser.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">11.</p>
  <p class="remove-vertical-margin"><strong>Divers.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.1</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Notifications.</strong>
    Sous réserve de le spécifier autrement aux présentes : (a) toutes les notifications devront être effectuées par écrit et adressées à l’attention du service juridique et de la personne de contact principal de l’autre partie ; et (b) les notifications seront considérées comme délivrées (i) suite à la vérification de leur réception par écrit si elles ont été expédiées par messager personnel ou sous 24 heures, ou lorsque elles ont été reçues suite à leur envoi par e-mail sans vérification de leur réception ; ou (ii) lorsqu’elles ont été vérifiées moyennant réception automatisée ou registres électroniques si elles ont été envoyées par facsimile ou e-mail.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.2</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Cession.</strong>
    Aucune des parties ne pourra céder ou transférer toute partie du présent Accord sans le consentement par écrit de l’autre partie, sauf à un Affilié, sous réserve : (a) que le cessionnaire consente par écrit à être engagé par les conditions du présent Accord ; et (b) que le cédant demeure responsable des obligations assumées en vertu de l’Accord préalablement à la cession. Toute autre tentative de transfert ou cession est nulle et non avenue.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.3</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Changement de Contrôle.</strong>
    Changement de Contrôle.  Suite à un changement de contrôle (par exemple, moyennant une acquisition ou vente d’actions, fusion ou toute autre forme de transaction corporative) : (a) la partie dont le contrôle a changé le notifiera par écrit à l’autre partie dans un délai de trente jours à compter du changement de contrôle ; et (b) l’autre partie pourra résilier le présent Accord de manière immédiate, à tout moment entre le changement de contrôle et un délai de trente jours à compter de la réception de la notification par écrit susmentionnée à la sous-section (a).
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.4</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Force Majeure.</strong>
    Aucune des parties ne sera responsable d’un rendement inadéquat dans la mesure où ce dernier a été causé par une circonstance (par exemple, catastrophe naturelle, acte de guerre ou terrorisme, émeute, circonstance en matière de travail/grève, intervention gouvernementale et dérangement d’Internet) échappant au contrôle raisonnable de cette partie.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.5</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Absence de Renonciation.</strong>
    Le manquement au respect de toute disposition du présent Accord ne sera pas constitutif d’une renonciation.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.6</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Nullité Partielle.</strong>
    Si l’une quelconque des dispositions du présent Accord devenait inapplicable, le reste de ce dernier demeurera en vigueur et pleine effectivité.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.7</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Absence d’Entremise.</strong>
    Absence d’Entremise. Les parties sont des contractants indépendants et le présent Accord ne créé aucune entremise, aucun partenariat ni joint-venture.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.8</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Absence de Tiers Bénéficiaires.</strong>
    Il n’existe aucun tiers bénéficiaire au présent Accord.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.9</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Réparation Équitable.</strong>
    Réparation Équitable. Aucune disposition du présent Accord ne limitera le droit de chaque partie à solliciter une réparation équitable.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.10</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Loi Applicable.</strong>
    Le présent Accord est régi par la loi applicable en Caroline du Nord, excluant ainsi le conflit de lois. EN CAS DE DIFFÉREND ISSU OU RELATIF AU PRÉSENT ACCORD, LES PARTIES CONVIENNENT D’AVOIR RECOURS AU RESSORT DE L’ÉTAT DE LA CAROLINE DU NORD.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.11</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Avenants.</strong>
    Tout avenant devra être rédigé par écrit et expressément confirmer qu’il modifie le présent Accord.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.12</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Survivance.</strong>
    Les sections suivantes survivront à l’expiration ou résiliation du présent Accord : Sections 6 (Confidentialité), 7 (Propriété Intellectuelle), 9 (Déclarations, Garanties et Clauses de Non Responsabilité), 10 (Résiliation), 11 (Indemnisation), 12 (Limitation de Responsabilité), et 13 (Divers).
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.13</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Accord dans son Intégralité.</strong>
    El presente Acuerdo, así como toda la documentación a la cual el presente hace referencia, constituyen el acuerdo de las partes en su integralidad y relativo a su objeto, y anulan y substituyen todo acuerdo previo o actual en este ámbito. Las condiciones establecidas en una URL, a las cuales se refiera el presente Acuerdo, se incorporan al presente mediante dicha referencia.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.14</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Interprétation des Conditions Contradictoires.</strong>
    En cas de contradiction entre les documents qui composent le présent Accord, ceux-ci prévaudront dans l’ordre suivant : l’Accord, puis les conditions stipulées dans toute URL https://medimix.net/termsandconditions/.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">12.</p>
  <p class="remove-vertical-margin"><strong>Définitions.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
    “Política relativa a un Uso Adecuado” significa toda política relativa a un uso adecuado de los Servicios disponibles en el Anexo A al presente documento.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
    « Administrateurs » signifie tout personnel technique au service du Client qui administre les Services pour les Utilisateurs Finaux.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
    « Filiale » signifie toute entité qui contrôle directement ou indirectement, est contrôlée par, ou se trouve sous le contrôle commun d’une partie.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
    « Informations Confidentielles » signifie toute information divulguée par une partie à l’autre partie, conformément au présent Accord, considérée comme confidentielle ou qui serait normalement considérée comme confidentielle étant donné les circonstances. Les Données du Client sont considérées comme des Informations Confidentielles.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
    « Données de l’Utilisateur Final » signifie les données, adresse e-mail compris, fournies, générées, transmises ou affichées par les Utilisateurs Finaux au moyen des Services.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
    « Problème Prioritaire de Sécurité » signifie : (a) un usage des Services par l’Utilisateur Final en violation de la Politique relative à un Usage Convenable, ce qui est susceptible de déranger : (i) les Services ; (ii) l’usage des Services par un autre Utilisateur Final ; ou (iii) l’accès non autorisé aux Services par un tiers.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
    « Utilisateurs Finaux » signifie les personnes à qui Evidera permet d’utiliser les Services.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
    « Compte d’Utilisateur Final » signifie tout compte hébergé par Evidera et créé par un Utilisateur Final afin d’utiliser les Services.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
    « Lois sur le Contrôle des Exports » signifie toute loi et règlementation relative aux exports et réexportations, y compris les Règlementations Administratives sur l’Export (ci-après, « EAR ») dépendant du Département de Commerce des États-Unis, les sanctions commerciales et économiques dépendant de l’Organisme de Contrôle des Actifs Étrangers du Département du Trésor des États-Unis, ainsi que la Réglementation Américaine sur le Trafic d'Armes au Niveau International (ci-après, « ITAR ») dépendant du Département d’État.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
    « HIPAA » signifie la Loi relative à la Portabilité et Responsabilité de l’Assurance Médicale, en date de 1996, telle que modifiée de manière ponctuelle, ainsi que toute règlementation publiée ci-après.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
    « Droits de Propriété Intellectuelle » signifie tout droit actuel et futur à échelle mondiale protégé par la loi sur les brevets, loi sur les droits d’auteur, loi sur le secret commercial, loi sur les marques déposées, loi sur les droits moraux, ainsi que tout autre droit similaire.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
    « Services » signifie le « Portail HCP d’Evidera », application tout-en-un pour les médecins enregistrés afin de faciliter leur participation régulière aux études en ligne d’Evidera.
  </p>
</div>


<p class="remove-vertical-margin justify" style="margin-top: 48px;">
  <strong><i>Notifications:</i></strong>
  <span style="padding-left: 4px;">L’Utilisateur Final recevra une notification dès qu’une nouvelle étude devient disponible.</span>
</p>

<p class="remove-vertical-margin justify" style="margin-top: 16px;">
    <strong><i>Participations:</i></strong>
    <span style="padding-left: 4px;">Révise les participations nouvelles et en cours.</span>
</p>

<p class="remove-vertical-margin justify" style="margin-top: 16px;">
    <strong><i>Démarrage de l’Étude:</i></strong>
    <span style="padding-left: 4px;">Démarre ou reprend les participations des Utilisateurs Finaux.</span>
</p>

<p class="remove-vertical-margin justify" style="margin-top: 16px;">
    <strong><i>Données Personnelles:</i></strong>
    <span style="padding-left: 4px;">Révise les données personnelles des Utilisateurs Finaux et envoie des modifications.</span>
</p>

<p class="remove-vertical-margin justify" style="margin-top: 16px;">
    <strong><i>Règlements:</i></strong>
    <span style="padding-left: 4px;">Révise les conclusions passées des Utilisateurs Finaux et suit les règlements.</span>
</p>

<p class="remove-vertical-margin justify" style="margin-top: 16px;">
    <strong><i>Avez-vous une question ou un problème ?</i></strong>
    <span style="padding-left: 4px;">Accédez à l’assistance au moyen du chat en direct ou grâce à la section des FAQ d’Evidera.</span>
</p>


<h3 style="text-align: center; margin-top: 50px; margin-bottom: 0;">ANNEXE A</h3>
<h4 style="text-align: center; margin-bottom: 30px; margin-top: 8px;">Politique relative à un Usage Convenable</h4>

<div class="flex flex-row">
  <p class="remove-vertical-margin"><strong>ADMISSIBILITÉ</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">1.1</p>
  <p class="remove-vertical-margin justify flexAuto">
    Seule une inscription par personne interrogée est admise. Toute violation peut vous inhabiliter à recevoir la rémunération applicable et entraîner votre exclusion lors de futures études. Si vous vous êtes préalablement inscrit et avez juste besoin de mettre à jour une nouvelle adresse e-mail, veuillez-vous connecter et actualiser votre e-mail dans l’espace Compte.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">2.2</p>
  <p class="remove-vertical-margin justify flexAuto">
    Toute dénaturation de votre identité ou de toute autre information au moment de votre inscription ou de la réponse aux études, vous inhabilitera à recevoir la rémunération applicable et entraînera votre exclusion lors de futures études.
  </p>
</div>

<p style="margin-top: 20; margin-bottom: 20;" class="justify">
  <i>Professionnels de la Santé:</i>Seuls les professionnels de la santé actuellement en activité sont admis à participer. Toute dénaturation de vos références vous inhabilitera à recevoir la rémunération applicable et entraînera votre exclusion lors de participations futures.
</p>

<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">1.3</p>
  <p class="remove-vertical-margin justify flexAuto">
    Les invitations sont nominales et ne pourront pas être envoyées à d’autres personnes sous réserve d’y avoir été expressément autorisé par l’invitation e-mail.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">2.4</p>
  <p class="remove-vertical-margin justify flexAuto">
    Evidera aura le droit d’inviter ou de ne pas inviter de participants potentiels à son entière discrétion.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">3.5</p>
  <p class="remove-vertical-margin justify flexAuto">
    Toutes les réponses devront être véridiques et précises en toute bonne foi ; la fourniture de réponses fausses ou erronées entraînera la perte de la rémunération et l’interdiction de participations futures.
  </p>
</div>


<p class="remove-vertical-margin" style="margin-top: 20px;"><strong>RÉMUNÉRATION</strong></p>
<p class="remove-vertical-margin justify" style="margin-top: 4px; padding-left: 16px;">
  Evidera fournira une liste de participations préalables à l’Utilisateur Final, accompagnée du montant de la rémunération et des donations, y compris le solde d’ensemble de l’Utilisateur Final.
</p>

<p class="remove-vertical-margin" style="margin-top: 20px;"><strong>TAXES/LÉGAL</strong></p>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">1.1</p>
  <p class="remove-vertical-margin justify flexAuto">
    Vous êtes exclusivement responsable de déclarer toute taxe, le cas échéant, auprès de vos autorités financières locales, en raison de la rémunération que vous recevez. Vous reconnaissez qu’Evidera peut fournir des informations aux agences fiscales, ou retenir des taxes à la demande desdites agences ou si Evidera le juge approprié et à son entière discrétion. Evidera ne sera responsable d’aucune taxe ni de l’absence d’assistance en matière fiscale.
  </p>
</div>

<p style="margin-top: 20; margin-bottom: 20;" class="justify">
  <i>Résidents aux États-Unis:</i> Vous pouvez être requis, conformément à toute loi fédérale, étatique et/ou locale de devoir acquitter des taxes sur la rémunération que vous recevez. Vous reconnaissez et acceptez que vous fournirez à Evidera toute information personnelle additionnelle via le Formulaire IRS W-9, telle que le numéro de sécurité sociale ou celui de votre identifiant fiscal, qu’Evidera doit respecter avec toute obligation de compte rendu ou rétention. Si vous manquez de fournir de telles informations dans un délai de 30 jours à compter de la sollicitude envoyée par Evidera à l’adresse e-mail principale saisie à cet instant, le règlement de votre rémunération pourra être retardé.
</p>

<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">1.2</p>
  <p class="remove-vertical-margin justify flexAuto">
    L’Utilisateur Final reconnaît et convient que tout règlement reçu constitue la juste valeur de sa participation, est versé de plein gré et l’Utilisateur Final convient expressément qu’il ne s’agit pas d’une incitation à : prescrire tout traitement médical, ni promouvoir ou administrer de produit ou service médical quelconque, ou autrement modifier votre comportement de quelle que manière que ce soit eu égard à l’administration du soin.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">2.3</p>
  <p class="remove-vertical-margin justify flexAuto">
    Professionnels de la Santé : Tel que le requièrent les lois et règlementations sur la divulgation dans plusieurs ressorts, M3 peut se voir obligé à différer les règlements effectués en votre faveur au titre de votre participation à notre recherche marketing et autres programmes. En participant à ces programmes, vous nous autorisez à divulguer la rémunération que vous avez reçue et autre règlements applicables si nécessaire à respecter de tels prérequis.
  </p>
</div>

<p style="margin-top: 50px;"></p>
`;