import React, { useEffect, useState, useContext } from "react";
import { StyleSheet, View, Linking, Platform, TouchableOpacity } from "react-native";
import {
  Text,
  useTheme,
  Switch,
  overlay,
  Colors,
} from "react-native-paper";
import color from "color";

import Auth from "@aws-amplify/auth";
import { ScrollView } from "react-native-gesture-handler";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import * as firebaseApp from "@react-native-firebase/app";
import * as SecureStore from "expo-secure-store";
import { I18n } from "@aws-amplify/core";
import { API, graphqlOperation } from "aws-amplify";
import { StackNavigationProp } from "@react-navigation/stack"; // eslint-disable-line no-unused-vars
import gql from "graphql-tag";
import { PreferencesContext } from "../../context/preferencesContext";
import secureStoreOptionsKeychain from "../../data/secure-store-keychain";
import { UserInfoContext } from "../../context/userInfo";
import { StackNavigatorParamlist } from "../../navigation/types"; // eslint-disable-line no-unused-vars
import { getTranslations, translate } from "../../components/Localei18n/languagesFunctions";
import { getDataListLangages } from "../../components/Localei18n";
import { sendAnalyticsEvent } from "../../components/Analytics/analytics";

const secureStoreOptions = {
  keychainService: secureStoreOptionsKeychain,
  keychainAccessible: SecureStore.ALWAYS, // iOS only
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingVertical: 12,
  },
  title: {
    fontSize: 14,
    paddingHorizontal: 16,
    paddingBottom: 10,
  },
  caption: { fontSize: 14 },

  row: {
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 0.5,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 8,
    paddingHorizontal: 16,
    height: 40,
  },
});

type Props = {
  navigation?: StackNavigationProp<StackNavigatorParamlist>;
};

export const Legalinfo = (props: Props) => {
  const { userInfo, language, updateLanguage } = useContext(UserInfoContext);
  const [listOpened, setListOpened] = useState(false);
  const [listLanguage, setListLanguage] = useState([]);
  const [viewTag, setViewTag] = useState(window.viewTag);
  // const [loading, setLoading] = useState(true);
  const theme = useTheme();

  window.viewTag = viewTag;

  // console.log("Preferences", userInfo);

  const labelColor = color(theme.colors.text)
    .alpha(0.67)
    .rgb()
    .string();

  const ShadowColor = color(theme.colors.text)
    .alpha(1)
    .rgb()
    .string();

  const check = color(Colors.green500)
    .alpha(1)
    .rgb()
    .string();

  const { toggleTheme } = React.useContext(
    PreferencesContext
  );

  const toggleViewTag = () => {
    setViewTag(!viewTag);
  };

  const surfaceBackground = theme.dark
    ? (overlay(6, theme.colors.surface) as string)
    : "white";

  const itemBackground = theme.dark ? Colors.grey700 : Colors.grey50;

  const setLanguage = async (lang, name) => {
    const newLanguage = { language: lang, preferredLanguage: lang, language_name: name };
    await getTranslations(newLanguage);
    updateLanguage(newLanguage);

    const mutation = gql`
      mutation updateContactLanguage($panelist_id: Int, $language: String){
        updateContactLanguage(panelist_id: $panelist_id, language: $language)
      }
    `;

    try {
      API.graphql(graphqlOperation(mutation, { panelist_id: userInfo.panelist_id, language: lang }));

      const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
      Auth.updateUserAttributes(user, { locale: lang });
    } catch (err) {
      console.log(err);
    }
  };

  const openPrivacy = () => {
    const link = "https://www.ppd.com/who-we-are/company-resources/privacy-policy/";
    if (Platform.OS === "web") {
      window.open(link, "privacy-policy").focus();
    } else {
      Linking.openURL(link);
    }
  };

  const openNotice = () => {
    const link = "https://www.ppd.com/who-we-are/company-resources/legal-notices/";
    if (Platform.OS === "web") {
      window.open(link, "legal-notices").focus();
    } else {
      Linking.openURL(link);
    }
  };

  const getListLanguages = async () => {
    const listLanguages = await getDataListLangages();
    setListLanguage(listLanguages);
  };

  useEffect(() => {
    getListLanguages();
  }, [language]);

  useEffect(() => {
    sendAnalyticsEvent("Legalinfo");
  }, []);

  // Analytics.logEvent("PreferencesOpened");
  // Analytics.logEvent("Preferences");
  return (
    <ScrollView style={[styles.container, { backgroundColor: theme.colors.background }]}>

      { window?.environment === "MAINTENANCE"
          && (
          <View style={[styles.row, { backgroundColor: surfaceBackground, shadowColor: ShadowColor }]}>
            <Text style={styles.caption}>{translate("See Language Tag")}</Text>

            <View>
              <Switch
                onValueChange={toggleViewTag}
                value={viewTag}
                trackColor={{ false: "#cccccc", true: color(theme.colors.primary).alpha(0.2).rgb().string() }}
                thumbColor={viewTag ? theme.colors.primary : "whitesmoke"}
                ios_backgroundColor="whitesmoke"
              />
            </View>

          </View>
          )}

      <Text style={[styles.title, { marginTop: 18, color: labelColor }]}>{translate("Legal Information")}</Text>

      <TouchableOpacity onPress={() => props.navigation
        && props.navigation.push("Information", {
          title: translate("Terms and Conditions"),
          headerTitle: translate("Terms and Conditions"),
          language: language.preferredLanguage,
        })}
      >
        <View style={[styles.row, { backgroundColor: surfaceBackground, shadowColor: ShadowColor }]}>
          <Text style={styles.caption}>{translate("Terms and Conditions")}</Text>
          <View pointerEvents="none">
            <MaterialCommunityIcons name="chevron-right" size={18} color={labelColor} />
          </View>
        </View>
      </TouchableOpacity>

      <View style={{ height: StyleSheet.hairlineWidth * 2 }} />
      <TouchableOpacity onPress={openPrivacy}>
        <View style={[styles.row, { backgroundColor: surfaceBackground, shadowColor: ShadowColor }]}>
          <Text style={styles.caption}>{translate("Privacy Policy")}</Text>
          <View pointerEvents="none">
            <MaterialCommunityIcons name="chevron-right" size={18} color={labelColor} />
          </View>
        </View>
      </TouchableOpacity>

      <View style={{ height: StyleSheet.hairlineWidth * 2 }} />
      <TouchableOpacity onPress={openNotice}>
        <View style={[styles.row, { backgroundColor: surfaceBackground, shadowColor: ShadowColor }]}>
          <Text style={styles.caption}>{translate("Legal Notice")}</Text>
          <View pointerEvents="none">
            <MaterialCommunityIcons name="chevron-right" size={18} color={labelColor} />
          </View>
        </View>
      </TouchableOpacity>


    </ScrollView>
  );
};
