import React, { useState } from "react";
import { Platform } from "react-native";
import * as Font from "expo-font";
import AppLoading from "expo-app-loading";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { Amplify } from "@aws-amplify/core";
// import { AmplifyAuthStorage } from "./modules/AmplifyAuthStorage"; // workaround because aws-amplify has a huge bug in it.
// import { MemoryStorage } from './modules/MemoryStorage'; // workaround because aws-amplify has a huge bug in it.
// import UserContext from "./context/UserContext";
import axios from "axios";
import awsconfig from "./aws-exports";
import ExpoReleaseEnvironments from "./ExpoReleaseEnvironments";
import { Main } from "./screens/main";
import useCachedResources from './helpers/useCachedResources';

// Dont remove this change the default timeout of axios
axios.interceptors.request.use((config) => {
  config.timeout = 60000;
  return config;
}, (error) => Promise.reject(error));

window.LOG_LEVEL = ExpoReleaseEnvironments.LOG_LEVEL;

const awsConfig = {
  ...awsconfig,
  Analytics: {
    disabled: true,
  },
  // storage: AmplifyAuthStorage,
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: awsconfig.aws_cognito_identity_pool_id,

    // REQUIRED - Amazon Cognito Region
    region: awsconfig.aws_project_region,

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    identityPoolRegion: awsconfig.aws_project_region,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: awsconfig.aws_user_pools_id,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: awsconfig.aws_user_pools_web_client_id,

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,

    // We use session storage to logout user on web
    storage: Platform.OS === "web" ? sessionStorage : null,

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
  },
  API: {
    aws_appsync_graphqlEndpoint: awsconfig.aws_appsync_graphqlEndpoint,
    aws_appsync_region: awsconfig.aws_appsync_region,
    aws_appsync_authenticationType: awsconfig.aws_appsync_authenticationType,
    aws_appsync_apiKey: awsconfig.aws_appsync_apiKey,
    timeout: 60000,
  },
};

Amplify.configure(awsConfig);

export default function App() {
  const isLoadingComplete = useCachedResources();

  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <SafeAreaProvider>
        <Main />
      </SafeAreaProvider>
    );
  }
}
