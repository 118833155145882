import { translate } from "../../components/Localei18n/languagesFunctions";

const AuthMessage = (code, fallback = "") => {
  const msg = message?.[code] || fallback;
  return translate(msg);
};

const message = {
  UserNotFoundException: "Invalid username or email address",
  UserNotConfirmedException: "Email address is not verified. \nPlease verify your email address first.",
};

export default AuthMessage;
