import React, { useState, useEffect, useContext } from "react";
import { View, Text } from "react-native";
import PropTypes from "prop-types";
import { HCPPortalTheme } from "./AmplifyTheme";

const ErrorComponent = (props) => {
  const [error, setError] = useState("");

  useEffect(() => {
    setError(props.error);
  }, [props.error]);

  if (error) {
    return (
      <View style={[HCPPortalTheme.errorRow]}>
        <Text style={HCPPortalTheme.errorRowText}>
          *
          {" "}
          {error}
        </Text>
      </View>
    );
  }
  return null;
};

ErrorComponent.propTypes = {
  error: PropTypes.string.isRequired,
};

ErrorComponent.defaultProps = {
  error: "",
};

export default ErrorComponent;
