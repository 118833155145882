/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
/* ---------------------------------
      Verify email
      enter email then verify
------------------------------------- */
import Auth from "@aws-amplify/auth";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Platform, StyleSheet, Text, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { TextInput, Button, useTheme, IconButton } from "react-native-paper";
import { HCPPortalTheme } from "../../../components";
import AlertBox from "../../../components/Alert";
import FormField from "../../../components/FormValidator/FormField";
import LanguageSessionSelector from "../../../components/languageSessionSelector";
import { translate } from "../../../components/Localei18n/languagesFunctions";
import { AuthContext } from "../AuthContext";
import AuthMessage from "../AuthMessage";
import { ReferenceDataContext } from "./ReferenceDataContext";
import SignUpHeading from "./SignUpHeading";
import { sendAnalyticsEvent } from "../../../components/Analytics/analytics";


const styles = StyleSheet.create({
  input: {
    height: 37, width: "100%", backgroundColor: "rgb(239, 239, 239)", padding: 5, borderColor: "rgb(220, 218, 218)", borderRadius: 3,
  },
  title: {
    textAlign: "center", color: "rgb(130, 130, 130)", fontSize: 14,
  },
  button: {
    backgroundColor: "rgb(26, 158, 168)", height: 40, color: "white", borderRadius: 3, padding: 10,
  },
});

export const Stage2 = (props) => {
  const theme = useTheme();
  const {
    AuthData, setAuthData, AuthError, setAuthError,
  } = useContext(AuthContext);
  const [error, setError] = useState("");

  const tempEmail = AuthData.email;
  const tempError = AuthMessage(AuthError.code, AuthError.message);

  // console.log(AuthError);

  const {
    control,
    trigger,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const { authenticatorData, setAuthenticatorData } = useContext(ReferenceDataContext);

  const goToConfirm = async () => {
    trigger();

    if (Object.keys(errors).length <= 0) {
      if (authenticatorData.email !== "") {
        sendAnalyticsEvent("AccountSignup");
        Auth.resendSignUp(authenticatorData.email.toLowerCase());
        props.goToStage(1);
      }
    }
  };

  const goToForm = () => {
    props.goToStage(0);
    setError("");
  };

  const updateEmail = (email) => {
    const datas = {
      email,
    };
    setAuthenticatorData(datas);
  };

  useEffect(() => {
    if (AuthData?.email) {
      setAuthenticatorData({ email: AuthData?.email });
      setError(tempError);

      setAuthData({});
      setAuthError({});
    }
    // reset
    return () => {
    };
  }, []);

  return (
    <>
      <ScrollView
        keyboardShouldPersistTaps="never"
        style={[{ flex: 1, width: "100%" }]}
        contentContainerStyle={[{
          padding: 30,
          paddingTop: 10,
          paddingBottom: 20,
          minHeight: "100%",
        }]}
      >
        <View style={{
          height: "40%",
        }}
        >
          <View style={{
            position: "relative",
            marginLeft: -20,
            zIndex: 1000,
          }}
          >
            <IconButton
              icon="arrow-left"
              color={theme.colors.primary}
              size={30}
              onPress={goToForm}
              style={{ }}
            />

          </View>

          <SignUpHeading
            style={{ flex: 1, justifyContent: "center" }}
            title={translate("Enter your email to proceed")}
            subheading={translate("We will send a verification code in your email. Use the code to verify your account.")}
          />

        </View>

        <View>


          <FormField errors={errors?.email}>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  placeholder="e.g. john.doe@email.com"
                  onChangeText={(val) => {
                    onChange(val);
                    updateEmail(val);
                  }}
                  error={!!errors.email}
                  value={value}
                  label={translate("Enter your email")}
                />
              )}
              name="email"
              defaultValue={authenticatorData.email || tempEmail || ""}
              rules={{
                required: {
                  value: true,
                  message: translate("Please provide an Email."),
                },
                pattern: {
                  value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: translate("Please enter a valid email format."),
                },
              }}
            />
          </FormField>


          <AlertBox type="error" style={{ marginTop: !!errors?.email && 12 }}>{error}</AlertBox>

          <View style={{ marginTop: (!!errors?.email || error !== "") && 20, marginBottom: 20 }}>
            <Button
              mode="contained"
              color={theme.colors.primary}
              onPress={goToConfirm}
              contentStyle={{ paddingVertical: 8 }}
            >
              {translate("Go to Verify")}
            </Button>
          </View>

        </View>
      </ScrollView>

    </>
  );
};

Stage2.propTypes = {
  goToStage: PropTypes.func.isRequired,
};

Stage2.defaultProps = {};

export default Stage2;
