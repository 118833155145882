import React from "react";
import { View, StyleSheet, Text, ScrollView } from "react-native";
import {
  Surface,
  Subheading,
  TouchableRipple,
  Title,
  useTheme,
} from "react-native-paper";
import { AntDesign, MaterialCommunityIcons } from "@expo/vector-icons";

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  container: {
    padding: 15,
    marginTop: 20,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.2,
    shadowRadius: 0.9,
  },
  listContainer: {
    // flex: 1,
    marginTop: 20,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.2,
    shadowRadius: 0.9,
  },
  header: {
    paddingBottom: 15,
    flexDirection: "row",
    alignItems: "center",
  },
  row: {
    paddingBottom: 15,
  },
  iconButton: {
    width: 30,
    height: 30,
    borderRadius: 15,
    paddingVertical: 2,
    paddingHorizontal: 3,
  },
  contentDetailsWrapper: {
    flexDirection: "row",
    alignItems: "flex-start",
  },

  chevronRight: {
    width: 16,
    textAlign: "center",
  },
  contentDetailsLeft: {
    width: 66,
    textAlign: "center",
  },
  contentDetailsTitle: {
    fontSize: 14,
    lineHeight: 16,
    fontWeight: "600",
  },
  contentListTitle: {
    fontSize: 16,
    lineHeight: 18,
    fontWeight: "500",
  },
  contentDetailsRight: {
    flex: 1,
  },
  contentDetailsInlineRight: {
    flexDirection: "row",
    flex: 1,
  },
});

type Props = {
  title: string;
  hideEdit?: boolean;
  children?: any;
  style?: Object;
  onPress?: () => void;
};

export const CardIT = (props: Props) => {
  const theme = useTheme();

  return (
    <Surface style={[props.style, styles.container]}>
      <View style={styles.header}>
        <Subheading style={{ flex: 1, fontSize: 16, fontWeight: "400" }}>
          {props.title}
        </Subheading>
        {!(props.hideEdit && props.hideEdit === true) && props.onPress && (
          <TouchableRipple
            style={[styles.iconButton]}
            onPress={() => props.onPress()}
          >
            <AntDesign name="edit" size={24} color={theme.colors.primary} />
          </TouchableRipple>
        )}
      </View>
      <View>
        {props.children}
      </View>
    </Surface>
  );
};

type PropsDetails = {
  icon?: any;
  label?: string;
  children?: any;
  style?: Object;
};

export const CardITDetails = (props: PropsDetails) => (
  <View style={[styles.contentDetailsWrapper, props.style]}>
    <Text style={styles.contentDetailsLeft}>{props.icon}</Text>
    <View style={styles.contentDetailsRight}>
      <Title style={styles.contentDetailsTitle}>{props.label}</Title>
      <View style={{}}>{props.children}</View>
    </View>
  </View>
);

type PropsDetailsInline = {
  icon?: any;
  children?: any;
  style?: Object;
};

export const CardITDetailsInline = (props: PropsDetailsInline) => (
  <View style={[styles.contentDetailsWrapper, props.style]}>
    <Text style={styles.contentDetailsLeft}>{props.icon && props.icon}</Text>
    <View style={styles.contentDetailsInlineRight}>{props.children}</View>
  </View>
);

type PropsDetailsInlineItem = {
  label?: string;
  children?: any;
  style?: Object;
};
export const CardITDetailsInlineItem = (props: PropsDetailsInlineItem) => (
  <View style={[styles.contentDetailsRight, props.style]}>
    <Title style={styles.contentDetailsTitle}>{props.label}</Title>
    <View style={{ flexDirection: "column" }}>{props.children}</View>
  </View>
);

export const CardITList = (props: Props) => {
  const theme = useTheme();
  return (
    <Surface style={[props.style, styles.listContainer]}>
      <View
        style={[
          styles.header,
          { paddingTop: 15, paddingHorizontal: 15, paddingBottom: 5 },
        ]}
      >
        <Subheading style={{ flex: 1, fontSize: 20, fontWeight: "700" }}>
          {props.title}
        </Subheading>
        {props.onPress && (
          <TouchableRipple
            style={[styles.iconButton]}
            onPress={() => props.onPress()}
          >
            <AntDesign name="edit" size={24} color={theme.colors.primary} />
          </TouchableRipple>
        )}
      </View>
      {props.children}
    </Surface>
  );
};

type PropsList = {
  label: string | object;
  children?: any;
  style?: Object;
  avatar?: any;
  onPress: () => void;
};

export const CardITListItem = (props: PropsList) => (
  <TouchableRipple
    style={[
      styles.contentDetailsWrapper,
      props.style,
      { paddingHorizontal: 15, paddingVertical: 10 },
    ]}
    onPress={props.onPress}
  >
    <View style={{
      minHeight: 40, flexDirection: "row", alignItems: "center", flex: 1,
    }}
    >
      {props.avatar && (
        <View style={{ alignSelf: "flex-start" }}>{props.avatar}</View>
      )}

      <View style={styles.contentDetailsRight}>
        <Title style={styles.contentListTitle}>{props.label}</Title>
        {props.children && (
          <View style={{ flexDirection: "column" }}>{props.children}</View>
        )}
      </View>

      <Text style={styles.chevronRight}>
        <MaterialCommunityIcons name="chevron-right" size={24} color="grey" />
      </Text>
    </View>
  </TouchableRipple>
);

type PropsListItemAction = {
  children?: any;
  style?: Object;
};

export const CardITListItemAction = (props: PropsListItemAction) => (
  <View
    style={[
      styles.contentDetailsWrapper,
      props.style,
      { paddingHorizontal: 15, paddingVertical: 7 },
    ]}
  >
    <View style={{ minHeight: 40, flexDirection: "row", alignItems: "center" }}>
      <View style={styles.contentDetailsRight}>
        {props.children && (
          <View style={{ flexDirection: "row" }}>{props.children}</View>
        )}
      </View>
    </View>
  </View>
);
