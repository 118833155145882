/* eslint-disable camelcase */
// eslint-disable-next-line import/no-extraneous-dependencies
import { API, graphqlOperation } from "aws-amplify";
// eslint-disable-next-line import/no-extraneous-dependencies
import Auth from "@aws-amplify/auth";
import gql from "graphql-tag";

import * as SecureStore from "expo-secure-store";
import { Platform } from "react-native";
import Constants from "expo-constants";
import axios from "axios";
import secureStoreOptionsKeychain from "../data/secure-store-keychain";
import { getTranslations } from "../components/Localei18n/languagesFunctions";
import UnsecureStorage from "../data/UnsecureStorage";
import ExpoReleaseEnvironments from "../ExpoReleaseEnvironments";
import useAmplifyAuth from "./useAmplifyAuth";

const secureStoreOptions = {
  keychainService: secureStoreOptionsKeychain,
  keychainAccessible: SecureStore.ALWAYS, // iOS only
};

type Language = {
  default_language_code: string;
  default_language_name: string;
}
const UserService = () => {
  // eslint-disable-next-line camelcase
  const { handleSignout, refreshCurrentUser } = useAmplifyAuth();
  const testUserCheck = async (email) => {
    try {
      const qry = gql`
      query getContactsInfo_by_email($email: String!){
        getTesterPrivileges(email: $email){ id first_name last_name }
      }
    `;

      const response: any = await API.graphql(graphqlOperation(qry, {
        email,
      }));

      return response.data.getTesterPrivileges;
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  const getUserInfo = async (email, field = "email") => {
    try {
      const qry = gql`
        query getuser($email: String!){
          getContactsInfo_by_email(email: $email){
            ${field}
          }
        }
      `;
      const response: any = await API.graphql(graphqlOperation(qry, {
        email,
      }));

      return response.data.getContactsInfo_by_email;
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  const applyLanguagePreference = async (response: Language) => {
    // await registerExpoPushToken(response.data.getContactsInfo_by_email.panelist_id);

    const res = Platform.OS === "web" ? null : await SecureStore.getItemAsync("language", secureStoreOptions);
    const lang = (res === null || res === "") ? response?.default_language_code : res;

    const languages = ["en", "pt", "de", "es", "it", "fr"];
    if (!languages.includes(lang)) {
      const obj = {
        preferredLanguage: "en",
        language: "en",
        language_name: "English",
      };

      return obj;
    }

    const obj = {
      preferredLanguage: lang,
      language: response?.default_language_code,
      language_name: response?.default_language_name,
    };

    if (response?.default_language_code && response?.default_language_code !== null) {
      // await getTranslations(obj);
      // setLanguage(obj);
      return obj;
    }
    const cacheLanguage = Platform.OS === "web" ? JSON.parse(UnsecureStorage.getItemAsync("language")) : JSON.parse(await SecureStore.getItemAsync("language", secureStoreOptions));

    if (cacheLanguage) {
      // await getTranslations(cacheLanguage);
      // setLanguage(cacheLanguage);
      return cacheLanguage;
    }

    return null;
    // await getTranslations(language);
    // setLanguage(language);
  };

  const loginDevice = async () => {
    // console.log((await Auth.currentSession()).getAccessToken());

    const {
      accessToken, panelist_id, clientId, event_id, token,
    } = await Auth.currentSession()
      .then(({
        accessToken: {
          jwtToken = "",
          payload: { client_id = "", event_id = "" },
        }, idToken: {
          payload = {},
        }, refreshToken: {
          token = "",
        },
      }: any) => ({
        event_id,
        clientId: client_id,
        accessToken: jwtToken,
        token,
        panelist_id: payload?.["custom:panelist_id"],
      }));

    const mutation = gql`
      mutation myquery($panelist_id: Int, $uniqueId: String, $accessToken: String, $clientId: String, $deviceKey: String) {
        insertDeviceLogin(panelist_id: $panelist_id, uniqueId: $uniqueId, accessToken: $accessToken, clientId: $clientId, deviceKey: $deviceKey)
      }
    `;

    const response: any = await API.graphql(graphqlOperation(mutation, {
      panelist_id, uniqueId: event_id, accessToken: token, clientId, deviceKey: "",
    }));

    if (response?.data?.insertDeviceLogin === true) {
      try {
        await axios.post(
          `${ExpoReleaseEnvironments().backofficeUrl}api/hcp/deactivateSession`,
          { panelist_id },
          { headers: { Authorization: `Bearer ${accessToken}` } }
        );
      } catch (e) {
        console.log(e);
      }

      return true;
    }
    return false;
  };

  const verifyUser = async (signout = false) => {
    try {
      const token = (await Auth.currentSession()).getAccessToken().getJwtToken();

      const sessionResponse = await axios.post(
        `${ExpoReleaseEnvironments().backofficeUrl}api/hcp/verifyUser`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return sessionResponse?.data?.status === "ok";
    } catch (e) {
      console.warn("error fetching:", JSON.stringify(e));
      if (signout) handleSignout();
      return false;
    }
  };

  const checkUser = async () => {
    try {
      await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });
      return true;
    } catch (e) {
      return false;
    }
  };

  // save a request to delete account
  const deleteAccountRequest = async (data: any) => {
    try {
      const {
        accessToken, panelist_id, cognitoUsername,
      } = await Auth.currentSession()
        .then(({
          accessToken: {
            jwtToken = "",
          },
          idToken: {
            payload = {},
          },
        }: any) => ({
          accessToken: jwtToken,
          panelist_id: payload?.["custom:panelist_id"],
          cognitoUsername: payload?.["cognito:username"],
        }));

      const formValue = {
        ...data,
        panelist_id,
        cognitoUsername,
        userPoolId: ExpoReleaseEnvironments().userPoolId,
      };

      const mutation = gql`
        mutation InsertAccountDeletionRequest($formValue: FormAccountDeletion) {
          insertAccountDeletionRequest(formValue: $formValue)
        }`;

      try {
        const response: any = await API.graphql(graphqlOperation(mutation, { formValue }));

        return response?.data?.insertAccountDeletionRequest;
      } catch (err) {
        console.log(["failed to insert deletion request: ", err, data]);
      }
    } catch (e) {
      console.warn("error fetching:", JSON.stringify(e));
      return false;
    }
  };

  return {
    testUserCheck,
    getUserInfo,
    applyLanguagePreference,
    loginDevice,
    verifyUser,
    checkUser,
    deleteAccountRequest,
  };
};

export default UserService;
