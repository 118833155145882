/* eslint-disable camelcase */
import React, { useState, useCallback, useEffect, useContext } from "react";
import { View,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  TouchableOpacity,
  TouchableWithoutFeedback,
  Alert } from "react-native";
import { Picker } from "@react-native-picker/picker";
import { useForm, Controller } from "react-hook-form";
import { TextInput,
  useTheme,
  Title,
  Subheading,
  Portal,
  Text,
  Button,
  Snackbar,
  Switch } from "react-native-paper";
import debounce from "lodash/debounce";
import { API, graphqlOperation } from "aws-amplify";
import gql from "graphql-tag";
import { StackNavigationProp } from "@react-navigation/stack";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { styles } from "./forms.style";
import { ProfileParamlist } from "../types"; // eslint-disable-line no-unused-vars
import { translate } from "../../../components/Localei18n/languagesFunctions";
import ConfirmDialog from "../../Auth/components/ConfirmDialog";
import { PickerCustom } from "../../../components/picker.custom";
import { UserInfoContext } from "../../../context/userInfo";
import FormField from "../../../components/FormValidator/FormField";

type Props = {
  // eslint-disable-next-line react/require-default-props
  navigation?: StackNavigationProp<ProfileParamlist>;
  route: {
    params: any
  }
};


const EditContactsAddress = (props: Props) => {
  const theme = useTheme();
  const {
    route: {
      params: {
        onDelete,
      },
    },
  } = props;
  const {
    control, register, getValues, setValue, formState: { errors },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  let unmounted = false;
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [DeleteLoading, setDeleteLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [inputData, setInputData] = useState(props.route.params); // eslint-disable-line react/prop-types
  const [confirmDialogOptions, setConfirmDialogOptions] = useState({
    visible: false,
    title: null,
    message: null,
    confirmFn: null,
    cancelFn: null,
  });

  const [Options, setOptions] = useState({ countries: [] });
  const [selectOpen, setSelectOpen] = useState(false);
  const { userInfo: { uic } } = useContext(UserInfoContext);

  const hideDialog = () => {
    setConfirmDialogOptions({ ...confirmDialogOptions, visible: false });
  };

  const onSubmit = async (data: object) => {
    const mutation = gql`
      mutation update($formValue: FormContactsAddressInput, $panelist_id: Int!, $status: Int, $uic: String){
        insertIgnoreFormsContacts(panelist_id: $panelist_id)
        insertIgnoreForsmContactsAddress(panelist_id: $panelist_id)
        updateVerificationStatus(status: $status, panelist_id: $panelist_id, uic: $uic)
        updateFormAddress(formValue: $formValue)
      }
    `;

    try {
      const response = await API.graphql(graphqlOperation(mutation, {
        formValue: { ...data, primary_address: data.primary_address ? 1 : 0 },
        panelist_id: inputData.panelist_id,
        status: 2,
        uic,
      }));

      if (response.data.updateFormAddress !== true) {
        setMessage(translate("Failed to update Address."));
        setSnackbarVisible(true);
      }
    } catch (err) {
      console.log(["failed to update contacts", err, data]);
    }
  };

  const getOptions = () => new Promise((resolve, reject) => {
    const qry = gql`
    {
      getCountries {
        country_code country_name
      }
    }
    `;

    try {
      const response: any = API.graphql(graphqlOperation(qry, {}));
      resolve(response);
    } catch (err) {
      reject(err);
    }
  });

  const autoSave = useCallback(debounce(() => {
    if (Object.keys(errors).length === 0) {
      if (!unmounted) {
        onSubmit(getValues());
        // Analytics.logEvent("AddressUpdated");
      }
    }
  }, 1000, { leading: false }), []);

  const handleDelete = () => {
    if (Platform.OS === "web") {
      setDeleteLoading(true);
      hideDialog();
      onDelete()
        .then((res) => {
          setDeleteLoading(false);
          if (res === true) {
            // Analytics.logEvent("AddressUpdated");
            props.navigation.goBack();
          } else if (res === false) {
            setMessage(translate("Failed to delete Address."));
            setSnackbarVisible(true);
          }
        });
    } else {
      Alert.alert(
        translate("Confirm delete"),
        translate("Delete this address?"),
        [
          {
            text: translate("Cancel"),
            onPress: () => {},
            style: "cancel",
          },
          {
            text: "Delete",
            onPress: () => {
              setDeleteLoading(true);
              props.route.params
                .onDelete()
                .then((res) => {
                  setDeleteLoading(false);
                  if (res === true) {
                  // Analytics.logEvent("AddressUpdated");
                    props.navigation.goBack();
                  } else if (res === false) {
                    setMessage(translate("Failed to delete Address."));
                    setSnackbarVisible(true);
                  }
                });
            }
            ,
          },
        ],
        { cancelable: false }
      );
    }
  };

  useEffect(() => {
    register("panelist_id", { value: inputData.panelist_id });
    register("contact_id", { value: inputData.contact_id });
    register("address_id", { value: inputData.address_id });
    getOptions().then((response) => {
      if (unmounted) return;
      const newOptions = Options;
      newOptions.countries = response.data.getCountries;


      const selected = Options.countries.filter((e) => e.country_code === inputData.address_country_code);
      setInputData({ ...inputData, address_country_code: inputData.address_country_code, address_country_code_desc: selected.length >= 1 ? selected[0].country_name : "" });

      setOptions(newOptions);
    }).catch((e) => {
      console.log(["error fetching list numbertype", e]);
    });

    return () => {
      unmounted = true;
    };
  }, []);

  const cancelSetAddressAsPrimary = () => {
    setValue("primary_address", false);
    hideDialog();
  };

  const handleSetAddressAsPrimary = (as_primary) => {
    setInputData({ ...inputData, primary_address: as_primary });
    autoSave();
    setConfirmDialogOptions({ ...confirmDialogOptions, visible: false });
  };

  const setAddressAsPrimaryHandler = (as_primary) => {
    if (as_primary) {
      setConfirmDialogOptions({
        ...confirmDialogOptions,
        visible: true,
        title: translate("Confirm"),
        message: translate("Make this address as primary?"),
        confirmFn: () => handleSetAddressAsPrimary(as_primary),
        cancelFn: cancelSetAddressAsPrimary,
      });
    }
  };

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={[
        styles.root,
        { backgroundColor: theme.colors.background },
      ]}
    >
      <ScrollView style={styles.scrollWrapper}>
        <View style={[styles.scrollInnerResponsive, { flex: 1 }]}>
          <View style={styles.titleWRapper}>
            <Title style={styles.title}>{translate("Update Address")}</Title>
            <Subheading style={styles.subheadingRegular}>
              {translate("Any changes to address is subject for verification.")}
            </Subheading>
          </View>

          <FormField
            bottomSpace
            hint={translate("Street Address, Building Name")}
            errors={errors?.address_line1}
          >
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  multiline
                  returnKeyType="next"
                  mode="outlined"
                  style={[styles.formInput, { textAlign: "left" }]}
                  onBlur={onBlur}
                  onChangeText={(ev) => {
                    onChange(ev);
                    autoSave();
                  }}
                  value={value}
                  placeholder=""
                  label={translate("Address Line 1")}
                />
              )}
              name="address_line1"
              defaultValue={inputData.address_line1}
            />
          </FormField>

          <FormField
            bottomSpace
            hint={translate("Department name or unit/space #")}
          >
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  multiline
                  returnKeyType="next"
                  mode="outlined"
                  style={[styles.formInput, { textAlign: "left" }]}
                  onBlur={onBlur}
                  onChangeText={(ev) => {
                    onChange(ev);
                    autoSave();
                  }}
                  value={value}
                  placeholder=""
                  label={translate("Address Line 2")}
                />
              )}
              name="address_line2"
              defaultValue={inputData.address_line2}
            />
          </FormField>

          <FormField bottomSpace>
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  multiline
                  returnKeyType="next"
                  mode="outlined"
                  style={[styles.formInput, { textAlign: "left" }]}
                  onBlur={onBlur}
                  onChangeText={(ev) => {
                    onChange(ev);
                    autoSave();
                  }}
                  value={value}
                  placeholder=""
                  label={translate("Hospital / Health Center Name")}
                />
              )}
              name="address_center_name"
              defaultValue={inputData.address_center_name}
            />
          </FormField>

          <FormField errors={errors?.address_country_code}>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <PickerCustom
                  style={{ flex: 1 }}
                  value={value}
                  mode="outlined"
                  required
                  onChange={(itemValue) => {
                    onChange(itemValue);
                    autoSave();
                  }}
                  label={translate("Country")}
                >
                  <Picker.Item label={translate("Select Country")} value="" />
                  {Options.countries && Options.countries.map((e, index) => (
                    <Picker.Item
                      key={index.toString()}
                      label={translate(e.country_name)}
                      value={e.country_code}
                    />
                  ))}
                </PickerCustom>
              )}
              name="address_country_code"
              defaultValue={inputData.address_country_code}
              rules={{
                required: {
                  value: true, message: translate("Please select country"),
                },
              }}
            />
          </FormField>

          <View style={[styles.formField]}>
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  multiline
                  returnKeyType="next"
                  mode="outlined"
                  style={[styles.formInput, { textAlign: "left" }]}
                  onBlur={onBlur}
                  onChangeText={(ev) => {
                    onChange(ev);
                    autoSave();
                  }}
                  value={value}
                  placeholder=""
                  label={translate("State/Province")}
                />
              )}
              name="address_state_or_province"
              defaultValue={inputData.address_state_or_province}
            />
          </View>

          <View style={[styles.formFieldRow, { marginBottom: 24 }]}>
            <View style={[styles.formField, { flex: 1, marginBottom: 0, marginRight: 15 }]}>
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextInput
                    returnKeyType="next"
                    mode="outlined"
                    style={[styles.formInput, { textAlign: "left" }]}
                    onBlur={onBlur}
                    onChangeText={(ev) => {
                      onChange(ev);
                      autoSave();
                    }}
                    placeholder={translate("e.g. New York")}
                    value={value}
                    label={translate("City")}
                  />
                )}
                name="address_city"
                rules={{ required: true }}
                defaultValue={inputData.address_city}

              />
            </View>

            <View style={[styles.formField, { marginBottom: 0, width: 130 }]}>
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextInput
                    returnKeyType="next"
                    mode="outlined"
                    style={[styles.formInput, { textAlign: "left" }]}
                    onBlur={onBlur}
                    onChangeText={(ev) => {
                      onChange(ev);
                      autoSave();
                    }}
                    value={value}
                    placeholder=""
                    label={translate("Postal Code")}
                  />
                )}
                name="address_postal_code"
                defaultValue={inputData.address_postal_code}
              />
            </View>

          </View>

          <View style={styles.formField}>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <Controller
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    {inputData.primary_address
                      ? (
                        <View style={{ flexDirection: "row", alignItems: "center" }}>
                          <MaterialCommunityIcons name="check-bold" size={24} color={theme.colors.primary} />
                          <Text style={{ fontSize: 18, fontWeight: "700", color: theme.colors.primary }}>
                            {translate("Primary Address")}
                          </Text>
                        </View>
                      )
                      : (
                        <>

                          <TouchableWithoutFeedback onPress={() => {
                            Alert.alert(
                              translate("Confirm"),
                              translate("Make this address as primary?"),
                              [
                                {
                                  text: translate("Cancel"),
                                  onPress: () => {},
                                  style: "cancel",
                                },
                                {
                                  text: translate("Set as Primary"),
                                  onPress: () => {
                                    setInputData({ ...inputData, primary_address: !value });
                                    onChange(!value);
                                    autoSave();
                                  }
                                  ,
                                },
                              ],
                              { cancelable: false }
                            );
                          }}
                          >
                            <View style={{ flexDirection: "row", alignItems: "center" }}>
                              <View pointerEvents="none">
                                <Switch
                                  color={theme.colors.primary}
                                  value={value}
                                  onValueChange={() => {
                                    if (Platform.OS === "web") {
                                      setAddressAsPrimaryHandler(!value);
                                    }
                                    onChange(!value);
                                  }}
                                />
                              </View>
                              <Text style={{ fontSize: 17, fontWeight: "500", marginLeft: 12 }}>{translate("Set as Primary")}</Text>
                            </View>
                          </TouchableWithoutFeedback>

                        </>
                      )}
                  </>

                )}
                name="primary_address"
                defaultValue={inputData.primary_address}
              />

            </View>
          </View>


          <View style={styles.formActionField}>
            <Button
              disabled={inputData.primary_address || DeleteLoading === true}
              loading={DeleteLoading}
              onPress={Platform.OS === "web" ? () => setConfirmDialogOptions({
                ...confirmDialogOptions,
                visible: true,
                title: translate("Confirm Delete"),
                message: translate("Delete this address?"),
                confirmFn: handleDelete,
                cancelFn: hideDialog,
              }) : handleDelete}
              contentStyle={[styles.button]}
              dark
              color="red"
            >
              {translate("Delete")}
            </Button>
          </View>

        </View>
        <Portal>
          {selectOpen
            && (
            <View style={{ flex: 1, backgroundColor: "rgba(0,0,0,0.31)", justifyContent: "flex-end" }}>
              <View style={styles.pickerWrapper}>
                <View style={styles.pickerLabelWrapper}>
                  <View style={{ flex: 1 }}>
                    <Text style={styles.pickerLabel}>{translate("Select")}</Text>
                  </View>
                  <TouchableOpacity style={styles.pickerActionClose} onPress={() => setSelectOpen(!selectOpen)}>
                    <Text style={styles.pickerActionText}>{translate("Done")}</Text>
                  </TouchableOpacity>
                </View>
                <View style={{ height: 200 }}>

                  <Picker
                    selectedValue={inputData.address_country_code}
                    style={{ width: "100%" }}
                    onValueChange={(itemValue) => {
                      const selected = Options.countries.filter((e) => e.country_code === itemValue);
                      setInputData({ ...inputData, address_country_code: itemValue, address_country_code_desc: selected.length >= 1 ? selected[0].country_name : "" });
                      setValue("address_country_code", itemValue);
                      autoSave();
                    }}
                  >

                    <Picker.Item
                      label="N/A"
                      value="0"
                    />
                    {Options.countries && Options.countries.map((e, index) => <Picker.Item key={index.toString()} label={e.country_name.substr(12).replace(/_/g, " ")} value={e.country_code} />)}
                  </Picker>
                </View>
              </View>
            </View>
            )}
        </Portal>
      </ScrollView>

      <ConfirmDialog
        visible={confirmDialogOptions.visible}
        cancelEvt={confirmDialogOptions.cancelFn}
        confirmEvt={confirmDialogOptions.confirmFn}
        title={confirmDialogOptions.title}
        message={confirmDialogOptions.message}
      />

      <Snackbar
        visible={snackbarVisible}
        onDismiss={() => setSnackbarVisible(false)}
        duration={5000}
        action={{
          label: "×",
          onPress: () => {
            setSnackbarVisible(false);
          },
        }}
        style={{
          maxWidth: 500,
          width: "100%",
          padding: 10,
        }}
        wrapperStyle={{ alignItems: "center" }}
      >
        {message}
      </Snackbar>
    </KeyboardAvoidingView>
  );
};

export default EditContactsAddress;
