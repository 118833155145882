import React, { useState, useEffect, useContext } from "react";
import { StyleSheet, View, ScrollView, Text, LogBox, Platform } from "react-native";
import {
  useTheme,
  ActivityIndicator, TouchableRipple, Snackbar,
} from "react-native-paper";
import color from "color";

import { API, graphqlOperation } from "aws-amplify";
import gql from "graphql-tag";
import { I18n } from "@aws-amplify/core";

import { StackNavigationProp } from "@react-navigation/stack"; // eslint-disable-line no-unused-vars
// import FormField from "../../helpers/FormField";
import { StackNavigatorParamlist } from "../../../navigation/types"; // eslint-disable-line no-unused-vars
import { CardITListItem, CardITList, CardITListItemAction } from "../../../components/details-icon-text-card";
import { translate } from "../../../components/Localei18n/languagesFunctions";
import { UserInfoContext } from "../../../context/userInfo";

type Props = {
  // eslint-disable-next-line react/require-default-props
  navigation?: StackNavigationProp<StackNavigatorParamlist>; // eslint-disable-line react/no-unused-prop-types

};

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  inlineCardDetails: {
    flexDirection: "row",
  },
});

export const ProfileContactsContactsEdit = (props: Props) => {
  if (Platform.OS !== "web") {
    LogBox.ignoreLogs([
      "Warning: Failed prop type: Invalid prop `textComponent` of type `object` supplied to `PhoneInput`, expected `function`.",
    ]);
  }

  // eslint-disable-next-line react/prop-types
  const managedUserInfo = props.route.params;
  const [contact_id, setContact_id] = useState(null);
  const theme = useTheme();
  const { environment } = useContext(UserInfoContext);

  const [loading, setLoading] = useState(false);
  const [numbers, setNumbers] = useState([]);
  const [emails, setEmail] = useState([]);

  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [message, setMessage] = useState("");

  const mutedColor = color(theme.colors.text)
    .alpha(0.67)
    .rgb()
    .string();

  const { userInfo: { uic } } = useContext(UserInfoContext);

  const getContacts = async () => {
    try {
      const qry = gql`
        query ($email: String!) {
          getFormsContactsInfo_by_email(email: $email){
            verification_status contact_id
          }
          getContacts_numbers(email: $email){
            number panelist_id country_phone_code primary_number number_type number_id number_type_name number_status_id
          }
          getContacts_emails_by_email(email: $email){
            panelist_id email email_status_id primary_email_address email_id
          }
          getFormContacts_numbers(email: $email){
            number panelist_id country_phone_code primary_number number_type number_id number_type_name contact_id number_status_id
          }
          getFormContacts_emails_by_email(email: $email){
            panelist_id email email_status_id primary_email_address email_id
          }
        }
      `;
      const response: any = await API.graphql(graphqlOperation(qry, {
        // eslint-disable-next-line react/prop-types
        email: managedUserInfo.email,
      }));
      setContact_id(response.data.getFormsContactsInfo_by_email?.contact_id);

      if (response.data.getFormsContactsInfo_by_email?.verification_status // eslint-disable-line react/prop-types
        && response.data.getFormsContactsInfo_by_email?.verification_status !== null // eslint-disable-line react/prop-types
        && [2].includes(response.data.getFormsContactsInfo_by_email?.verification_status)) { // eslint-disable-line react/prop-types
        setEmail(response.data.getFormContacts_emails_by_email.length > 0
          && response.data.getContacts_emails_by_email !== response.data.getFormContacts_emails_by_email
          ? response.data.getFormContacts_emails_by_email : response.data.getContacts_emails_by_email);

        setNumbers(response.data.getFormContacts_numbers.length > 0
          && response.data.getContacts_numbers !== response.data.getFormContacts_numbers
          ? response.data.getFormContacts_numbers : response.data.getContacts_numbers);
      } else {
        setNumbers(response.data.getContacts_numbers);
        setEmail(response.data.getContacts_emails_by_email);
      }
      setLoading(false);
    } catch (e) {
      console.log(["profilecontects->getContacts->error", e]);
      setLoading(false);
      throw e;
    }
  };

  const onDeleteEmail = async (id) => {
    const mutation = gql`
        mutation delete($email_id: Int!,$panelist_id: Int!, $status: Int, $uic: String){
          insertIgnoreFormsContacts(panelist_id: $panelist_id)
          insertIgnoreFormsContactsEmails(panelist_id: $panelist_id)
          updateVerificationStatus(status: $status, panelist_id: $panelist_id, uic: $uic)
          deleteFormEmail(email_id: $email_id)
        }
      `;
    try {
      const response = await API.graphql(graphqlOperation(mutation, {
        email_id: id,
        panelist_id: managedUserInfo.panelist_id,
        status: 2,
        uic,
      }));

      if (response.data.deleteFormEmail) {
        const newEmail = emails.filter((e) => e.email_id !== id);
        setEmail(newEmail);
        setMessage(translate("Email deleted."));
        setSnackbarVisible(true);
      }
      return new Promise((resolve) => resolve(true));
    } catch (err) {
      console.log(["error", err]);
      return new Promise((resolve) => resolve(false));
    }
  };

  const onDeleteNumber = async (id) => {
    const mutation = gql`
        mutation delete($number_id: Int!,$panelist_id: Int!, $status: Int, $uic: String){
          insertIgnoreFormsContacts(panelist_id: $panelist_id)
          insertIgnoreForsmContactsNumbers(panelist_id: $panelist_id)
          updateVerificationStatus(status: $status, panelist_id: $panelist_id, uic: $uic)
          deleteFormNumber(number_id: $number_id)
        }
      `;
    try {
      const response = await API.graphql(graphqlOperation(mutation, {
        number_id: id,
        panelist_id: managedUserInfo.panelist_id,
        status: 2,
        uic,
      }));

      if (response.data.deleteFormNumber) {
        const newNumber = numbers.filter((e) => e.number_id !== id);
        setNumbers(newNumber);
        setMessage(translate("Number deleted."));
        setSnackbarVisible(true);
      }
      return new Promise((resolve) => resolve(true));
    } catch (err) {
      console.log(["error", err]);
      return new Promise((resolve) => resolve(false));
    }
  };

  const openSnackbarHandler = (msg) => {
    setSnackbarVisible(true);
    setMessage(msg);
  };

  const handleEnvCheck = () => {
    if (environment === "PRODUCTION") return true;

    alert("Editing disabled for test user.");
    return false;
  };

  useEffect(() => {
    setLoading(true);
    getContacts();
    // console.log(emails);
    const unsubscribe = props.navigation.addListener("focus", () => {
      getContacts();
    });

    return unsubscribe;
  }, []);

  return (
    <View style={{ flex: 1 }}>

      <ScrollView style={styles.root}>
        {!loading
          ? (
            <View>
              <CardITList
                title={translate("Emails")}
              >

                {emails && emails.length > 0 && emails.map((e, index) => (
                  <View key={index.toString()}>
                    {index !== 0 && (<View style={{ height: StyleSheet.hairlineWidth, backgroundColor: "rgba(0,0,0,0.4)", marginLeft: 10 }} />)}
                    <CardITListItem
                      label={e.email}
                      onPress={() => handleEnvCheck() && props.navigation.push("EditContactsEmail",
                        {
                          ...e,
                          onDelete: () => onDeleteEmail(e.email_id),
                        })}
                    >
                      {e.primary_email_address === true
                        && <Text style={{ color: mutedColor }}>{translate("Primary")}</Text>}
                    </CardITListItem>
                  </View>
                ))}
                <View style={{ height: StyleSheet.hairlineWidth, backgroundColor: "rgba(0,0,0,0.4)", marginLeft: 10 }} />
                <CardITListItemAction>
                  <TouchableRipple
                    onPress={() => handleEnvCheck() && props.navigation.push("AddContactsEmail", {
                      panelist_id: managedUserInfo.panelist_id,
                    })}
                    style={{ paddingHorizontal: 8, paddingVertical: 10 }}
                  >
                    <Text style={{ color: theme.colors.primary }}>{translate("Add Email")}</Text>
                  </TouchableRipple>
                </CardITListItemAction>

              </CardITList>


              <CardITList
                title={translate("Mobile Number")}
              >

                {numbers && numbers.length > 0 && numbers.map((e, index) => (
                  <View key={index.toString()}>
                    {index !== 0 && (<View style={{ height: StyleSheet.hairlineWidth, backgroundColor: "rgba(0,0,0,0.4)", marginLeft: 10 }} />)}
                    <CardITListItem
                      label={`+${e.country_phone_code} ${e.number}`}
                      onPress={() => handleEnvCheck() && props.navigation.push("EditContactsNumber", {
                        ...e,
                        contact_id,
                        uic,
                        onDelete: () => onDeleteNumber(e.number_id),
                        onOpenSnackbar: openSnackbarHandler,
                      })}
                    >
                      <Text style={{ color: mutedColor }}>
                        {e.number_type_name && e.number_type_name !== "" ? translate(e.number_type_name.substr(4)) : translate("Not set")}
                        {e.primary_number === true && ` · ${translate("Primary")}`}
                      </Text>

                    </CardITListItem>
                  </View>
                ))}
                <View style={{ height: StyleSheet.hairlineWidth, backgroundColor: "rgba(0,0,0,0.4)", marginLeft: 10 }} />
                <CardITListItemAction>
                  <TouchableRipple
                    onPress={() => handleEnvCheck() && props.navigation.push("AddContactsNumber", {
                      panelist_id: managedUserInfo.panelist_id,
                      contact_id,
                      onOpenSnackbar: openSnackbarHandler,
                    })}
                    style={{ paddingHorizontal: 8, paddingVertical: 10 }}
                  >
                    <Text style={{ color: theme.colors.primary }}>{translate("Add Number")}</Text>
                  </TouchableRipple>
                </CardITListItemAction>

              </CardITList>

            </View>

          )
          : (
            <View style={{ paddingTop: 30 }}>
              <ActivityIndicator size={40} color={theme.colors.primary} />
            </View>
          )}
      </ScrollView>

      <Snackbar
        visible={snackbarVisible}
        onDismiss={() => setSnackbarVisible(false)}
        duration={5000}
        action={{
          label: "×",
          onPress: () => {
            setSnackbarVisible(false);
          },
        }}
      >
        {message}
      </Snackbar>
    </View>
  );
};
