
import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  scrollWrapper: {
    flex: 1,
    width: "100%",
  },
  scrollInner: {
    flex: 1,
    paddingHorizontal: 40,
    paddingTop: 24,
    paddingBottom: 18,
  },
  formField: {
    flexDirection: "column",
    marginBottom: 24,
  },

  formActionField: {
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingVertical: 24,
  },
  formInput: {
    width: "100%",
  },
  formMessageWrapper: {
    fontSize: 12,
  },
  button: {
    paddingVertical: 4,
  },
  titleWRapper: {
    paddingBottom: 20,
  },
  title: {
    fontWeight: "500",
  },
  subheadingRegular: {
    marginTop: 10,
  },
  subheadingSmall: {
    fontSize: 14,
    letterSpacing: 1,
    marginVertical: 8,
  },
  pickerWrapper: {
    backgroundColor: "#fff",
    padding: 10,
  },
  pickerLabel: {
    fontSize: 16,
  },
  pickerAndroidStyle: {
    borderRadius: 4,
    borderColor: "grey",
    borderWidth: 1,
  },
  pickerAndroidLabel: {
    color: "grey",
    fontSize: 14,
    letterSpacing: 1,
    marginBottom: 5,
  },
  pickerAndroidWrapper: {
    borderRadius: 5,
    borderColor: "grey",
    borderWidth: 1,
    paddingHorizontal: 4,
  },
  pickerLabelWrapper: {
    flexDirection: "row",
    alignItems: "center",
  },
  pickerActionClose: {
    paddingHorizontal: 8,
    paddingVertical: 5,
  },
  hidden: {
    display: "none",
  },
  show: {
    display: "flex",
  },
});
