/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
import React, { useState, useEffect, useContext } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableWithoutFeedback,
  ScrollView,
  LogBox,
  Platform,
} from "react-native";
import {
  TextInput,
  useTheme,
  Button,
} from "react-native-paper";
import Auth from "@aws-amplify/auth";
import { useForm, Controller } from "react-hook-form";
import FormField from "../../components/FormValidator/FormField";
import { translate } from "../../components/Localei18n/languagesFunctions";
import TextInputPassword from "../../components/TextInputPassword";
import AuthMessage from "./AuthMessage";
import AlertBox from "../../components/Alert";
import { ColorsEvidera } from "../../theme/variables";
import { AuthContext } from "./AuthContext";
import SignUpHeading from "./SignUp/SignUpHeading";
import AuthLogoHolder from "./AuthLogoHolder";

const styles = StyleSheet.create({
  input: {
    height: 37,
    width: "100%",
    backgroundColor: "rgb(239, 239, 239)",
    padding: 5,
    borderColor: "rgb(220, 218, 218)",
    borderRadius: 3,
  },
  title: {
    textAlign: "center",
    // color: "rgb(130, 130, 130)",
    color: "#4e4e4e",
    fontSize: 16,
  },
  button: {
    backgroundColor: "rgb(26, 158, 168)",
    height: 40,
    color: "white",
    borderRadius: 3,
    padding: 10,
  },
});

type Props = {
  onStateChange?: () => void,
  email: string,
  setEmail: React.Dispatch<React.SetStateAction<string>>,
}

export const CustomSignIn = (props: any) => {
  const { setAuthData, setAuthError, setAuthStage } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  let unmounted = false;
  if (Platform.OS !== "web") LogBox.ignoreLogs(["Warning: Cannot update a component from inside"]);
  const uTheme = useTheme();

  const {
    control, watch, trigger, getValues, setValue, formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const changeState = ($nextStage, data) => {
    if (!unmounted) {
      props.onStateChange($nextStage, data);
    }
  };

  const onSubmit = async () => {
    await trigger();

    if (Object.keys(errors).length === 0) {
      setError("");
      const { email, password } = getValues();
      if (!unmounted) {
        setLoading(true);

        try {
          await Auth.signIn({
            username: email.toLowerCase(),
            password,
          });

          props.goToStage(1);
        } catch (er) {
          setLoading(false);
          // console.log("SIGN IN", er);
          if (er.code === "UserNotConfirmedException") {
            // console.log("Signin error", er.code);
            setAuthData({ email: email.toLowerCase() });
            // add update usecontext here
            setAuthStage(2);
            setAuthError(er);
            changeState("signUp", "");
          }
          setError(AuthMessage(er.code, er.message));
        }
      }
    }
  };

  const handleGotoSignUp = () => {
    props.setPageTrigger("signIn");
    changeState("signUp", "");
  };

  const handleGotoForgot = () => {
    setError("");
    changeState("forgotPassword", "");
  };

  useEffect(() => {
    if (props.email !== "") {
      if (!unmounted) setValue("email", props.email);
    }

    return () => {
      unmounted = true;
    };
  }, []);

  return (
    props.authState === "signIn" && (
    <ScrollView
      style={[{ flex: 1, width: "100%" }]}
      contentContainerStyle={[{
        padding: 30,
        paddingTop: 10,
        paddingBottom: 20,
        minHeight: "100%",
      }]}
      keyboardShouldPersistTaps="never"
    >
      <View style={{ flexGrow: 1 }}>

        <View style={[{ justifyContent: "flex-end", height: "30%" }]}>

          <AuthLogoHolder style={{ justifyContent: "center" }} />
          <SignUpHeading style={[{ alignItems: "center" }, (Platform.OS !== "web" && { marginBottom: 10, marginTop: 20 })]} title={translate("Sign in to your account")} />
        </View>
        <View style={{ flexGrow: 1 }}>

          <FormField errors={errors?.email}>
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  returnKeyType="next"
                  mode="flat"
                  keyboardType="email-address"
                  autoCapitalize="none"
                  label={translate("Email")}
                  placeholder={translate("Enter your email")}
                  textContentType="emailAddress"
                  onBlur={onBlur}
                  onChangeText={(ev) => {
                    onChange(ev);
                    props.setEmail(ev);
                  }}
                  error={errors.email?.message}
                  value={value}
                />
              )}
              name="email"
              defaultValue={props.email}
              rules={{
                required: {
                  value: true,
                  message: translate("Email is mandatory."),
                },
                pattern: {
                  value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: translate("Entered value does not match email format"),
                },
              }}
            />
          </FormField>

          <FormField errors={errors?.password}>

            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInputPassword
                  error={!!errors?.password}
                  returnKeyType="next"
                  mode="flat"
                  label={translate("Password")}
                  style={{ marginTop: 6 }}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  value={value}
                  textContentType="password"
                  placeholder={translate("Enter your password")}
                  secureTextEntry
                />
              )}
              name="password"
              defaultValue=""
              rules={{
                required: {
                  value: true,
                  message: translate("You must specify a password"),
                },
              }}
            />
          </FormField>

          <View
            style={[{ marginTop: errors?.password ? 10 : -5 },
              { justifyContent: "flex-end", alignItems: "flex-end" },
            ]}
          >
            <TouchableWithoutFeedback onPress={handleGotoForgot}>
              <Text style={[{
                color: ColorsEvidera.additionalSecondary,
                fontSize: 14,
                fontWeight: "600",
                ...Platform.OS === "web" && { cursor: "pointer" },
              }]}
              >
                {translate("Forgot your password?")}
              </Text>
            </TouchableWithoutFeedback>
          </View>

          <View
            style={{
              marginTop: 30,
              marginBottom: 15,
            }}
          >
            <Button
              compact
              loading={loading}
              disabled={loading}
              mode="contained"
              contentStyle={{ paddingVertical: 8 }}
              onPress={() => onSubmit()}
              color={uTheme.colors.primary}
            >
              {translate("Sign in")}
            </Button>
          </View>

          <AlertBox style={[{ marginTop: 20 }]} type="error">{error}</AlertBox>

        </View>

      </View>
      <View style={[{
        flexDirection: "row", alignItems: "center", justifyContent: "center", marginTop: 20,
      }]}
      >
        <Text style={{ fontSize: 14 }}>
          {translate("Don't have an account?  ")}
        </Text>
        <TouchableWithoutFeedback onPress={handleGotoSignUp}>
          <Text style={{
            color: ColorsEvidera.additionalSecondary,
            fontWeight: "800",
            fontSize: 14,
            ...Platform.OS === "web" && { cursor: "pointer" },
          }}
          >
            {translate("Create account")}
          </Text>
        </TouchableWithoutFeedback>
      </View>
    </ScrollView>
    )
  );
};

export default CustomSignIn;
