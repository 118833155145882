/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import gql from "graphql-tag";
import { View } from "react-native";
import {
  Title,
  Text,
  TouchableRipple,
  useTheme,
  overlay,
} from "react-native-paper";
import color from "color";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import styles from "./profile.interest.item.styles";
import { translate } from "../../../components/Localei18n/languagesFunctions";

type Props = {
  panelist_id: string;
  question_id: number;
  qnum: number;
  question: string;
  response: "Y" | "N";
  reload: () => {}
};

type Answer = "Y" | "N";


export const ProfileInterestItem = (props: Props) => {
  const theme = useTheme();
  // const [loading, setLoading] = useState(false);
  const [ans, setAns] = useState(props.response);
  const [Loading, setLoading] = useState(false);

  const contentColor = color(theme.colors.text)
    .alpha(0.8)
    .rgb()
    .string();

  const surfaceOnDark = theme.dark
    ? (overlay(6, theme.colors.surface) as string)
    : theme.colors.surface;

  const separatorColor = color(theme.colors.text)
    .alpha(0.11)
    .rgb()
    .string();

  const answere = async (a: Answer) => {
    if (a === ans) return false;
    setLoading(false);
    setAns(a);
    try {
      const mutation = gql`
          mutation ($panelist_id: Int, $question_id: Int, $response: String) {
            addMiniSurveyResponse(panelist_id: $panelist_id, question_id: $question_id, response: $response)
          }
        `;
      const response: any = await API.graphql(
        graphqlOperation(mutation, {
          panelist_id: props.panelist_id,
          question_id: props.question_id,
          response: a,
        })
      );
    } catch (e) {
      console.log(["ans->interest->error", e, {
        panelist_id: props.panelist_id,
        question_id: props.question_id,
        response: a,
      }]);
    }
  };

  useEffect(() => {

  }, []);

  return (
    <View style={[styles.root,
      { backgroundColor: (theme.dark ? surfaceOnDark : theme.colors.surface) }]}
    >

      <View style={styles.container}>
        {/* <Title style={{ marginRight: 10, color: "grey" }}>
          {`${translate(props.qnum)}.`}
        </Title> */}
        <Title style={{ flex: 1, fontSize: 18, lineHeight: 22 }}>
          {translate(props.question)}
        </Title>
      </View>

      <View>
        <View style={[styles.actionContainer, { borderTopWidth: 1, borderTopColor: separatorColor }]}>

          <TouchableRipple onPress={() => answere("Y")} style={{ borderRadius: 15, marginRight: 15 }}>
            <View style={[styles.actionButton
              , (ans === "Y" && { backgroundColor: theme.colors.accent, borderRadius: 15 })]}
            >
              <MaterialCommunityIcons name="check" size={14} color={(ans === "Y" ? "white" : contentColor)} />
              <Text style={[styles.actionButtonText, (ans === "Y" && { color: "white" })]}>{translate("Yes")}</Text>
            </View>
          </TouchableRipple>

          <TouchableRipple onPress={() => answere("M")} style={{ borderRadius: 15, marginRight: 15 }}>
            <View style={[styles.actionButton
              , (ans === "M" && { backgroundColor: "gray", borderRadius: 15 })]}
            >
              <MaterialCommunityIcons name="history" size={14} color={(ans === "M" ? "white" : contentColor)} />
              <Text style={[styles.actionButtonText, (ans === "M" && { color: "white" })]}>{translate("Maybe Later")}</Text>
            </View>
          </TouchableRipple>

          <TouchableRipple onPress={() => answere("N")} style={{ borderRadius: 15 }}>
            <View style={[styles.actionButton
              , (ans === "N" && { backgroundColor: "gray", borderRadius: 15 })]}
            >
              <MaterialCommunityIcons name="close" size={14} color={(ans === "N" ? "white" : contentColor)} />
              <Text style={[styles.actionButtonText, (ans === "N" && { color: "white" })]}>{translate("Not Interested")}</Text>
            </View>
          </TouchableRipple>

        </View>

      </View>

    </View>
  );
};
