import { API, graphqlOperation } from "aws-amplify";
import { StackNavigationProp } from "@react-navigation/stack";
import gql from "graphql-tag";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { KeyboardAvoidingView, Platform, ScrollView, TouchableWithoutFeedback, View } from "react-native";
import { Button, Snackbar, Subheading, Switch, Text, TextInput, Title, useTheme } from "react-native-paper";
import { translate } from "../../../components/Localei18n/languagesFunctions";
import { UserInfoContext } from "../../../context/userInfo";
import { ProfileParamlist } from "../types"; // eslint-disable-line no-unused-vars
import { styles } from "./forms.style";


type Props = {
  navigation?: StackNavigationProp<ProfileParamlist>;
};

const AddContactsEmail = (props: Props) => {
  const theme = useTheme();
  const {
    control, handleSubmit, getValues, register, trigger, formState: { errors },
  } = useForm({ mode: "onChange", reValidateMode: "onChange" });

  const [Submitting, setSubmitting] = useState(false);
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [inputData] = useState(props.route.params); // eslint-disable-line react/prop-types
  const { userInfo: { uic } } = useContext(UserInfoContext);


  const onSubmit = async (data: object) => {
    setSubmitting(true);
    const mutation = gql`
      mutation addEmail($formValue: FormContactsEmailInput!, $panelist_id: Int!, $status: Int,  $uic: String){
        insertIgnoreFormsContacts(panelist_id: $panelist_id)
        insertIgnoreFormsContactsEmails(panelist_id: $panelist_id)
        updateVerificationStatus(status: $status, panelist_id: $panelist_id, uic: $uic)
        addFormEmail(formValue: $formValue)
      }
    `;

    try {
      const response = await API.graphql(graphqlOperation(mutation, {
        formValue: { ...data, domain: (data.email).substr(data.email.indexOf("@") + 1, data.email.length), primary_email_address: data.primary_email_address ? 1 : 0 },
        panelist_id: inputData.panelist_id,
        status: 2,
        uic,
      }));

      if (response.data.addFormEmail === true) {
        setMessage(translate("Email added!"));
        setSnackbarVisible(true);
        setTimeout(() => {
          props.navigation.goBack();
        },
        1500);
      } else {
        setMessage(translate("Failed to update email."));
        setSnackbarVisible(true);
      }
    } catch (err) {
      setMessage(translate("Failed to update email."));
      setSnackbarVisible(true);
      setSubmitting(false);
      throw err;
      // console.log(["failed to update contacts", err, data]);
    }
  };

  const savedEmailTrackingHandler = () => {
    // Analytics.logEvent("EmailUpdated");

    if (Object.keys(errors).length === 0) {
      const data = getValues();
      onSubmit(data);
    }
  };

  useEffect(() => {
    register("panelist_id", { value: inputData.panelist_id });
  }, [errors]);

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={[
        styles.root,
        { backgroundColor: theme.colors.background },
      ]}
    >
      <ScrollView style={styles.scrollWrapper}>
        <View style={[styles.scrollInnerResponsive, { flex: 1 }]}>
          <View style={styles.titleWRapper}>
            <Title style={styles.title}>{translate("New Email")}</Title>
            <Subheading style={styles.subheadingRegular}>
              {translate("New email is subject for verification.")}
            </Subheading>
          </View>

          <View style={styles.formField}>
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  returnKeyType="next"
                  mode="outlined"
                  style={styles.formInput}
                  onBlur={onBlur}
                  onChangeText={(ev) => {
                    onChange(ev);
                    // handleSubmit(onSubmit);
                  }}
                  value={value}
                  label={translate("Email Address")}
                />
              )}
              name="email"
              defaultValue=""
              rules={{
                required: { value: true, message: translate("Please enter your email address") },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: translate("Invalid email address"),
                },
              }}
            />

            {errors.email?.message?.length > 0
                && (<Text style={{ color: "red", marginTop: 4 }}>{errors.email?.message}</Text>)}

          </View>

          <View style={styles.formField}>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <Controller
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>

                    <Switch color={theme.colors.primary} value={value} onValueChange={() => onChange(!value)} />

                    <TouchableWithoutFeedback onPress={() => onChange(!value)}>

                      <Text style={{ marginLeft: 12, fontSize: 17, fontWeight: "500" }}>{translate("Set as Primary")}</Text>

                    </TouchableWithoutFeedback>
                  </>
                )}
                name="primary_email_address"
                defaultValue={false}
              />

            </View>
          </View>

          <View style={styles.formActionField}>
            <Button loading={Submitting} disabled={Submitting} onPress={savedEmailTrackingHandler} contentStyle={[styles.button]} dark mode="contained">
              {translate("Save")}
            </Button>
          </View>

        </View>
      </ScrollView>
      <Snackbar
        visible={snackbarVisible}
        onDismiss={() => setSnackbarVisible(false)}
        duration={5000}
        action={{
          label: "×",
          onPress: () => {
            setSnackbarVisible(false);
          },
        }}
      >
        {message}
      </Snackbar>
    </KeyboardAvoidingView>
  );
};

export default AddContactsEmail;
