import { StyleSheet } from "react-native";
import { colors } from "../../theme/variables";


export const deepSquidInk = colors.primaryBlueColor;
export const linkUnderlayColor = "#FFF";
export const errorIconColor = "#30d0fe";

const HCPPortalTheme = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    paddingTop: 20,
    width: "100%",
    backgroundColor: "#FFF",
  },
  section: {
    flex: 1,
    width: "100%",
    padding: 30,
  },
  sectionHeader: {
    width: "100%",
    marginBottom: 32,
    paddingBottom: 18,
  },
  sectionHeaderText: {
    color: deepSquidInk,
    fontSize: 20,
  },
  sectionFooter: {
    width: "100%",
    padding: 10,
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 15,
    marginBottom: 20,
  },
  sectionFooterLink: {
    fontSize: 14,
    color: colors.primaryBlueColor,
    alignItems: "baseline",
    textAlign: "center",
  },
  navBar: {
    marginTop: 35,
    padding: 15,
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  navButton: {
    marginLeft: 12,
    borderRadius: 4,
  },
  cell: {
    flex: 1,
    width: "50%",
  },
  errorRow: {
    flexDirection: "row",
  },
  errorRowText: {
    marginTop: -10,
    marginBottom: 10,
    marginLeft: 10,
    color: "red",
  },
  photo: {
    width: "100%",
  },
  album: {
    width: "100%",
  },
  button: {
    backgroundColor: colors.primaryBlueColor,
    alignItems: "center",
    padding: 16,
  },
  buttonDisabled: {
    backgroundColor: colors.primaryBlueGreyColor,
    alignItems: "center",
    padding: 16,
  },
  buttonText: {
    color: "#fff",
    fontSize: 14,
  },
  buttonTextDisabled: {
    color: colors.primaryGreyColor,
    fontSize: 14,
  },
  formField: {
    marginBottom: 22,
  },
  input: {
    padding: 16,
    borderWidth: 1,
    borderRadius: 3,
    borderColor: "#C4C4C4",
  },
  inputLabel: {
    marginBottom: 8,
  },
  phoneContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  phoneInput: {
    flex: 1,
    padding: 10,
    borderWidth: 1,
    borderRadius: 3,
    borderColor: "#C4C4C4",
  },
  picker: {
    height: 44,
    width: 100,
  },
  pickerItem: {
    height: 44,
    width: 100,
  },
});

export { HCPPortalTheme };
