import React from "react";
import { StyleSheet, View, Image } from "react-native";
import {
  Surface,
  Title,
  Text,
  Avatar,
  TouchableRipple,
  useTheme,
} from "react-native-paper";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import Color from "color";
import { I18n } from "@aws-amplify/core";

import Moment from "../../helpers/moment";
import { ColorsEvidera } from "../../theme/variables";
import { translate } from "../../components/Localei18n/languagesFunctions";


type Props = {
  type: "activity";
  valid_from: string; // eslint-disable-line camelcase
  valid_until: string; // eslint-disable-line camelcase
  status_code?: number;
  disease: object; // eslint-disable-line camelcase
  status_description: string;
  link: string;
  onPress: () => void;
};

const styles = StyleSheet.create({
  root: {
  },
  container: {
    flexDirection: "row",
    paddingTop: 15,
    paddingRight: 15,
    paddingBottom: 15,
    borderRadius: 3,

    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 0.9,
  },
  leftColumn: {
    width: 60,
    alignItems: "center",
  },
  rightColumn: {
    flex: 1,
  },
  topRow: {
    flexDirection: "column",
    alignItems: "baseline",
  },
  handle: {
    marginRight: 3,
  },
  dot: {
    fontSize: 3,
  },
  image: {
    borderWidth: StyleSheet.hairlineWidth,
    marginTop: 10,
    borderRadius: 20,
    width: "100%",
    height: 150,
  },
  title: {
    lineHeight: 16,
    fontSize: 14,
  },
  iconContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  iconDescription: {
    marginLeft: 2,
    lineHeight: 12,
  },
  chipNew: {
    color: "white",
    paddingVertical: 1,
    paddingHorizontal: 4,
    borderRadius: 9,
    backgroundColor: "#68bd47",
    fontSize: 13,
  },
  chipComplete: {
    color: "white",
    paddingVertical: 1,
    paddingHorizontal: 4,
    borderRadius: 9,
    backgroundColor: "#8e57a3",
    fontSize: 13,
  },
  textContent: {
    fontSize: 12,
  },
});

export const ActivityItem = (props: Props) => {
  const theme = useTheme();

  const mutedContentColor = Color(theme.colors.text)
    .alpha(0.7)
    .rgb()
    .string();

  return (
    <TouchableRipple onPress={props.onPress} style={styles.root}>
      <Surface style={styles.container}>
        <View style={styles.leftColumn}>
          <Image
            width={32}
            height={32}
            resizeMode="contain"
            source={require("../../assets/notifications/2.png")}
            style={{ backgroundColor: "transparent", width: 32, height: 32 }}
          />
        </View>
        <View style={styles.rightColumn}>
          <View style={styles.topRow}>
            <Title style={[styles.title, { flex: 1 }]}>
              <Text>{"LiveTracker "}</Text>
            </Title>
            <Text style={[{ fontWeight: "bold" }, props.status_description !== "Not profiled" ? { color: ColorsEvidera.danger } : { color: ColorsEvidera.secondary }]}>
              {props.status_description !== "Not profiled" ? translate(props.status_description) : translate("Initial Profiling")}
            </Text>
          </View>
          <View style={{ justifyContent: "flex-start", alignItems: "baseline" }}>
            {(props.disease?.disease_code && props.disease?.disease_code !== "") && (
            <Text style={[styles.textContent, { color: mutedContentColor }]}>
              {`Oncology: ${props.disease?.disease_code}`}
            </Text>
            )}
            {props.valid_until && (
              <Text style={[styles.textContent, { color: mutedContentColor }]}>
                {`${translate("Valid until")} `}
                <Moment format="MMM D, YYYY" date={props.valid_until} />
              </Text>
            )}
          </View>

        </View>
        <View
          style={{ flexDirection: "row", alignItems: "center" }}
        >
          <TouchableRipple
            onPress={props.onPress}
          >
            <MaterialCommunityIcons name="play" color={theme.colors.text} size={36} />
          </TouchableRipple>
        </View>
      </Surface>
    </TouchableRipple>
  );
};
