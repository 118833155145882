import React, { useContext, useState, useEffect } from "react";
import * as Notifications from "expo-notifications";
import { createDrawerNavigator } from "@react-navigation/drawer";

import { useTheme, ActivityIndicator, Portal } from "react-native-paper";

import gql from "graphql-tag";
import { API, graphqlOperation } from "aws-amplify";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { View, Vibration, StatusBar } from "react-native";

import { StackNavigator } from "./stack.navigator";
import { DrawerContent } from "./drawerContent";
import UserContext from "../context/UserContext";
import { UserInfoContext } from "../context/userInfo";
// import registerForPushNotifications from "../helpers/registerForPushNotifications";
import useAmplifyAuth from "../helpers/useAmplifyAuth";
import { getTranslations } from "../components/Localei18n/languagesFunctions";
import WelcomeNew from "../screens/welcome/welcome.new";
import PushNotificationService from "../helpers/push.notification.service";
import UserService from "../helpers/user.service";

const Drawer = createDrawerNavigator();


type Language = {
  language: string,
  language_name: string, // eslint-disable-line camelcase
  preferredLanguage?: string,
}

export const RootNavigator = (propsRoot) => {
  const theme = useTheme();
  const userContext: any = useContext(UserContext);
  const [unmounted, setUnmounted] = useState(false);
  const [loading, setLoading] = useState(true);
  const { handleSignout } = useAmplifyAuth();

  const {
    testUserCheck,
    getUserInfo,
    applyLanguagePreference,
    loginDevice,
  } = UserService();

  const {
    userInfo,
    environment,
    language,
    setUserInfo,
    setManagedUserInfo,
    setEnvironment,
    setLanguage,
  } = useContext(UserInfoContext);
  // const onSignout = () => {
  //   handleSignout().then((e) => {
  //     if (e === true) props.navigation.navigate("Authenticate");
  //   });
  // };

  const handleSetUserInfo = async () => {
    const mutation = gql`mutation updateLastLogin($panelist_id: Int!){
          updateLastLogin(panelist_id: $panelist_id)
        }
    `;
    // const response = await API.graphql(graphqlOperation(mutation, {

    try {
      const response = await API.graphql(graphqlOperation(mutation, {
        panelist_id: userInfo.panelist_id, // eslint-disable-line camelcase
      }));

      setUserInfo({ ...userInfo, hcpportal_last_login: response.data.updateLastLogin });
    } catch (err) {
      console.log(["Failed to update last login", err]);
    }
  };

  StatusBar.setBarStyle(
    !theme.dark ? "dark-content" : "light-content",
    true
  );

  const lastUserTested = async () => {
    const response = await AsyncStorage.getItem("lastUserTested");

    return response;
  };

  const applyPref = async (response) => {
    const appliedLanguage = await applyLanguagePreference(response);

    if (appliedLanguage) {
      await getTranslations(appliedLanguage);
      setLanguage(appliedLanguage);
      return true;
    }
    await getTranslations(language);
    setLanguage(language);
    return null;
  };

  const fetchLastUserTested = async (email) => {
    try {
      const mUser: any = await getUserInfo(email, "first_name last_name email amazon_market paypal_id panelist_id uic default_language_code default_language_name contact_status_id");
      if (mUser !== null) {
        setManagedUserInfo(mUser);
        if (mUser.default_language_code) applyPref(mUser);
        else {
          await getTranslations(language);
          setLanguage(language);
        }
      }
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  };


  const onInit = async () => {
    setLoading(true);

    if (userContext) {
      const testUser = await testUserCheck(userContext.attributes.email);

      if (testUser && testUser?.id) {
        setEnvironment("MAINTENANCE");
        window.environment = "MAINTENANCE";
        setUserInfo(testUser);

        const xLastUserTested = await lastUserTested();

        if (xLastUserTested !== null) {
          await fetchLastUserTested(xLastUserTested);
        }
        setLoading(false);
        return false;
      }


      // if current user is not a tester
      const responseUser = await getUserInfo(userContext.attributes.email, "first_name last_name email paypal_id amazon_market panelist_id uic default_language_code default_language_name paypal_active hcpportal_last_login contact_status_id country_code");

      await applyPref(responseUser);

      window.currentLanguage = "en";
      window.environment = "PRODUCTION";

      if (responseUser) {
        setUserInfo(responseUser);
        setManagedUserInfo(responseUser);
      }
    }

    setLoading(false);
    return true;
  };

  // eslint-disable-next-line no-underscore-dangle
  const _handleNotification = () => {
    Vibration.vibrate(500, true);
    // this.setState({ notification });
  };

  useEffect(() => {
    if (propsRoot.userStatus === true && propsRoot.initialRoute !== "RootNavigator") {
      propsRoot.navigation.navigate(propsRoot.initialRoute);
    }
    if (propsRoot.userStatus) onInit();

    try {
      // Todo: https://docs.expo.io/push-notifications/overview/
      Notifications.addNotificationReceivedListener(_handleNotification);
    } catch (e) {
      console.log(e);
    }

    loginDevice();
    return function cleanup() {
      setUnmounted(true);
      // if (notificationSubscription) Notifications.(notificationSubscription);
    };
  }, [environment, propsRoot.user, propsRoot.initialRoute]);

  return (
    <>
      <Drawer.Navigator
        screenOptions={{
          header: () => {},
        }}
        drawerContent={(props) => (
          <DrawerContent
            {...props}
            loading={loading}
            environment={environment}
            userInfo={userInfo}
            handleSignout={handleSignout}
          />
        )}
      >
        <Drawer.Screen name="Home" component={StackNavigator} />
      </Drawer.Navigator>
      <Portal>

        {loading && (
        <View style={{ flex: 1, backgroundColor: theme.colors.background, justifyContent: "center" }}>
          <ActivityIndicator size={40} />
        </View>
        )}

        {(userInfo.hcpportal_last_login === null) && (
        <WelcomeNew handleSetUserInfo={handleSetUserInfo} />
        )}

        {/* {(userInfo.hcpportal_last_login !== null) && (
                <MiniSurveyComponent user={managedUserInfo} />
              )} */}
      </Portal>
    </>

  );
};
