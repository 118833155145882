import { Dimensions, Platform } from "react-native";

const device = Dimensions.get("window");

let scale;

if (Platform.OS === "android") {
  if (device.width <= 414) {
    // Android smartphones
    scale = device.width / 414;
  } else {
    // Android tablets
    scale = 1;
  }
} else if (Platform.OS === "ios") {
  switch (device.width) {
    // iPhone4/4S and iPhone5/5S
    case 320:
      scale = 0.77;
      break;
    // iPhone6/6S
    case 375:
      scale = 0.902;
      break;
    // iPhone6plus/6Splus
    case 414:
      scale = 1;
      break;
    // iPad
    default:
      scale = 1;
  }
} else {
  scale = 1;
}

export const scaleDisplay = (size) => {
  let scale = 1;
  switch (device.width) {
    // iPhone4/4S and iPhone5/5S
    case 320:
      scale = 0.65;
      break;
    // iPhone6/6S
    case 375:
      scale = 0.852;
      break;
    // iPhone6plus/6Splus/Android smartphones
    case 414:
      scale = 1;
      break;
    // iPad
    default:
      scale = 1;
  }
  return Math.ceil(size * scale);
};

export default function applyScale(size) {
  return Math.ceil(size * scale);
}
