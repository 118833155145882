import React, { useContext } from "react";
import { Title, Text } from "react-native-paper";
import { View } from "react-native";
import { I18n } from "@aws-amplify/core";

import { UserInfoContext } from "../../context/userInfo";

export const PrivacyPolicy = () => {
  const { language } = useContext(UserInfoContext);

  return (


    <View style={{ padding: 20 }}>
      <Title>
        {translate("Privacy Policy")}
      </Title>
      <Text style={{ marginTop: 10 }}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus blandit congue nulla gravida consectetur.
        Fusce molestie odio mauris, eget ornare nulla dapibus id.
        Quisque ut ex magna. Praesent posuere nibh in vulputate congue. Aliquam tincidunt gravida iaculis.
        Fusce nec est egestas, porttitor lacus eget, fringilla turpis. Etiam auctor metus eu enim ullamcorper pretium.
        Quisque pulvinar elit tortor, in interdum nibh viverra eget.
        {"\n\n"}
        Donec eget ligula justo. Nullam lobortis diam justo, vel dignissim lacus vehicula ac.
        Nullam vitae neque sed purus pretium commodo et aliquam sapien.
        Vivamus neque dolor, porta quis dolor vel, pulvinar suscipit nulla.
        Curabitur gravida ante ex, id ultricies quam faucibus at. Integer a nibh vel odio pretium pellentesque in quis nisi.
        Etiam eu est libero. In venenatis dignissim lorem vel elementum. Fusce vel dolor leo.
        Suspendisse bibendum, orci ut mollis sollicitudin, diam est ultrices lacus, dictum fringilla augue nibh a risus.
        In luctus dui felis, ac consectetur magna elementum a.
        {"\n\n"}
        Fusce finibus nulla sit amet mauris blandit venenatis. Ut ut orci justo.
        Praesent metus ante, facilisis a magna sed, dapibus molestie elit. Sed ac gravida sapien.
        Vestibulum ac libero malesuada, auctor quam in, facilisis odio. Nam eget luctus mauris.
        Praesent tempus bibendum enim, interdum euismod tellus bibendum ac.
        Vestibulum lacus arcu, pulvinar ac metus id, ultrices ornare ex. Ut nec faucibus eros.
        Vestibulum vitae iaculis justo. Duis a enim in risus placerat bibendum.
        Integer aliquam vestibulum nulla vestibulum consequat.
        {"\n\n"}
        Aliquam ut risus ac tortor imperdiet porta quis quis eros. Suspendisse tempor consectetur urna quis malesuada.
        Etiam vel libero vel purus ultrices porttitor vel nec nunc. Mauris tempus ligula ac pulvinar varius.
        Nunc rutrum nibh nisl, at vehicula neque facilisis lacinia. Mauris sed odio vitae urna posuere aliquam eget rutrum neque.
        Morbi vestibulum, lorem eget aliquet molestie, lacus odio blandit lacus, eget tristique felis eros in tellus.
        Fusce porttitor molestie nisl quis lacinia. Maecenas quis est ultrices, vulputate ipsum sed, malesuada justo.
        {"\n\n"}
        Etiam eleifend ornare eros quis iaculis. Mauris eget dignissim tortor.
        Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.
        Pellentesque interdum sed sem ac egestas. Nulla non feugiat risus. Vivamus lectus est, mollis eu faucibus sit amet, dictum at nibh.
        Nullam eu nibh ac leo commodo cursus. Donec velit nisi, blandit vitae mattis eu, interdum ut dui.
        Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.
        Sed id felis velit. Nulla pretium gravida arcu, eget iaculis orci aliquet vel.
        Ut quis pretium diam. Maecenas posuere dolor varius rhoncus porta.
      </Text>
    </View>
  );
};
