import Color from "color";
import React, { useEffect } from "react";
import { StyleSheet, View, Image, Alert, Platform } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { Title, useTheme, Text, TouchableRipple, Button } from "react-native-paper";
import { I18n } from "@aws-amplify/core";
import { SafeAreaView } from "react-native-safe-area-context";
import AnimateFade from "../../helpers/animation.fade";
import { translate } from "../../components/Localei18n/languagesFunctions";

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  rootInner: {
    flex: 1,
    padding: 30,
  },
  illustrations: {
    paddingTop: 30,
    paddingBottom: 20,
  },
  content: {
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontSize: 20, lineHeight: 28, textAlign: "center", fontWeight: "700",
  },
  subHeader: {
    fontSize: 16, lineHeight: 22, textAlign: "center", fontWeight: "400",
  },
  listHolder: {
    paddingBottom: 30,
  },
  listItem: {
    marginBottom: 3,
    shadowOffset: { height: 0, width: 0 },
    shadowColor: "rgb(121,121,121)",
    shadowOpacity: 0.06,
    shadowRadius: 1,
    height: 60,
    paddingVertical: 10,
    paddingHorizontal: 20,
    justifyContent: "center",
    borderRadius: 6,
  },
  webWidth: {
    maxWidth: 600,
  },
});

const list = [
  "Receive notification as soon as a new study is available for you",
  "Review new and pending participations",
  "Start or resume your participations",
  "Review your personal data and submit changes",
  "Review your past completions and follow your payments",
  "Get support through live chat and FAQ",
];
type Props = {
  handleSetUserInfo: () => void;
}
const WelcomeNew = (props: Props) => {
  const theme = useTheme();
  const rootBg = Color(theme.colors.placeholder)
    .alpha(0.04)
    .rgb()
    .string();


  useEffect(() => {

  }, []);

  return (
    <SafeAreaView style={[styles.root, { backgroundColor: theme.colors.background }]}>
      <ScrollView style={[styles.rootInner, { backgroundColor: rootBg }]}>
        <View style={styles.illustrations}>
          <Image
            source={require("../../assets/welcome-new.png")}
            resizeMode="contain"
            style={{
              width: "100%",
              height: 162,
            }}
          />
        </View>
        <View style={styles.content}>
          <AnimateFade fadeInDuration={500}>

            <Title style={[styles.title, { marginBottom: 8 }]}>
              {translate("Thank you for joining Medimix HCP Portal!")}
            </Title>

          </AnimateFade>
          <AnimateFade delay={800} fadeInDuration={500}>
            <View>
              <Text style={[styles.subHeader, { marginBottom: 15 }]}>
                {translate("With this application you will be able to:")}
              </Text>

            </View>
          </AnimateFade>
        </View>

        <View style={[{ flexDirection: "row" }, Platform.OS === "web" && { justifyContent: "center" }]}>
          <View style={[styles.listHolder, Platform.OS === "web" && { maxWidth: 600, flex: 1 }]}>
            {list.map((item, index) => (
              <AnimateFade
                key={index.toString()}
                delay={1300 + (150 * index)}
                fadeInDuration={300}
              >
                <TouchableRipple style={[styles.listItem, { backgroundColor: theme.colors.surface }]}>
                  <Text>{translate(item)}</Text>
                </TouchableRipple>
              </AnimateFade>
            ))}
          </View>
        </View>

        <View style={[{ flexDirection: "row" }, Platform.OS === "web" && { justifyContent: "center" }]}>
          <View style={[{ paddingBottom: 10 }, Platform.OS === "web" && { maxWidth: 600, flex: 1 }]}>
            <AnimateFade delay={800} fadeInDuration={500}>
              <View>
                <Text style={{ marginBottom: 15 }}>
                  {translate(`If you are a new participant, you will receive a notification for an initial survey to assess your expertise and determine which study you might be qualified for.
                \nYou'll be then notified as soon as a new study is available for you.`)}
                </Text>

              </View>
            </AnimateFade>
          </View>
        </View>

        <View style={[{ flexDirection: "row", paddingBottom: 70 }, Platform.OS === "web" && { justifyContent: "center" }]}>

          <Button
            style={[{ paddingVertical: 8, flex: 1 }, Platform.OS === "web" && { maxWidth: 600 }]}
            mode="contained"
            onPress={() => props.handleSetUserInfo()}
          >
            {translate("Got it!")}
          </Button>

        </View>

      </ScrollView>
    </SafeAreaView>
  );
};

export default WelcomeNew;
