/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "cognito",
            "endpoint": "https://kt2jboqww7.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "hcps3docs",
            "endpoint": "https://a5xxf6pv6b.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://ktaqzr2tyref5oanxfsixtkopi.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-u3raqwx2srcdnllzc66vpkbk7e",
    "aws_cognito_identity_pool_id": "us-east-1:3709263c-aa78-4ce1-be32-67b620148470",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_pRLcMvm74",
    "aws_user_pools_web_client_id": "josrl9n9j9mp2n3lhl27i7ebb",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "hcpportal-20200124125853-hostingbucket-production",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://dpfmldaxrc2uv.cloudfront.net"
};


export default awsmobile;
