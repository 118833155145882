import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  page: {
    flex: 1,
    // alignItems: "center",
  },
  pageGray: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "#efefef",
  },
  container: {
    alignItems: "center",
    width: "100%",
  },
  header: {
    // flex: 1,
    flexDirection: "row",
    width: "100%",
  },
  header_section: {
    flex: 1,
    flexDirection: "row",
    // width: "80%",
    justifyContent: "center",
    marginHorizontal: "auto",
  },
  fluid: {
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
  },
  footerDark: {
    flex: 1,
    flexDirection: "row",
    width: "100%",
    marginTop: "2rem",
    paddingTop: "1rem",
    paddingBottom: "1rem",
    backgroundColor: "#27505e",
  },
  footerLight: {
    flex: 1,
    flexDirection: "row",
    width: "100%",
    marginTop: "2rem",
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },
  section: {
    flex: 1,
    flexDirection: "row",
    width: "80%",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  sectionCentered: {
    flex: 1,
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
  },
  text: {
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 20,
    marginRight: 20,
    fontSize: 15,
  },
  textBullet: {
    fontWeight: "700",
    fontSize: 18,
  },
  textBulletItem: {
    fontSize: 15,
    marginBottom: 20,
  },
  textWhite: {
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 20,
    marginRight: 20,
    fontSize: 15,
    color: "white",
  },
  textTitle: {
    marginTop: 20,
    marginBottom: 20,
    marginLeft: 20,
    marginRight: 20,
    fontSize: 18,
    textAlign: "center",
    paddingLeft: "15%",
    paddingRight: "15%",
  },
  textTitleWhite: {
    marginTop: 20,
    marginBottom: 20,
    marginLeft: 20,
    marginRight: 20,
    fontSize: 18,
    textAlign: "center",
    paddingLeft: "15%",
    paddingRight: "15%",
    color: "white",
  },
  textHighlight: {
    color: "#4e2183",
  },
  textTitleHighlight: {
    color: "#ffb444",
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 20,
    marginRight: 20,
    borderBottomColor: "#ffb444",
    borderBottomWidth: 1,
    paddingBottom: "8px",
    width: "fit-content",
    fontWeight: "700",
  },
  textButton: {
    color: "#ababab",
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 20,
    marginRight: 20,
  },
  textFooter: {
    fontSize: 12,
    textAlign: "center",
    width: "100%",
    color: "white",
  },
  textFooterDark: {
    fontSize: 12,
    textAlign: "center",
    width: "100%",
    color: "#828282",
  },
  textSignIn: {
    flex: 1,
    textAlign: "right",
    alignSelf: "center",
    marginRight: 20,
    fontSize: 18,
    fontWeight: "300",
  },
  image: {
    marginTop: 50,
    marginBottom: 30,
    alignSelf: "center",
  },
  brand: {
    marginTop: 50,
    marginBottom: 50,
    marginRight: 20,
    padding: 100,
    alignSelf: "center",
  },
  bg_image: {
    flex: 1,
    resizeMode: "contain",
    justifyContent: "center",
  },
  overlay: {
    flex: 1,
    width: "100%",
    height: "auto",
    backgroundImage:
      "linear-gradient(0deg, #000000ed, #0000004a)",
  },
  card: {
    width: "50%",
    minHeight: "400px",
    margin: 0,
    padding: 4,
  },
  cardFull: {
    width: "100%",
    minHeight: "300px",
    margin: 0,
    padding: 4,
  },
  cardButton: {
    width: "fit-content",
    backgroundColor: "none",
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 20,
  },
  cardButtonTitle: {
    fontSize: 13,
    color: "#ffffff",
    fontWeight: "500",
  },
  cardButtonTitleActive: {
    fontSize: 13,
    color: "#ffb444",
    fontWeight: "500",
  },
  icon: {
    textAlignVertical: "center",
  },
  hero: {
    width: "100%",
    alignContents: "center",
    // flex: 1,
    flexDirection: "column",
    paddingTop: "3rem",
    paddingBottom: "3rem",
  },
  overlap: {
    width: "100%",
    backgroundColor: "#0e51a5",
    height: "200px",
    marginBottom: "-7rem",
  },
  imageButton: {
    width: 130,
    height: 40,
    marginLeft: 35,
  },
});
