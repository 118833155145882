// import React, { createContext, useState } from "react";

// export const ReferenceDataContext = createContext(null);

// const ReferenceDataContextProvider = (props) => {
//   const [personalInfoData, setPersonalInfoData] = useState({});
//   return (
//     <ReferenceDataContext.Provider
//       value={{ personalInfoData, setPersonalInfoData }}
//     >
//       {props.children}
//     </ReferenceDataContext.Provider>
//   );
// };

// export default ReferenceDataContextProvider;

import React from "react";

type ReferenceDataType = {
  personalInfoData: any,
  stage: number,
  setPersonalInfoData: (object: any) => void,
  setStage: (stage: number) => void
};

export const ReferenceDataContext = React.createContext<ReferenceDataType>({
  personalInfoData: {},
  stage: 0,
  setPersonalInfoData: () => {},
  setStage: () => {},
});
