import { useTheme } from "@react-navigation/native";
import React from "react";
import {
  View,
  Text,
  StyleSheet,
  StyleProp, // eslint-disable-line no-unused-vars
  ViewStyle, // eslint-disable-line no-unused-vars
} from "react-native";
import color from "color";
import AnimateFade from "../../helpers/animation.fade";

type Props = {
  errors?: object | {};
  style?: StyleProp<ViewStyle> | {};
  children: any;
  bottomSpace?: boolean;
  hint?: any;
}

const styles = StyleSheet.create({
  formField: {
    // flexDirection: "row",
    marginBottom: 20,
  },
  formFieldError: {
    paddingHorizontal: 10,
    marginTop: -15,
    flexDirection: "row",
    // flexWrap: "wrap",
  },

  formFieldErrorText: {
    fontSize: 14,
    color: "red",
    flex: 1,
    lineHeight: 18,
  },

  message: {
    padding: 20,
    backgroundColor: "rgba(122,122,122,0.067)",
    borderColor: "rgba(122,122,122,0.1)",
    borderWidth: StyleSheet.hairlineWidth,
  },
  messageContent: {
    color: "rgba(122,122,122,0.9)",
  },
  messagedanger: { // danger
    backgroundColor: "#f2dede",
    borderColor: "#ecc0c0",
  },
  messageContentdanger: {
    color: "#aa4c51",
  },
  messagesuccess: { // success
    backgroundColor: "#dff0d8",
    borderColor: "#b3d0a7",
  },
  messageContentsuccess: {
    color: "#46763d",
  },
  messageinfo: { // info
    backgroundColor: "#d9edf7",
    borderColor: "#c9eaf5",
  },
  messageContentinfo: {
    color: "#457997",
  },
  messagewarning: { // warning
    backgroundColor: "#fcf8e3",
    borderColor: "#fdf4e2",
  },
  messageContentwarning: {
    color: "#967b59",
  },
});

const FormField = (props: Props) => {
  const {
    errors, style, children, bottomSpace, hint,
  } = props;
  const theme = useTheme();
  const mutedContentColor = color(theme.colors.text)
    .alpha(0.6)
    .rgb()
    .string();

  return (
    <View style={style}>
      <View style={[styles.formField]}>
        {children}
      </View>
      {errors?.message?.length > 0 && (
      <AnimateFade fadeInDuration={100}>
        <View style={styles.formFieldError}>
          <Text style={[styles.formFieldErrorText, bottomSpace && { marginBottom: 10 }]}>
            {errors?.message}
          </Text>
        </View>
      </AnimateFade>
      )}

      {(hint && !errors?.message) && (
      <AnimateFade fadeInDuration={100}>
        <View style={styles.formFieldError}>
          <Text style={[{ color: mutedContentColor }, bottomSpace && { marginBottom: 10 }]}>
            {hint}
          </Text>
        </View>
      </AnimateFade>
      )}
    </View>
  );
};
type PropsMessage = {
  style?: StyleProp<ViewStyle>;
  children?: any;
  type?: "default" | "success" | "info" | "warning" | "danger"
}

export const Message = (props: PropsMessage) => {
  const {
    type, style, children,
  } = props;

  return (
    <>
      {!!children && (
      <View style={[styles.message, (type !== "default" && styles[`message${type}`]), style]}>
        <Text style={[styles.messageContent, (type !== "default" && styles[`messageContent${type}`])]}>
          {children}
        </Text>
      </View>
      )}
    </>
  );
};

export default FormField;
