/* eslint-disable camelcase */
import React, { useState } from "react";
import { View, Image } from "react-native";
import {
  Title,
  Text,
  Avatar,
  useTheme,
  overlay,
  Surface,
  TouchableRipple,
} from "react-native-paper";
import Color from "color";
import { API, graphqlOperation } from "aws-amplify";
import gql from "graphql-tag";
import styles from "./notification.item.styles";
import TimeAgo from "../../helpers/timeago/TimeAgo";
import Moment from "../../helpers/moment";


type Props = {
  type: "notifications";
  id: number;
  panelist_id: number;
  sound?: string;
  title: string;
  body: string;
  data?: string;
  created_at: string;
  opened_at: string;
  send_at: string;
  link: string;
  onPress?: () => void;
};

export const NotificationItem = (props: Props) => {
  const theme = useTheme();
  const [read, setRead] = useState(props?.opened_at);

  const markAsRead = async (id) => {
    try {
      const qry = gql`
          mutation ($id: Int!){
            markAsReadNotification(id: $id)
          }`;
      const response: any = await API.graphql(graphqlOperation(qry, {
        id,
      }));

      return (response.data?.markAsReadNotification === true);
    } catch (e) {
      return false;
    }
  };

  const handleOnPress = async () => {
    markAsRead(props?.id).then((e) => {
      if (e) setRead(new Date().toString());
    });

    props.onPress();
  };

  const mutedContentColor = Color(theme.colors.text)
    .alpha(0.4)
    .rgb()
    .string();

  const surfaceOnDark = theme.dark
    ? (overlay(6, theme.colors.surface) as string)
    : theme.colors.surface;

  const separatorColor = Color(theme.colors.text)
    .alpha(0.11)
    .rgb()
    .string();

  const mutedSurfaceColor = Color(theme.colors.surface)
    .alpha(0.75)
    .rgb()
    .string();

  return (
    <TouchableRipple onPress={handleOnPress}>
      <View style={[styles.root, { shadowColor: separatorColor }]}>

        <Surface style={[styles.container, read && ({ backgroundColor: mutedSurfaceColor })]}>
          <View style={styles.leftColumn}>
            <Image
              width={32}
              height={32}
              resizeMode="contain"
              source={require("../../assets/notifications/1.png")}
              style={{ backgroundColor: "transparent", width: 32, height: 32 }}
            />
          </View>
          <View style={styles.rightColumn}>
            <View style={styles.titleRow}>
              <Title numberOfLines={1} style={styles.title}>{props.title}</Title>
              <Text style={styles.timeAgo}><Moment format="MMM D, YYYY" date={props.created_at} /></Text>
            </View>
            <View style={styles.contentRow}>
              <Text numberOfLines={2} style={{ fontSize: 12, color: mutedContentColor }}>
                {props.body}
              </Text>
            </View>
          </View>
          {!read
            && (<View style={styles.indicator} />)}

        </Surface>

      </View>
    </TouchableRipple>
  );
};
