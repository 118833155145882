import React from "react";
import { View, StyleSheet } from "react-native";
import { Text, useTheme } from "react-native-paper";

type Props = { title?: String, style?: any, }
const styles = StyleSheet.create({
  stateWrapper: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  title: { fontSize: 16, fontWeight: "700" },
});
const ZeroRequestState = (props:Props) => {
  const theme = useTheme();
  return (
    <View style={[styles.stateWrapper, props.style]}>
      {props.title
      && <Text style={[styles.title, { color: theme.colors.disabled }]}>{props.title}</Text>}
    </View>
  );
};
export default ZeroRequestState;
