import React from "react";
import { Platform, StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { Subheading, Title } from "react-native-paper";

type Props = {
  title: string;
  subheading?: string;
  style?: StyleProp<ViewStyle>;
}

const styles = StyleSheet.create({
  root: { },
  title: {
    fontWeight: "700",
    marginBottom: 0,
  },
  subheading: {},
});
const stylesWeb = StyleSheet.create({
  root: { marginVertical: 20 },
  title: {
    fontWeight: "700",
    fontSize: 18,
    marginBottom: 0,
  },
  subheading: {
    fontSize: 12,
    lineHeight: 16,
  },
});
const SignUpHeading = (props: Props) => {
  const useStyle = Platform.OS === "web" ? stylesWeb : styles;

  return (
    <View style={[useStyle.root, props.style]}>
      <Title style={[useStyle.title]}>
        {props.title}
      </Title>
      {props.subheading && <Subheading style={[useStyle.subheading]}>{props.subheading}</Subheading>}
    </View>

  );
};

export default SignUpHeading;
