import React from "react";
import { StyleProp, StyleSheet, TextProps, View, ViewProps, Dimensions } from "react-native";
import { Text, useTheme } from "react-native-paper";


const styles = StyleSheet.create({
  root: {
    flex: 1,
    borderRadius: 10,
  },
  title: {
    paddingTop: 24,
    paddingHorizontal: 18,
    paddingBottom: 8,
  },
  titleText: {
    fontSize: 22,
    fontWeight: "400",
    letterSpacing: 0,
    lineHeight: 28,
    color: "#202124",
  },
  row: {
    paddingRight: 18,
    marginLeft: 18,
    paddingLeft: 2,
    paddingTop: 15,
    paddingBottom: 16,
    flexDirection: "row",
  },
  label: {
    flexBasis: 156,
    justifyContent: "center",
    marginRight: 24,
    paddingTop: 4,
  },
  labelText: {
    padding: 0,
    letterSpacing: 0.8,
    fontSize: 11,
    fontWeight: "500",
    lineHeight: 16,
    textTransform: "uppercase",
    color: "#5f6368",
    margin: 0,
    width: "100%",
  },
  value: {
    justifyContent: "center",
    paddingTop: 4,
    flexGrow: 1,
    flexShrink: 1,
  },
  valueText: {
    letterSpacing: 0.1,
    fontSize: 16,
    fontWeight: "400",
    lineHeight: 24,
    color: "#202124",
    margin: 0,
    padding: 0,
    width: "100%",
  },
  separator: {
    borderTopWidth: StyleSheet.hairlineWidth,
    borderTopColor: "#dadce0",
  },
});

type Props = {
  children: any,
  style?: any,
  title?: string,
}
type PropsRow = {
  children: any,
  style?: any,
}

type PropsData = {
  children: any,
  style?: StyleProp<TextProps>,
}

type PropsImageData = {
  children: any,
  style?: StyleProp<any>,
}
export const TableDisplay = (props: Props) => {
  const { children, style, title } = props;
  const theme = useTheme();
  const { width } = Dimensions.get("window");

  return (
    <View style={[styles.root, { backgroundColor: theme.colors.surface }, style, width < 600 && { marginHorizontal: 10 }]}>
      {title?.length > 0
        && (
        <View style={styles.title}>
          <Text style={styles.titleText}>
            {title}
          </Text>
        </View>
        )}
      <>
        {!(children?.length > 0) && children}

        {children?.length > 1 && (
        children.map((e, i) => (e ? React.cloneElement(e, { key: i.toString(), style: i !== 0 && styles.separator }) : null))
      )}
      </>
    </View>
  );
};
export const TableRow = (props: PropsRow) => {
  const { children, style } = props;
  const { width } = Dimensions.get("window");

  return (
    <View style={[styles.row, style, width < 600 && { flexDirection: "column" }]}>
      {children}
    </View>
  );
};

export const CellLabel = (props: PropsData) => {
  const { children, style } = props;

  return (
    <View style={[styles.label, style]}>
      <Text style={styles.labelText}>
        {children}
      </Text>
    </View>
  );
};

export const CellValue = (props: PropsData) => {
  const { children, style } = props;

  return (
    <View style={[styles.value, style]}>
      <Text style={styles.valueText}>
        {children}
      </Text>
    </View>
  );
};

export const CellThumb = (props: PropsImageData) => {
  const { children, style } = props;

  return (
    <View style={[styles.value, style]}>
      <View>
        {children}
      </View>
    </View>
  );
};

export const CellView = (props: PropsImageData) => {
  const { children, style } = props;

  return (
    <View style={[styles.value, style]}>
      <View>
        {children}
      </View>
    </View>
  );
};
