import React, { useState, useEffect, useContext } from "react";
import {
  View,
  Text,
  Linking,
  Image,
  Dimensions,
  ImageBackground,
  TouchableOpacity,
  Platform,
  ScrollView,
} from "react-native";
import { I18n } from "@aws-amplify/core";
import { Card, Button } from "react-native-elements";
import { StackNavigationProp } from "@react-navigation/stack";
import { Divider, useTheme } from "react-native-paper";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import { styles } from "./landing.style";
import applyScale from "../../helpers/image/applyScale";
import { StackNavigatorParamlist } from "../../navigation/types";
import { translate } from "../../components/Localei18n/languagesFunctions";
import LanguageSessionSelector from "../../components/languageSessionSelector";
import AuthContextProvider, { AuthContext } from "../Auth/AuthContext";

type Props = {
  user: any;
  userStatus: boolean;
  initialRoute?: string;
  navigation?: StackNavigationProp;
  signOutInactiveFromWeb: boolean;
  setSignOutInactiveFromWeb: any;
};

const LandingMain = (props: Props) => {
  const { AuthLanguage } = useContext(AuthContext);
  let unmounted = false;
  const [imageWidth, setImageWidth] = useState(220);
  const [imageHeight, setImageHeight] = useState(100);
  const [cardStyle, setCardStyle] = useState(styles.card);
  const [buttonStyle, setButtonStyle] = useState(styles.cardButtonTitle);
  const [buttonStyle2, setButtonStyle2] = useState(styles.cardButtonTitle);

  const { colors } = useTheme();

  useEffect(() => {
    if (props.signOutInactiveFromWeb == true) {
      props.setSignOutInactiveFromWeb(false);
      props.navigation.push("Authenticate");
      console.log('redirect landing to home');
    }
  }, [props.signOutInactiveFromWeb]);

  const onResize = () => {
    if (Dimensions.get("window").width < 850) {
      if (!unmounted) setCardStyle(styles.cardFull);
    } else if (!unmounted) setCardStyle(styles.card);
  };

  const buttonActive = (state, button) => {
    if (button === 1) {
      if (state) {
        setButtonStyle(styles.cardButtonTitleActive);
      } else {
        setButtonStyle(styles.cardButtonTitle);
      }
    }
    if (button === 2) {
      if (state) {
        setButtonStyle2(styles.cardButtonTitleActive);
      } else {
        setButtonStyle2(styles.cardButtonTitle);
      }
    }
  };

  const openUrl = (url) => {
    if (Platform.OS === "web") {
      (url?.length > 0) && window.open(url, "_blank");
    } else {
      Linking.openURL(url);
    }
  };

  useEffect(() => {
    setImageWidth(applyScale(220));
    setImageHeight(applyScale(100));
    Dimensions.addEventListener("change", onResize);
    onResize();
    return () => {
      unmounted = true;
      Dimensions.removeEventListener("change", onResize);
    };
  }, [AuthLanguage]);

  return (
    /* Page */
    <ScrollView>
      <View style={styles.page}>
        {/* Container */}
        <View style={styles.container}>
          {/* Header */}
          <View style={styles.header}>
            <View style={styles.header_section}>
              <Image
                source={require("../../assets/medimix-evidera-2.png")}
                resizeMode="contain"
                style={{
                  ...styles.image,
                  width: imageWidth,
                  height: imageHeight,
                  marginLeft: -40,
                }}
              />
            </View>
          </View>
          {/* Welcome Hero Section */}
          <View style={styles.header}>
            <View style={[styles.hero, { paddingTop: 20 }]}>
              <Text style={[styles.textTitle, { color: colors.text, fontSize: 20, fontWeight: "500" }]}>
                {translate("Thank you for reaching the Medimix International website. As of July 1, 2019 Medimix has joined")}
                {" "}
                <Text
                  style={styles.textHighlight}
                  onPress={() => openUrl("https://www.evidera.com/what-we-do/real-world-evidence-data-solutions/")}
                >
                  Evidera
                </Text>
                ,
                {" "}
                {translate("a business of")}
                {" "}
                <Text
                  style={styles.textHighlight}
                  onPress={() => openUrl("https://ppd.com")}
                >
                  PPD
                </Text>
                .
              </Text>
              <Divider style={{ width: "20%", margin: "auto" }} />
            </View>
          </View>
          {/* Section 1 */}
          <View style={styles.fluid}>
            {/* Card 1 */}
            <View style={cardStyle}>
              <TouchableOpacity
                style={styles.bg_image}
                onPress={() => openUrl(
                  "https://www.evidera.com/what-we-do/real-world-evidence-data-solutions/"
                )}
              >
                <ImageBackground source={require("../../assets/evidera_bg.png")} style={styles.bg_image}>
                  <View style={styles.overlay}>
                    <Card containerStyle={{
                      margin: 0, flex: 1, backgroundColor: "none", flexDirection: "column-reverse",
                    }}
                    >
                      <Text style={styles.textTitleHighlight}>{translate("WORK IN A LIFE SCIENCE ORGANIZATION?")}</Text>
                      <Text style={styles.textWhite}>
                        {translate("If you work in a LifeScience organization and would like more information about our Real World Evidence (RWE) services and extended offering through Evidera.")}
                      </Text>
                      <View style={{ flex: 1, flexDirection: "row" }}>
                        <TouchableWithoutFeedback
                          onPress={() => openUrl(
                            "https://www.evidera.com/what-we-do/real-world-evidence-data-solutions/"
                          )}
                        >
                          <View
                            pointerEvents="auto"
                            onMouseEnter={() => buttonActive(true, 1)}
                            onMouseLeave={() => buttonActive(false, 1)}
                            style={styles.cardButton}
                          >
                            <Text
                              style={buttonStyle}
                            >
                              {`${translate("LEARN MORE")} 🡢`}

                            </Text>
                          </View>
                        </TouchableWithoutFeedback>
                      </View>
                    </Card>
                  </View>
                </ImageBackground>
              </TouchableOpacity>
            </View>
            {/* Card 2 */}
            <View style={cardStyle}>
              <TouchableOpacity
                style={styles.bg_image}
                onPress={() => (!props.user ? props.navigation.push("Download") : props.navigation.push("Authenticate"))}
              >
                <ImageBackground source={require("../../assets/hcp_bg.jpg")} style={styles.bg_image}>
                  <View style={styles.overlay}>
                    <Card containerStyle={{
                      margin: 0, flex: 1, backgroundColor: "none", flexDirection: "column-reverse",
                    }}
                    >
                      <Text style={styles.textTitleHighlight}>{translate("HEALTHCARE PROFESSIONAL?")}</Text>
                      <Text style={styles.textWhite}>
                        {translate("If you are a Healthcare Professional and wants to participate in the LiveTracker® and other Real World Evidence programs visit our")}
                        {" HCP Portal."}
                      </Text>
                      <View style={styles.header}>
                        {props.userStatus
                          && (
                            <TouchableWithoutFeedback
                              // onPress={() => window.open("/authenticate", "hcpportal").focus()}

                              onPress={() => props.navigation.push("Authenticate")}

                            >
                              <View pointerEvents="auto" style={styles.cardButton}>
                                <Text
                                  onMouseEnter={() => buttonActive(true, 2)}
                                  onMouseLeave={() => buttonActive(false, 2)}
                                  style={buttonStyle2}
                                >
                                  {`${!props.user ? (translate("SIGN IN / SIGN UP")) : (translate("HCP Portal"))} 🡢`}

                                </Text>
                              </View>
                            </TouchableWithoutFeedback>
                          )}
                      </View>
                    </Card>
                  </View>
                </ImageBackground>
              </TouchableOpacity>
            </View>
          </View>
          {/* Section 2 */}
          <View style={styles.header}>
            <View style={styles.hero}>
              <View style={styles.sectionCentered}>
                <TouchableOpacity
                  onPress={() => openUrl(
                    "https://www.evidera.com/what-we-do/real-world-evidence-data-solutions/"
                  )}
                >
                  <Image
                    source={require("../../assets/evidera_logo.png")}
                    resizeMode="contain"
                    style={{
                      ...styles.brand,
                      margin: "1rem",
                      width: 100,
                      height: 50,
                    }}
                  />
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => openUrl("https://ppd.com")}
                >
                  <Image
                    source={require("../../assets/ppd_logo.png")}
                    resizeMode="contain"
                    style={{
                      ...styles.brand,
                      margin: "1rem",
                      width: 100,
                      height: 50,
                    }}
                  />
                </TouchableOpacity>
              </View>
            </View>
          </View>
          {/* Footer */}
          <View>
            <Text style={[styles.textFooter, { color: colors.text, marginBottom: 10 }]}>
              {translate("Copyright ©")}
              {" "}
              {(new Date().getFullYear())}
              {" "}
              {translate("Medimix International. All rights reserved.")}
            </Text>
          </View>
          <LanguageSessionSelector />
        </View>
      </View>
    </ScrollView>
  );
};

export default LandingMain;
