import React, { useEffect, useState } from "react";
import { StyleSheet, View, Text } from "react-native";
import { ActivityIndicator, Portal, useTheme } from "react-native-paper";
import color from "color";
import { translate } from "./Localei18n/languagesFunctions";

type Props = {
  currentUser: any;
  selectedUic?: undefined | any;
  loading?: false;
}

const styles = StyleSheet.create({
  root: {
    minHeight: 79,
    borderRadius: 8,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: "rgba(127,127,127,0.3)",
  },
  displayedProfile: {
    paddingTop: 8,
    paddingBottom: 15,
    paddingHorizontal: 10,
  },
  title: {
    textTransform: "capitalize",
    marginTop: 12,
    fontSize: 24,
    lineHeight: 24,
    fontWeight: "800",
    marginBottom: 5,
  },
  subtitle: {
    fontSize: 14,
    lineHeight: 16,
    marginVertical: 3,
  },
});

const DrawerMiniProfile = (props: Props) => {
  // const { currentUser, selectedUic } = props;

  const [currentUser] = useState(props.currentUser);
  const [selectedUic, setSelectedUic] = useState(props.selectedUic);
  const theme = useTheme();

  useEffect(() => {
    setSelectedUic(props.selectedUic);
  }, [props.selectedUic]);

  const subtextColor = color(theme.colors.text)
    .alpha(0.5)
    .rgb()
    .string();

  const statusText = {
    1: "VERIFIED",
    2: "ACTIVE",
    3: "INACTIVE",
    4: "PERMANENTLY INACTIVE",
    6: "FOR REVIEW",
  };

  // backgroundColor: theme.colors.background >
  return (
    <View style={[styles.root, { backgroundColor: "transparent" }]}>
      {!props.loading
        ? (
          <>
            {Object.keys(selectedUic).length >= 1
              && (
                <View style={styles.displayedProfile}>
                  <Text style={styles.title}>{`${selectedUic.first_name} ${selectedUic.last_name}`}</Text>
                  <Text style={[styles.subtitle, { color: subtextColor }]}>
                    {selectedUic.uic}
                  </Text>
                  <Text style={[styles.subtitle, { color: subtextColor }]}>
                    {translate(statusText[selectedUic.contact_status_id])}
                  </Text>
                </View>
              )}

            {Object.keys(selectedUic).length <= 0
              && (
                <View style={styles.displayedProfile}>
                  <Text style={styles.title}>{`${currentUser.first_name} ${currentUser.last_name}`}</Text>
                  <Text style={[styles.subtitle, { color: subtextColor }]}>
                    {translate("TEST USER")}
                  </Text>
                </View>
              )}

          </>
        ) : (
          <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
            <ActivityIndicator size={40} color={theme.colors.primary} />
          </View>
        )}
    </View>
  );
};

export default DrawerMiniProfile;
