import React from "react";
import { View, StyleSheet, Image } from "react-native";
// eslint-disable-next-line no-unused-vars
import { MaterialBottomTabNavigationProp } from "@react-navigation/material-bottom-tabs";
import { Surface, Divider, List } from "react-native-paper";

import { StackNavigationProp } from "@react-navigation/stack";
import { Faq, Chat, Call, Whatsapp } from "./components";
import AppHeader from "../../components/app.header";
import { StackNavigatorParamlist } from "../../navigation/types";

type Props = {
  navigation?: StackNavigationProp<StackNavigatorParamlist>;
};


const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
  },
  containerView: {
    flex: 1,
    padding: 10,
  },
});

export const Support = (props: Props) => (
  <>

    <AppHeader style={{ elevation: 3 }}>
      <Image
        source={require("../../assets/medimix-evidera-2.png")}
        resizeMode="contain"
        style={{
          height: 30,
          width: 200,
        }}
      />
    </AppHeader>

    <Surface
      style={styles.container}
    >
      <Faq />
      <Divider />
      <Chat navigation={props.navigation} />
    </Surface>
  </>
);
