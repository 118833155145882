/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  StyleSheet,
  View,
  TouchableOpacity,
  Linking,
  Image,
  Platform,
  RefreshControl,
  useWindowDimensions,
  TouchableWithoutFeedback,
} from "react-native";
import { DefaultTheme, Text, useTheme, TouchableRipple, ActivityIndicator, Checkbox, Snackbar } from "react-native-paper";
import { ScrollView } from "react-native-gesture-handler";
import * as SecureStore from "expo-secure-store";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { API, graphqlOperation } from "aws-amplify";
import gql from "graphql-tag";
import { ReferenceDataContext } from "./ReferenceDataContext";
import ErrorComponent from "../../../components/ErrorComponent";
import { styles } from "./styles";
import { PersonalInfoTitle } from "./StageTitle";
import { TableDisplay, TableRow, CellLabel, CellValue, CellView } from "../../../components/TableDisplay";
import { translate } from "../../../components/Localei18n/languagesFunctions";
import LanguageSessionSelector from "../../../components/languageSessionSelector";
import { TermsConditions } from "../../preferences/terms.conditions";
import applyScale from "../../../helpers/image/applyScale";
import secureStoreOptionsKeychain from "../../../data/secure-store-keychain";
import UserRegistrationService from "../../../helpers/user.registration.service";
import { sendAnalyticsEvent } from "../../../components/Analytics/analytics";
import { AuthContext } from "../AuthContext";

const inlineStyles = StyleSheet.create({
  rootWeb: {
    maxWidth: applyScale(700),
    marginHorizontal: "auto",
  },
});

type Props = {
  backTo: () => void,
  user: any,
  updateUserAttributes: (user: any, attributes: any) => void,
}

const secureStoreOptions = {
  keychainService: secureStoreOptionsKeychain,
  keychainAccessible: SecureStore.ALWAYS, // iOS only
};

export const Stage3 = (props: Props) => {
  // const [] = useState(true);
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const theme = useTheme();
  const [submitting, setSubmitting] = useState(false);
  const [checkedApproveTerms, setCheckedApproveTerms] = useState(false);
  const [checkedComplyLaw, setCheckedComplyLaw] = useState(false);
  const [checkedPrivacyPolicy, setCheckedPrivacyPolicy] = useState(false);
  const [error, setError] = useState(false);
  const termsContentWidth = applyScale(useWindowDimensions().width);
  const { AuthLanguage } = useContext(AuthContext);

  const { personalInfoData, setPersonalInfoData } = useContext(
    ReferenceDataContext
  );

  const { updateRegistrationStatus } = UserRegistrationService();

  const validatedContact = async () => {
    setError(false);
    if (!(checkedApproveTerms && checkedComplyLaw && checkedPrivacyPolicy)) {
      setError(true);
      return;
    }
    setSubmitting(true);
    try {
      updateRegistrationStatus(props.user?.attributes["custom:contact_id"],
        props.user?.attributes["custom:panelist_id"] ? 6 : 4, null);

      // Dont remove the await otherwise the request is cancelled.
      await props.updateUserAttributes(props.user, {
        "custom:review_status": "6",
        "custom:approved_terms": new Date().toISOString(),
        "custom:comply_with_law": new Date().toISOString(),
      });

      setPersonalInfoData({});

      openUrl("review", "_self");
    } catch (e) {
      console.log(e);
      setSnackbarVisible(true);
    }
  };

  const openUrl = (url, target = "_blank") => {
    if (Platform.OS === "web") {
      window.open(url, target).focus();
    } else {
      Linking.openURL(url);
    }
  };

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    setTimeout(() => {
      setRefreshing(false);
    }, 1000);
  }, []);

  useEffect(() => {
    sendAnalyticsEvent("stage3");
  }, [AuthLanguage]);

  const DisplayLicenseDocumentComponent = () => {
    if (personalInfoData.urlImage) {
      return (
        <Image
          source={{ uri: personalInfoData.urlImage }}
          resizeMode="contain"
          style={{
            height: 177, backgroundColor: "black", width: "100%",
          }}
        />
      );
    }
    return null;
  };

  return (
    <>
      <PersonalInfoTitle style={styles.fluid} />

      <ScrollView
        style={[
          styles.contentWrapper,
          Platform.OS === "web" && styles.contentWrapperWeb,
        ]}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }
        // contentContainerStyle={{ paddingHorizontal: 10 }}
      >
        <View style={{ flexDirection: "row" }}>
          <TableDisplay
            title={translate("Basic Information")}
            style={[
              styles.boxShadow,
              Platform.OS === "web" && inlineStyles.rootWeb,
              { flex: 1, maxWidth: 700, marginTop: 20 },
            ]}
          >
            <TableRow>
              <CellLabel>{`${translate("name")}`}</CellLabel>
              <CellValue>{`${personalInfoData.first_name} ${personalInfoData.last_name}`}</CellValue>
            </TableRow>

            <TableRow>
              <CellLabel>{`${translate("Country")}`}</CellLabel>
              <CellValue>{translate(personalInfoData.selected_country?.country_name)}</CellValue>
            </TableRow>
          </TableDisplay>
        </View>

        <View style={{ flexDirection: "row" }}>
          <TableDisplay
            title={translate("Professional Information")}
            style={[
              styles.boxShadow,
              Platform.OS === "web" && inlineStyles.rootWeb,
              { flex: 1, maxWidth: 700, marginTop: 20 },
            ]}
          >
            <TableRow>
              <CellLabel>{`${translate("Healthcare Category")}`}</CellLabel>
              <CellValue>
                {personalInfoData.category !== "" && personalInfoData.categoryList
                  ? translate(

                    personalInfoData.categoryList.find(
                      (x) => x.target_code === Number(personalInfoData.target_code)
                    )
                      .target_name
                  )
                  : ""}

              </CellValue>
            </TableRow>

            <TableRow>
              <CellLabel>{`${translate("Salutation")}`}</CellLabel>
              <CellValue>
                {personalInfoData.salutation_code !== 0 && personalInfoData.salutation_code !== "" && personalInfoData.salutationList
                  ? translate(
                      personalInfoData.salutationList?.find(
                        (x) => x.salutation_code === Number(personalInfoData.salutation_code)
                      ).salutation_name
                  )
                  : ""}

              </CellValue>
            </TableRow>

            <TableRow>
              <CellLabel>{`${translate("Primary Specialty")}`}</CellLabel>
              <CellValue>
                {personalInfoData.firstSpecialty !== ""
                  && personalInfoData.specialtyList
                  ? translate(personalInfoData.specialtyList[
                    personalInfoData.specialtyList.findIndex(
                      (x) => x.code === personalInfoData.level1_specialty_code
                    )
                  ].name)
                  : ""}

              </CellValue>
            </TableRow>

            <TableRow>
              <CellLabel>{`${translate("Second Specialty")}`}</CellLabel>
              <CellValue>
                {personalInfoData?.level2_specialty_code && personalInfoData?.level2_specialty_code !== "" && personalInfoData?.level2_specialty_code !== 0
                  && personalInfoData.secondSpecialtyList
                  && personalInfoData.secondSpecialtyList.findIndex((x) => x?.code === personalInfoData.level2_specialty_code) !== -1
                  ? translate(
                    personalInfoData.secondSpecialtyList.find(
                      (x) => x.code === personalInfoData.level2_specialty_code
                    )
                      .name
                  )
                  : ""}
              </CellValue>
            </TableRow>

            <TableRow>
              <CellLabel>{`${translate("License ID")}`}</CellLabel>
              <CellValue>{personalInfoData.document_number}</CellValue>
            </TableRow>

            {(personalInfoData.document_type_id && parseInt(personalInfoData.document_type_id, 10) > 1) && (
            <TableRow>
              <CellLabel>{`${translate("License Type")}`}</CellLabel>
              <CellValue>
                {/* {personalInfoData?.documentTypeList
                  && Array.isArray(personalInfoData?.documentTypeList) && personalInfoData?.documentTypeList.length > 0
                  ? translate(

                    (personalInfoData.documentTypeList.find(
                      (x) => x.document_type_id === personalInfoData?.document_type_id
                    )) ? personalInfoData.documentTypeList.find(
                        (x) => x.document_type_id === personalInfoData?.document_type_id
                      )
                        .document_type_name : personalInfoData?.document_type_id
                  )
                  : ""} */}
                {translate(
                  personalInfoData?.documentTypeList.find((e) => parseInt(e?.document_type_id, 10) === parseInt(personalInfoData?.document_type_id, 10))
                    ?.document_type_name || ""
                )}

              </CellValue>
            </TableRow>
            )}

            {(personalInfoData.document_type_id && parseInt(personalInfoData.document_type_id, 10) === 1) && (
              <TableRow>
                <CellLabel>{`${translate("License Type")}`}</CellLabel>
                <CellValue>{personalInfoData?.specified_type_name}</CellValue>
              </TableRow>
            )}
            {(personalInfoData.document_type_id && parseInt(personalInfoData.document_type_id, 10) === 1) && (
              <TableRow>
                <CellLabel>{`${translate("Organization")}`}</CellLabel>
                <CellValue>{personalInfoData?.specified_organization}</CellValue>
              </TableRow>
            )}

            <TableRow>
              <CellLabel>{`${translate("Photo of License")}`}</CellLabel>
              <CellView>
                <DisplayLicenseDocumentComponent />
              </CellView>
            </TableRow>
          </TableDisplay>
        </View>

        <View style={{ flexDirection: "row" }}>
          <TableDisplay
            title={translate("Contact Number")}
            style={[
              styles.boxShadow,
              Platform.OS === "web" && inlineStyles.rootWeb,
              { flex: 1, maxWidth: 700, marginTop: 20 },
            ]}
          >
            <TableRow>
              <CellLabel>{`${translate("Phone Number")}`}</CellLabel>
              <CellValue>{`+${personalInfoData.numberCountryCode} ${personalInfoData.number}`}</CellValue>
            </TableRow>

          </TableDisplay>
        </View>

        <View style={{ flexDirection: "row" }}>
          <TableDisplay
            title={translate("Terms & Conditions")}
            style={[
              styles.boxShadow,
              Platform.OS === "web" && inlineStyles.rootWeb,
              { flex: 1, maxWidth: 700, marginVertical: 20 },
            ]}
          >
            <TableRow>
              <CellView>
                <View style={{ flexDirection: "row", maxWidth: termsContentWidth }}>
                  <TermsConditions language={AuthLanguage} />
                </View>
                <View style={{ flexDirection: "row", width: "85%" }}>
                  <Checkbox.Android
                    status={checkedApproveTerms ? "checked" : "unchecked"}
                    uncheckedColor={theme.colors.primary}
                    onPress={() => {
                      setCheckedApproveTerms(!checkedApproveTerms);
                    }}
                  />
                  <Text theme={DefaultTheme} style={{ color: ((error && !checkedApproveTerms) ? "red" : theme.colors.primary) }}>{`${translate("By clicking here, you confirm that you agree to proceed based on the notice and terms above, and you acknowledge for avoidance of doubt that the above notice and terms constitute a legally binding agreement between you and Evidera, Ltd.")}`}</Text>
                </View>
                <View style={{ flexDirection: "row", width: "85%", marginTop: 15 }}>
                  <Checkbox.Android
                    status={checkedComplyLaw ? "checked" : "unchecked"}
                    onPress={() => {
                      setCheckedComplyLaw(!checkedComplyLaw);
                    }}
                  />
                  <Text theme={DefaultTheme} style={{ color: ((error && !checkedComplyLaw) ? "red" : theme.colors.primary) }}>{`${translate("In confirming your agreement with these terms, you (a) have all necessary legal authority, and (b) have complied with all relevant laws and regulations, including notwithstanding that de-identified patient information may still constitute protected “ personal data” under GDPR, you: (i) have the necessary legal basis under Articles 6 and 9 of GDPR; (ii) having provided any required data protection notice to patients as required by Article 13 GDPR; and (iii) having acquired any necessary ethical or regulatory permissions, for the purposes of disclosing to Evidera information about patients that you personally follow at your hospital or practice (all of subparts (i), (ii) and (iii) being true and correct, notwithstanding that de-identified patient information may still constitute protected “personal data” under GDPR;); and (iv) any other applicable law, regulation, or contractual provision governing your sharing with Evidera of de-identified information about patients attending your hospital or practice, such that Evidera and Evidera affiliate companies are lawfully entitled to retain and use all such information in the manner described in these terms.")}`}</Text>
                </View>
                <View style={{
                  flexDirection: "row", width: "85%", marginTop: 15, alignItems: "center",
                }}
                >
                  <Checkbox.Android
                    status={checkedPrivacyPolicy ? "checked" : "unchecked"}
                    onPress={() => {
                      setCheckedPrivacyPolicy(!checkedPrivacyPolicy);
                    }}
                  />
                  <Text theme={DefaultTheme} style={{ color: ((error && !checkedPrivacyPolicy) ? "red" : theme.colors.primary) }}>
                    {`${translate("By clicking here, you confirm that you have read and agree to the ")}`}
                    <TouchableWithoutFeedback
                      onPress={() => openUrl(
                        "https://www.ppd.com/who-we-are/company-resources/privacy-policy/"
                      )}
                    >
                      <Text style={
                        [(Platform.OS === "web" ? { cursor: "pointer" } : {}),
                          {
                            fontWeight: "bold", textDecorationLine: "underline", color: "#00008a",
                          }]
                      }
                      >
                        {translate("Privacy Policy.")}
                      </Text>

                    </TouchableWithoutFeedback>
                  </Text>
                </View>
                <View style={[styles.formActionSection, { marginTop: 20 }]}>
                  <TouchableOpacity
                    onPress={validatedContact}
                    style={[styles.formButton, {
                      backgroundColor: ((checkedPrivacyPolicy && checkedApproveTerms && checkedComplyLaw) ? theme.colors.accent : theme.colors.disabled),
                      alignItems: "center",

                    }]}
                  >
                    <View style={{ flexDirection: "row" }}>
                      {submitting && (
                        <ActivityIndicator
                          size={14}
                          color="white"
                          style={{ marginRight: applyScale(10) }}
                        />
                      )}
                      <Text style={{ color: "white" }}>{translate("Confirm")}</Text>
                    </View>
                    {/* <Text theme={DefaultTheme} style={[{ color: "white" }]}>
                      {translate("Confirm")}
                    </Text> */}
                  </TouchableOpacity>

                  <TouchableRipple
                    disabled={submitting}
                    onPress={props.backTo}
                    style={[styles.formButton, styles.formButtonDisabled]}
                  >
                    <Text
                      theme={DefaultTheme}
                      style={{ color: theme.colors.primary }}
                    >
                      {translate("Back")}
                    </Text>
                  </TouchableRipple>

                </View>


                <ErrorComponent />
              </CellView>
            </TableRow>

          </TableDisplay>
        </View>
        <View style={[
          Platform.OS === "web" && styles.rootLangSelector,
          { marginTop: Platform.OS === "web" ? -10 : 0, marginLeft: Platform.OS === "web" ? 0 : -20, paddingBottom: 30 }]}

        >
          <LanguageSessionSelector style={{ margin: 0 }} />
        </View>

      </ScrollView>
      <Snackbar
        style={{ backgroundColor: "#dd361e" }}
        visible={snackbarVisible}
        onDismiss={() => setSnackbarVisible(false)}
        duration={3000}
        action={{
          label: <Text>✕</Text>,
          onPress: () => {
            setSnackbarVisible(false);
          },
        }}
      >
        {translate("Failed to complete registration!")}
      </Snackbar>
    </>
  );
};

export default Stage3;
