import React, { useState, useContext, useEffect } from "react";
import { StyleSheet, View, ScrollView, Text, Platform } from "react-native";
import { useTheme } from "react-native-paper";
import color from "color";

import { API, graphqlOperation } from "aws-amplify";
import gql from "graphql-tag";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { StackNavigationProp } from "@react-navigation/stack"; // eslint-disable-line no-unused-vars
// import FormField from "../../helpers/FormField";
import ContentLoader from "react-native-easy-content-loader";
import { StackNavigatorParamlist } from "../../../navigation/types"; // eslint-disable-line no-unused-vars
import {
  CardIT,
  CardITDetails,
  CardITDetailsInline,
  CardITDetailsInlineItem,
} from "../../../components/details-icon-text-card";
import { UserInfoContext } from "../../../context/userInfo";
import Moment from "../../../helpers/moment";
import { translate } from "../../../components/Localei18n/languagesFunctions";
import { SessionContext } from "../../../context/SessionContext";
import UserService from "../../../helpers/user.service";
import { sendAnalyticsEvent } from "../../../components/Analytics/analytics";

type Props = {
  navigation?: StackNavigationProp<StackNavigatorParamlist>;
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    width: "100%",
  },
  inlineCardDetails: {
    flexDirection: "row",
  },
  messageWarning: {
    backgroundColor: "orange",
    paddingVertical: 10,
    paddingHorizontal: 15,
  },
  messageDanger: {
    backgroundColor: "#f74364",
    paddingVertical: 10,
    paddingHorizontal: 15,
  },
});

export const ProfileContacts = (props: Props) => {
  const { handleSessionDeactivation } = useContext(SessionContext);
  const { checkUser } = UserService();

  const { managedUserInfo, isProductionCheck } = useContext(UserInfoContext);
  const theme = useTheme();

  const [loading, setLoading] = useState(false);
  const [numbers, setNumbers] = useState([]);
  const [emails, setEmail] = useState([]);
  const [address, setAddress] = useState([]);

  const [info, setInfo] = useState({});
  // const [frm, setState] = useState({
  //   first_name: "",
  //   last_name: "",
  //   panelist_id: 10,
  // });

  const mutedColor = color(theme.colors.text)
    .alpha(0.67)
    .rgb()
    .string();

  const autoUpdatePrimaryEmail = async (data, formData) => {
    if (data.length === 1 && data[0].primary_email_address === false) {
      const mutation = gql`
        mutation updateContacts_emails($updateContactsEmailsInput: UpdateContactsEmailsInput!){
          updateContacts_emails(updateContactsEmailsInput: $updateContactsEmailsInput){
            email primary_email_address
          }
        }
      `;

      try {
        // const response = await API.graphql(graphqlOperation(mutation, {
        const response = await API.graphql(graphqlOperation(mutation, {
          updateContactsEmailsInput: { primary_email_address: 1, email_id: data[0].email_id },
        }));

        const newData = [{ ...data[0], primary_email_address: true }];
        setEmail(newData);

        return response.data.updateContacts_emails.primary_email_address;
      } catch (err) {
        console.log(["failed auto update primary", err, { primary_email_address: 1, email_id: data[0].email_id }]);
        return false;
      }
    }
    return false;
  };

  const autoUpdatePrimaryNumber = async (data, formData) => {
    if (data.length === 1 && data[0].primary_number === false) {
      const mutation = gql`
        mutation updateContacts_emails($formValue: FormContactsNumbersInput!){
          updateContacts_numbers(formValue: $formValue)
        }
      `;

      try {
        // const response = await API.graphql(graphqlOperation(mutation, {
        const response = await API.graphql(graphqlOperation(mutation, {
          formValue: { primary_number: 1, number_id: data[0].number_id, panelist_id: managedUserInfo.panelist_id },
        }));

        const newData = [{ ...data[0], primary_number: true }];

        setNumbers(newData);

        return response.data.updateContacts_numbers;
      } catch (err) {
        console.log(["failed number", err]);
        return false;
      }
    }
    return false;
  };

  const getContacts = async () => {
    if (await checkUser() !== true) handleSessionDeactivation();

    const qry = gql`
        query ($email: String!) {
          getContacts_numbers(email: $email){
            number panelist_id country_phone_code primary_number number_id
          }
          getContacts_emails_by_email(email: $email){
            panelist_id email email_status_id primary_email_address email_id
          }
          getContactsInfo_by_email(email: $email){
            uic last_name first_name date_of_birth title salutation panelist_id target_code salutation_code
          }
          listAddressByEmail(email: $email){
            address_center_name address_city address_country_code address_line1 address_line2 address_name address_postal_code address_state_or_province address_type address_type_name panelist_id primary_address region_code
          }

          getFormContacts_numbers(email: $email){
            number panelist_id country_phone_code primary_number number_id
          }
          getFormContacts_emails_by_email(email: $email){
            panelist_id email email_status_id primary_email_address email_id
          }
          listFormAddressByEmail(email: $email){
            address_center_name address_city address_country_code address_line1 address_line2 address_name address_postal_code address_state_or_province address_type address_type_name panelist_id primary_address region_code
          }
          getFormsContactsInfo_by_email(email: $email){
            verification_status uic last_name first_name date_of_birth title salutation salutation_code panelist_id contact_id target_code
          }
        }
      `;
    try {
      const response: any = await API.graphql(
        graphqlOperation(qry, {
          email: managedUserInfo.email,
        })
      );


      if (
        response.data.getFormsContactsInfo_by_email
          && response.data.getFormsContactsInfo_by_email?.verification_status !== null
      ) {
        setInfo({
          ...response.data.getContactsInfo_by_email,
          ...response.data.getFormsContactsInfo_by_email,
        });

        setAddress(
          response.data.listFormAddressByEmail.length > 0
            && response.data.listAddressByEmail
              !== response.data.getFormsContacts_emails_by_email
            ? response.data.listFormAddressByEmail
            : response.data.listAddressByEmail
        );

        setEmail(
          (response.data.getFormContacts_emails_by_email.length > 0)
            && (response.data.getContacts_emails_by_email
              !== response.data.getFormContacts_emails_by_email)
            ? response.data.getFormContacts_emails_by_email
            : response.data.getContacts_emails_by_email
        );

        setNumbers(
          response.data.getFormContacts_numbers.length > 0
            && response.data.getContacts_numbers
              !== response.data.getFormContacts_numbers
            ? response.data.getFormContacts_numbers
            : response.data.getContacts_numbers
        );
      } else {
        setNumbers(response.data.getContacts_numbers);
        setEmail(response.data.getContacts_emails_by_email);
        setInfo(response.data.getContactsInfo_by_email);
        setAddress(response.data.listAddressByEmail);
      }

      autoUpdatePrimaryEmail(response.data.getContacts_emails_by_email, response.data.getFormContacts_emails_by_email);
      autoUpdatePrimaryNumber(response.data.getContacts_numbers, response.data.getFormContacts_numbers);
      setLoading(false);
      // resolve(response);
    } catch (e) {
      console.log(["profilecontects->getContacts->error", e]);
      setLoading(false);
      // reject(e);
    }
  };

  const updatePersonal = (newData) => {
    setInfo({ ...info, ...newData });
  };

  useEffect(() => {
    setLoading(true);

    setNumbers([]);
    setEmail([]);
    setInfo({});
    setAddress([]);

    getContacts();

    sendAnalyticsEvent("Contacts");

    const unsubscribe = props.navigation.addListener("focus", () => {
      getContacts();
    });

    return unsubscribe;
  }, []);


  // Analytics.logEvent("ContactsOpened");
  return (
    <ScrollView style={styles.root}>
      <View style={{ paddingBottom: 30 }}>
        {[2].includes(info.verification_status) && (
          <View style={[styles.messageWarning]}>
            <Text style={{ color: "white", fontWeight: "600" }}>
              {translate("Pending Verification")}
            </Text>
          </View>
        )}

        {info?.verification_status === 5 && (
        <View style={[styles.messageDanger]}>
          <Text style={{ color: "white", fontWeight: "600" }}>
            {translate("Changes to your profile were rejected.")}
          </Text>
        </View>
)}

        <CardIT
          title={translate("Contact Details")}
          onPress={() => props.navigation
            && props.navigation.push("ProfileContactsContacts", {
              ...managedUserInfo,
              contact_id: info.contact_id ? info.contact_id : 0,
              verification_status: info.verification_status,
            })}
        >
          <CardITDetails
            label={translate("Emails")}
            icon={(
              <MaterialCommunityIcons
                name="email-outline"
                size={24}
                color={mutedColor}
              />
            )}
          >
            {!loading ? (
              emails.length > 0
              && emails.map((e, index) => (
                <View
                  style={{ flexDirection: "row", alignItems: "center" }}
                  key={index.toString()}
                >
                  {e.primary_email_address === true && (
                    <MaterialCommunityIcons
                      style={{ color: "lightgreen", paddingBottom: 0 }}
                      name="check-circle"
                      size={16}
                      color={mutedColor}
                    />
                  )}
                  <Text
                    style={[
                      { color: mutedColor, paddingVertical: 2 },
                      e.primary_email_address !== true && { marginLeft: 16 },
                    ]}
                  >
                    {` ${e.email}`}
                  </Text>
                </View>
              ))
            ) : (
              <View style={{ paddingTop: 5, marginBottom: 1 }}>
                <ContentLoader
                  animationDuration={500}
                  active
                  pRows={1}
                  listSize={1}
                  tHeight={10}
                  titleStyles={{ marginBottom: 0 }}
                  pHeight={10}
                  paragraphStyles={{ marginBottom: 0 }}
                />
              </View>
            )}
          </CardITDetails>

          <CardITDetails
            label={translate("Mobile Numbers")}
            style={{ marginTop: 15 }}
            icon={(
              <MaterialCommunityIcons
                name="phone"
                size={24}
                color={mutedColor}
              />
            )}
          >
            {!loading ? (
              numbers.length > 0
              && numbers.map((e, index) => (
                <View
                  style={{ flexDirection: "row", alignItems: "center" }}
                  key={index.toString()}
                >
                  {e.primary_number === true && (
                    <MaterialCommunityIcons
                      style={{ color: "lightgreen", paddingBottom: 0 }}
                      name="check-circle"
                      size={16}
                      color={mutedColor}
                    />
                  )}
                  <Text
                    style={[
                      { color: mutedColor, paddingVertical: 2 },
                      e.primary_number !== true && { marginLeft: 16 },
                    ]}
                  >
                    {` +${e.country_phone_code} ${e.number}`}
                  </Text>
                </View>
              ))
            ) : (
              <View style={{ paddingTop: 5, marginBottom: 1 }}>
                <ContentLoader
                  animationDuration={500}
                  active
                  pRows={1}
                  listSize={1}
                  tHeight={10}
                  titleStyles={{ marginBottom: 0 }}
                  pHeight={10}
                  paragraphStyles={{ marginBottom: 0 }}
                />
              </View>
            )}
          </CardITDetails>
        </CardIT>

        <CardIT
          title={translate("Personal Information")}
          onPress={() => isProductionCheck() && props.navigation
            && props.navigation.push("EditContactsPersonal", {
              ...info,
              reload: updatePersonal,
            })}
        >
          {!loading ? (
            <>
              <CardITDetails
                label={translate("Name")}
                icon={(
                  <MaterialCommunityIcons
                    name="account-outline"
                    size={24}
                    color={mutedColor}
                  />
                )}
              >
                <Text style={{ color: mutedColor }}>
                  {/* {info.salutation && `${translate(info.salutation)}. `} */}
                  {info.first_name && `${info.first_name} `}
                  {info.last_name && `${info.last_name}`}
                </Text>
              </CardITDetails>

              {/* <CardITDetails
                label={translate("Title")}
                style={{ marginTop: 15 }}
                icon={(
                  <MaterialCommunityIcons
                    name="stethoscope"
                    size={24}
                    color={mutedColor}
                  />
              )}
              >
                <Text style={{ color: mutedColor }}>
                  {info.title ? `${info.title}` : "-"}
                </Text>
              </CardITDetails> */}


              <CardITDetails
                style={{ marginTop: 15 }}
                label={translate("Salutation")}

              >
                <Text style={{ color: mutedColor, marginVertical: 4 }}>{translate(info?.salutation)}</Text>
              </CardITDetails>
            </>
          ) : (
            <View style={{ paddingTop: 5, marginBottom: 1 }}>
              <ContentLoader
                aSize={25}
                animationDuration={500}
                avatar
                avatarStyles={{ marginLeft: 10 }}
                active
                pRows={1}
                listSize={2}
                tHeight={14}
                titleStyles={{ marginLeft: 10, marginBottom: 4 }}
                pHeight={10}
                paragraphStyles={{ marginLeft: 10, marginBottom: 0 }}
              />
            </View>
          )}
        </CardIT>

        <CardIT
          title={translate("Address")}
          onPress={() => props.navigation
            && props.navigation.push("ProfileContactsAddressList", {
              ...managedUserInfo,
              verification_status: info.verification_status,
            })}
        >
          <CardITDetails
            label={translate("Address")}
            icon={
              <MaterialCommunityIcons name="map" size={24} color={mutedColor} />
            }
          >

            {!loading ? (
              address
              && address.length > 0
              && address
                .filter((e) => e.address_line1 !== "")
                .map((e, index) => {
                  const line1 = e.address_line1 && e.address_line1 !== "" && e.address_line1;
                  const line2 = e.address_line2
                  && e.address_line2 !== ""
                  && `${e.address_line2}`;

                  const provincialAddress = (e.address_state_or_province !== ""
                    ? `${e.address_state_or_province}`
                    : "")
                  + (e.address_state_or_province !== ""
                    && e.address_country_code !== ""
                    ? ", "
                    : "")
                  + (e.address_country_code !== ""
                    ? `${e.address_country_code.toUpperCase()}`
                    : "");

                  const line3 = `${e.address_postal_code}${e.address_city
                  && e.address_city !== ""
                  && ` ${e.address_city}`}, ${provincialAddress}`;

                  const line4 = e.address_center_name;

                  const final = line1.trim()
                  + (line1.trim() !== "" && line2.trim() !== "" ? "\n" : "")
                  + line2.trim()
                  + (line2.trim() !== "" && line3.trim() !== "" ? "\n" : "")
                  + line3.trim()
                  + (line4.trim() !== "" && line3.trim() !== "" ? "\n" : "")
                  + line4.trim();

                  return (
                    <View key={index.toString()}>
                      {index !== 0 && (
                      <View
                        style={{
                          height: StyleSheet.hairlineWidth,
                          backgroundColor: "rgba(0,0,0,0.4)",
                        }}
                      />
                      )}
                      <View style={{ flexDirection: "row", paddingVertical: 5 }}>
                        {e.primary_address === 1 && (
                        <View style={{ paddingTop: 6, paddingRight: 6 }}>
                          <MaterialCommunityIcons
                            style={{ color: "lightgreen", paddingBottom: 0 }}
                            name="check-circle"
                            size={16}
                            color={mutedColor}
                          />
                        </View>
                        )}
                        <Text
                          style={[
                            { flex: 1, color: mutedColor, paddingVertical: 6 },
                            e.primary_address !== 1 && { marginLeft: 22 },
                          ]}
                        >
                          {final.trim() !== "" ? final.trim() : "[Empty]"}
                        </Text>
                      </View>
                    </View>
                  );
                })
            ) : (
              <View style={{ paddingTop: 5, marginBottom: 1 }}>
                <ContentLoader
                  animationDuration={500}
                  active
                  pRows={1}
                  listSize={2}
                  tHeight={10}
                  titleStyles={{ marginBottom: 0 }}
                  pHeight={10}
                  paragraphStyles={{ marginBottom: 0 }}
                />
              </View>
            )}
          </CardITDetails>

        </CardIT>
      </View>

    </ScrollView>
  );
};
