/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  forwardRef,
} from "react";
import {
  View,
  ScrollView,
  Platform,
  RefreshControl,
} from "react-native";
import {
  DefaultTheme,
  TextInput,
  Text,
  useTheme,
  ProgressBar,
  Title,
  Surface,
  TouchableRipple,
  ActivityIndicator,
} from "react-native-paper";
import { API, graphqlOperation } from "aws-amplify";
import gql from "graphql-tag";
import { Controller, useForm } from "react-hook-form";
import { Picker } from "@react-native-picker/picker";
import { StackNavigationProp } from "@react-navigation/stack";
import * as SecureStore from "expo-secure-store";
import { ReferenceDataContext } from "./ReferenceDataContext";
import { styles } from "./styles";
import { PickerCustom } from "../../../components/picker.custom";
import FormField from "../../../components/FormValidator/FormField";
import { PersonalInfoTitle } from "./StageTitle";
import { StageLoader } from "./StageLoader";
import { translate } from "../../../components/Localei18n/languagesFunctions";
import LanguageSessionSelector from "../../../components/languageSessionSelector";
import secureStoreOptionsKeychain from "../../../data/secure-store-keychain";
// const logger = new Logger("PersonalInfo:Stage0");

type Props = {
  ref: any;
  goTo: () => void;
  user: any;
  userStatus: any;
  handleSignout: () => void;
  navigation?: StackNavigationProp;
  updateUserAttributes: (user: any, attributes: any) => void;
};

export const Stage0 = forwardRef((props: Props, ref) => {
  const {
    control, getValues, register, trigger, formState: { errors },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const { personalInfoData, setPersonalInfoData } = useContext(
    ReferenceDataContext
  );
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [contactId, setContactId] = useState(null);

  const theme = useTheme();

  let unmounted = false;

  const updateContactsStage0 = async (data) => {
    setSubmitting(true);
    try {
      const mutationreplaceContactsStage0 = gql`
        mutation replaceContactsStage0(
          $formValue: replaceContactsStage0Input!,
          $contact_id: Int!
          $status: Int!,
          $approver: String
        ) {
          replaceContactsStage0(formValue: $formValue)
          createVerificationControl(contact_id: $contact_id,status: $status, approver: $approver)
        }
      `;

      await API.graphql(
        graphqlOperation(mutationreplaceContactsStage0, {
          formValue: data,
          contact_id: props.user?.attributes["custom:contact_id"],
          status: 1,
          approver: null,
        })
      );

      if (data.country !== null) {
        setPersonalInfoData({
          ...data,
          selected_country: countryList.find((e) => e.country_code === data.country),
          contact_id: contactId,
        });
        props.updateUserAttributes(props.user, {
          "custom:country": data.country,
        });
      }

      props.goTo();
    } catch (e) {
      setSubmitting(false);
      console.log(e);
      return false;
    }
    return true;
  };

  const onSubmit = async () => {
    await trigger();
    if (Object.keys(errors).length === 0) {
      const data = getValues();
      if (!unmounted) await updateContactsStage0(data);
    }
  };

  const loadContactsStage0 = async () => {
    const contactIdCognito = props.user?.attributes["custom:contact_id"];
    setContactId(contactIdCognito);

    try {
      const qry = gql`
        query($contact_id: Int!) {
          getContactsStage0(contact_id: $contact_id) {
            first_name
            last_name
            country: country_code
          }
          getCountries {
            country_code
            country_name
          }
        }
      `;
      if (unmounted) return;
      console.log(`contactIdCognito:${contactIdCognito}`);
      const response: any = await API.graphql(
        graphqlOperation(qry, {
          contact_id: contactIdCognito,
        })
      );
      if (unmounted) return;
      setCountryList(response.data.getCountries);
      setPersonalInfoData(response.data.getContactsStage0[0]);
    } catch (e) {
      return new Promise((reject) => reject(e));
    }
    return new Promise((resolve) => resolve(true));
  };

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    loadContactsStage0().then(() => {
      setRefreshing(false);
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    loadContactsStage0().then(() => {
      setLoading(false);
    });
    register("contact_id", { value: props.user?.attributes["custom:contact_id"] });
    register("identity_id", { value: props.user?.attributes.sub });

    return () => {
      unmounted = true;
    };
  }, [props.userStatus, props.user]);

  return (
    <>
      <PersonalInfoTitle style={[styles.fluid]} />
      <ScrollView
        keyboardShouldPersistTaps="never"
        style={[
          styles.contentWrapper,
          Platform.OS === "web" && styles.contentWrapperWeb,
        ]}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }
        contentContainerStyle={{ paddingHorizontal: 10 }}
      >

        <Surface
          style={[
            styles.root,
            styles.boxShadow,
            Platform.OS === "web" && styles.rootWeb,
          ]}
        >
          {submitting && (
          <View style={styles.mask}>
            <ProgressBar indeterminate color={theme.colors.accent} />
          </View>
          )}

          <Title style={[styles.title, { color: theme.colors.primary }]}>
            {translate("Personal Information")}
          </Title>

          {!loading && (
            <>
              <FormField errors={errors?.first_name}>
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextInput
                      returnKeyType="next"
                      style={{
                        flex: 1,
                        backgroundColor: "transparent",
                      }}
                      onBlur={onBlur}
                      onChangeText={(ev) => {
                        onChange(ev);
                      }}
                      error={errors.first_name?.message}
                      placeholder={translate("Enter your first name")}
                      maxLength={50}
                      value={value}
                      label={`${translate("First Name")} *`}
                    />
                  )}
                  name="first_name"
                  defaultValue={
                    personalInfoData?.first_name
                      ? personalInfoData?.first_name
                      : ""
                  }
                  rules={{
                    required: {
                      value: true,
                      message: translate("Please enter your first name"),
                    },
                  }}
                />
              </FormField>

              <FormField errors={errors?.last_name}>
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextInput
                      returnKeyType="next"
                      style={{
                        flex: 1,
                        backgroundColor: "transparent",
                      }}
                      onBlur={onBlur}
                      onChangeText={(ev) => {
                        onChange(ev);
                        // autoUpdate();
                      }}
                      placeholder={translate("Enter your Last name")}
                      value={value}
                      maxLength={50}
                      label={`${translate("Last Name")} *`}
                    />
                  )}
                  name="last_name"
                  defaultValue={
                    personalInfoData?.last_name
                      ? personalInfoData?.last_name
                      : ""
                  }
                  rules={{
                    required: {
                      value: true,
                      message: translate("Please enter your last name"),
                    },
                  }}
                />
              </FormField>

              <FormField errors={errors?.country}>
                <Controller
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <PickerCustom
                      style={{ flex: 1 }}
                      value={value}
                      mode="flat"
                      required
                      onChange={(itemValue) => {
                        onChange(itemValue);
                      }}
                      label={translate("Country")}
                      withTheme={false}
                    >
                      <Picker.Item label={translate("Select Country")} value="" />
                      {countryList
                          && countryList
                            .sort((current, next) => {
                              if ((current.country_name).toLowerCase().includes("other")) return 1;
                              if ((next.country_name).toLowerCase().includes("other")) return -1;

                              if (translate(current.country_name) < translate(next.country_name)) return -1;
                              if (translate(current.country_name) > translate(next.country_name)) return 1;
                              return 0;
                            })
                            .map((e, index) => (
                              <Picker.Item
                                key={index.toString()}
                                label={translate(e.country_name)}
                                value={e.country_code}
                              />
                            ))}
                    </PickerCustom>
                  )}
                  name="country"
                  defaultValue={
                    personalInfoData?.country ? personalInfoData?.country : ""
                  }
                  rules={{
                    required: {
                      value: true,
                      message: translate("Please select country"),
                    },
                  }}
                />
              </FormField>

              {/* action button ************************************* */}
              <View style={styles.formActionSection}>
                <TouchableRipple
                  disabled={submitting}
                  onPress={() => onSubmit()}
                  style={[
                    styles.formButton,
                    {
                      backgroundColor: (Object.keys(errors).length === 0 ? theme.colors.accent : theme.colors.disabled),
                    },
                  ]}
                >
                  <View style={{ flexDirection: "row" }}>
                    {submitting && (
                      <ActivityIndicator
                        size={14}
                        color="white"
                        style={{ marginRight: 10 }}
                      />
                    )}
                    <Text style={{ color: "white" }}>{translate("Next")}</Text>
                  </View>
                </TouchableRipple>

                <TouchableRipple
                  disabled={submitting}
                  onPress={() => props.handleSignout()}
                  style={[styles.formButton, styles.formButtonDisabled]}
                >
                  <Text
                    theme={DefaultTheme}
                    style={{ color: theme.colors.primary }}
                  >
                    {translate("Logout")}
                  </Text>
                </TouchableRipple>
              </View>
            </>
          )}
          {loading && <StageLoader style={{ paddingTop: 10 }} />}
        </Surface>

        <View style={[
          Platform.OS === "web" && styles.rootLangSelector,
          { marginTop: Platform.OS === "web" ? -10 : 0, marginLeft: Platform.OS === "web" ? 0 : -20, paddingBottom: 30 }]}
        >
          <LanguageSessionSelector style={{ margin: 0 }} />
        </View>
      </ScrollView>
    </>
  );
});
