/* eslint-disable react/require-default-props */
/* eslint-disable camelcase */
import React, { useEffect } from "react";
import { StyleSheet, Text, View } from "react-native";
import { Avatar, Button, Subheading, Title } from "react-native-paper";
import { useTheme } from "@react-navigation/native";
import color from "color";

type Prop = {
  uic?: String;
  first_name?: String;
  last_name?: String;
  loadUic: () => void;
  // loading: boolean;
  status: Object;
}

const column = [
  { field: "hcpportal_registration_status", label: "HCP Registration Status" },
  { field: "hcp_registration_date", label: "HCP Registration Date" },
  { field: "hcp_registration_approver", label: "HCP Registration Approver" },
  { field: "verification_status", label: "Verification Status" },
  { field: "verification_datetime", label: "Verified at" },
  { field: "verified_by", label: "verified by" },
  { field: "contact_status", label: "Contact Status" }];


const UicInfoComponent = ({
  uic, salutation, first_name, last_name, loadUic, status,
}: Prop) => {
  // const [uicLoading, setUicLoading] = useState(true);
  // const [UicStatus, setUicStatus] = useState({});

  const theme = useTheme();

  let unmounted = false;


  const separatorColor = color(theme.colors.text)
    .alpha(0.11)
    .rgb()
    .string();

  const labelColor = color(theme.colors.text)
    .alpha(0.41)
    .rgb()
    .string();

  useEffect(() => () => {
    unmounted = false;
    // getContactStatus();
    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <>

      <View style={{
        flexDirection: "row", paddingHorizontal: 15, alignItems: "top", marginBottom: 20,
      }}
      >
        <View style={{ marginRight: 10 }}>
          <Avatar.Icon size={40} icon="account" />
        </View>
        <View style={{ flex: 1 }}>
          <Title style={{ marginBottom: 0, lineHeight: 19 }}>{`${first_name || ""} ${last_name || ""}`}</Title>
          <Subheading style={{ marginTop: 0 }}>{uic || ""}</Subheading>
        </View>
      </View>

      <Button mode="outlined" onPress={() => loadUic()}>Load UIC</Button>

      <View style={{ marginVertical: 15 }}>
        <Text>Status</Text>
      </View>

      <View>
        {
                  column.map((obj) => (
                    <View key={obj.field}>
                      <View style={{ flexDirection: "row", height: 36, alignItems: "center" }}>
                        <Text style={{ color: labelColor }}>{obj.label}</Text>
                        <Text style={{ flex: 1, textAlign: "right" }}>{status?.[obj.field] || "-"}</Text>
                      </View>
                      <View style={{ height: StyleSheet.hairlineWidth, backgroundColor: separatorColor }} />
                    </View>
                  ))
                }
      </View>
    </>
  );
};

export default UicInfoComponent;
