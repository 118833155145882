/* eslint-disable camelcase */
import React from "react";
import { View } from "react-native";
import {
  Title,
  Text,
  Avatar,
  useTheme,
  overlay,
  Surface,
  TouchableRipple,
  IconButton,
} from "react-native-paper";
import color from "color";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import styles from "./search.uic.item.styles";
import { translate } from "../../components/Localei18n/languagesFunctions";

type Props = {
  panelist_id: number;
  first_name: string;
  last_name: string;
  uic: string;
  level1_specialty_name: string;
  country_code: string;
  onPress?: (data) => void;
  openBottomSheet: () => void;
  contact_status: string;
  hcpportal_registration_status: String;
  hcp_registration_date: String;
  hcp_registration_approver: String;
  verification_status: String;
  verification_datetime: String;
  verified_by: String;
  hcpportal_last_login: String;
};

const statusColor = {
  active: "#67bd4a",
  inactive: "#fe6032",
  "permanently inactive": "#d91b5c",
  test: "#009bd9",
  verified: "grey",
};


export const SearchUicItem = (props: Props) => {
  const theme = useTheme();

  const mutedContentColor = color(theme.colors.text)
    .alpha(0.7)
    .rgb()
    .string();

  const surfaceOnDark = theme.dark
    ? (overlay(6, theme.colors.surface) as string)
    : theme.colors.surface;

  const shadowColor = color(theme.colors.text)
    .alpha(0.11)
    .rgb()
    .string();

  return (
    <Surface style={[styles.root, { shadowColor, backgroundColor: surfaceOnDark }]}>

      <TouchableRipple
        onPress={() => props.onPress(props)}
      >
        <View style={[styles.container]}>
          <View style={styles.leftColumn}>
            {/* <Avatar.Image source={require("../../assets/Evidera-icon.png")} size={40} /> */}
            <MaterialCommunityIcons name="account-circle" size={40} color={mutedContentColor} />
          </View>
          <View style={styles.rightColumn}>

            <View style={styles.titleRow}>
              <Title numberOfLines={1} style={styles.title}>
                {`${props.uic} - `}
                <Text style={{ fontWeight: "600", color: statusColor[props.contact_status.toLowerCase()] }}>{props.contact_status}</Text>
              </Title>
            </View>

            <View style={{ flexDirection: "row", marginBottom: 2, flexWrap: "wrap" }}>
              <Text style={{ fontSize: 13, color: mutedContentColor }}>HCP Registration: </Text>
              <Text style={{ fontSize: 13 }}>{props.hcpportal_registration_status || "NA"}</Text>
              <Text style={{ fontSize: 13 }}>{props.hcpportal_last_login ? `HCP Last Login: ${props.hcpportal_last_login}` : ""}</Text>
            </View>

            <View style={styles.contentRow}>
              <Text numberOfLines={2} style={{ fontSize: 13, color: mutedContentColor }}>
                {`${props.first_name} ${props.last_name}, `}
                {`Change: ${translate(props.verification_status)} ${props.verification_datetime ? `/${props.verification_datetime}` : ""}`}
              </Text>
              <Text numberOfLines={2} style={{ fontSize: 13, color: mutedContentColor }}>
                {`${translate(props.country_code).toUpperCase()} ∙ ${translate(props.level1_specialty_name)}`}
              </Text>
            </View>

          </View>
          <View>

            <IconButton
              icon="information"
              color="grey"
              size={24}
              style={{ height: 40, width: 24 }}
              onPress={() => props.openBottomSheet()}
            />
          </View>
        </View>
      </TouchableRipple>

    </Surface>
  );
};
