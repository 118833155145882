import React from "react";
import { Image, Platform, StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import { StackActions, useNavigation } from "@react-navigation/native";
import { TouchableRipple } from "react-native-paper";

const styles = StyleSheet.create({
  image: {
    marginTop: 10,
    alignSelf: "center",
  },

});

type Props = {
  style?: StyleProp<ViewStyle>;
}
const AuthLogoHolder = (props: Props) => {
  const navigation = useNavigation();


  return (
    <View style={props.style}>
      <TouchableRipple onPress={() => {
        navigation.navigate("Home");
      }}
      >
        <View>
          <Image
            source={require("../../assets/medimix-evidera-2.png")}
            resizeMode="contain"
            style={[styles.image, {
              height: 45,
              marginLeft: -25,
            },
            (Platform.OS === "web" ? {
              width: 200,
              height: 45,
            } : {}),
            ]}
          />
        </View>
      </TouchableRipple>

    </View>
  );
};

export default AuthLogoHolder;
