import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#FFFFFF",
  },
  userInfoSection: {
    // justifyContent: "center",
    // alignItems: "center",
    paddingTop: 10,
    paddingLeft: 15,
    paddingRight: 15,
  },
  headerImage: {
    height: 140,
  },
  headerWrapper: {
    alignItems: "center",
    flexDirection: "row",
    height: 140,
    // paddingVertical: 20,
    paddingHorizontal: 15,
    // borderBottomWidth: 1,
    // backgroundColor: "#24004c",
  },

  listItemSubheader: {
    fontSize: 15,
    fontWeight: "700",
  },
  wrapperListItemSubheader: {
    paddingHorizontal: 20,
    paddingBottom: 10,
    paddingTop: 10,
  },
  separator: {
    height: 1,
  },
  title: {
    fontWeight: "700",
    textTransform: "capitalize",
    fontSize: 26,
    lineHeight: 28,
    color: "white",
  },
  subtitle: {
    color: "white",
    fontSize: 14,
  },
  listItem: {
    flex: 1,
    flexDirection: "row",
    padding: 20,
  },
  listItemContent: {
    marginLeft: 22,
    flex: 1,
  },
  listItemTitle: {
    fontSize: 16,
    fontWeight: "500",
  },
  listItemSubtitle: {
    fontSize: 12,
  },
  chipDanger: {
    backgroundColor: "#f73636",
  },
});
