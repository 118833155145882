import React, { createContext, useState, useMemo } from "react";

export const ReferenceDataContext = createContext(null);

const ReferenceDataContextProvider = (props) => {
  const [authenticatorData, setAuthenticatorData] = useState({});

  const value = useMemo(() => ({
    authenticatorData,
    setAuthenticatorData,
  }), [authenticatorData]);

  return (
    <ReferenceDataContext.Provider
      value={value}
    >
      {props.children}
    </ReferenceDataContext.Provider>
  );
};

export default ReferenceDataContextProvider;
