/* eslint-disable camelcase */
import React, { useContext, useEffect, useState } from "react";
import { Linking, StyleSheet, Platform, View } from "react-native";
import {
  Text,
  useTheme,
  Title,
  Surface,
  Button,
} from "react-native-paper";
import Color from "color";

import { ScrollView } from "react-native-gesture-handler";
import * as SecureStore from "expo-secure-store";
import { API, graphqlOperation } from "aws-amplify";
import gql from "graphql-tag";
import ContentLoader from "react-native-easy-content-loader";
import secureStoreOptionsKeychain from "../../data/secure-store-keychain";
import { UserInfoContext } from "../../context/userInfo";
import { translate } from "../../components/Localei18n/languagesFunctions";
import Moment from "../../helpers/moment";


const secureStoreOptions = {
  keychainService: secureStoreOptionsKeychain,
  keychainAccessible: SecureStore.ALWAYS, // iOS only
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  container: {
    paddingTop: 25,
  },
  actionContainer: {
    paddingTop: 25,
  },
  title: {
    textTransform: "capitalize",
    fontSize: 14,
    paddingHorizontal: 16,
    paddingBottom: 10,
  },
  caption: { fontSize: 14, textTransform: "capitalize" },
  timeAgo: {
    fontSize: 13,
    lineHeight: 16,
    // backgroundColor: "grey",
  },
  rootInner: {
    marginVertical: 15,
    paddingTop: 15,
    paddingBottom: 20,
    paddingHorizontal: 15,
  },
});

type Props = {

}

export const NotificationDetails = (props: any) => {
  const { language } = useContext(UserInfoContext);
  const [notification, setNotification] = useState<any>({});
  const [loading, setLoading] = useState(true);
  let unmounted = false;
  const theme = useTheme();

  const labelColor = Color(theme.colors.text)
    .alpha(0.67)
    .rgb()
    .string();

  const ShadowColor = Color(theme.colors.text)
    .alpha(1)
    .rgb()
    .string();

  const openUrl = (url) => {
    if (Platform.OS === "web") {
      window.open(url, "hcpportal").focus();
    } else {
      Linking.openURL(url);
    }
  };

  const getNotification = async () => {
    try {
      const qry = gql`
          query ($id: Int!){
            getNotification(id: $id){
              id panelist_id sound title body data created_at send_at link opened_at
            }
          }`;

      const response: any = await API.graphql(graphqlOperation(qry, {
        id: props.route.params.id,
      }));
      if (!unmounted) setNotification(response.data.getNotification);
    } catch (e) {
      console.log(e);
    }

    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    getNotification();
    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <ScrollView style={styles.root}>
      <Surface style={styles.rootInner}>

        {!loading ? (
          <>
            <View>
              <Title>
                {translate(notification?.title)}
              </Title>
              <Text style={styles.timeAgo}><Moment format="MMM D, YYYY" date={notification?.created_at} /></Text>
            </View>

            <View style={styles.container}>
              <Text>
                {translate(notification?.body)}
              </Text>

            </View>

            {(!!notification?.link && notification?.link !== "")
            && (
            <View style={styles.actionContainer}>
              <Button mode="contained" onPress={() => openUrl(notification?.link)}>Open Link</Button>
            </View>
            )}
          </>

        ) : (
          <View>
            <View style={{ marginBottom: 20 }}>

              <ContentLoader
                animationDuration={800}
                active
                pRows={1}
                listSize={1}
                tHeight={20}
                titleStyles={{ marginBottom: 0 }}
                pHeight={12}
                paragraphStyles={{ marginBottom: 0 }}
              />

            </View>
            <ContentLoader
              animationDuration={800}
              active
              title={false}
              pRows={3}
              listSize={1}
              pHeight={12}
              pWidth={[320, 320, 300]}
              paragraphStyles={{ marginBottom: 0 }}
            />
          </View>
        )}

      </Surface>
    </ScrollView>
  );
};
