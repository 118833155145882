import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  root: {
    // marginHorizontal: 10,
    // marginBottom: 10,
    // shadowOffset: { width: 0, height: 0 },
    // shadowOpacity: 0.2,
    // shadowRadius: 0.5,
  },
  container: {
    flexDirection: "row",
    paddingRight: 15,
    paddingTop: 18,
    paddingBottom: 18,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 0.9,
  },
  leftColumn: {
    width: 60,
    alignItems: "center",
  },
  rightColumn: {
    flex: 1, marginRight: 10,
  },
  titleRow: {
    height: 20,
    overflow: "hidden",
    flexDirection: "row",
    marginBottom: 3,
    alignItems: "baseline",
  },
  title: {
    flex: 1,
    lineHeight: 18,
    height: 18,
    fontSize: 14,
    marginRight: 10,
  },
  timeAgo: {
    fontSize: 11,
    lineHeight: 16,
    // backgroundColor: "grey",
  },
  contentRow: {
    flex: 1,
  },
  indicator: {
    height: 10,
    width: 10,
    backgroundColor: "#0099ff",
    alignSelf: "center",
    borderRadius: 5,
  },

});

export default styles;
