/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable camelcase */

import React, { useContext, useEffect, useState } from "react";
import { Platform, View } from "react-native";
import { ProgressBar } from "react-native-paper";
import { WebView } from "react-native-webview";
import { API, graphqlOperation } from "aws-amplify";
import gql from "graphql-tag";
import { StackNavigationProp } from "@react-navigation/stack";
import Auth from "@aws-amplify/auth";
import ExpoReleaseEnvironments from "../../../ExpoReleaseEnvironments";
import UserContext from "../../../context/UserContext";
import { UserInfoContext } from "../../../context/userInfo";
import { StackNavigatorParamlist } from "../../../navigation/types";
// import RenderHTML from "react-native-render-html";

type Props = {
  navigation?: StackNavigationProp<StackNavigatorParamlist>;
  route: { params: any };
}

export const ChatRoom = (props: Props) => {
  const [isReady, setIsReady] = useState(false);
  const [paramsLoaded, setparamsLoaded] = useState(false);
  const [Progress, setProgress] = useState(0);
  const user = useContext(UserContext);
  const userInfo = useContext(UserInfoContext);
  // replace this values with user context data
  const [chatUrlParams, setChatUrlParams] = useState("");

  const { managedUserInfo } = useContext(UserInfoContext);
  const [token, setToken] = useState(`${user?.signInUserSession?.accessToken?.jwtToken}`);
  // const token = `${user?.signInUserSession?.accessToken?.jwtToken}`;
  // console.log(props);

  const getContactsData = async () => {
    const CognitoUser = await Auth.currentAuthenticatedUser({
      bypassCache: true,
    });

    setToken(`${CognitoUser?.signInUserSession?.accessToken?.jwtToken}`);

    const qry = gql`
        query ($email: String!, $countrycode: String!) {
          getContactsInfo_by_email(email: $email){
            panelist_id uic level1_specialty_name default_language_code country_name country_code
          }
          getContacts_numbers(email: $email){
            number country_phone_code primary_number number_type number_id number_type_name number_status_id
          }
          getFW_team_scope(countrycode:$countrycode){
            id livechat_department livechat_theme livechat_online
          }
        }
      `;
    try {
      const response: any = await API.graphql(
        graphqlOperation(qry, {
          email: managedUserInfo.email,
          countrycode: managedUserInfo.country_code,
        })
      );

      const countryAndSpecialty = response.data.getContactsInfo_by_email;
      const country = countryAndSpecialty.country_name.length > 0 ? (countryAndSpecialty.country_name.replace("LNG_Country_", "")).replace(/_/g, "") : "";
      const specialty = countryAndSpecialty.level1_specialty_name.length > 0 ? (countryAndSpecialty.level1_specialty_name.replace("LNG_Level1_", "")).replace(/_/g, "") : "";
      const liveChatDepartment = Object.keys(response.data.getFW_team_scope).length > 0 ? response.data.getFW_team_scope.livechat_department : "fieldwork-en";

      const langSkillArray = {
        "": "English - Written",
        en: "English - Written",
        es: "Spanish - Written",
        pt: "Portuguese - Written",
        de: "German - Written",
      };

      let params = {
        country_code: countryAndSpecialty.country_code,
        country,
        panelistid: countryAndSpecialty.panelist_id,
        uic: countryAndSpecialty.uic,
        specialty,
        preferred_language: (userInfo.language.preferredLanguage).substr(0, 2) || "en",
        email: user.attributes?.email,
        phone: "",
        firstname: userInfo.userInfo.first_name,
        lastname: userInfo.userInfo.last_name,
        langSkill: langSkillArray[liveChatDepartment.substr(10)],
        liveChatDepartment,
      };

      if (langSkillArray[params.preferred_language.substr(0, 2)] !== undefined) {
        params = {
          ...params,
          langSkill: langSkillArray[params.preferred_language],
          liveChatDepartment: `fieldwork-${params.preferred_language}`,
        };
      } else {
        params = {
          ...params,
          preferred_language: liveChatDepartment.substr(10),
        };
      }

      const contactNumber = response.data.getContacts_numbers?.find((e) => e.primary_number === true);
      if (user.attributes?.phone_number === undefined) {
        params = { ...params, phone: `${contactNumber.country_phone_code}${contactNumber.number}` };
      } else {
        params = { ...params, phone: (user.attributes?.phone_number).replace("+", "") };
      }

      return params;
    } catch (e) {
      // console.log("error fetching contacts info: ", e);
      return e;
    }
  };

  useEffect(() => {
    getContactsData().then((params) => {
      setparamsLoaded(false);
      setChatUrlParams(() => `panelistid=${params.panelistid}&uic=${params.uic}&specialty=${params.specialty}&email=${params.email}&country=${params.country}&phone=${params.phone}&country_code=${params.country_code}&preferred_language=${params.preferred_language}`);

      setTimeout(() => {
        setparamsLoaded(true);
      }, 500);
    }).catch((e) => console.log(e));
  }, []);

  if (Platform.OS === "web") {
    return (
      <View style={{ flex: 1, height: "100%" }} />
    );
  }

  return (
    <View style={{ flex: 1 }}>
      {paramsLoaded && (
      <View style={[isReady ? { flex: 1 } : {
        flex: 0, display: "none", height: 0,
        // flex: 1,
      }]}
      >
        <WebView
          source={{
            headers: { Authorization: `Bearer ${token}` },
            uri: `${ExpoReleaseEnvironments().backofficeUrl}genesys/livechat?${chatUrlParams}`,
          }}
          onLoadProgress={({ nativeEvent }) => {
            // loadingProgress = nativeEvent.progress;
            setProgress(nativeEvent.progress);
          }}
          onLoadEnd={(syntheticEvent) => {
            // update component to be aware of loading status
            const { nativeEvent } = syntheticEvent;

            setIsReady(!nativeEvent.loading);
          }}
          onHttpError={(syntheticEvent) => {
            const { nativeEvent } = syntheticEvent;
            if (nativeEvent.statusCode !== 200) {
              props.navigation.goBack();
              props.navigation.push("SendMessage", props.route.params.fallbackProp);
            }
          }}
          style={{ flex: 1 }}
        />
      </View>
      )}
      {!isReady && (
        <View style={{
          flex: 1, alignItems: "center", justifyContent: "center",
        }}
        >
          <ProgressBar progress={Progress + 0} style={{ width: 100 }} />
        </View>
      )}
    </View>
  );
};
