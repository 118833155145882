import React, { useState, useEffect, useContext } from "react";
import { View, StyleSheet, TouchableOpacity, FlatList, Platform } from "react-native";
import { Headline, Text, TextInput, useTheme } from "react-native-paper";
import { API, graphqlOperation } from "aws-amplify";
import gql from "graphql-tag";
import ContentLoader from "react-native-easy-content-loader";
import * as SecureStore from "expo-secure-store";
import { colors } from "../../../theme/variables";
import { FaqModal } from "./FaqModal";
import { translate } from "../../../components/Localei18n/languagesFunctions";
import secureStoreOptionsKeychain from "../../../data/secure-store-keychain";
import { HCPPortalTheme } from "../../../components";
import { UserInfoContext } from "../../../context/userInfo";
import { SessionContext } from "../../../context/SessionContext";
import UserService from "../../../helpers/user.service";
import { sendAnalyticsEvent } from "../../../components/Analytics/analytics";

const secureStoreOptions = {
  keychainService: secureStoreOptionsKeychain,
  keychainAccessible: SecureStore.ALWAYS, // iOS only
};

const styles = StyleSheet.create({
  containerView: {
    flex: 1,
    padding: 10,
  },
  centerText: {
    textAlign: "center",
    marginBottom: 10,
  },
  centerTextInput: {
    textAlign: "center",
  },
  button: {
    marginTop: 20,
  },
  modalWebViewLoading: {
    flex: 1,
    flexDirection: "column",
    paddingTop: 20,
    alignItems: "center",
  },
  buttonCloseModalWebView: {
    paddingTop: 30,
    paddingBottom: 30,
    justifyContent: "flex-start",
    alignItems: "center",
  },
  buttonModalWebView: {
    fontSize: 20,
    color: colors.primaryVioletColor,
  },
  viewModalFaq: {
    flex: 1,
    flexDirection: "column",
    paddingTop: 50,
  },
  flatListFaq: {
    flex: 1,
    flexDirection: "column",
    marginTop: 16,
  },
  viewListFaq: {
    flex: 1,
    flexDirection: "column",
    alignSelf: "stretch",
    borderColor: "rgba(150,150,150,0.2)",
    borderWidth: 1,
    borderRadius: 6,
    marginBottom: 8,
  },
  itemQuestion: {
    padding: 10,
    fontSize: 18,
    height: 54,
  },
  modalTextQuestion: {
    padding: 10,
    fontSize: 22,
  },
  modalTextAnswer: {
    padding: 10,
    fontSize: 18,
  },
});

export const Faq = () => {
  const { handleSessionDeactivation } = useContext(SessionContext);
  const { checkUser } = UserService();

  const [visibleModalFaq, setVisibleModalFaq] = useState(false);
  const [currentFaqItem, setCurrentFaqItem] = useState({ question: "", answer: "" });

  const { language } = useContext(UserInfoContext);
  const [listFaq, setListFaq] = useState([]);
  const [visibleListFaq, setVisibleListFaq] = useState([]);
  const [search, setSearch] = useState("");
  const [loadingList, setLoadingList] = useState(true);

  const showModalFaq = (item) => {
    setVisibleModalFaq(true);
    setCurrentFaqItem(item);
  };
  const hideModalFaq = () => setVisibleModalFaq(false);

  const { colors } = useTheme();

  const runQuery = async () => {
    if (await checkUser() !== true) {
      handleSessionDeactivation();
    }

    try {
      // console.log("FAQ", language);

      const qryListFaq = gql`
        query listFaq {
          listFaq(identifier: "${language.language}") {
            id
            question
            answer
          }
        }
        `;

      const result = await API.graphql(graphqlOperation(qryListFaq));
      const newArray = result.data.listFaq;
      // console.log("FAQ", newArray);
      setLoadingList(false);
      setListFaq(newArray);
      setVisibleListFaq(newArray);
    } catch (err) {
      // We wouldn't actually handle errors like this
      console.log("error: ", err);
    }
  };

  useEffect(() => {
    runQuery();
  }, [language]);

  useEffect(() => {
    sendAnalyticsEvent("Support");
  }, []);

  const onChangeText = (searchInptuText) => {
    const listTmpFaq = listFaq.filter((item) => {
      let found = false;
      if ((item.question).toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(searchInptuText.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))) {
        found = true;
      } else if ((item.answer).toUpperCase().includes(searchInptuText.toUpperCase())) {
        found = true;
      }
      return found;
    });
    setVisibleListFaq(listTmpFaq);
    setSearch(searchInptuText);
  };
  // Analytics.logEvent("Support");
  return (
    <>
      <FaqModal
        currentFaqItem={currentFaqItem}
        visibleModalFaq={visibleModalFaq}
        hideModalFaq={hideModalFaq}
      />
      <View
        style={styles.containerView}
      >
        <Headline style={[styles.centerText, { color: colors.primary }]}>
          {translate("How can we help you")}
          ?
        </Headline>
        <TextInput
          style={styles.centerTextInput}
          label={translate("Search")}
          value={search}
          onChangeText={(text) => onChangeText(text)}
        />
        {loadingList === true ? (
          <>
            <View style={[HCPPortalTheme.sectionHeader, {
              flex: 1, paddingTop: 28, marginLeft: -10,
            }]}
            >
              <ContentLoader
                animationDuration={500}
                active
                pRows={2}
                listSize={1}
                tHeight={15}
                titleStyles={{ marginBottom: 6, borderRadius: 14 }}
                pHeight={15}
                pWidth={[270, 240]}
                paragraphStyles={{ marginBottom: 6, borderRadius: 14 }}
              />
            </View>
          </>
        ) : (
          <FlatList
            style={styles.flatListFaq}
            data={visibleListFaq}
            keyExtractor={(item) => `${item.id}`}
            renderItem={({ item }) => (
              <View style={styles.viewListFaq}>
                <TouchableOpacity onPress={() => showModalFaq(item)}>
                  <Text style={[styles.itemQuestion, { height: "auto" }]}>
                    {item.question}
                    {"\n"}
                    <Text style={[styles.itemQuestion, {
                      paddingTop: 12, fontSize: 13, fontWeight: "bold", lineHeight: 22, color: colors.warn,
                    }]}
                    >
                      LEARN MORE
                    </Text>
                  </Text>
                </TouchableOpacity>
              </View>
            )}
          />
        )}
      </View>
    </>
  );
};
