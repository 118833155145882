import React, { useMemo, useState } from "react";
import * as SecureStore from "expo-secure-store";
import { Platform } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import secureStoreOptionsKeychain from "../data/secure-store-keychain";
import PushNotificationService from "../helpers/push.notification.service";

type UserInfoContextType = {
  userInfo: any,
  managedUserInfo: any,
  language: any,
  environment: "PRODUCTION" | "MAINTENANCE",
  updateInfo: (user) => any,
  updateLanguage: (user) => any,
  updateManageUser: (user) => any,
  isProductionCheck: () => boolean,
  setUserInfo: React.Dispatch<any>,
  setManagedUserInfo: React.Dispatch<any>,
  setEnvironment: React.Dispatch<any>,
  setLanguage: React.Dispatch<any>,

};

type Language = {
  language: string,
  language_name: string, // eslint-disable-line camelcase
  preferredLanguage?: string,
}

export const UserInfoContext = React.createContext<UserInfoContextType>({
  userInfo: {},
  managedUserInfo: {},
  environment: "PRODUCTION",
  language: { language: "en", preferredLanguage: "en", language_name: "English" },
  updateInfo: () => false,
  updateManageUser: () => false,
  updateLanguage: () => false,
  isProductionCheck: () => false,
  setUserInfo: () => {},
  setManagedUserInfo: () => {},
  setEnvironment: () => "PRODUCTION",
  setLanguage: () => {},
});

type UserInfoContextProviderProps = {
  children: any
};


const secureStoreOptions = {
  keychainService: secureStoreOptionsKeychain,
  keychainAccessible: SecureStore.ALWAYS, // iOS only
};


const UserInfoContextProvider = (props: UserInfoContextProviderProps) => {
  const [userInfo, setUserInfo] = useState<any>({});
  const [managedUserInfo, setManagedUserInfo] = useState({});
  const [environment, setEnvironment] = useState<"MAINTENANCE" | "PRODUCTION">("PRODUCTION");
  const [language, setLanguage] = useState<Language>({ language: "en", preferredLanguage: "en", language_name: "English" });

  const {
    registerExpoPushToken,
  } = PushNotificationService();

  function handleLanguageChange(lang: Language) {
    if (Platform.OS === "web") {
      AsyncStorage.setItem("language", lang.preferredLanguage ? JSON.stringify(lang) : "");
    } else {
      SecureStore.setItemAsync("language", lang.preferredLanguage ? JSON.stringify(lang) : "", secureStoreOptions);
    }

    setLanguage(lang);
  }

  function handleManageUserChange(selectedData) {
    return new Promise((resolve, reject) => {
      try {
        setManagedUserInfo(selectedData);

        // SecureStore.setItemAsync("language", selectedData.default_language_code ? JSON.stringify(selectedData) : "", secureStoreOptions);

        if (Platform.OS === "web") {
          AsyncStorage.setItem("lastUserTested", selectedData.email.toString());
        } else {
          SecureStore.setItemAsync("lastUserTested", selectedData.email.toString(), secureStoreOptions);
        }

        handleLanguageChange({
          language: selectedData.default_language_code !== null ? selectedData.default_language_code : "",
          preferredLanguage: selectedData.default_language_code,
          language_name: selectedData.default_language_name,
        });
        registerExpoPushToken(selectedData.panelist_id);
        resolve(true);
      } catch (err) {
        console.log("failed handle Manage User Change", err);
        reject(err);
      }
    });
  }

  function handleUpdateManageUser(selectedData) {
    setManagedUserInfo(selectedData);
  }

  function handleEnvCheck() {
    console.log(environment);

    if (environment === "PRODUCTION") {
      return true;
    }
    // if (Platform.OS === "web") {
    //   alert("Editing is disabled for Test users.");
    // }
    // eslint-disable-next-line no-alert
    alert("Editing is disabled for Test users.");

    return false;
  }

  const value = useMemo(
    () => ({
      updateInfo: handleManageUserChange,
      updateLanguage: handleLanguageChange,
      updateManageUser: handleUpdateManageUser,
      isProductionCheck: handleEnvCheck,
      setUserInfo,
      setManagedUserInfo,
      setEnvironment,
      setLanguage,
      userInfo,
      environment,
      managedUserInfo,
      language,
    }),
    [userInfo, managedUserInfo, environment, language]
  );

  return (
    <UserInfoContext.Provider
      value={value}
    >
      {props.children}
    </UserInfoContext.Provider>
  );
};

export default UserInfoContextProvider;

