import { MaterialCommunityIcons } from "@expo/vector-icons";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { useNavigation } from "@react-navigation/native";
import React, { useEffect } from "react";
import { Platform, View } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { Appbar, useTheme, Text } from "react-native-paper";
import applyScale from "../helpers/image/applyScale";

type Props = {
  goBack: () => void;
  // eslint-disable-next-line react/require-default-props
  showBackButton?: boolean;
  children: any;
}
const AppHeader = (props: Props) => {
  const navigation = useNavigation();
  const theme = useTheme();
  const sizeIcon = applyScale(30);

  useEffect(() => {
    // console.log(props.showBackButton);
  }, [
    // props.showBackButton
  ]);
  return (

    <Appbar.Header
      style={{ elevation: 3, zIndex: 2, position: "relative" }}
      theme={{ colors: { primary: theme.colors.surface } }}
    >
      {props.showBackButton ? (
        <Appbar.BackAction
          onPress={props.goBack} // eslint-disable-line react/prop-types
          color={theme.colors.primary}
        />
      ) : (
        <TouchableOpacity
          style={{ marginLeft: 10 }}
          onPress={() => {
            ((navigation as any) as DrawerNavigationProp<{}>).openDrawer();
          }}
        >
          <MaterialCommunityIcons
            name="menu"
            color={theme.colors.primary}
            size={sizeIcon}
          />
        </TouchableOpacity>
      )}

      <Appbar.Content
        title={(
          <Text style={{ color: theme.colors.primary }} numberOfLines={1}>{props.children}</Text>
        )}
        titleStyle={{
          fontSize: 18,
          height: (typeof (props.children) === "string" ? 30 : 40),
          lineHeight: 30,
          overflow: "visible",
          textAlign: "center",
          color: theme.colors.primary,
          marginLeft: Platform.OS === "ios" ? -15 : -20,
          marginTop: (Platform.OS !== "android") && (typeof props.children !== "string") ? 15 : 0,
        }}
      />
    </Appbar.Header>

  );
};
export default AppHeader;
