/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
import React, { useState, useEffect } from "react";
import {
  View,
  StyleSheet,
  ScrollView,
} from "react-native";
import {
  TextInput,
  Button,
  useTheme,
  IconButton,
} from "react-native-paper";
import Auth from "@aws-amplify/auth";
import { useForm, Controller } from "react-hook-form";
import FormField from "../../components/FormValidator/FormField";
import { HCPPortalTheme } from "../../components";
import { translate } from "../../components/Localei18n/languagesFunctions";
import TextInputPassword from "../../components/TextInputPassword";
import AlertBox from "../../components/Alert";
import CognitoPasswordChecker, { passwordPolicyCheck } from "../../components/CognitoPasswordChecker";
import SignUpHeading from "./SignUp/SignUpHeading";


export const CustomForgotPassword = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [emailSended, setEmailSended] = useState("");
  const [stage, setStage] = useState(0);
  let unmounted = false;

  const uTheme = useTheme();

  const {
    control, watch, trigger, getValues, formState: { errors },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const onSubmit = async () => {
    await trigger();
    if (Object.keys(errors).length === 0) {
      const { email } = getValues();
      if (!unmounted) {
        setLoading(true);

        try {
          const result = await Auth.forgotPassword(email.toLowerCase());
          setEmailSended(email);
          console.log("FORGOT", result);
          setStage(1);
          setLoading(false);
          setError("");
        } catch (er) {
          setLoading(false);
          console.log("FORGOT", er);
          setError(translate(er.message));
        }
      }
    }
  };

  const resendCode = async () => {
    if (!unmounted) {
      setLoading(true);

      try {
        const result = await Auth.forgotPassword(emailSended);
        console.log("FORGOT", result);
        setLoading(false);
      } catch (er) {
        setLoading(false);
        console.log("FORGOT", er);
        setError(translate(er.message));
      }
    }
  };

  const changeState = ($nextStage, data) => {
    if (!unmounted) {
      props.onStateChange($nextStage, data);
    }
  };

  const onSubmitPassword = async () => {
    await trigger();
    if (Object.keys(errors).length === 0) {
      if (!passwordPolicyCheck(getValues("password"), 8)) {
        return;
      }

      const { code, password } = getValues();
      if (!unmounted) {
        setLoading(true);

        try {
          const result = await Auth.forgotPasswordSubmit(emailSended, code, password);

          console.log(result);

          changeState("signIn", "");
          setLoading(false);
        } catch (er) {
          setLoading(false);
          console.log("FORGOT", er);
          setError(translate(er.message));
        }
      } else {
        console.log("FORGOT", 2);
      }
    } else {
      console.log("FORGOT", errors);
    }
  };

  const handleGotoSignIn = () => {
    setError("");
    changeState("signIn", "");
  };

  useEffect(() => () => {
    unmounted = true;
    setLoading(false);

    return () => {};
  }, []);

  return (
    props.authState === "forgotPassword" && (
      <ScrollView
        keyboardShouldPersistTaps="never"
        style={[{ flex: 1, width: "100%" }]}
        contentContainerStyle={[{
          padding: 30,
          minHeight: "100%",
          paddingTop: 10,
          paddingBottom: 20,
        }]}
      >

          { stage === Number(0) && (
            <View>
              <View style={{}}>
                <View style={{
                  position: "relative",
                  marginLeft: -20,
                  zIndex: 1000,
                }}
                >
                  <IconButton
                    icon="arrow-left"
                    color={uTheme.colors.primary}
                    size={30}
                    onPress={handleGotoSignIn}
                    style={{ }}
                  />

                </View>

                <SignUpHeading
                  style={{
                    justifyContent: "center", marginTop: 20, marginBottom: 30,
                  }}
                  title={translate("Reset your password")}
                  subheading={translate("We will send a verification code in your email. Use the code to reset your password.")}
                />

              </View>

              <View>

                <FormField errors={errors?.email}>
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextInput
                        returnKeyType="next"
                        mode="flat"
                        label={translate("Email")}
                        keyboardType="email-address"
                        autoCapitalize="none"
                        placeholder="e.g. john.doe@domain.com"
                        label={translate("Enter your email")}
                        textContentType="emailAddress"
                        onBlur={onBlur}
                        onChangeText={(ev) => {
                          onChange(ev);
                        }}
                        error={errors.email?.message}
                        value={value}
                      />
                    )}
                    name="email"
                    defaultValue=""
                    rules={{
                      required: {
                        value: true,
                        message: translate("Email is mandatory."),
                      },
                      pattern: {
                        value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: translate("Entered value does not match email format"),
                      },
                    }}
                  />
                </FormField>

                <View
                  style={{
                    marginTop: 16,
                    marginBottom: 15,
                    flexDirection: "row",
                  }}
                >
                  <Button
                    compact
                    loading={loading}
                    disabled={loading}
                    mode="contained"
                    style={{ flex: 1 }}
                    onPress={onSubmit}
                    contentStyle={{ paddingVertical: 8 }}
                    color={uTheme.colors.primary}
                  >
                    {translate("Send code")}
                  </Button>
                </View>

              </View>
            </View>
          ) }

          { stage === Number(1) && (
            <>
              <View style={{
                // height: "40%",
              }}
              >
                <View style={{
                  position: "relative",
                  marginLeft: -20,
                  zIndex: 1000,
                }}
                >
                  <IconButton
                    icon="arrow-left"
                    color={uTheme.colors.primary}
                    size={30}
                    onPress={handleGotoSignIn}
                    style={{ }}
                  />

                </View>

                <SignUpHeading
                  style={{ justifyContent: "center", paddingTop: 20, paddingBottom: 20 }}
                  title={translate("Reset your password")}
                  subheading={translate("Enter the code that we sent on your email and your new password.")}
                />

              </View>
              <View>

                <FormField errors={errors?.email}>
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextInput
                        returnKeyType="next"
                        mode="flat"
                        keyboardType="number-pad"
                        label={translate("Code")}
                        placeholder={translate("Enter code")}
                        textContentType="oneTimeCode"
                        onBlur={onBlur}
                        onChangeText={(ev) => {
                          onChange(ev);
                        }}
                        error={errors.email?.message}
                        value={value}
                        dense
                      />
                    )}
                    name="code"
                    defaultValue=""
                    rules={{
                      required: {
                        value: true,
                        message: translate("Code is mandatory."),
                      },
                    }}
                  />
                </FormField>

                <FormField errors={errors?.password}>
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextInputPassword
                        returnKeyType="next"
                        mode="flat"
                        label={translate("New Password")}
                        dense
                        style={{ marginTop: 6 }}
                        onBlur={onBlur}
                        onChangeText={(ev) => {
                          onChange(ev);
                          // console.log(getValues("repeatPassword"));

                          if (getValues("repeatPassword") !== "") trigger("repeatPassword");
                        }}
                        value={value}
                        textContentType="password"
                        placeholder={translate("Enter your new password")}
                        secureTextEntry
                      />
                    )}
                    name="password"
                    defaultValue=""
                    rules={{
                      required: {
                        value: true,
                        message: translate("You must specify a password"),
                      },
                      minLength: {
                        value: 8,
                        message: translate("Minimum 8 characters required"),
                      },
                    }}
                  />
                </FormField>

                <FormField errors={errors?.repeatPassword}>
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextInputPassword
                        mode="flat"
                        label={translate("Repeat Password")}
                        dense
                        style={{ marginTop: 6 }}
                        onBlur={onBlur}
                        onChangeText={onChange}
                        value={value}
                        placeholder={translate("Repeat Password")}
                        textContentType="password"
                        secureTextEntry
                      />
                    )}
                    name="repeatPassword"
                    defaultValue=""
                    rules={{
                      required: {
                        value: true,
                        message: translate("Please retype your new password"),
                      },
                      minLength: {
                        value: 8,
                        message: translate("Minimum 8 characters required"),
                      },
                      validate: (value) => value === watch("password") || translate("Password did not match"),
                    }}
                  />
                </FormField>

                <View
                  style={{
                    marginTop: 5,
                    marginBottom: 15,
                  }}
                >
                  <CognitoPasswordChecker style={{ marginBottom: 18, marginTop: 10 }} min={8} password={watch("password")} />


                  <Button
                    compact
                    loading={loading}
                    disabled={loading}
                    mode="contained"
                    onPress={onSubmitPassword}
                    contentStyle={{ paddingVertical: 8 }}
                    color={uTheme.colors.primary}
                  >
                    {translate("Change Password")}
                  </Button>
                </View>

                <View style={[{
                  marginTop: 10, flexDirection: "row", justifyContent: "center", paddingHorizontal: 5,
                }]}
                >
                  <Button
                    mode="text"
                    onPress={resendCode}
                    contentStyle={{ paddingVertical: 4 }}
                  >
                    {translate("Resend code")}
                  </Button>

                </View>

              </View>
            </>
          ) }

        <AlertBox style={[{ marginTop: 10 }]} type="error">{error}</AlertBox>

      </ScrollView>
    )
  );
};

CustomForgotPassword.defaultProps = {};

export default CustomForgotPassword;
