export const TermsPT = `
<h3 style="text-align: center; margin-top: 25px; margin-bottom: 0;">UTILIZADOR FINAL</h3>
<h4 style="text-align: center; margin-bottom: 40px; margin-top: 8px;">TERMOS E CONDIÇÕES</h4>

<div class="agreement">
  <p class="justify">
  Este contrato (o " Contrato") é celebrado entre Evidera, Ltd., uma empresa britânica, com escritórios em The Ark 201 Talgarth Rd. Hammersmith, Londres W6 8BJ ("Evidera") e a entidade que concorda com os termos do presente contrato ("Utilizador Final"). Este Contrato rege o acesso e a utilização dos Serviços pelo Utilizador Final e será efetivo a partir da Data Efetiva. Este Contrato é válido a partir da data em que o Utilizador Final clica no botão "Aceito" abaixo (a "Data Efetiva"). Se aceitar em nome do Utilizador Final, declara e garante que: (i) tem plena autoridade legal para vincular o seu empregador, ou a entidade aplicável, aos presentes termos e condições; (ii) leu e entendeu o presente Contrato; e (iii) concorda, em nome da parte que representa, com o presente Contrato. Se não tiver autoridade legal para vincular o Utilizador Final, por favor não clique no botão "Aceito" abaixo. Este Contrato rege o acesso e utilização dos Serviços por parte do Utilizador Final.
  </p>
</div>

<div class="flex flex-row">
  <p class="order remove-vertical-margin">1.</p>
  <p class="remove-vertical-margin"><strong>Serviços.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">1.1</p>
  <p class="remove-vertical-margin justify flexAuto">
  Instalações e Transferência de Dados. Todas as instalações utilizadas para armazenamento e processamento de Dados do Utilizador Final respeitarão as normas de segurança razoáveis e cumprirão todas as leis e regulamentos aplicáveis em matéria de proteção de dados, incluindo mas não se limitando às leis nacionais que implementam o Regulamento Geral de Proteção de Dados ("Regulamento") da União Europeia ("UE"), a Lei de Portabilidade e Responsabilização de Seguros de Saúde (Health Insurance Portability and Accountability Act - "HIPAA") e as leis estaduais de violação de segurança nos Estados Unidos, a legislação de proteção de dados adotada por um número crescente de outras jurisdições a nível mundial, e os requisitos de privacidade e confidencialidade das Boas Práticas de Fabricação (ICH Good Clinical Practice - "GCP").
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">1.2</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Alterações.</strong>
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub2">a.</p>
  <p class="remove-vertical-margin justify flexAuto">
  Aos Serviços.  Evidera terá o direito de alterar, suspender ou descontinuar qualquer aspecto do Serviço a qualquer momento, sem aviso prévio. Se a Evidera fizer uma alteração material nos Serviços, a Evidera informará o Utilizador Final.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub2">b.</p>
  <p class="remove-vertical-margin justify flexAuto">
  A este Contrato.  A Evidera reserva-se o direito de alterar ou modificar qualquer dos termos e condições contidas no presente Contrato ou qualquer política que regule o Serviço, a qualquer momento, publicando o novo contrato em https://medimix.net/termsandconditions/ ou o URL que a Evidera possa fornecer. A Evidera notificará o Utilizador Final de quaisquer atualizações a este Contrato. Quaisquer alterações ao presente Contrato se tornarão vinculativas (i) pela aceitação online pelo Utilizador Final dos termos atualizados, ou (ii) após a utilização continuada do Serviço pelo Utilizador Final, depois de tais termos terem sido atualizados pela Evidera.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">2.</p>
  <p class="remove-vertical-margin"><strong>Obrigações do Utilizador Final.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">2.1</p>
  <p class="remove-vertical-margin justify flexAuto">
  Conformidade.  O Cliente utilizará os Serviços de acordo com a Política de Uso Aceitável. A Evidera pode disponibilizar periodicamente novas aplicações, características ou funcionalidades para os Serviços, cuja utilização pode estar condicionada ao acordo do Cliente a termos adicionais.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">2.2</p>
  <p class="remove-vertical-margin justify flexAuto">
  Restrições à Utilização.  A menos que a Evidera concorde especificamente por escrito, o Cliente não o fará, e envidará esforços comerciais adequados para garantir que nenhum terceiro possa: (a) vender, revender, alugar, ou o equivalente funcional os Serviços a terceiros (a menos que expressamente autorizado no presente Contrato); (b) tentar inverter a engenharia dos Serviços ou de qualquer componente; (c) tentar criar um serviço substituto ou similar através da utilização, ou acesso, aos Serviços; (d) utilizar os Serviços para Atividades de Alto Risco; ou (e) utilizar os Serviços para armazenar ou transferir quaisquer Dados de Utilizador Final que sejam controlados para exportação ao abrigo das Leis de Controlo de Exportação. O Utilizador Final é o único responsável por qualquer conformidade aplicável com a HIPAA (Lei de Portabilidade e Responsabilização de Seguros de Saúde).
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">2.3</p>
  <p class="remove-vertical-margin justify flexAuto">
  Políticas e Limites de Utilização.  É permitido ao Utilizador Final ter uma Conta de Utilizador Final. O Utilizador Final deverá cumprir quaisquer políticas e limites de utilização adicionais relativos à utilização do Serviço, tal como solicitado ocasionalmente pela Evidera.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">2.4</p>
  <p class="remove-vertical-margin justify flexAuto">
  Privacidade. O Utilizador Final apenas comunicará informações não identificadas individuais do paciente, provenientes dos registros médicos. Os dados de informação do Utilizador Final serão armazenados em segurança e serão mantidos pela Evidera e tais informações e resultados relacionados poderão ser disponibilizados pela Evidera e pelas suas empresas afiliadas, a partes externas para fins comerciais (incluindo fins de pesquisa comercial), num formato anônimo agregado; e ainda, para evitar qualquer dúvida, tais informações anônimas agregadas e resultados relacionados pertencerão e serão propriedade da Evidera.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">3.</p>
  <p class="remove-vertical-margin justify flexAuto">
  Serviços de Apoio Técnico. Fornece apoio por telefone, por e-mail e através do LifeChat. A periodicidade é de segunda a sexta-feira, exceto nos feriados públicos dos países que prestam o apoio. O apoio é prestado tanto por equipes de trabalho de campo (Brasil e Filipinas) como por agentes localizados na Irlanda, Espanha e Alemanha. Os horários são as horas de expediente da equipe que apoia as seguintes línguas: espanhol para cobrir a América Latina e Espanha (a partir de Espanha e Brasil), português para cobrir o Brasil e Portugal (a partir do Brasil), alemão para a Alemanha, a partir da Alemanha, e o resto do mundo em inglês, a partir das Filipinas e Irlanda.
  <br/>
  Os horários são as horas de expediente da equipa que apoia as seguintes línguas: Espanhol para cobrir a América Latina e Espanha (a partir de Espanha e Brasil), português para cobrir o Brasil e Portugal (a partir do Brasil), alemão para a Alemanha, a partir da Alemanha, e o resto do mundo em inglês, a partir das Filipinas e Irlanda.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">4.</p>
  <p class="remove-vertical-margin"><strong>Suspensão.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">4.1</p>
  <p class="remove-vertical-margin justify flexAuto">
  De Contas de Utilizador Final pela Evidera.  Se a Evidera tomar conhecimento da violação do Contrato por parte de um Utilizador Final, então a Evidera pode Suspender a Conta de Utilizador Final.
  <br/>
  A duração de qualquer Suspensão será mantida até que o Utilizador Final tenha corrigido a infração que causou a Suspensão.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">4.2</p>
  <p class="remove-vertical-margin justify flexAuto">
  Questões de Segurança de Emergência.  Não obstante o precedente, se houver uma Questão de Segurança de Emergência, então a Evidera pode suspender automaticamente a utilização. A suspensão terá a extensão mínima e a duração mínima necessária para evitar ou pôr termo à Questão de Segurança de Emergência.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">5.</p>
  <p class="remove-vertical-margin"><strong>Informação Confidencial.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">5.1</p>
  <p class="remove-vertical-margin justify flexAuto">
  Obrigações.  Cada parte irá: (a) proteger a Informação Confidencial da outra parte com o mesmo nível de cuidado que usa para proteger a sua própria Informação Confidencial; e (b) não revelar a Informação Confidencial, exceto às Afiliadas, empregados e agentes que necessitem de a conhecer e que tenham concordado por escrito em mantê-la confidencial. Cada parte (e quaisquer Afiliados, empregados e agentes a quem tenha divulgado Informações Confidenciais) pode utilizar as Informações Confidenciais apenas para exercer direitos e cumprir as suas obrigações ao abrigo do presente Contrato, ao mesmo tempo que utiliza o cuidado necessário para a proteger. Cada parte é responsável por quaisquer ações das suas Afiliadas, empregados e agentes que violem a presente Cláusula.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">5.2</p>
  <p class="remove-vertical-margin justify flexAuto">
  Exceções.  A Informação Confidencial não inclui informação que: (a) o destinatário da Informação Confidencial já sabia; (b) se torna pública sem que haja culpa por parte do destinatário; (c) foi elaborada independentemente pelo destinatário; ou (d) foi legitimamente dada ao destinatário por outra parte.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">5.3</p>
  <p class="remove-vertical-margin justify flexAuto">
  Divulgação Obrigatória.  Cada parte pode revelar a Informação Confidencial da outra parte quando exigido por lei, mas só depois de: (a) utilizar esforços comercialmente razoáveis para notificar a outra parte; e (b) dar à outra parte a oportunidade de contestar a divulgação.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">6.</p>
  <p class="remove-vertical-margin"><strong>Direitos de Propriedade Intelectual; Características da Marca.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">6.1</p>
  <p class="remove-vertical-margin justify flexAuto">
  Direitos de Propriedade Intelectual.  Exceto conforme aqui expressamente estabelecido, o presente Contrato não concede a nenhuma das partes quaisquer direitos, implícitos ou não, ao conteúdo da outra ou a qualquer propriedade intelectual da outra. 
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">7.</p>
  <p class="remove-vertical-margin"><strong>Representações, Garantias e Reclamações.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">7.1</p>
  <p class="remove-vertical-margin justify flexAuto">
  Representações e Garantias.  Cada parte declara que tem plenos poderes e autoridade para celebrar o Contrato. Cada parte garante que cumprirá todas as leis e regulamentos aplicáveis à sua prestação, ou utilização dos Serviços, conforme aplicável (incluindo a lei de notificação de violação de segurança).
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">7.2</p>
  <p class="remove-vertical-margin justify flexAuto">
  O Utilizador Final deverá abster-se de distribuir qualquer conteúdo que possa ser responsabilizado por ações penais e de violar quaisquer outros direitos de terceiros (particularmente direitos de propriedade intelectual), direito da concorrência ou direitos pessoais gerais, bem como regulamentos de proteção de dados. O Utilizador Final é responsável pelo cumprimento de todas as leis e regulamentos aplicáveis relacionados com o comércio eletrônico.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">7.3</p>
  <p class="remove-vertical-margin justify flexAuto">
  Reclamações.  NA MEDIDA MÁXIMA PERMITIDA PELA LEI APLICÁVEL, EXCETO NOS CASOS EXPRESSAMENTE PREVISTOS NESTE DOCUMENTO, NENHUMA DAS PARTES FAZ QUALQUER OUTRA GARANTIA DE QUALQUER TIPO, EXPRESSA, IMPLÍCITA, ESTATUTÁRIA OU OUTRA, INCLUINDO, SEM LIMITAÇÃO, GARANTIAS DE COMERCIABILIDADE, ADEQUAÇÃO A UMA DETERMINADA UTILIZAÇÃO E DE NÃO VIOLAÇÃO.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">8.</p>
  <p class="remove-vertical-margin"><strong>Cessação.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">8.1</p>
  <p class="remove-vertical-margin justify flexAuto">
  Pelo Utilizador Final.  O Utilizador Final pode interromper a utilização do Serviço em qualquer altura.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">8.2</p>
  <p class="remove-vertical-margin justify flexAuto">
  Pela Evidera.  O Utilizador Final concorda que a Evidera pode, a qualquer momento e por qualquer razão, rescindir o presente Contrato e/ou terminar a prestação de todo ou qualquer parte do Serviço. Não obstante o precedente, a Evidera notificará o Cliente com pelo menos trinta (30) dias de antecedência antes de terminar ou suspender o Serviço; na condição de o Serviço poder ser terminado imediatamente se (i) o Cliente tiver violado o presente Contrato ou (ii) a Evidera determinar com razoabilidade que é comercialmente impraticável continuar a prestar o Serviço à luz das leis aplicáveis.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">8.3</p>
  <p class="remove-vertical-margin justify flexAuto">
  Efeitos da Cessação.  Se este Contrato cessar, então os direitos concedidos por uma parte à outra cessarão imediatamente.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">9.</p>
  <p class="remove-vertical-margin"><strong>Indenização.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">9.1</p>
  <p class="remove-vertical-margin justify flexAuto">
  O Utilizador Final indenizará, defenderá e isentará a Evidera de todas as responsabilidades, danos e custos (incluindo custos de liquidação e honorários de advogados) decorrentes de uma reclamação de terceiros ou relativos à utilização dos Serviços por parte do Utilizador Final em violação da Política de Uso Aceitável. A parte que requer a indenização notificará imediatamente a outra parte da reclamação e cooperará com esta na defesa da reclamação. A parte indenizadora tem total controlo e autoridade sobre a defesa, com exceção de: (a) qualquer acordo que exija que a parte que procura indenização admita responsabilidade ou pague qualquer quantia em dinheiro, exigirá o consentimento prévio por escrito dessa parte, tal consentimento não será retido ou atrasado de forma injustificada; e (b) a outra parte pode juntar-se à defesa com o seu próprio advogado às suas próprias custas.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">10.</p>
  <p class="remove-vertical-margin"><strong>Limitação da Responsabilidade.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">10.1</p>
  <p class="remove-vertical-margin justify flexAuto">
  Limitação da Responsabilidade Indireta.  NENHUMA DAS PARTES SERÁ RESPONSÁVEL NOS TERMOS DESTE CONTRATO POR PERDA DE RECEITAS OU DANOS INDIRETOS, ESPECIAIS, INCIDENTAIS, CONSEQUENCIAIS, EXEMPLARES OU PUNITIVOS, MESMO QUE A PARTE SOUBESSE OU DEVESSE SABER QUE TAIS DANOS ERAM POSSÍVEIS E MESMO QUE OS DANOS DIRETOS NÃO SATISFAÇAM UMA COMPENSAÇÃO.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">10.2</p>
  <p class="remove-vertical-margin justify flexAuto">
  Limitação do Valor da Responsabilidade.  NENHUMA DAS PARTES PODE SER RESPONSABILIZADA AO ABRIGO DESTE CONTRATO EM MAIS DE MIL DÓLARES ($1000 USD).
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">10.3</p>
  <p class="remove-vertical-margin justify flexAuto">
  Exceções às Limitações.  Estas limitações de responsabilidade aplicam-se na medida máxima permitida pela lei aplicável, mas não se aplicam a violações de obrigações de confidencialidade, violações dos direitos de propriedade intelectual de uma parte pela outra, ou obrigações de indenização.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">11.</p>
  <p class="remove-vertical-margin"><strong>Diversos.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.1</p>
  <p class="remove-vertical-margin justify flexAuto">
  Notificações.  A menos que especificado em contrário aqui: a) todas as notificações devem ser feitas por escrito e dirigidas ao departamento jurídico da outra parte e ao principal ponto de contato; e b) a notificação será considerada como tendo sido feita: (i) quando verificado por recibo escrito se enviado por correio, correio noturno, ou quando recebido se enviado por correio sem verificação de recibo; ou (ii) quando verificado por recibo automático ou registros eletrônicos se enviado por fax ou correio eletrônico.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.2</p>
  <p class="remove-vertical-margin justify flexAuto">
  Transferência.  Nenhuma das partes pode ceder ou transferir qualquer parte do presente Contrato sem o consentimento escrito da outra parte, exceto para uma Afiliada, mas apenas no caso de: a) o destinatário concordar por escrito em ficar vinculado aos termos do presente Contrato; e b) a parte cedente permanecer responsável pelas obrigações contraídas nos termos do Contrato antes da transferência. Qualquer outra tentativa de transferência ou cedência é nula.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.3</p>
  <p class="remove-vertical-margin justify flexAuto">
  Alteração de Controle.  Em caso de alteração de controle (por exemplo, através de uma compra ou venda de ações, fusão, ou outra forma de transação corporativa): (a) a parte que sofrer a alteração de controle notificará por escrito a outra parte no prazo de trinta dias após essa alteração; e (b) a outra parte pode rescindir imediatamente o presente Contrato a qualquer momento entre a alteração de controle e trinta dias após receber a notificação por escrito na alínea (a).
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.4</p>
  <p class="remove-vertical-margin justify flexAuto">
  Força Maior.  Nenhuma das partes será responsável pelo incumprimento devido a uma condição (por exemplo, desastre natural, ato de guerra ou terrorismo, motim, condições laborais, greve, ação governamental, e perturbação da Internet) que estava fora do controlo da parte em condições normais.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.5</p>
  <p class="remove-vertical-margin justify flexAuto">
  Não Renúncia.  A incapacidade de cumprir qualquer disposição do presente Contrato não poderá constituir uma renúncia.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.6</p>
  <p class="remove-vertical-margin justify flexAuto">
  Separabilidade.  Se qualquer disposição do presente Contrato for considerada inexequível, o restante conteúdo do Contrato permanecerá plenamente em vigor e com efeito.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.7</p>
  <p class="remove-vertical-margin justify flexAuto">
  Não Dependência.  As partes são contratantes independentes, e este Contrato não cria uma agência, parceria ou joint-venture.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.8</p>
  <p class="remove-vertical-margin justify flexAuto">
  Sem Beneficiários Terceiros.  Não há beneficiários terceiros neste Contrato.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.9</p>
  <p class="remove-vertical-margin justify flexAuto">
  Recurso Gracioso.  Nada no presente Contrato limitará a capacidade de qualquer uma das partes de recorrer a recurso gracioso.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.10</p>
  <p class="remove-vertical-margin justify flexAuto">
  Direito Aplicável.  Este Contrato é regido pela lei da Carolina do Norte, excluindo as regras de escolha de lei desse Estado. PARA QUALQUER LITÍGIO RESULTANTE OU RELACIONADO COM ESTE CONTRATO, AS PARTES CONCORDAM COM A JURISDIÇÃO NO ESTADO DA CAROLINA DO NORTE.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.11</p>
  <p class="remove-vertical-margin justify flexAuto">
  Emendas.  Qualquer emenda deve ser feita por escrito e declarar expressamente que está a alterar o presente Contrato.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.12</p>
  <p class="remove-vertical-margin justify flexAuto">
  Perenidade.  As cláusulas seguintes subsistirão ao termo ou cessação do presente Contrato: Cláusulas 6 (Confidencialidade), 7 (Propriedade Intelectual), 9 (Representações, Garantias e Reclamações), 10 (Cessação), 11 (Indenização), 12 (Limitação de Responsabilidade), e 13 (Diversos).
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.13</p>
  <p class="remove-vertical-margin justify flexAuto">
  Contrato Integral.  O presente Contrato, e todos os documentos aqui referidos, corresponde ao Contrato na sua íntegra celebrado pelas partes relativamente ao seu objeto e substitui quaisquer acordos anteriores ou contemporâneos sobre o assunto. Os termos localizados num URL e referenciados no presente Contrato ficam incorporados por essa referência.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.14</p>
  <p class="remove-vertical-margin justify flexAuto">
  Interpretação de Termos Conflituosos.  Se houver um conflito entre os documentos que compõem este Contrato, os documentos serão tratados na seguinte ordem: o Contrato e os termos localizados em qualquer URL.https://medimix.net/termsandconditions/.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">12.</p>
  <p class="remove-vertical-margin"><strong>Definições.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  "Política de Uso Aceitável" - designa a política de uso aceitável para os Serviços disponíveis no Anexo A do presente documento
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  "Administradores" - designa o pessoal técnico indicado pelo Cliente que administra os Serviços aos Utilizadores Finais.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  "Afiliado" - significa qualquer entidade que, direta ou indiretamente, controla, é controlada por, ou está sob controlo comum com uma das partes.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  "Informação Confidencial" - significa informação divulgada por uma parte à outra parte ao abrigo do presente Contrato que é marcada como confidencial ou que, dadas as circunstâncias, seria normalmente considerada confidencial. Os Dados do Cliente são Informações Confidenciais do Cliente.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  "Dados do Utilizador Final".  designa dados, incluindo correio eletrônico, fornecidos, gerados, transmitidos ou exibidos através dos Serviços por Utilizadores Finais.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  "Questão de Segurança de Emergência".  designa tanto: (a) Utilização dos Serviços pelo Utilizador Final em violação da Política de Uso Aceitável, o que poderia perturbar: (i) os Serviços; (ii) a utilização dos Serviços por outros Utilizadores Finais; ou (iiib) o acesso não autorizado de terceiros aos Serviços.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  "Utilizadores Finais" - designa os indivíduos a quem a Evidera permite a utilização dos Serviços.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  "Conta de Utilizador Final" - é uma conta estabelecida pelo Utilizador Final na Evidera com o objetivo de utilizar os Serviços.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  "Leis de Controlo de Exportação" - designa todas as leis e regulamentos de controlo de exportação e reexportação aplicáveis, incluindo os Regulamentos da Administração de Exportações do Departamento de Comércio dos EUA, as sanções comerciais e econômicas mantidas pelo Gabinete de Controle de Ativos Estrangeiros do Departamento do Tesouro, e a Regulamentação sobre o Comércio Internacional de Armas do Departamento de Estado.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  "HIPAA" - significa Lei de Portabilidade e Responsabilização de Seguros de Saúde de 1996, que pode ser emendada ocasionalmente, e qualquer regulamentação emitida ao abrigo da mesma.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  "Direitos de Propriedade Intelectual" - designa os direitos mundiais, atuais e futuros, ao abrigo do direito de patentes, direito de autor, direito de segredo comercial, direito de marca, direito moral e outros direitos semelhantes.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  "Serviços" – corresponde a “Evidera HCP Portal“ que é uma aplicação de uma etapa para os médicos registrados para facilitar a participação regular nos estudos online da Evidera.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 4px;">
  <p class="remove-vertical-margin justify flexAuto">
  Esta aplicação está disponível em 3 plataformas diferentes:
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  iOS (iPhone e iPad) – Apple Store
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  Android (Smartphones, tablets e Chromebook) – Google Play
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  Web (Windows ou Mac)
  </p>
</div>

<div class="flex flex-row" style="margin-top: 4px;">
  <p class="remove-vertical-margin justify flexAuto">
  Notificações:
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  O Utilizador Final receberá uma notificação assim que um novo estudo estiver disponível.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="remove-vertical-margin justify flexAuto">
  Participações:
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  Rever as participações novas e pendentes.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="remove-vertical-margin justify flexAuto">
  Iniciar Inquérito:
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  Iniciar ou retomar as participações do Utilizador Final.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="remove-vertical-margin justify flexAuto">
  Dados Pessoais:
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  Rever os dados pessoais do Utilizador Final e submeter alterações.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="remove-vertical-margin justify flexAuto">
  Pagamentos:
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  Rever os pagamentos concluídos do Utilizador Final e seguir os pagamentos.
  </p>
</div>
<br/>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="remove-vertical-margin justify flexAuto">
  Tem uma dúvida ou um problema?
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  Consiga assistência através de chat ao vivo ou com a seção de perguntas mais frequentes da Evidera.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  "Suspensão" - significa a impossibilidade imediata de acesso aos Serviços, ou aos componentes dos Serviços, conforme o caso, impedindo a continuação da utilização dos Serviços.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  "Cessação" - significa o termo do Contrato, que terá início na Data Efetiva e continuará até que primeiro ocorra uma destas situações: (i) o fim do último Término de Serviços ou (ii) a cessação do Contrato conforme aqui estabelecido.
  </p>
</div>


<h3 style="text-align: center; margin-top: 50px; margin-bottom: 0;">ANEXO A</h3>
<h4 style="text-align: center; margin-bottom: 30px; margin-top: 8px;">Política de Uso Aceitável </h4>

<div class="flex flex-row">
  <p class="remove-vertical-margin"><strong>ELIGIBILIDADE</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">1.</p>
  <p class="remove-vertical-margin justify flexAuto">
  Só é permitido um registo por participante. A violação pode desqualificá-lo de receber a compensação aplicável e resultar na sua proibição de participar em futuros inquéritos. Se já se registrou anteriormente e apenas precisa de atualizar para um novo endereço de correio eletrônico, por favor inicie sessão e atualize o seu correio eletrônico na seção Conta.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">2.</p>
  <p class="remove-vertical-margin justify flexAuto">
  A falsificação da sua identidade, ou qualquer outra informação aquando do registo ou resposta a inquéritos, privá-lo-á da possibilidade de receber a compensação aplicável e poderá resultar na sua proibição de participar em futuros inquéritos.
  </p>
</div>

<p style="margin-top: 20; margin-bottom: 20;" class="justify">
  <i>Profissionais de Saúde:</i> apenas os profissionais de saúde atualmente em exercício são elegíveis para participar. A falsa declaração das suas credenciais irá desqualificá-lo da obtenção da remuneração aplicável e poderá resultar na proibição da sua participação futura.
</p>

<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">3.</p>
  <p class="remove-vertical-margin justify flexAuto">
  Os convites são pessoais e não transmissíveis, a menos que explicitamente autorizado no convite por e-mail.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">4.</p>
  <p class="remove-vertical-margin justify flexAuto">
  A Evidera tem o direito, a seu exclusivo critério, de convidar ou não potenciais participantes.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">5.</p>
  <p class="remove-vertical-margin justify flexAuto">
  Todas as respostas devem ser verdadeiras e precisas, tanto quanto seja do seu conhecimento; fornecer respostas falsas ou enganosas resultará na perda da remuneração e na proibição de participação futura.
  </p>
</div>


<p class="remove-vertical-margin" style="margin-top: 20px;"><strong>COMPENSAÇÃO</strong></p>
<p class="remove-vertical-margin justify" style="margin-top: 4px; padding-left: 16px;">
Evidera fornecerá uma lista das participações anteriores do Utilizador Final com o montante e donativos, incluindo o saldo global do Utilizador Final.
</p>

<p class="remove-vertical-margin" style="margin-top: 20px;"><strong>TAXAS/IMPOSTOS</strong></p>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">1.</p>
  <p class="remove-vertical-margin justify flexAuto">
  É o único responsável por declarar quaisquer impostos, se devidos, às suas autoridades financeiras locais como resultado da compensação que receber. Reconhece que a Evidera pode fornecer informações a organismos fiscais, ou reter impostos, a pedido desses organismos ou atuando como Evidera considere apropriado. A Evidera não será responsável por quaisquer impostos ou pela prestação de qualquer assistência em matéria fiscal.
  </p>
</div>

<p style="margin-top: 20; margin-bottom: 20;" class="justify">
  <i>Residentes nos EUA:</i> poderá ser-lhe exigido pelas leis federais, estaduais e/ou locais o pagamento de impostos sobre a compensação que receber. Aceita e concorda em fornecer à Evidera quaisquer informações pessoais adicionais através do Formulário W-9 das Finanças, tais como o número de INSS ou CPF, que a Evidera necessita para cumprir com quaisquer obrigações de declaração ou retenção.
</p>
<p style="margin-top: 20; margin-bottom: 20;" class="justify">
  O não fornecimento dessas informações no prazo de 30 dias após um pedido enviado pela Evidera para o endereço principal registrado pode resultar no atraso do pagamento da sua compensação.
</p>

<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">2.</p>
  <p class="remove-vertical-margin justify flexAuto">
  O Utilizador Final toma conhecimento e concorda que qualquer pagamento recebido é o valor justo pela sua participação e o Utilizador Final concorda explicitamente que não é um incentivo para: prescrever qualquer tratamento médico, nem promover ou entregar qualquer produto ou serviço médico, nem modificar de qualquer outra forma o seu comportamento relacionado com a prestação de cuidados.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">3.</p>
  <p class="remove-vertical-margin justify flexAuto">
  Profissionais de Saúde: conforme exigido pelas leis e regulamentos de divulgação em várias jurisdições, a M3 pode ser obrigada a comunicar os pagamentos efetuados ao utilizador pela sua participação na nossa pesquisa de marketing e outros programas. Através da sua participação nestes programas, permite-nos divulgar a remuneração obtida e outros pagamentos aplicáveis, conforme aplicável, para cumprir tais requisitos.
  </p>
</div>

<p style="margin-top: 50px;"></p>
`;