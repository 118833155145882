/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import {
  Dimensions, Image,
  Linking, Platform, StyleSheet, Text, TouchableOpacity, View,
} from "react-native";
import { errorMap } from "../../components";
import { translate } from "../../components/Localei18n/languagesFunctions";
import applyScale from "../../helpers/image/applyScale";
import UserService from "../../helpers/user.service";
import { colors } from "../../theme/variables";
import { AuthContext } from "./AuthContext";
import CustomForgotPassword from "./CustomResetPassword";
import CustomSignIn from "./CustomSignIn";
import ReAuth from "./ReAuth";
import SignUp from "./SignUp";
import UserCheck from "./UserCheck";
import AlertBox from "../../components/Alert";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    alignContent: "center",
  },
  grid: {
    flex: 1,
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  box: {
    paddingTop: "2rem",
  },
  boxBorder: {
    // padding: scaleDisplay(25),
    paddingTop: 5,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#dcdada",
    backgroundColor: "#ffffff",
  },
  image: {
    alignSelf: "center",
  },
  text: {
    width: "100%",
    textAlign: "center",
  },
  textFooterDark: {
    fontSize: 10,
    textAlign: "center",
    width: "100%",
    color: "#828282",
  },
});

const theme = StyleSheet.create({
  sectionHeader: {
    fontSize: 14,
    color: "#828282",
    marginTop: 20,
    marginBottom: 10,
    textAlign: "center",
  },
  sectionFooter: {
    textAlign: "center",
  },
  sectionFooterSecondaryContent: {
    fontSize: 14,
    color: "#828282",
    textAlign: "center",
  },
  container: {
    fontFamily:
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
  },
  button: {
    height: 40,
    width: "100%",
    backgroundColor: "#1a9ea8",
    color: "white",
    border: "none",
    alignItems: "center",
    marginTop: 10,
    marginBottom: 10,
    cursor: "pointer",
    borderRadius: 3,
  },
  hint: {
    fontSize: 14,
    color: "#828282",
    marginTop: 25,
    textAlign: "center",
  },
  inputLabel: {
    paddingTop: 10,
    paddingBottom: 5,
    display: "none",
  },
  label: {
    paddingTop: 10,
  },
  a: {
    marginTop: 15,
    color: colors.primaryBlueColor,
    cursor: "pointer",
  },
  input: {
    height: 25,
    width: "96.5%",
    padding: 5,
    marginTop: 7,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#dcdada",
    borderRadius: 3,
    background: "#efefef",
    marginBottom: 15,
  },
  imageButton: {
    width: 130,
    height: 70,
  },
});

export const Authenticator = (props: any) => {
  const { AuthLanguage } = useContext(AuthContext);
  const [imageWidth, setImageWidth] = useState(300);
  const [imageHeight, setImageHeight] = useState(60);
  const [AuthState, setAuthState] = useState("");
  const [pageState, setPageState] = useState("userCheck");
  const [email, setEmail] = useState("");
  const [pageTrigger, setPageTrigger] = useState("userCheck");
  const { checkUser } = UserService();
  const [userAuthValid, setUserAuthValid] = useState<boolean | undefined>(true);

  const appleID = "1504950824";
  const googlePlay = "https://play.google.com/store/apps/details?id=com.ppd.medimix.hcpportal&hl=en";
  const appleStore = `http://itunes.apple.com/us/app/id${appleID}`;

  const onStateChange = (authState) => {
    setPageState(authState);
    if (authState === "signedIn") {
      checkUser().then((e) => {
        if (e === false) {
          setUserAuthValid(false);
        }
      });
    }
  };

  const onResize = () => {
    if (Dimensions.get("window").width < 500) {
      setImageWidth(applyScale(150));
      setImageHeight(applyScale(60));
    } else {
      setImageWidth(applyScale(300));
      setImageHeight(applyScale(100));
    }
  };

  const openUrl = (url) => {
    if (Platform.OS === "web") {
      window.open(url, "_blank").focus();
    } else {
      Linking.openURL(url);
    }
  };

  useEffect(() => {
    setImageWidth(applyScale(300));
    setImageHeight(applyScale(100));
    Dimensions.addEventListener("change", onResize);
    onResize();
    return () => {
      Dimensions.removeEventListener("change", onResize);
    };
  }, [AuthState, AuthLanguage]);

  return (

    <View style={styles.box}>
      {props.showMessage && (
      <AlertBox
        animateTime={300}
        style={[{
          marginBottom: 20,
          borderRadius: 3,
          backgroundColor: "#FF9800",
        },
        Dimensions.get("window").width < 850 ? { marginHorizontal: 32 } : {},
        Dimensions.get("window").width > 850 && { maxWidth: 335 }]}
        type="warning"
      >
        {translate("You we're logged out.")}
        {" "}
        {"\n"}
        {props.showMessage}
      </AlertBox>
      )}

      <View
        style={[
          styles.boxBorder,
          Dimensions.get("window").width < 850 ? { borderColor: "white" } : {},
          Dimensions.get("window").width > 850 && { maxWidth: 335 },
        ]}
      >


        <View>
          <>{(userAuthValid === false && AuthState === "signedIn") && (<ReAuth />)}</>
          <UserCheck authState={pageState} setEmail={setEmail} onStateChange={onStateChange} />
          <SignUp authState={pageState} refreshCurrentUser={props.refreshCurrentUser} email={email} setEmail={setEmail} pageTrigger={pageTrigger} onStateChange={onStateChange} />
          <CustomForgotPassword authState={pageState} onStateChange={onStateChange} />
          <CustomSignIn authState={pageState} email={email} setEmail={setEmail} setPageTrigger={setPageTrigger} onStateChange={onStateChange} />
        </View>
      </View>

      <View style={[styles.grid, { marginTop: 20 }]}>
        <Text style={styles.text}>{translate("Get the app.")}</Text>
        <TouchableOpacity onPress={() => openUrl(appleStore)}>
          <Image
            source={require("../../assets/apple_store_croped.png")}
            resizeMode="contain"
            style={theme.imageButton}
          />
        </TouchableOpacity>
        <TouchableOpacity onPress={() => openUrl(googlePlay)}>
          <Image
            source={require("../../assets/google_play_croped.png")}
            resizeMode="contain"
            style={theme.imageButton}
          />
        </TouchableOpacity>
      </View>
      <View style={{ marginTop: 20 }}>
        <Text style={styles.textFooterDark}>
          {translate("Apple and the App Store logo are trademarks of Apple Inc.")}
        </Text>
        <Text style={styles.textFooterDark}>
          {translate("Google Play and the Google Play logo are trademarks of Google LLC.")}
        </Text>
      </View>
    </View>

  );
};
