import React, { useState, useCallback, useEffect, useRef } from "react";
import { View, KeyboardAvoidingView, Platform, ScrollView, TouchableWithoutFeedback, Alert, LogBox } from "react-native";
import { useForm, Controller } from "react-hook-form";
import { TextInput, useTheme, Title, Subheading, Text, Button, Snackbar, Switch, ActivityIndicator } from "react-native-paper";
import debounce from "lodash/debounce";
// eslint-disable-next-line import/no-extraneous-dependencies
import { API, graphqlOperation } from "aws-amplify";
// eslint-disable-next-line import/no-extraneous-dependencies
import Auth from "@aws-amplify/auth";
import gql from "graphql-tag";
// eslint-disable-next-line no-unused-vars
import { StackNavigationProp } from "@react-navigation/stack";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import PhoneInput from "../../../components/PhoneInput";
import { styles } from "./forms.style";
import { ProfileParamlist } from "../types"; // eslint-disable-line no-unused-vars
import { translate } from "../../../components/Localei18n/languagesFunctions";
import ConfirmDialog from "../../Auth/components/ConfirmDialog";
import { CountryPicker } from "../../Auth/components/CountryPicker";
import FormField from "../../../components/FormValidator/FormField";
import VerifyNumber from "./profile.contacts.number.verify";

type Props = {
  // eslint-disable-next-line react/require-default-props
  navigation?: StackNavigationProp<ProfileParamlist>;
  route: any;
};


const EditContactsNumber = (props: Props) => {
  const theme = useTheme();

  const {
    control, register, getValues, setValue, trigger, formState: { errors },
  } = useForm();

  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [inputData, setInputData] = useState({
    ...props.route.params,
    formattedNumber: `+${props.route.params.country_phone_code}${props.route.params.number}`,
  }); // eslint-disable-line react/prop-types

  const [verificationMessage, setVerificationMessage] = useState("");
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openVerify, setOpenVerify] = useState(false);
  const [SendingCode, setSendingCode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [countryPickerIsVisible, setCountryPickerIsVisible] = useState(false);
  const phoneInput = useRef<PhoneInput>(null);

  if (Platform.OS !== "web") {
    LogBox.ignoreLogs([
      "Non-serializable values were found in the navigation state",
    ]);
  }

  let unmounted = false;

  const [confirmDialogOptions, setConfirmDialogOptions] = useState({
    visible: false,
    title: null,
    message: null,
    confirmFn: null,
    cancelFn: null,
  });

  const hideDialog = () => {
    setConfirmDialogOptions({ ...confirmDialogOptions, visible: false });
  };

  const onSubmit = async (data: object) => {
    await trigger();

    if (Object.keys(errors).length >= 1) {
      return;
    }

    const mutation = gql`
      mutation updateNumber($formValue: FormContactsNumbersInput, $panelist_id: Int!, $status: Int, $uic: String){
        insertIgnoreFormsContacts(panelist_id: $panelist_id)
        insertIgnoreForsmContactsNumbers(panelist_id: $panelist_id)
        updateVerificationStatus(status: $status, panelist_id: $panelist_id, uic: $uic)
        updateFormNumber(formValue: $formValue)
      }
    `;

    try {
      if (data?.phoneNumber) delete data?.phoneNumber;
      const response = await API.graphql(graphqlOperation(mutation, {
        formValue: { ...data, number_5last: (data.number).substr(data.number.length - 5, data.number.length), primary_number: data.primary_number ? 1 : 0 },
        panelist_id: inputData.panelist_id,
        status: 2,
        uic: inputData?.uic,
      }));

      if (response.data.updateFormNumber !== true) {
        setMessage(translate("Failed to update number."));
      }
    } catch (err) {
      console.log(err);

      setMessage(translate("Failed to update number."));
      setSnackbarVisible(true);
      // console.log(["failed to update contacts", err, { ...data, number_5last: (data.number).substr(data.number.length - 5, data.number.length), primary_number: data.primary_number ? 1 : 0 }]);
    }
  };

  const autoUpdate = useCallback(debounce(() => {
    if (Object.keys(errors).length === 0) {
      if (!unmounted) {
        onSubmit(getValues());
        // Analytics.logEvent("PhoneNumberUpdated");
      }
    }
  }, 500, { leading: false }), []);


  const handleDelete = () => {
    if (Platform.OS === "web") {
      setDeleteLoading(true);
      hideDialog();
      props.route.params.onDelete()
        .then((res) => {
          if (res === true) {
            // Analytics.logEvent("PhoneNumberUpdated");
            setDeleteLoading(false);
            props.navigation.goBack();
          } else if (res === false) {
            setMessage(translate("Failed to delete number."));
            setSnackbarVisible(true);
          }
        });
    } else {
      Alert.alert(
        translate("Confirm delete"),
        translate("Delete this Number?"),
        [
          {
            text: translate("Cancel"),
            onPress: () => {},
            style: "cancel",
          },
          {
            text: translate("Delete"),
            onPress: () => {
              setDeleteLoading(true);
              props.route.params.onDelete()
                .then((res) => {
                  if (res === true) {
                    // Analytics.logEvent("PhoneNumberUpdated");
                    setDeleteLoading(false);
                    props.navigation.goBack();
                  } else if (res === false) {
                    setMessage(translate("Failed to delete number."));
                    setSnackbarVisible(true);
                  }
                });
            }
            ,
          },
        ],
        { cancelable: false }
      );
    }
  };

  const sendCode = async () => {
    setSendingCode(true);
    setOpenVerify(true);

    const completeNumber = `+${inputData.country_phone_code}${inputData.number}`;
    const formattedCompleteNumber = completeNumber.replace(/[\- ]+/g, "");

    setVerificationMessage(`${(translate("Sending Verification Code to"))} ${completeNumber}`);

    await Auth.currentAuthenticatedUser()
      .then(async (cognito) => {
        await Auth.updateUserAttributes(cognito, {
          phone_number: formattedCompleteNumber,
        });
      });


    // console.log(`sending code to ${getValues("phoneNumber")}`);

    await Auth.verifyCurrentUserAttribute("phone_number")
      .then(() => {
        setVerificationMessage((`${(translate("We sent a verification code to your number"))} ${completeNumber}`));
      })
      .catch((e) => {
        setVerificationMessage((`${translate("Failed to send verificaiton code.")}\n${e.message}`));
      });
    // setVerificationMessage((`${translate("We sent a verification code to your number")} ${getValues("phoneNumber")}`));

    setSendingCode(false);
    return true;
  };

  useEffect(() => {
    let unmount = false;
    register("panelist_id", { value: inputData.panelist_id });
    register("contact_id", { value: inputData.contact_id });
    register("number_id", { value: inputData.number_id });
    register("number_type", { value: 2 });
    register("number", { value: inputData.number });
    register("country_phone_code", { value: inputData.country_phone_code });
    setLoading(false);

    return () => {
      unmount = true;
      unmounted = true;
    };
  }, []);


  const cancelSetNumberAsPrimary = () => {
    setValue("primary_number", false);
    hideDialog();
  };

  const handleSetNumberAsPrimary = (as_primary) => {
    setInputData({ ...inputData, primary_number: as_primary });
    autoUpdate();
    setConfirmDialogOptions({ ...confirmDialogOptions, visible: false });
  };

  const setNumberAsPrimaryHandler = (as_primary) => {
    if (as_primary) {
      setConfirmDialogOptions({
        ...confirmDialogOptions,
        visible: true,
        title: translate("Confirm"),
        message: translate("Make this number primary?"),
        confirmFn: () => handleSetNumberAsPrimary(as_primary),
        cancelFn: cancelSetNumberAsPrimary,
      });
    }
  };

  const countryData = phoneInput.current?.getPickerData();

  const selectCountryHandler = (country) => {
    phoneInput.current?.selectCountry(country.iso2);
    setTimeout(() => {
      setCountryPickerIsVisible(false);
    }, 300);
  };

  const dismissCountryPicker = () => {
    setCountryPickerIsVisible(false);
  };

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={[
        styles.root,
        { backgroundColor: theme.colors.background },
      ]}
    >
      <ScrollView style={styles.scrollWrapper}>
        {!openVerify ? (
          <View style={[styles.scrollInnerResponsive, { flex: 1 }]}>
            <View style={styles.titleWRapper}>
              <Title style={styles.title}>{translate("Update Number")}</Title>
              <Subheading style={styles.subheadingRegular}>
                {translate("Any changes to your number is subject for verification.")}
              </Subheading>
            </View>

            {(Platform.OS !== "ios" && countryPickerIsVisible)
            && (
              <CountryPicker
                countries={countryData}
                dismissModal={dismissCountryPicker}
                setCountry={selectCountryHandler}
              />
            )}

            {loading ? (
              <View>
                <ActivityIndicator size={40} />
              </View>
            )
              : (

                <>
                  <FormField errors={errors?.phoneNumber}>
                    <Controller
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <PhoneInput
                          ref={phoneInput}
                          defaultCode="US"
                          onChangePhoneNumber={(number) => {
                            onChange(number);
                            setValue("number", number.replace(`+${phoneInput.current?.getCountryCode()}`, ""));
                            setValue("country_phone_code", phoneInput.current?.getCountryCode());
                            setInputData({
                              ...inputData,
                              number: number.replace(`+${phoneInput.current?.getCountryCode()}`, ""),
                              country_phone_code: phoneInput.current?.getCountryCode(),
                            });
                          }}
                          value={value}
                          autoFormat={false}
                          textComponent={TextInput}
                          textProps={{ mode: "flat", style: { height: 40 }, editable: inputData?.number_status_id !== 2 }}
                          allowZeroAfterCountryCode
                          withDarkTheme
                          onSelectCountry={() => {
                          // phoneInput.current.selectCountry(iso2);
                            setValue("country_phone_code", phoneInput.current?.getCountryCode());
                            setValue("phoneNumber", `+${phoneInput.current?.getCountryCode()}${getValues("number")}`);
                            setInputData({
                              ...inputData,
                              number: inputData.number?.replace(`+${phoneInput.current?.getCountryCode()}`, ""),
                              country_phone_code: phoneInput.current?.getCountryCode(),
                            });
                          }}
                          {...(Platform.OS !== "ios" ? { onPressFlag: () => setCountryPickerIsVisible(true) } : {})}
                        />
                      )}
                      name="phoneNumber"
                      defaultValue={`+${inputData?.country_phone_code || ""}${inputData?.number || ""}`}
                      rules={{
                        required: { value: true, message: "Please enter your mobile number." },
                        validate: () => {
                          if (!phoneInput.current?.isValidNumber()) {
                            return "Invalid mobile number";
                          }
                          return true;
                        },
                      }}
                    />
                  </FormField>
                  {
                  true ? (
                    <FormField style={{ paddingLeft: 42 }}>
                      <Controller
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <>
                            {inputData.primary_number
                              ? (
                                <View style={{ flexDirection: "row", alignItems: "center" }}>
                                  <MaterialCommunityIcons name="check-bold" size={24} color={theme.colors.primary} />
                                  <Text style={{ fontSize: 18, fontWeight: "700", color: theme.colors.primary }}>
                                    {translate("Primary Number")}
                                  </Text>
                                </View>
                              )
                              : (
                                <>
                                  <TouchableWithoutFeedback onPress={() => {
                                    if (Platform.OS === "web") {
                                      setNumberAsPrimaryHandler(!value);
                                      onChange(!value);
                                      return;
                                    }
                                    console.log("change parimary");

                                    Alert.alert(
                                      translate("Confirm"),
                                      translate("Make this number primary?"),
                                      [
                                        {
                                          text: translate("Cancel"),
                                          onPress: () => { },
                                          style: "cancel",
                                        },
                                        {
                                          text: translate("Set as Primary"),
                                          onPress: () => {
                                            setInputData({ ...inputData, primary_number: !value });
                                            onChange(!value);
                                            autoUpdate();
                                          }
                                          ,
                                        },
                                      ],
                                      { cancelable: false }
                                    );
                                  }}
                                  >
                                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                                      <View pointerEvents="none">
                                        <Switch
                                          color={theme.colors.primary}
                                          value={value}
                                        />
                                      </View>
                                      <Text style={{ fontSize: 17, fontWeight: "500", marginLeft: 12 }}>{translate("Set as Primary")}</Text>
                                    </View>
                                  </TouchableWithoutFeedback>
                                </>
                              )}
                          </>

                        )}
                        name="primary_number"
                        defaultValue={inputData.primary_number}
                      />
                    </FormField>
                  ) : (
                    <View style={{ flexDirection: "row", alignItems: "flex-end", paddingLeft: 42 }}>
                      <Text style={{ flex: 1, flexWrap: "wrap", fontSize: 17 }}>
                        {translate("Verify and set this number to primary? ")}

                        <TouchableWithoutFeedback
                          disabled={SendingCode}
                          onPress={() => sendCode()}
                          style={{}}
                        >
                          <Text style={{
                            color: theme.colors.primary, fontSize: 17, fontWeight: "bold",
                          }}
                          >
                            {translate("Verify")}
                          </Text>

                        </TouchableWithoutFeedback>
                      </Text>
                    </View>
                  )
                }


                  <View style={[styles.formActionField, { paddingTop: 40, paddingBottom: 20 }]}>
                    <Button
                      disabled={inputData.primary_number || !!deleteLoading}
                      onPress={Platform.OS === "web" ? () => setConfirmDialogOptions({
                        ...confirmDialogOptions,
                        visible: true,
                        title: translate("Confirm Delete"),
                        message: translate("Delete this number?"),
                        confirmFn: handleDelete,
                        cancelFn: hideDialog,
                      }) : handleDelete}
                      contentStyle={[styles.button]}
                      dark
                      color="red"
                      loading={deleteLoading}
                    >
                      {translate("Delete")}
                    </Button>
                  </View>
                </>

              )}
          </View>
        ) : (
          <View style={[styles.scrollInnerResponsive]}>
            <VerifyNumber
              data={{ ...(getValues()) }}
                // data={{
                //   contact_id: "718",
                //   country_phone_code: "63",
                //   number: "09216266523",
                //   number_type: 2,
                //   panelist_id: 2452006,
                //   primary_number: 0,
                //   number_id: 3391932,
                // }}
              SendingCode={SendingCode}
              goBack={() => props.navigation.goBack()}
              sendCode={sendCode}
              message={verificationMessage}
              setMessage={setMessage}
              setOpenVerify={setOpenVerify}
              openSnackbar={props.route.params.onOpenSnackbar}
            />
          </View>
        )}
      </ScrollView>

      <ConfirmDialog
        visible={confirmDialogOptions.visible}
        cancelEvt={confirmDialogOptions.cancelFn}
        confirmEvt={confirmDialogOptions.confirmFn}
        title={confirmDialogOptions.title}
        message={confirmDialogOptions.message}
      />

      <Snackbar
        visible={snackbarVisible}
        onDismiss={() => setSnackbarVisible(false)}
        duration={5000}
        action={{
          label: "×",
          onPress: () => {
            setSnackbarVisible(false);
          },
        }}
      >
        {message}
      </Snackbar>
    </KeyboardAvoidingView>
  );
};

export default EditContactsNumber;
