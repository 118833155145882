export const TermsIT = `
<h3 style="text-align: center; margin-top: 25px; margin-bottom: 0;">UTENTE FINALE</h3>
<h4 style="text-align: center; margin-bottom: 40px; margin-top: 8px;">TERMINI E CONDIZIONI </h4>

<div class="agreement">
  <p class="justify">
  Il presente Accordo (l '"Accordo") &egrave; stipulato da e tra Evidera, Ltd., una societ&agrave; del Regno Unito, con uffici in The Ark 201 Talgarth Rd. Hammersmith, London W6 8BJ ("Evidera") e l'entit&agrave; che accetta i termini del presente documento ("Utente Finale"). Il presente Accordo regola l'accesso e l'utilizzo dei servizi da parte dell'Utente Finale ed entrer&agrave; in vigore dalla data effettiva. Il presente Accordo entra in vigore dalla data in cui l'Utente Finale clicca sul pulsante "Accetto" sotto (la "data effettiva"). Se accetta per conto dell'Utente Finale, dichiari e garantisca che: (i) ha piena autorit&agrave; legale per vincolare il suo datore di lavoro, o l'entit&agrave; applicabile, a questi termini e condizioni; (ii) ha letto e compreso il presente Accordo; e (iii) accetta, a nome della parte che rappresenta, il presente Accordo. Se non si dispone dell'autorit&agrave; legale per vincolare l'Utente Finale, non clicchi sul sottostante pulsante "Accetto". Il presente Accordo regola l'accesso e l'utilizzo dei Servizi da parte dell'Utente Finale.
  </p>
</div>

<div class="flex flex-row">
  <p class="order remove-vertical-margin">1.</p>
  <p class="remove-vertical-margin"><strong>Servizi.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">1.1</p>
  <p class="remove-vertical-margin justify flexAuto">
  Infrastrutture e trasferimento dati. Tutte le infrastrutture utilizzate per archiviare ed elaborare i dati degli utenti finali aderiranno a standard adeguati di sicurezza e rispetteranno tutte le leggi e i regolamenti applicabili sulla privacy dei dati, incluse ma non limitate alle leggi nazionali che implementano il regolamento generale sulla protezione dei dati dell'Unione Europea (" "), L'Health Insurance Portability and Accountability Act (" HIPAA ") e le leggi sulla violazione della sicurezza dello stato negli Stati Uniti, la legislazione sulla protezione dei dati adottata da un numero crescente di altre giurisdizioni a livello globale e i requisiti di privacy e Riservatezza di ICH Good Clinical Practice (" GCP ").
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">1.2</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Modifiche.</strong>
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub2">a.</p>
  <p class="remove-vertical-margin justify flexAuto">
  Ai servizi. Evidera avr&agrave; il diritto di modificare, sospendere o interrompere qualsiasi aspetto del Servizio in qualsiasi momento, senza preavviso. Se Evidera apporta una modifica sostanziale ai Servizi, Evidera informer&agrave; l'Utente Finale.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub2">b.</p>
  <p class="remove-vertical-margin justify flexAuto">
  A questo Accordo. Evidera si riserva il diritto di cambiare o modificare in qualsiasi momento i termini e le condizioni contenuti nel presente Accordo o qualsiasi normativa che regola il Servizio, pubblicando il nuovo accordo su https://medimix.net/termsandconditions/ o URL che Evidera fornir&agrave;. Evidera notificher&agrave; all'Utente Finale eventuali aggiornamenti al presente Accordo. Qualsiasi cambiamento o modifica al presente Accordo diventer&agrave; vincolante (i) dall'accettazione online da parte dell'Utente Finale dei termini aggiornati, o (ii) dopo che l'Utente Finale continuer&agrave; ad utilizzare il Servizio dopo che tali termini sono stati aggiornati da Evidera.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">2.</p>
  <p class="remove-vertical-margin"><strong>EObblighi dell'Utente Finale.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">2.1</p>
  <p class="remove-vertical-margin justify flexAuto">
  Conformit&agrave;. Il Cliente utilizzer&agrave; i Servizi in conformit&agrave; con le norme sull&rsquo;uso accettabile. Evidera pu&ograve; rendere disponibili di volta in volta nuove applicazioni, caratteristiche o funzionalit&agrave; per i Servizi, il cui utilizzo pu&ograve; essere subordinato all'accettazione di termini aggiuntivi da parte del Cliente.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">2.2</p>
  <p class="remove-vertical-margin justify flexAuto">
  Restrizioni d'uso. A meno che Evidera non sia espressamente d'accordo per iscritto, il Cliente non
      far&agrave; e utilizzer&agrave; sforzi commercialmente ragionevoli per assicurarsi che una terza parte non: (a)
      venda, rivenda, noleggi o l'equivalente funzionale, i Servizi a terzi (se non espressamente autorizzato nel
      presente Accordo); (b) tentare di decodificare i Servizi o qualsiasi componente; (c) tentare di creare un servizio
      sostitutivo o simile tramite l'uso o l'accesso ai Servizi; (d) utilizzare i Servizi per attivit&agrave; ad alto
      rischio; o (e) utilizzare i Servizi per archiviare o trasferire i Dati degli utenti finali controllati per
      l'esportazione ai sensi delle leggi sul controllo delle esportazioni. L'Utente Finale &egrave; l'unico
      responsabile per qualsiasi conformit&agrave; applicabile con HIPAA.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">2.3</p>
  <p class="remove-vertical-margin justify flexAuto">
      Politiche e limiti di utilizzo. All'Utente Finale &egrave; consentito uno (1) account Utente Finale.
      L'Utente Finale dovr&agrave; rispettare eventuali politiche e limiti di utilizzo aggiuntivi relativi all'uso del
      Servizio come richiesto di volta in volta da Evidera.
      </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">2.4</p>
  <p class="remove-vertical-margin justify flexAuto">
  Privacy. L'Utente Finale riporter&agrave; solo le informazioni a livello di singolo paziente anonimo
      provenienti da cartelle cliniche. Le informazioni sui dati dei rapporti dell'Utente Finale saranno archiviate in
      modo sicuro e saranno mantenute da Evidera e tali informazioni e i relativi risultati possono essere concessi in
      licenza o altrimenti commercializzati da Evidera e dalle sue societ&agrave; Affiliato, a parti esterne per scopi
      commerciali (inclusi scopi di ricerca commerciale), in un formato anonimo aggregato; e inoltre, a scanso di
      equivoci, tali informazioni anonime aggregate e relativi risultati apparterranno e saranno di propriet&agrave; di
      Evidera.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">3.</p>
  <p class="remove-vertical-margin justify flexAuto">
  I servizi di Supporto Tecnico sono forniti per telefono, e-mail e con LifeChat. La frequenza &egrave; dal
      luned&igrave; al venerd&igrave;, esclusi i giorni festivi nei paesi che gestiscono il supporto. In Brasile e nelle
      Filippine &egrave; gestito da personale locale e da agenti con sede in Irlanda, Spagna e Germania. L&rsquo;orario
      di lavoro del team che supporta le seguenti lingue: spagnolo per coprire l'America Latina e la Spagna (da Spagna e
      Brasile), portoghese per coprire Brasile e Portogallo (dal Brasile), tedesco per la Germania dalla Germania e il
      resto del mondo in Inglese dalle Filippine e dall'Irlanda.
  <br/>
  <br/>
  L&rsquo;orario di lavoro del team che supporta le seguenti lingue: spagnolo per coprire l'America Latina e la
    Spagna (da Spagna e Brasile), portoghese per coprire Brasile e Portogallo (dal Brasile), tedesco per la Germania
    dalla Germania e il resto del mondo in Inglese dalle Filippine e dall'Irlanda.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">4.</p>
  <p class="remove-vertical-margin"><strong>Sospensione.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">4.1</p>
  <p class="remove-vertical-margin justify flexAuto">
  Degli account degli Utenti Finali di Evidera. Se Evidera viene a conoscenza di una violazione dell'Accordo
      da parte di un Utente Finale, Evidera pu&ograve; sospendere l'Account dell'Utente Finale. La durata di qualsiasi
      sospensione sar&agrave; fino a quando l'Utente Finale non avr&agrave; sanato la violazione che ha causato la
      sospensione.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">4.2</p>
  <p class="remove-vertical-margin justify flexAuto">
  Problemi urgenti di sicurezza. Nonostante quanto sopra, in caso di problemi urgenti di sicurezza,Evidera
      pu&ograve; sospendere automaticamente l'uso illecito. La sospensione sar&agrave; nella misura minima e della
      durata minima necessaria per prevenire o porre fine all&rsquo;urgenza
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">5.</p>
  <p class="remove-vertical-margin"><strong>Informazioni Riservate.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">5.1</p>
  <p class="remove-vertical-margin justify flexAuto">
      Obblighi. Ciascuna parte: (a) protegger&agrave; le informazioni Riservate dell'altra parte con lo stesso
      standard di cura che utilizza per proteggere le proprie Informazioni Riservate; e (b) non divulgar&agrave; le
      Informazioni Riservate, tranne che ad affiliati, dipendenti e agenti che ne hanno bisogno e che hanno accettato
      per iscritto di mantenerle riservate. Ciascuna parte (e qualsiasi Affiliato, dipendente e agente a cui ha
      divulgato Informazioni Riservate) pu&ograve; utilizzare le Informazioni Riservate solo per esercitare i diritti e
      adempiere ai propri obblighi ai sensi del presente Accordo, utilizzando ragionevole cura per proteggerle. Ciascuna
      parte &egrave; responsabile per qualsiasi azione dei propri affiliati, dipendenti e agenti in violazione della
      presente sezione.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">5.2</p>
  <p class="remove-vertical-margin justify flexAuto">
      Eccezioni. Le informazioni Riservate non includono le informazioni che: (a) il destinatario delle
      Informazioni Riservate gi&agrave; conosceva; (b) diventano pubbliche non per colpa del destinatario; (c) &egrave;
      stato sviluppato in modo indipendente dal destinatario; o (d) che sono legittimamente cedute al destinatario da
      un'altra parte.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">5.3</p>
  <p class="remove-vertical-margin justify flexAuto">
  Divulgazione obbligatoria. Ciascuna parte pu&ograve; divulgare le informazioni Riservate dell'altra parte
      quando richiesto dalla legge ma solo dopo l&rsquo;autorizzazione legale, se legalmente consentito: (a) compie
      sforzi commercialmente ragionevoli per informare l'altra parte; e (b) offre all'altra parte la possibilit&agrave;
      di contestare la divulgazione.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">6.</p>
  <p class="remove-vertical-margin"><strong>Diritti di propriet&agrave; intellettuale; Caratteristiche del marchio.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">6.1</p>
  <p class="remove-vertical-margin justify flexAuto">
  Diritti di propriet&agrave; intellettuale. Ad eccezione di quanto espressamente stabilito nel presente
      documento, il presente Accordo non concede a nessuna delle parti alcun diritto, implicito o meno, sul contenuto o
      su alcuna propriet&agrave; intellettuale dell'altra parte.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">7.</p>
  <p class="remove-vertical-margin"><strong>Dichiarazioni, garanzie ed esclusioni di responsabilit&agrave;.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">7.1</p>
  <p class="remove-vertical-margin justify flexAuto">
  Dichiarazioni e garanzie. Ciascuna parte dichiara di avere pieno potere e autorit&agrave; per stipulare il
      Accordo. Ciascuna parte garantisce che rispetter&agrave; tutte le leggi e i regolamenti applicabili alla sua
      fornitura o all'utilizzo dei Servizi, a seconda dei casi (inclusa la legge sulla notifica di violazione della
      sicurezza).
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">7.2</p>
  <p class="remove-vertical-margin justify flexAuto">
  L'Utente Finale dovr&agrave; astenersi dal distribuire qualsiasi contenuto che potrebbe essere perseguibile
      penalmente e dal violare qualsiasi altro diritto di terzi (in particolare diritti di propriet&agrave;
      intellettuale), diritto della concorrenza o diritti personali generali, nonch&eacute; norme sulla protezione dei
      dati. L'Utente Finale &egrave; responsabile del rispetto di tutte le leggi e i regolamenti applicabili relativi al
      commercio elettronico.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">7.3</p>
  <p class="remove-vertical-margin justify flexAuto">
      Esclusione di responsabilit&agrave;. NELLA MISURA MASSIMA CONSENTITA DALLA LEGGE VIGENTE, SALVO QUANTO
      ESPRESSAMENTE PREVISTO NEL PRESENTE DOCUMENTO, NESSUNA PARTE FORNISCE ALCUNA ALTRA GARANZIA DI ALCUN TIPO, SIA
      ESPLICITA, IMPLICITA, LEGALE O ALTRO, INCLUSE SENZA LIMITAZIONE GARANZIE DI NON COMMERCIABILIT&Agrave; E DI NON
      IDONEIT&Agrave; A PARTICOLARIT&Agrave;.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">8.</p>
  <p class="remove-vertical-margin"><strong>Risoluzione.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">8.1</p>
  <p class="remove-vertical-margin justify flexAuto">
  Da parte dell'Utente Finale. L'Utente Finale pu&ograve; interrompere l'utilizzo del Servizio in qualsiasi
      momento.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">8.2</p>
  <p class="remove-vertical-margin justify flexAuto">
  Da Evidera. L'Utente Finale accetta che Evidera possa in qualsiasi momento e per qualsiasi motivo
      rescindere il presente Accordo e / o interrompere la fornitura di tutto o parte del Servizio. Nonostante quanto
      sopra, Evidera fornir&agrave; un preavviso di almeno trenta (30) giorni al Cliente prima di terminare o sospendere
      il Servizio; a condizione che il Servizio possa essere risolto immediatamente se (i) il Cliente ha violato il
      presente Accordo o (ii) Evidera ragionevolmente determina che &egrave; commercialmente impraticabile continuare a
      fornire il Servizio alla luce delle leggi applicabili.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">8.3</p>
  <p class="remove-vertical-margin justify flexAuto">
  Effetti della risoluzione. Se il presente Accordo termina, i diritti concessi da una parte all'altra
      cesseranno immediatamente.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">9.</p>
  <p class="remove-vertical-margin"><strong>Risarcimento.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">9.1</p>
  <p class="remove-vertical-margin justify flexAuto">
  L'Utente Finale indennizzer&agrave;, difender&agrave; e terr&agrave; indenne Evidera da e contro tutte le
    responsabilit&agrave;, danni e costi (compresi i costi di liquidazione e ragionevoli spese legali) derivante da un
    reclamo di terze parti o riguardante l'utilizzo dei Servizi da parte dell'Utente Finale in violazione della Norme
    d'Uso Accettabile. La parte richiedente il risarcimento informer&agrave; prontamente l'altra parte del reclamo e
    collaborer&agrave; con l'altra parte nella difesa del reclamo. La parte indennizzante ha il pieno controllo e
    l'autorit&agrave; sulla difesa, tranne che: (a) qualsiasi accordo che richiede alla parte che chiede l'indennizzo di
    ammettere responsabilit&agrave; o di pagare qualsiasi somma di denaro richieder&agrave; il previo consenso scritto
    di tale parte, tale consenso a non essere irragionevolmente negato o ritardato;&nbsp;e (b) l'altra parte pu&ograve;
    unirsi alla difesa con il proprio consiglio a proprie spese.ny money will require that party's prior written consent, such consent not to be unreasonably withheld or delayed; and (b) the other party may join in the defense with its own counsel at its own expense.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">10.</p>
  <p class="remove-vertical-margin"><strong>Limitazione di responsabilit&agrave;.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">10.1</p>
  <p class="remove-vertical-margin justify flexAuto">
  Limitation on Indirect Liability.  NEITHER PARTY WILL BE LIABLE UNDER THIS AGREEMENT FOR LOST REVENUES OR INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES, EVEN IF THE PARTY KNEW OR SHOULD HAVE KNOWN THAT SUCH DAMAGES WERE POSSIBLE AND EVEN IF DIRECT DAMAGES DO NOT SATISFY A REMEDY.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">10.2</p>
  <p class="remove-vertical-margin justify flexAuto">
    Limitazione dell'importo di responsabilit&agrave;. NESSUNA DELLE PARTI PU&Ograve; ESSERE RITENUTA
    RESPONSABILE AI SENSI DEL PRESENTE ACCORDO PER PI&Ugrave; DI MILLE DOLLARI (1000 dollari).<
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">10.3</p>
  <p class="remove-vertical-margin justify flexAuto">
    Eccezioni alle limitazioni. Queste limitazioni di responsabilit&agrave; si applicano nella misura massima
    consentita dalla legge applicabile, ma non si applicano alle violazioni degli obblighi di Riservatezza, violazioni
    dei diritti di propriet&agrave; intellettuale di una parte da parte dell'altra parte o obblighi di indennizzo.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">11.</p>
  <p class="remove-vertical-margin"><strong>Varie.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.1</p>
  <p class="remove-vertical-margin justify flexAuto">
    Avvisi. Salvo diversamente specificato nel presente documento: (a) tutte le comunicazioni devono essere in
    forma scritta e indirizzate all'attenzione dell'ufficio legale dell'altra parte e del punto di contatto
    principale; e (b) l'avviso si considerer&agrave; dato: (i) verificato mediante ricevuta scritta se inviato tramite
    corriere personale, corriere espresso o quando ricevuto se inviato per posta senza verifica di ricevimento; o (ii)
    se verificato mediante ricezione automatica o registri elettronici se inviati tramite fax o e-mail.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.2</p>
  <p class="remove-vertical-margin justify flexAuto">
    Assegnazione. Nessuna delle parti pu&ograve; cedere o trasferire alcuna parte del presente Accordo senza il
    consenso scritto dell'altra parte, ad eccezione di un Affiliato, ma solo se: (a) il cessionario accetta per
    iscritto di essere vincolato dai termini del presente Accordo; e (b) la parte cedente rimane responsabile per gli
    obblighi assunti ai sensi del Accordo prima della cessione. Qualsiasi altro tentativo di trasferimento o
    assegnazione &egrave; nullo.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.3</p>
  <p class="remove-vertical-margin justify flexAuto">
    Cambio di controllo. In caso di cambiamento di controllo (ad esempio, tramite acquisto o vendita di azioni,
    fusione o altra forma di transazione societaria): (a) la parte che subisce il cambiamento di controllo ne
    d&agrave; comunicazione scritta all'altra parte entro trenta (30) giorni dal cambiamento di controllo; e (b)
    l'altra parte pu&ograve; recedere immediatamente il presente Accordo in qualsiasi momento tra il cambiamento di
    controllo e trenta giorni (30) dopo aver ricevuto la notifica scritta di cui al paragrafo (a).
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.4</p>
  <p class="remove-vertical-margin justify flexAuto">
    Forza maggiore. Nessuna delle parti sarà responsabile per prestazioni inadeguate nella misura in cui sono
    causate da una condizione (ad esempio, disastro naturale, atto di guerra o terrorismo, sommossa, condizione
    lavorativa / sciopero, azione governativa e interruzione di Internet) al di fuori del ragionevole controllo della
    parte.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.5</p>
  <p class="remove-vertical-margin justify flexAuto">
    Nessuna rinuncia. La mancata applicazione di qualsiasi disposizione del presente Accordo non
    costituir&agrave; una rinuncia.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.6</p>
  <p class="remove-vertical-margin justify flexAuto">
    Separabilit&agrave;. Se una qualsiasi disposizione del presente Accordo viene ritenuta inapplicabile, il
    resto del Accordo rimarr&agrave; in vigore a tutti gli effetti.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.7</p>
  <p class="remove-vertical-margin justify flexAuto">
    Nessuna agenzia. Le parti sono contraenti indipendenti e il presente Accordo non crea un'agenzia, una
    partnership o una joint venture.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.8</p>
  <p class="remove-vertical-margin justify flexAuto">
    Nessun beneficiario terzo. Non esistono beneficiari terzi del presente Accordo.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.9</p>
  <p class="remove-vertical-margin justify flexAuto">
    Equa riparazione. Nulla nel presente Accordo limiter&agrave; la capacit&agrave; di entrambe le parti di
    cercare un equa riparazione.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.10</p>
  <p class="remove-vertical-margin justify flexAuto">
    Legge applicabile. Il presente Accordo &egrave; regolato dalla legge della Carolina del Nord, ad
    esclusione delle norme di scelta della legge di tale stato. PER QUALSIASI CONTROVERSIA DERIVANTE O RELATIVA AL
    PRESENTE ACCORDO, LE PARTI ACCONSENTONO ALLA GIURISDIZIONE PERSONALE NELLO STATO DELLA CAROLINA DEL NORD.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.11</p>
  <p class="remove-vertical-margin justify flexAuto">
    Modifiche. Qualsiasi modifica deve essere in forma scritta e indicare espressamente che sta modificando il
    presente Accordo
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.12</p>
  <p class="remove-vertical-margin justify flexAuto">
    Sopravvivenza. Le seguenti sezioni sopravvivranno alla scadenza o alla risoluzione del presente Accordo:
    Sezioni 6 (Riservatezza), 7 (Propriet&agrave; intellettuale), 9 (Rappresentanze, garanzie ed esonero di
    responsabilit&agrave;), 10 (Risoluzione), 11 (Indennizzo), 12 (Limitazione di responsabilit&agrave;), e 13
    (Varie).
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.13</p>
  <p class="remove-vertical-margin justify flexAuto">
    Intero accordo. Il presente Accordo, e tutti i documenti qui menzionati, costituiscono l'intero accordo
    delle parti relativo al suo oggetto e sostituisce qualsiasi accordo precedente o contemporaneo su tale argomento.
    I termini che si trovano in un URL e cui si fa riferimento nel presente Accordo sono qui incorporati da questo
    riferimento.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.14</p>
  <p class="remove-vertical-margin justify flexAuto">
    Interpretazione dei termini in conflitto. In caso di conflitto tra i documenti che compongono il presente
      Contratto, i documenti prevarranno nel seguente ordine: il Contratto seguito dai termini che si trovano in
      qualsiasi URL. https://medimix.net/termsandconditions/
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">12.</p>
  <p class="remove-vertical-margin"><strong>Definizioni.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
    "Politica di Utilizzo Accettabile". Indica la Politica di Utilizzo Accettabile dei Servizi, &egrave; disponibile
    nell'Appendice A di questo documento
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
    "Amministratori". Indica il personale tecnico designato dal Cliente che amministra i Servizi agli Utenti Finali.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
    "Affiliato". Indica qualsiasi entit&agrave; che direttamente o indirettamente controlla, &egrave; controllata o
    &egrave; sotto il controllo comune di una parte.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
    "Informazioni Confidenziali". Indica le informazioni divulgate da una parte all'altra parte ai sensi del
    presente Accordo che sono contrassegnate come riservate o che normalmente sarebbero considerate riservate date le
    circostanze. I dati del cliente sono Informazioni Riservate del cliente.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  "End User Data".  means data, including email address, provided, generated, transmitted or displayed via the Services by End Users.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
    "Problemi urgenti di sicurezza ". significa: (a) l'utilizzo dei Servizi da parte dell'Utente Finale in
    violazione delle norme di Utilizzo Accettabile, che potrebbe interrompere: (i) i Servizi; (ii) l'utilizzo dei
    Servizi da parte di altri Utenti finali; o (iii) accesso non autorizzato di terze parti ai Servizi.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
    "Utenti Finali". indica le persone a cui Evidera consente di utilizzare i Servizi.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  "Account Utente Finale". Indica un account ospitato da Evidera creato dall'Utente Finale allo scopo di
  utilizzare i Servizi.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  "Export Control Laws".  means all applicable export and reexport control laws and regulations, including the Export Administration Regulations ("EAR") maintained by the U.S. Department of Commerce, trade and economic sanctions maintained by the Treasury Department's Office of Foreign Assets Control, and the International Traffic in Arms Regulations ("ITAR") maintained by the Department of State.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  "HIPAA".  means the Health Insurance Portability and Accountability Act of 1996, as may be amended from time to time, and any regulations issued thereunder.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  "Intellectual Property Rights".  means current and future worldwide rights under patent law, copyright law, trade secret law, trademark law, moral rights law, and other similar rights.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  "Services". means “Evidera HCP Portal“ which is a one stop application for registered physicians to facilitate regular participations to Evidera online studies.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 4px;">
  <p class="remove-vertical-margin justify flexAuto">
    Questa applicazione &egrave; disponibile su 3 diverse piattaforme:
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  iOS (iPhone e iPad) – Apple Store
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  Android (Smartphones, tablets e Chromebook) – Google Play
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  Web (Windows or Mac)
  </p>
</div>

<div class="flex flex-row" style="margin-top: 4px;">
  <p class="remove-vertical-margin justify flexAuto">
    Notifiche:
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
    L'Utente Finale
    ricever&agrave; una notifica quando un nuovo studio sar&agrave; disponibile.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="remove-vertical-margin justify flexAuto">
    Partecipazioni:
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
    Accedere a nuovi
    studi e a quelli in lavorazione.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="remove-vertical-margin justify flexAuto">
    Inizio dello studio:
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
    Iniziare o
    riprendere gli studi degli Utenti Finali.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="remove-vertical-margin justify flexAuto">
    Dati personali:
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
    Rivedere i dati
    personali dell'Utente Finale e apportare modifiche.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="remove-vertical-margin justify flexAuto">
    Pagamenti:
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
    Visionare gli
    studi dell'Utente Finale completati in passato e seguire i pagamenti.
  </p>
</div>
<br/>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="remove-vertical-margin justify flexAuto">
    Domande o problemi?
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
    Ottenere supporto
    tramite la live chat o con la sezione delle domande pi&ugrave; frequenti di Evidera.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
    "Sospendi". Indica la disabilitazione immediata dell'accesso ai Servizi, o componenti dei Servizi, a seconda dei
    casi, per impedire l'ulteriore utilizzo dei Servizi.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
    "Termine". indica la durata del Accordo, che inizier&agrave; alla Data di entrata in vigore e continuer&agrave;
    fino alla prima tra: (i) la fine dell'ultimo termine di Servizi o (ii) il Accordo si estingue come stabilito nel
    presente documento.
  </p>
</div>


<h3 style="text-align: center; margin-top: 50px; margin-bottom: 0;">APPENDICE A</h3>
<h4 style="text-align: center; margin-bottom: 30px; margin-top: 8px;">Politica di utilizzo accettabile</h4>

<div class="flex flex-row">
  <p class="remove-vertical-margin"><strong>ELEGGIBILIT&Agrave;</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">1.</p>
  <p class="remove-vertical-margin justify flexAuto">
    &Egrave; consentita una sola registrazione per persona. La violazione potrebbe squalificarla dalla riscossione
    della compensazione applicabile e comportare il divieto di partecipare a studi futuri. Se &egrave; gi&agrave;
    registrato e ha solo bisogno di aggiornare un nuovo indirizzo email, acceda alla sezione Account per aggiornare la
    sua email.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">2.</p>
  <p class="remove-vertical-margin justify flexAuto">
    Mentire sulla sua identit&agrave; o qualsiasi altra informazione al momento della registrazione o durante la
    partecipazione a uno studio la squalificher&agrave; dalla riscossione della compensazione applicabile e potrebbe
    comportare il divieto di partecipare a futuri studi.
  </p>
</div>

<p style="margin-top: 20; margin-bottom: 20;" class="justify">
  <i>Professionisti sanitari:</i> solo gli operatori sanitari che attualmente esercitano la professione possono partecipare.
  Mentire sulle proprie credenziali la squalificher&agrave; dalla riscossione del risarcimento applicabile e potrebbe
  comportare il divieto di partecipare in futuro.

<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">3.</p>
  <p class="remove-vertical-margin justify flexAuto">
    Gli inviti sono personali e non possono essere inoltrati ad altri se non esplicitamente autorizzati nell'e-mail
    di invito.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">4.</p>
  <p class="remove-vertical-margin justify flexAuto">
    Evidera ha il diritto di invitare o meno potenziali partecipanti a sua esclusiva discrezione.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">5.</p>
  <p class="remove-vertical-margin justify flexAuto">
    Tutte le risposte devono essere vere e accurate al meglio delle sue conoscenze; fornire risposte false o
    fuorvianti comporter&agrave; il mancato risarcimento e il divieto di partecipare in futuro.
  </p>
</div>


<p class="remove-vertical-margin" style="margin-top: 20px;"><strong>COMPENSAZIONE</strong></p>
<p class="remove-vertical-margin justify" style="margin-top: 4px; padding-left: 16px;">
  Evidera fornir&agrave; un elenco delle precedenti partecipazioni dell'Utente Finale con l'importo e le donazioni,
  compreso il saldo complessivo dell'Utente Finale
</p>

<p class="remove-vertical-margin" style="margin-top: 20px;"><strong>TASSE / LEGALI</strong></p>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">1.</p>
  <p class="remove-vertical-margin justify flexAuto">
    Lei, come Utente Finale &egrave; l'unico responsabile della dichiarazione di eventuali tasse, se dovute, alle
    autorit&agrave; finanziarie locali a seguito del pagamento ricevuto. Riconosce che Evidera pu&ograve; fornire
    informazioni ad agenzie fiscali, o trattenere le tasse, su richiesta di tali agenzie o come Evidera a sua
    esclusiva discrezione ritiene appropriato. Evidera non sar&agrave; responsabile per eventuali tasse o per fornire
    assistenza in materia fiscale.
    <br/>
    <br/>
    <i>Residenti negli Stati Uniti: </i>in base alle leggi federali, statali e / o locali potrebbe essere richiesto
    all&rsquo; Utente Finale di pagare le tasse sulla compensazione ricevuta. Riconosce e accetta di fornire a Evidera
    qualsiasi informazione personale aggiuntiva tramite il modulo IRS W-9, come il numero di previdenza sociale o il
    numero di identificazione del contribuente, che Evidera deve rispettare qualsiasi obbligo di segnalazione o di
    ritenuta. Il mancato conferimento da parte dell'utente di tali informazioni entro 30 giorni dalla richiesta inviata
    da Evidera all'indirizzo postale principale registrato in quel momento pu&ograve; comportare il ritardo nel
    pagamento.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">2.</p>
  <p class="remove-vertical-margin justify flexAuto">
    L'Utente Finale riconosce e accetta che qualsiasi pagamento ricevuto &egrave; un valore equo per la
    partecipazione dell'Utente Finale, &egrave; effettuato a condizioni di mercato e l'Utente finale accetta
    esplicitamente che non &egrave; un incentivo a: prescrivere alcun trattamento medico, n&eacute; promuovere o
    fornire alcun medico prodotto o servizio, o modificare in altro modo il suo comportamento in qualsiasi modo
    correlato elle cure prestate.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">3.</p>
  <p class="remove-vertical-margin justify flexAuto">
  <i>Professionisti sanitari:</i> come richiesto dalle leggi e dai regolamenti sulla divulgazione in varie
  giurisdizioni, M3 potrebbe essere obbligata a segnalare i pagamenti effettuati per la partecipazione alle nostre
  ricerche di mercato e ad altri programmi. Avendo accettato di partecipare a questi programmi, ci ha autorizzato a
  divulgare il suo guadagno e altri pagamenti applicabili come richiesto per soddisfare tali requisiti.
  </p>
</div>

<p style="margin-top: 50px;"></p>
`;