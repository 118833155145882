import React, { useState, useCallback, useEffect } from "react";
import {
  View,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
} from "react-native";
import { useForm, Controller } from "react-hook-form";
import {
  TextInput,
  useTheme,
  Title,
  Subheading,
} from "react-native-paper";
import debounce from "lodash/debounce";
import { API, graphqlOperation } from "aws-amplify"; // eslint-disable-line import/no-extraneous-dependencies
import gql from "graphql-tag";
import { Picker } from "@react-native-picker/picker";
import { styles } from "./forms.style";
import { PersonalInfo } from "../types"; // eslint-disable-line no-unused-vars
import { translate } from "../../../components/Localei18n/languagesFunctions";
import AlertBox from "../../../components/Alert";
import FormField from "../../../components/FormValidator/FormField";
import { PickerCustom } from "../../../components/picker.custom";

const EditContactsPersonal = (props) => {
  const [saved, setSaved] = useState<boolean | string>(false);
  const [saveError, setSaveError] = useState(null);
  const [salutationList, setSalutationList] = useState([]);
  const theme = useTheme();
  let unmounted = false;
  const {
    control, getValues, setValue, formState: { errors },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const [personalInfo] = useState<PersonalInfo>(props.route.params); // eslint-disable-line react/prop-types

  const onSubmit = async (data: object) => {
    setSaveError(null);
    console.log("check form input", data);

    try {
      const mutation = gql`
      mutation updateFormsContacts(
        $formsContactsInput: FormsContactsInput!
        $panelist_id: Int!
      ) {
        insertIgnoreFormsContacts(panelist_id: $panelist_id)
        updateFormsContacts(formsContactsInput: $formsContactsInput)
      }
    `;
      await API.graphql(
        graphqlOperation(mutation, {
          formsContactsInput: {
            ...data,
            panelist_id: personalInfo.panelist_id,
          },
          panelist_id: personalInfo.panelist_id,
        })
      );
    } catch (err) {
      console.log("save error: ", err.errors[0].message);
      setSaveError(err.errors[0].message);
      return false;
    }
    return true;
  };


  const autoSave = useCallback(
    debounce(
      (field) => {
        console.log(field);

        if (Object.keys(errors).length === 0) {
          if (!unmounted) {
            // if (diffDuration.asYears() >= 18) {
            setSaved(false);
            onSubmit(getValues())
              .then((res) => {
                if (res) setSaved(field);
              });

            // Analytics.logEvent("PersonalInfoUpdated");

            setTimeout(() => {
              setSaved(false);
            }, 5000); // wait 5 seconds, then reset to false
          }
        }
      },
      1000,
      { leading: false }
    ),
    []
  );

  const displaySaveStatus = () => (saveError && saveError.length > 0) || saved;

  const getSalutations = async (codeSelected) => {
    if (!codeSelected) { return false; }
    // setValue("salutation_code", "");
    if (codeSelected !== "") {
      const qry = gql`
        query($code: String!) {
          getListSalutations(code: $code) {
            salutation_code
            salutation_name
          }
        }
      `;

      const response: any = await API.graphql(
        graphqlOperation(qry, {
          code: codeSelected,
        })
      );

      if (response.data.getListSalutations.length === 0) {
        setValue("salutation_code", 0);
      }

      setSalutationList(response.data.getListSalutations);
    } else {
      setSalutationList([]);
    }
    return true;
  };

  useEffect(() => {
    unmounted = false;
    getSalutations(props.route.params.target_code);
    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={[styles.root, { backgroundColor: theme.colors.background }]}
    >
      <ScrollView style={styles.scrollWrapper}>
        <View style={styles.scrollInnerResponsive}>
          <View style={styles.titleWRapper}>
            <Title style={styles.title}>
              {translate("Update Personal Details")}
            </Title>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <Subheading style={styles.subheadingRegular}>
                {translate(
                  "Changes to your personal details is subject for verification"
                )}
              </Subheading>
              {/* {displaySaveStatus() && (
                <View style={{ flexDirection: "row", alignItems: "center", paddingLeft: 5 }}>
                  <MaterialCommunityIcons
                    // eslint-disable-next-line no-nested-ternary
                    name={saveError
                      ? "account-alert-outline"
                      : saved
                        ? "account-check-outline"
                        : "account-arrow-right-outline"}
                    size={24}
                    color={saveError ? "red" : saved ? "green" : "grey"}
                  />
                  <Text style={{ color: saveError ? "red" : saved ? "green" : "grey" }}>{displaySaveLabel()}</Text>
                </View>
              )} */}
            </View>
          </View>

          <Subheading style={styles.subheadingSmall}>
            {translate("Salutation").toUpperCase()}
          </Subheading>

          <FormField errors={errors?.salutation_code}>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => {
                const newValue = salutationList.find(
                  (e) => e.salutation_code === Number(value)
                );
                return (
                  <PickerCustom
                    style={{ flex: 1 }}
                    textCaption={
                          newValue?.salutation_name
                            ? translate(newValue?.salutation_name)
                            : ""
                        }
                    value={value}
                    mode="outlined"
                    required={salutationList?.length > 0}
                    disabled={salutationList?.length === 0}
                    onChange={(itemValue) => {
                      onChange(itemValue);
                    }}
                    label={salutationList?.length !== 0 ? translate("Salutation") : translate("Not Applicable")}
                    withTheme={false}
                  >
                    <Picker.Item label={salutationList?.length !== 0 ? translate("Select Salutation") : ""} value={0} />
                    {salutationList
                          && salutationList.map((e, index) => (
                            <Picker.Item
                              key={index.toString()}
                              label={translate(e.salutation_name)}
                              value={e.salutation_code}
                            />
                          ))}
                  </PickerCustom>
                );
              }}
              name="salutation_code"
              defaultValue={Number(personalInfo?.salutation_code) || 0}
              rules={{
                required: {
                  value: salutationList?.length > 0,
                  message: translate("Please specify salutation"),
                },
              }}
            />
          </FormField>

          <Subheading style={styles.subheadingSmall}>
            {translate("NAME")}
          </Subheading>

          <FormField>
            <Controller
              control={control}
              render={({ field: { onChange, value, onBlur } }) => (
                <TextInput
                  right={displaySaveStatus() === "first_name" && (
                  <TextInput.Icon
                    size={20}
                    icon="check"
                    color="green"
                  />
                  )}
                  returnKeyType="next"
                  mode="outlined"
                  style={styles.formInput}
                  onBlur={onBlur}
                  onChangeText={(ev) => {
                    setSaved(false);
                    onChange(ev);
                    // update({ first_name: ev });
                    autoSave("first_name");
                  }}
                  value={value}
                  label={translate("First Name")}
                />
              )}
              name="first_name"
              defaultValue={personalInfo.first_name}
            />
          </FormField>

          <View style={styles.formField}>
            <Controller
              control={control}
              render={({ field: { onChange, value, onBlur } }) => (
                <TextInput
                  right={displaySaveStatus() === "last_name" && (
                  <TextInput.Icon
                    size={20}
                    icon="check"
                    color="green"
                  />
                  )}
                  returnKeyType="next"
                  mode="outlined"
                  style={styles.formInput}
                  onBlur={onBlur}
                  onChangeText={(ev) => {
                    setSaved(false);
                    onChange(ev);
                    // update({ last_name: ev });
                    autoSave("last_name");
                  }}
                  value={value}
                  label={translate("Last Name")}
                />
              )}
              name="last_name"
              defaultValue={personalInfo.last_name}
            />
          </View>

          <AlertBox type="error" style={{ marginTop: 12 }}>{saveError}</AlertBox>

        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};


export default EditContactsPersonal;
