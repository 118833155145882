/* eslint-disable import/no-extraneous-dependencies */

import React, { useState, useEffect, useCallback, useContext } from "react";
import { FlatList, View, RefreshControl, Text, Platform, StyleSheet, Linking, Image } from "react-native";
import { useTheme } from "react-native-paper";
import Color from "color";
import { API, graphqlOperation } from "aws-amplify";
import gql from "graphql-tag";
import Auth from "@aws-amplify/auth";
import { createStackNavigator, StackNavigationProp } from "@react-navigation/stack"; // eslint-disable-line no-unused-vars
import ContentLoader from "react-native-easy-content-loader";
import { timezone } from "expo-localization";
import { useLinkTo } from "@react-navigation/native";
import { StackNavigatorParamlist } from "../../navigation/types"; // eslint-disable-line no-unused-vars
import { NotificationItem } from "./notification.item";
import ZeroRequestState from "../../components/zero.request.states";
import { ActivityItem } from "./activity.item";
import { UserInfoContext } from "../../context/userInfo";
import { translate } from "../../components/Localei18n/languagesFunctions";
import ExpoReleaseEnvironments from "../../ExpoReleaseEnvironments";
import { NotificationDetails } from "./notification.details";
import AppHeader from "../../components/app.header";
import UserService from "../../helpers/user.service";
import { SessionContext } from "../../context/SessionContext";
import { sendAnalyticsEvent } from "../../components/Analytics/analytics";

const styles = StyleSheet.create({
  header: {
    paddingHorizontal: 10,
    marginTop: 6,
    paddingTop: 10,
    paddingBottom: 10,
  },
});

type ActivityProps = React.ComponentProps<typeof ActivityItem>;

type NotificationProps = React.ComponentProps<typeof NotificationItem>;

type Props = {
  navigation?: StackNavigationProp<StackNavigatorParamlist>;
};

export const Notifications = (props: Props) => {
  const { managedUserInfo } = useContext(UserInfoContext);
  const theme = useTheme();
  const [notificationData, setNotificationData] = useState<Omit<NotificationProps | ActivityProps, "onPress">[]>([]);
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const linkTo = useLinkTo();
  const { handleSessionDeactivation } = useContext(SessionContext);
  const { checkUser } = UserService();

  const getEligibleSurvey = useCallback(async () => {
    const uic = managedUserInfo?.uic;
    try {
      const CognitoUser = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });

      const token = (CognitoUser?.signInUserSession?.accessToken?.jwtToken) ? CognitoUser?.signInUserSession?.accessToken?.jwtToken : "";

      const { getEligibleSurveyURL } = ExpoReleaseEnvironments();

      const response = await fetch(
        `${getEligibleSurveyURL}${uic}`, {
        // "https://mmx.medimix.biz/backoffice/public/api/hcp/status/US40037773", {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((e) => e.json())
        .catch((error) => {
          console.error("failed to fetch hcp status ", error);

          return null;
        });

      console.log("LINK", response);
      return response?.status_code && response?.status_code !== -1 ? response : null;
    } catch (e) {
      console.log("FAILED TO FETCH UIC STATUS", e);
      return null;
    }
  }, [managedUserInfo?.uic]);

  const updateSentAt = async (ids) => {
    try {
      const qry = gql`
          mutation ($ids: [Int]!){
            updateNotificationSentAt(ids: $ids)
          }`;
      const response: any = await API.graphql(graphqlOperation(qry, {
        ids,
      }));

      return (response.data?.updateNotificationSentAt === true);
    } catch (e) {
      console.error("Failed to update sent at: ", e);

      return false;
    }
  };

  const getNotifications = async (email) => {
    try {
      const qry = gql`
          query ($email: String!){
            getNotifications(email: $email){
              id panelist_id sound title body data created_at send_at link opened_at
            }
          }`;

      const response: any = await API.graphql(graphqlOperation(qry, {
        email,
        timezone,
      }));

      const ids = response.data.getNotifications.map((e) => e.id);
      if (Array.isArray(ids) && ids.length > 0) {
        updateSentAt(ids);
      }

      return response.data.getNotifications;
    } catch (e) {
      console.error("failed to fetch notifications ", e);
      return e.data;
    }
  };

  const fetchNotifications = async (u) => {
    // eslint-disable-next-line consistent-return
    setLoading(true);

    if (await checkUser() !== true) {
      handleSessionDeactivation(0);
    }

    setNotificationData([]);
    const responseParticipation = u?.uic ? await getEligibleSurvey() : null;
    const responseNotification = u?.email ? await getNotifications(u?.email) : null;

    return {
      data: {
        getNotifications: responseNotification || [],
        getParticipation: responseParticipation ? [responseParticipation] : [],
      },
    };
  };

  const updateNotificationState = (response) => {
    const Participation: ActivityProps[] = response.data?.getParticipation.map((e) => ({ ...e, type: "activity" }));
    const notification: NotificationProps[] = response.data?.getNotifications.map((e) => ({ ...e, type: "notifications" }));

    const xList: any = [
      ...Participation,
      ...notification];

    setNotificationData(xList);
  };

  useEffect(() => {
    let unmounted = false;

    sendAnalyticsEvent("Notifications");
    if (managedUserInfo) {
      fetchNotifications(managedUserInfo).then((response) => {
        if (!unmounted) {
          if (response) {
            updateNotificationState(response);
          }
          setLoading(false);
        }
      });
    }


    return () => {
      unmounted = true;
    };
  }, [managedUserInfo]);


  const data = notificationData.map((notificationProps: NotificationProps | ActivityProps, index) => ({
    ...notificationProps,
    onPress: async () => {
      // markAsRead(index);
      if (notificationProps.type === "activity") {
        if (notificationProps.link) {
          if (Platform.OS === "web") {
            window.open(notificationProps.link, "notifications").focus();
          } else {
            await Linking.openURL(notificationProps.link);
          }
        }
      } else if (notificationProps.type === "notifications") {
        if (props.navigation) {
          if (Platform.OS !== "web") {
            props.navigation.push("Notification", { id: notificationProps.id });
          }
          linkTo(`/dashboard/notification/${notificationProps.id}`);
        }
      }
    },
  }));

  const onRefresh = useCallback(() => {
    setRefreshing(true);

    if (managedUserInfo) {
      fetchNotifications(managedUserInfo).then((response) => {
        if (response != null) updateNotificationState(response);
        setLoading(false);
      });
    }
    setRefreshing(false);
  }, [loading, managedUserInfo]);

  const contentColor = Color(theme.colors.text)
    .alpha(0.4)
    .rgb()
    .string();

  const renderEmptyComponent = () => (
    <View>
      {loading ? (
        <View style={{ paddingTop: 10, marginBottom: 20 }}>
          <ContentLoader aSize={36} animationDuration={500} avatar active pRows={2} listSize={1} />
        </View>
      ) : (notificationData.length === 0
        && (
          <ZeroRequestState
            style={{ paddingTop: 30 }}
            title={translate("No notifications to show.")}
          />
        )
      )}
    </View>
  );

  const renderHeader = () => (
    <View>
      <View style={[styles.header, { backgroundColor: theme.colors.background }]}>
        <Text style={{
          color: contentColor, fontSize: 14, fontWeight: "700", textAlign: "left",
        }}
        >
          {translate("Notifications")}
        </Text>
      </View>
    </View>
  );

  // eslint-disable-next-line consistent-return
  const renderItem = ({ item }: { item: NotificationProps | ActivityProps }) => {
    if (item.type === "activity") {
      return <ActivityItem {...item} />;
    } if (item.type === "notifications") {
      return <NotificationItem {...item} />;
    }
  };

  const keyExtractor = (item: NotificationProps | ActivityProps) => {
    if (item.type === "activity") {
      return `activity-${item.token}`;
    } if (item.type === "notifications") {
      return `notifications-${item.id}`;
    }
    return (Math.random() * 100).toString();
  };
  // Analytics.logEvent("Notifications");
  return (
    <View style={{ flex: 1 }}>

      <FlatList
        contentContainerStyle={{ backgroundColor: theme.colors.background, paddingBottom: 18 }}
        style={{ backgroundColor: theme.colors.background }}
        data={data}
        scrollEventThrottle={16}
        renderItem={(itemProp:any) => renderItem(itemProp)}
        keyExtractor={(itemProp:any) => keyExtractor(itemProp)}
        refreshing={loading}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
          }
        ListEmptyComponent={renderEmptyComponent}
        ListHeaderComponent={renderHeader}
        ItemSeparatorComponent={() => (<View style={{ height: StyleSheet.hairlineWidth }} />)}
      />

    </View>
  );
};

export const NotificationsStack = (props: Props) => {
  const NotificationStack = createStackNavigator();
  useEffect(() => {
    // console.log(props.route.params?.id);

    if (props.route.params?.id) {
      props.navigation.navigate("Notifications", { screen: "NotificationDetails", params: { id: props.route.params?.id } });
    }
  }, []);

  return (
    <NotificationStack.Navigator
      initialRouteName="Notifications"
      mode="screen"
      screenOptions={{
        // eslint-disable-next-line react/prop-types
        header: (props) => {
          // eslint-disable-next-line no-nested-ternary
          const title = props.options?.headerTitle !== undefined // eslint-disable-line react/prop-types
            ? props.options?.headerTitle // eslint-disable-line react/prop-types
            : props.options?.title !== undefined // eslint-disable-line react/prop-types
              ? props.options?.title // eslint-disable-line react/prop-types
              : (
                <Image
                  source={require("../../assets/medimix-evidera-2.png")}
                  resizeMode="contain"
                  style={{
                    height: 30,
                    width: 200,
                  }}
                />
              );

          const handleGoBack = () => {
            if (props.back) {
              props.navigation.goBack();
            } else {
              props.navigation.navigate("Notifications");
            }
          };

          return (
            <AppHeader
              goBack={handleGoBack}
              showBackButton={props.options?.headerTitle?.length > 0 || props.options?.title?.length > 0}
            >
              {title}
            </AppHeader>
          );
        },
      }}
    >
      <NotificationStack.Screen name="Notifications" component={Notifications} />
      <NotificationStack.Screen options={{ title: "Details" }} name="Notification" component={NotificationDetails} />

    </NotificationStack.Navigator>
  );
};
