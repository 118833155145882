import React, { useState, useEffect } from "react";
import {
  Image,
  View,
  Text, StyleSheet,
  FlatList,
} from "react-native";
import {
  Portal,
  Dialog,
  Button,
  TouchableRipple,
  TextInput,
  useTheme,
  ProgressBar,
} from "react-native-paper";
import _, { debounce } from "lodash";
import color from "color";
import { translate } from "../../../components/Localei18n/languagesFunctions";

type Props = {
  visible: boolean;
  dismissModal: () => void;
  setCountry: (item: any) => void;
  countries: any;
  currentCountryCode: string;
}

export const CountryPicker = (props: Props) => {
  const {
    visible,
    dismissModal,
    setCountry,
    countries,
    currentCountryCode = "",
  } = props;
  const theme = useTheme();
  const [skey, setskey] = useState("");
  const [searching, setSearching] = useState(false);

  const newCountry = countries?.sort((current, next) => {
    if (currentCountryCode) {
      if (current.iso2 === currentCountryCode) return -1;
      if (next.iso2 === currentCountryCode) return 1;
    }

    if (current.label < next.label) return -1;
    if (current.label > next.label) return 1;

    // one liner
    // current.iso2 === currentCountryCode ? -1 : (next.iso2 === currentCountryCode) ? 1 : (current.iso2 < next.iso2) ? -1 : (current.iso2 > next.iso2) ? 1 : 0)
    return 0;
  })
  .filter((e) => e.label.toLowerCase().includes(skey.toLowerCase())) || [];

  const borderColor = color(theme.colors.text)
    .alpha(0.6)
    .rgb()
    .string();

  const styles = StyleSheet.create({
    search: {
      flex: 1,
      backgroundColor: "transparent",
      paddingTop: 2,
    },
  });

  const RenderItem = ({ item }) => (
    <TouchableRipple
      onPress={() => setCountry(item)}
      style={{ paddingLeft: 10 }}
    >
      <View style={{
        flexDirection: "row",
        flex: 1,
        alignItems: "center",
      }}
      >
        <Image
          source={item.image}
          resizeMode="stretch"
          style={{
            width: 36,
            height: 22,
            marginRight: 16,
          }}
        />
        <View style={{
          flexDirection: "row",
          flex: 1,
          paddingVertical: 12,
          borderBottomWidth: StyleSheet.hairlineWidth,
          borderBottomColor: borderColor,
        }}
        >
          <Text style={{
            flex: 1,
            ...(item.iso2 === currentCountryCode && { fontWeight: "bold" }),
          }}
          >
            {item.label}
          </Text>
          <Text style={{
            marginRight: 24,
            ...(item.iso2 === currentCountryCode && { fontWeight: "bold" }),
          }}
          >
            {item.dialCode}
          </Text>
        </View>
      </View>
    </TouchableRipple>
  );

  return (
    <Portal>
      <Dialog
        style={{ height: "80%", flexDirection: "column" }}
        visible
        onDismiss={dismissModal}
      >
        <View style={{
          flexDirection: "row",
          paddingHorizontal: 0,
          paddingVertical: 5,
          position: "relative",
        }}
        >
          <TextInput
            left={<TextInput.Icon size={16} icon="magnify" style={{ paddingTop: 5 }} />}
            style={[styles.search]}
            mode="flat"
            placeholder={translate("Type here to search a Country.")}
            dense
            onChangeText={debounce((text) => {
              setskey(text);
            }, 1000)}
            onChange={() => setSearching(true)}
          />

          <View style={{
            position: "absolute",
            bottom: 0,
            right: 0,
            left: 0,
          }}
          >
            {(searching && skey?.length > 0) && <ProgressBar indeterminate />}
          </View>
        </View>
        <Dialog.Content
          style={{
            flex: 1,
            paddingHorizontal: 0,
            paddingBottom: 0,
          }}
        >
          <FlatList
            style={{}}
            data={newCountry}
            renderItem={RenderItem}
            keyExtractor={(item) => item.key.toString()}
            onEndReached={() => setSearching(false)}
          />
        </Dialog.Content>
        <Dialog.Actions>
          <View style={{ flex: 1, flexDirection: "row", justifyContent: "center" }}>
            <Button
              onPress={dismissModal}
            >
              {translate("Cancel")}
            </Button>
          </View>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
};
