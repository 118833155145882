
import React from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import ContentLoader from "react-native-easy-content-loader";

type Props = {
  style?: StyleProp<ViewStyle>;
}
export const StageLoader = (props: Props) => (
  <View style={props.style}>
    <ContentLoader
      animationDuration={500}
      active
      pRows={1}
      listSize={4}
      tHeight={14}
      tWidth={170}
      titleStyles={{ marginBottom: 3, marginTop: 8 }}
      pHeight={30}
      pWidth={["100%"]}
      paragraphStyles={{ marginBottom: 7 }}
    />
    <View style={{ flexDirection: "row" }}>

      <ContentLoader
        animationDuration={500}
        active
        pRows={0}
        tHeight={48}
        tWidth="50%"
        titleStyles={{ marginBottom: 2, borderRadius: 0, marginLeft: "auto" }}
      />
    </View>

  </View>
);
