/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
import React, { useEffect, useState } from "react";
import { View, Platform, Text, TouchableWithoutFeedback, Keyboard, ScrollView, StyleSheet, TouchableNativeFeedback, StatusBar, Linking } from "react-native";
import { I18n } from "@aws-amplify/core";
import { API, graphqlOperation } from "aws-amplify";
import gql from "graphql-tag";
import ContentLoader from "react-native-easy-content-loader";
import {
  HCPPortalTheme,
} from "../../components";
import { ColorsEvidera } from "../../theme/variables";
import { scaleDisplay } from "../../helpers/image/applyScale";
import { PersonalInfoTitle } from "./PersonalInfo/StageTitle";
import { translate } from "../../components/Localei18n/languagesFunctions";
import { sendAnalyticsEvent } from "../../components/Analytics/analytics";

const styles = StyleSheet.create({
  container: {
    marginTop: 20,
    marginBottom: 30,
    width: scaleDisplay(460),
    padding: 35,
    borderRadius: 6,
    shadowColor: "black",
    shadowOpacity: 0.15,
    shadowOffset: { width: 0, height: 3 },
    shadowRadius: 6,
    backgroundColor: "white",
  },
  h1: {
    fontSize: 30,
    fontWeight: "700",
    color: ColorsEvidera.primary,
  },
});

const ViewPlatform = (innerProps) => {
  if (Platform.OS === "web") {
    return <View style={styles.container}>{innerProps.children}</View>;
  }
  return (
    <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
      <ScrollView style={[HCPPortalTheme.section]}>
        {innerProps.children}
      </ScrollView>
    </TouchableWithoutFeedback>
  );
};

type Props = {
  user: object;
  handleSignout: () => void;
  userStatus?: boolean;
  navigation?: StackNavigationProp;
  initialRoute?: string;
}

export const Review = (props: Props) => {
  // console.log(props.user.attributes);
  let unmounted = false;
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState({});
  StatusBar.setBarStyle("dark-content");

  const getContacts = async (status) => {
    setLoading(true);
    if (status === "3") {
      const qry = gql`query ($email: String!) {
          getContactsInfo_by_email(email: $email){
            last_name first_name salutation
          }
        }
      `;

      const response: any = await API.graphql(graphqlOperation(qry, {
        email: props.user?.attributes["email"],
      }));

      if (unmounted) return;
      setUserInfo(response.data.getContactsInfo_by_email);
    } else if (props.user) {
      const qry = gql`
        query ($contact_id: String!) {
          getFormsContactsInfo_by_contact_id(contact_id: $contact_id){
            last_name first_name salutation
          }
        }
      `;
      const response: any = await API.graphql(graphqlOperation(qry, {
        contact_id: props.user?.attributes["custom:contact_id"],
      }));
      if (unmounted) return;
      setUserInfo(response.data.getFormsContactsInfo_by_contact_id);
    }

    setLoading(false);
  };

  const [message, setMessage] = useState("");

  useEffect(() => {
    console.log(props);

    const status = props.user?.attributes["custom:review_status"];

    if (status === "6") {
      setMessage(translate("Thank you for registering.\nOur team is currently reviewing your information and will notify you by email when your access is created.\nThis usually takes less than 24 hours."));
    } else if (status === "3") {
      setMessage(translate("Sorry we encountered an issue with your access. Our system administrator was contacted and will contact you shortly.Send an email to surveyen@medimix.net with subject Inactive user attempt to register in HCP Portal."));
    }

    if (Platform.OS === "web" && props.userStatus === true && props.initialRoute !== "Review") {
      props.navigation.navigate(props.initialRoute);
    }
    if (Platform.OS === "web" && props.userStatus === true) {
      getContacts(status);
    }
    if (Platform.OS !== "web") {
      getContacts(status);
    }

    return () => {
      unmounted = true;
    };
  }, []);

  // this may not log correctly. check here if no review events found
  useEffect(() => {
    sendAnalyticsEvent("Review");
  }, []);


  return (
    <>
      <PersonalInfoTitle />
      <View style={{ flex: 1, flexDirection: "column", alignItems: "center" }}>

        <ViewPlatform>
          {(loading) && (
            <>
              <View style={[HCPPortalTheme.sectionHeader, {
                flex: 1, paddingTop: 28, marginLeft: -10,
              }]}
              >
                <ContentLoader
                  animationDuration={500}
                  active
                  pRows={2}
                  listSize={1}
                  tHeight={30}
                  titleStyles={{ marginBottom: 6, borderRadius: 14 }}
                  pHeight={30}
                  pWidth={[270, 240]}
                  paragraphStyles={{ marginBottom: 6, borderRadius: 14 }}
                />
              </View>

              <View style={{ marginLeft: -10 }}>
                <ContentLoader
                  animationDuration={500}
                  active
                  pRows={3}
                  listSize={1}
                  tHeight={18}
                  titleStyles={{ marginBottom: 0, borderRadius: 14 }}
                  pHeight={18}
                  pWidth={[270, 240, 130]}
                  paragraphStyles={{ marginBottom: 0, borderRadius: 14 }}
                />
              </View>


              <View style={{ marginTop: 56, marginLeft: -10 }}>
                <ContentLoader
                  animationDuration={500}
                  active
                  pRows={0}
                  tHeight={40}
                  tWidth="100%"
                  titleStyles={{ marginBottom: 2, borderRadius: 25 }}
                />
              </View>
            </>
          )}
          {(!loading) && (
            <View>
              <View style={[HCPPortalTheme.sectionHeader, { paddingTop: 24 }]}>
                <Text style={styles.h1}>
                  {translate("Welcome")}
                  ,
                </Text>
                <Text style={styles.h1}>
                  {userInfo?.salutation ? `${userInfo?.salutation}.` : ""}
                  {" "}
                  {`${userInfo?.first_name} ${userInfo?.last_name}!`}
                </Text>
              </View>

              <View>
                <Text style={{ marginBottom: 10, fontSize: 20, color: "grey" }}>{message}</Text>
              </View>

              <View style={[{ marginTop: 30 }]}>
                <TouchableWithoutFeedback
                  onPress={() => props.handleSignout().then((e) => { if (Platform.OS === "web" && e === true) props.navigation.navigate("Authenticate"); })}
                  style={{
                    borderWidth: 0,
                    borderColor: "none",
                  }}
                >
                  <View
                    pointerEvents="box-only"
                    style={{
                      backgroundColor: ColorsEvidera.additionalSecondary,
                      padding: 16,
                      borderRadius: 22,
                      minWidth: 100,
                      borderWidth: 0,
                      borderColor: "none",
                      cursor: "pointer",
                    }}
                  >
                    <Text style={{ fontWeight: "bold", color: "white", textAlign: "center" }}>{translate("Ok")}</Text>
                  </View>
                </TouchableWithoutFeedback>
              </View>
            </View>
          )}
        </ViewPlatform>
      </View>
    </>
  );
};

export default Review;
