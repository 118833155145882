import Color from "color";
import React, { useEffect } from "react";
import { StyleSheet, View, Image, Alert, TouchableWithoutFeedback, Linking, Platform, StatusBar } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { Title, useTheme, Text } from "react-native-paper";
import { I18n } from "@aws-amplify/core";
import { SafeAreaView } from "react-native-safe-area-context";
import { StackNavigationProp } from "@react-navigation/stack";
import AnimateFade from "../../helpers/animation.fade";
import { ColorsEvidera } from "../../theme/variables";
import { StackNavigatorParamlist } from "../../navigation/types";
import { PersonalInfoTitle } from "../Auth/PersonalInfo/StageTitle";
import { translate } from "../../components/Localei18n/languagesFunctions";

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  rootInner: {
    flex: 1,
    padding: 30,
    justifyContent: "center",
    // alignItems: "center",
  },
  illustrations: {
    paddingBottom: 20,
  },
  content: {
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontSize: 14, lineHeight: 24, textAlign: "left", fontWeight: "500",
  },
  link: {
    lineHeight: 24, fontWeight: "700",
  },

});

type Props = {
  user: object;
  handleSignout: () => Promise<any>;
  userStatus?: boolean;
  initialRoute?: string;
  navigation?: StackNavigationProp;
};

const Inactive = (props: Props) => {
  const theme = useTheme();
  const rootBg = Color(theme.colors.placeholder)
    .alpha(0.04)
    .rgb()
    .string();
  const textColor = Color(theme.colors.text)
    .alpha(0.85)
    .rgb()
    .string();

  StatusBar.setBarStyle("dark-content", true);

  useEffect(() => {
    if (props.userStatus === true && props.initialRoute !== "Inactive") {
      props.navigation.navigate(props.initialRoute);
    }
  }, [props.user, props.userStatus]);

  return (
    <View style={{ flex: 1 }}>
      <PersonalInfoTitle />
      <SafeAreaView style={[styles.root]}>
        <View style={[styles.rootInner]}>
          <View style={styles.illustrations}>
            <Image
              source={require("../../assets/inactive.png")}
              resizeMode="contain"
              style={{
                width: "100%",
                height: 300,
              }}
            />
          </View>

          <View style={{ flexDirection: "row", justifyContent: "center" }}>

            <View style={[{ paddingVertical: 8, flex: 1 }, Platform.OS === "web" && { maxWidth: 600 }]}>
              <AnimateFade delay={300} fadeInDuration={500}>
                <Text style={[styles.title, { marginBottom: 15, color: textColor }]}>
                  {translate("Your profile is inactive in our system.\nIf you think that this is a mistake please contact us at ")}
                  <TouchableWithoutFeedback onPress={() => Linking.openURL("mailto:surveyen@medimix.net")}>
                    <Text style={[styles.link, { marginBottom: 15, color: ColorsEvidera.primary }]}>SURVEYEN@MEDIMIX.NET</Text>
                  </TouchableWithoutFeedback>
                </Text>
              </AnimateFade>
            </View>

          </View>
          <View style={{ marginTop: 24, flexDirection: "row", justifyContent: "center" }}>

            <AnimateFade
              delay={800}
              fadeInDuration={500}
              style={[{ paddingVertical: 8, flex: 1 }, Platform.OS === "web" && { maxWidth: 600 }]}
            >
              <TouchableWithoutFeedback
                onPress={() => {
                  props.handleSignout()
                    .then((e) => {
                      if (Platform.OS === "web" && e === true) {
                        props.navigation.navigate("Authenticate");
                      }
                    });
                }}
                style={{
                  borderWidth: 0,
                  borderColor: "none",

                }}
              >
                <View
                  pointerEvents="box-only"
                  style={{
                    backgroundColor: ColorsEvidera.additionalSecondary,
                    padding: 16,
                    borderRadius: 22,
                    minWidth: 100,
                    borderWidth: 0,
                    borderColor: "none",
                  }}
                >
                  <Text style={{ fontWeight: "bold", color: "white", textAlign: "center" }}>{translate("Logout")}</Text>
                </View>
              </TouchableWithoutFeedback>
            </AnimateFade>

          </View>
        </View>

      </SafeAreaView>

    </View>
  );
};

export default Inactive;
