import React, { createContext } from "react";
import { StyleSheet, Text, View, Platform } from "react-native";
import { Button, Dialog, Paragraph, Portal } from "react-native-paper";
import { translate } from "../components/Localei18n/languagesFunctions";

type SessionContextProp = {
  sessionDlg: boolean,
  handleSessionDeactivation: (index?, user?) => void
  message: string | undefined,
  setMessage: (value?) => void,
  closeDialog: (value?) => void,
  // setCognitoUser: any
  setShowMessage: (value?) => void,
}
export const SessionContext = createContext<SessionContextProp>({
  sessionDlg: false,
  handleSessionDeactivation: () => { },
  message: undefined,
  setMessage: () => { },
  closeDialog: () => { },
  // setCognitoUser: () => {},
  setShowMessage: () => { },
});

type SessionContextProviderProp = {
  visible: boolean,
  closeDialog: () => void,
  message: string | undefined,
}


const styles = StyleSheet.create({
  text: {
    // lineHeight: 20,
    fontSize: 14,
  },

});
export const SessionContextDialog = ({ visible, closeDialog, message }: SessionContextProviderProp) => {
  const DialogContent = () => (
    <Dialog dismissable={false} visible={visible}>
      <Dialog.Title>{translate("Login")}</Dialog.Title>
      <Dialog.Content>
        <View style={{ flexDirection: "column" }}>
          <Text style={styles.text}>{translate("You we're signed out.")}</Text>
          {message && (<Text style={styles.text}>{translate(message)}</Text>)}
        </View>
      </Dialog.Content>
      <Dialog.Actions>
        <Button onPress={closeDialog}>{translate("Ok")}</Button>
      </Dialog.Actions>
    </Dialog>
  );
  return Platform.OS === "web" ? <DialogContent /> : <Portal><DialogContent /></Portal>
};


// export default SessionContextProvider;
