/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
import React, { useContext, useEffect } from "react";
import {
  StyleSheet,
} from "react-native";
import { Stage0 } from "./SignUp/Stage0";
import { Stage1 } from "./SignUp/Stage1";
import { Stage2 } from "./SignUp/Stage2";
import ReferenceDataContextProvider from "./SignUp/ReferenceDataContext";
import { scaleDisplay } from "../../helpers/image/applyScale";
import { AuthContext } from "./AuthContext";

const styles = StyleSheet.create({
  container: {
    marginTop: 20,
    marginBottom: 30,
    width: scaleDisplay(460),
    padding: 35,
    borderRadius: 6,
    shadowOpacity: 0.15,
    shadowOffset: { width: 0, height: 3 },
    shadowRadius: 6,
  },
  drop: {
    padding: 35,
    borderRadius: 6,
    borderColor: "rgb(196, 196, 196)",
    color: "rgb(196, 196, 196)",
    borderWidth: 1,
    textAlign: "center",
    width: "100%",
  },
});

const ViewPlatform = (innerProps) => (

  <ReferenceDataContextProvider>
    {innerProps.children}
  </ReferenceDataContextProvider>

);

type Props = {
  refreshCurrentUser: () => void;
  email: string;
  setEmail: React.Dispatch<any>;
  authState?: string;
  onStateChange?: | ((e) => void);
  pageTrigger: string;
}

export const SignUp = (props: Props) => {
  const { AuthStage, setAuthStage } = useContext(AuthContext);

  let unmounted = false;

  const goToStage = ($nextStage) => {
    setAuthStage($nextStage);
  };

  const changeState = ($nextStage, data) => {
    if (!unmounted) {
      props.onStateChange($nextStage, data);
    }
  };

  useEffect(() => () => {
    unmounted = true;
  }, [AuthStage]);

  return (
    props.authState === "signUp" && (
      <ViewPlatform>
        {AuthStage === Number(0) && (
          <Stage0
            goToStage={goToStage}
            changeState={changeState}
            email={props.email}
            setEmail={props.setEmail}
            pageTrigger={props.pageTrigger}
          />
        )}
        {AuthStage === Number(1) && <Stage1 goToStage={goToStage} changeState={changeState} refreshCurrentUser={props.refreshCurrentUser} />}
        {AuthStage === Number(2) && <Stage2 goToStage={goToStage} />}
      </ViewPlatform>
    )
  );
};


export default SignUp;
