import React, { useEffect } from "react";
import { StyleProp, StyleSheet, Text, View, ViewStyle } from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import AnimateFade from "../helpers/animation.fade";

interface Props {
  type: "error" | "warning" | "information" | "success";
  children?: any;
  style?: StyleProp<ViewStyle>;
  animateTime?: number;
}

const styles = StyleSheet.create({
  root: {
    flexDirection: "row",
    paddingVertical: 14,
    paddingHorizontal: 12,
    alignItems: "center",
  },
  error: {
    backgroundColor: "#d74a49",
  },
  warning: {
    backgroundColor: "#ecbf58",
  },
  success: {
    backgroundColor: "#14b89c",
  },
  information: {
    backgroundColor: "#14b89c",
  },
});

const AlertBox = (props: Props) => {
  useEffect(() => {}, [props.children]);

  if (props.children === "" || !props.children) {
    return (<View />);
  }
  const iconName = () => {
    switch (props.type) {
      case "information":
        return "information-outline";
        break;
      case "error":
        return "close-circle";
        break;
      case "success":
        return "check";
        break;
      case "warning":
        return "alert-outline";
        break;
      default:
        return "information-outline";
    }
  };

  const fontColor = () => {
    switch (props.type) {
      case "information":
        return "#ffffff";
        break;
      case "error":
        return "#ffffff";
        break;
      case "success":
        return "#ffffff";
        break;
      case "warning":
        return "#000000";
        break;
      default:
        return "#ffffff";
    }
  };
  return (
    <AnimateFade fadeInDuration={props.animateTime}>

      <View style={[styles?.[props.type], styles.root, props.style]}>

        <View style={{ width: 40, height: 40, justifyContent: "center" }}>
          <MaterialCommunityIcons
            name={iconName()}
            size={28}
            color={fontColor()} />
        </View>

        <View style={{ flex: 1 }}>
          <Text style={{ color: fontColor(), lineHeight: 20 }}>
            {props.children}
          </Text>
        </View>
      </View>
    </AnimateFade>
  );
};

AlertBox.defaultProps = {
  animateTime: 1000,
}
export default AlertBox;
