import { Platform } from "react-native";

export const RouteConfig = () => {
  if (Platform.OS !== "web") {
    return {
      screens: {
        Home: {
          path: "",
          screens: {
            FeedList: {
              path: "",
              screens: {
                Notifications: {
                  path: "",
                  screens: {
                    Notifications: {
                      path: "notifications",
                    },
                    Notification: {
                      path: "notification/:id?",
                      parse: { id: Number },
                    },
                  },
                },
                Profile: "profile",
                Account: "account",
                Support: "support",
              },
            },
            Legalinfo: {
              path: "Legalinfo",
            },
            Preferences: { path: "Preferences" },
          },
        },
      },
    };
  }
  return {
    screens: {
      Home: { path: "" },
      Authenticate: { path: "authenticate" },
      Download: "download",
      Review: { path: "review" },
      Inactive: { path: "inactive" },
      PersonalInfo: { path: "personalinfo" },
      TermsAndConditions: { path: "termsandconditions" },
      TermsAndConditionsPT: { path: "termsandconditions_pt" },
      TermsAndConditionsFR: { path: "termsandconditions_fr" },
      TermsAndConditionsGR: { path: "termsandconditions_gr" },
      TermsAndConditionsES: { path: "termsandconditions_es" },
      TermsAndConditionsIT: { path: "termsandconditions_it" },
      Dashboard: {
        path: "dashboard",
        // initialRoute: "",
        screens: {
          Home: {
            path: "",
            screens: {
              FeedList: {
                path: "",
                screens: {
                  Notifications: {
                    path: "",
                    screens: {
                      Notifications: {
                        path: "notifications",
                      },
                      Notification: {
                        path: "notification/:id?",
                        parse: { id: Number },
                      },
                    },
                  },
                  Profile: {
                    path: "Profile",
                  },
                  ProfileContacts: {
                    path: "ProfileContacts",
                  },
                  ProfileContactsAddressList: {
                    path: "ProfileContactsAddressList",
                  },
                  ProfilePayment: {
                    path: "ProfilePayment",
                  },
                  ProfileInterest: {
                    path: "ProfileInterest",
                  },
                  HCPIdentification: {
                    path: "HCPIdentification",
                  },
                  EditContactsEmail: {
                    path: "EditContactsEmail",
                  },
                  AddContactsEmail: {
                    path: "AddContactsEmail",
                  },
                  ProfileContactsContacts: {
                    path: "ProfileContactsContacts",
                  },
                  EditPayment: {
                    path: "EditPayment",
                  },
                  EditContactsPersonal: {
                    path: "EditContactsPersonal",
                  },
                  EditContactsNumber: {
                    path: "EditContactsNumber",
                  },
                  EditContactsAddress: {
                    path: "EditContactsAddress",
                  },
                  AddContactsAddress: {
                    path: "AddContactsAddress",
                  },
                  EditHcpDetails: {
                    path: "EditHcpDetails",
                  },
                  Account: {
                    path: "account",
                  },
                  Support: {
                    path: "support",
                  },
                },
              },
              Legalinfo: "Legalinfo",
              Preferences: "preferences",
            },
          },
        },
      },
    },
  };
};
