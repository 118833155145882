import React, { createContext, useState, useMemo } from "react";

export const AuthContext = createContext(null);
type AuthContextProps = {
  children: any;
}
const AuthContextProvider = (props: AuthContextProps) => {
  const [AuthData, setAuthData] = useState({});
  const [AuthError, setAuthError] = useState({});
  const [AuthStage, setAuthStage] = useState(0);
  const [AuthLanguage, setAuthLanguage] = useState("");


  const value = useMemo(() => ({
    AuthData, AuthError, AuthStage, AuthLanguage, setAuthData, setAuthError, setAuthStage, setAuthLanguage,
  }), [AuthData, AuthError, AuthStage, AuthLanguage]);

  return (
    <AuthContext.Provider value={value}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
