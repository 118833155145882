import React, { useEffect, useRef, useState } from "react";
import { Animated, StyleProp, View, ViewProps } from "react-native";

type Props = {
  fadeInDuration?: number;
  fadeOutDuration?: number;
  duration?: number;
  children: any;
  style?: StyleProp<any>;
  fadeOut?: false;
  delay?: number;
}
const AnimateFade = (props: Props) => {
  const fadeAnim = useRef(new Animated.Value(0)).current;

  const fadeIn = async () => {
    // Will change fadeAnim value to 1 in 5 seconds
    await Animated.timing(fadeAnim, {
      useNativeDriver: true,
      toValue: 1,
      duration: props.fadeInDuration || 1000,
    }).start();

    return new Promise((resolve) => resolve(true));
  };

  const fadeOut = () => {
    // Will change fadeAnim value to 0 in 5 seconds
    Animated.timing(fadeAnim, {
      useNativeDriver: true,
      toValue: 0,
      duration: props.fadeOutDuration || 1000,
    }).start();
  };


  useEffect(() => {
    setTimeout(() => {
      fadeIn().then(() => {
        if (props.fadeOut) {
          setTimeout(() => {
            fadeOut();
          }, props.duration || 2000);
        }
      });
    }, props.delay || 0);
  }, [fadeAnim]);

  return (
    <>
      <Animated.View style={[props.style, { opacity: fadeAnim }]}>
        {props.children}
      </Animated.View>
    </>
  );
};

export default AnimateFade;
