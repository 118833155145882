import React from "react";
import moment from "moment";
import { Text } from "react-native";

type Props = {
    format?: string;
    date?: string;
    style?: object;
}
const Moment = (props: Props) => {
  const { format, date } = props;
  return (
    <Text style={props.style}>{moment(date).format(format || "MMMM Do YYYY, h:mm:ss a")}</Text>
  );
};
export default Moment;
