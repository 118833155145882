
import React from "react";
import { Platform, StatusBar, StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { Appbar, useTheme } from "react-native-paper";
import { MedimixTitle } from "../../../components/MedimixTitle";

type Props = {
  style?: StyleProp<ViewStyle>;
}
export const PersonalInfoTitle = (props: Props) => {
  const theme = useTheme();
  return (
    <View style={[
      props.style]}
    >
      <StatusBar translucent backgroundColor={theme.colors.surface} barStyle="dark-content" />
      <View style={{
        width: "100%",
      }}
      >
        <Appbar.Header
          theme={{ colors: { primary: theme.colors.surface } }}
        >
          <Appbar.Content titleStyle={{ height: 40, alignItems: "center" }} title={<MedimixTitle style={{ marginLeft: Platform.OS === "ios" ? -40 : 0 }} />} />
        </Appbar.Header>
      </View>

    </View>
  );
};
