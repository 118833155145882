import React from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import { useTheme, Divider, Text } from "react-native-paper";
import { I18n } from "@aws-amplify/core";
import PropTypes from "prop-types";
import Modal from "../../../components/Modal";

import { colors } from "../../../theme/variables";
import { translate } from "../../../components/Localei18n/languagesFunctions";

const styles = StyleSheet.create({
  buttonCloseModalWebView: {
    paddingTop: 30,
    paddingBottom: 30,
    justifyContent: "flex-start",
    alignItems: "center",
  },
  buttonModalWebView: {
    fontSize: 20,
    color: colors.primaryVioletColor,
  },
  viewModalFaq: {
    flex: 1,
    flexDirection: "column",
    paddingTop: 50,
  },
  modalTextQuestion: {
    padding: 10,
    fontSize: 22,
  },
  modalTextAnswer: {
    padding: 10,
    fontSize: 18,
  },
});


export const FaqModal = (props) => {
  const theme = useTheme();

  return (
    <Modal
      animationType="slide"
      transparent={false}
      visible={props.visibleModalFaq}
      onDismiss={props.hideModalFaq}
      backgroundColor={theme.colors.background}
      style={styles.viewModalFaq}
    >
      <View style={{ ...styles.viewModalFaq, backgroundColor: theme.colors.background }}>
        <Text style={[styles.modalTextQuestion, { color: theme.colors.primary }]}>{props.currentFaqItem.question}</Text>
        <Text style={styles.modalTextAnswer}>{props.currentFaqItem.answer}</Text>
        <Divider />
        <TouchableOpacity onPress={props.hideModalFaq} style={styles.buttonCloseModalWebView}>
          <Text style={styles.buttonModalWebView}>{translate("Close")}</Text>
        </TouchableOpacity>
      </View>
    </Modal>
  );
};
FaqModal.propTypes = {
  visibleModalFaq: PropTypes.bool.isRequired,
  hideModalFaq: PropTypes.func.isRequired,
  currentFaqItem: PropTypes.shape({
    id: PropTypes.number,
    question: PropTypes.string,
    answer: PropTypes.string,
  }).isRequired,
};
