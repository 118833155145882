export const TermsEN = `
<h3 style="text-align: center; margin-top: 25px; margin-bottom: 0;">END USER</h3>
<h4 style="text-align: center; margin-bottom: 40px; margin-top: 8px;">TERMS AND CONDITIONS</h4>

<div class="agreement">
  <p class="justify">
  This Agreement (the "Agreement") is entered into by and between Evidera, Ltd., a UK corporation, with offices at The Ark 201 Talgarth Rd. Hammersmith, London W6 8BJ ("Evidera") and the entity agreeing to the terms herein ("End User"). This Agreement governs End User's access to and use of the Services and will be effective as of the Effective Date. This Agreement is effective as of the date End User clicks the "I Accept" button below (the "Effective Date"). If you are accepting on behalf of End User, you represent and warrant that: (i) you have full legal authority to bind your employer, or the applicable entity, to these terms and conditions; (ii) you have read and understand this Agreement; and (iii) you agree, on behalf of the party that you represent, to this Agreement. If you do not have the legal authority to bind End User, please do not click the "I Accept" button below. This Agreement governs End User's access to and use of the Services.
  </p>
</div>

<div class="flex flex-row">
  <p class="order remove-vertical-margin">1.</p>
  <p class="remove-vertical-margin"><strong>Services.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">1.1</p>
  <p class="remove-vertical-margin justify flexAuto">
  Facilities and Data Transfer.  All facilities used to store and process End User Data will adhere to reasonable security standards and comply with all applicable data privacy laws and regulations, including but not limited to national laws implementing the European Union (“EU”) General Data Protection Regulation (“Regulation”), the Health Insurance Portability and Accountability Act (“HIPAA”) and state security breach laws in the United States, data protection legislation adopted by increasing numbers of other jurisdictions globally, and the privacy and confidentiality requirements of ICH Good Clinical Practice (“GCP”).
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">1.2</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Modifications.</strong>
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub2">a.</p>
  <p class="remove-vertical-margin justify flexAuto">
  To the Services.  Evidera shall have the right to change, suspend or discontinue any aspect of the Service at any time, without notice. If Evidera makes a material change to the Services, Evidera will inform End User.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub2">b.</p>
  <p class="remove-vertical-margin justify flexAuto">
    To this Agreement.  Evidera reserves the right to change or modify any of the terms and conditions contained in this Agreement or any policy governing the Service, at any time, by posting the new agreement at https://medimix.net/termsandconditions/ or such URL as Evidera may provide. Evidera will notify End User any updates to this Agreement. Any changes or modifications to this Agreement will become binding (i) by End User's online acceptance of updated terms, or (ii) after End User's continued use of the Service after such terms have been updated by Evidera.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">2.</p>
  <p class="remove-vertical-margin"><strong>End User Obligations.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">2.1</p>
  <p class="remove-vertical-margin justify flexAuto">
  Compliance.  Customer will use the Services in accordance with the Acceptable Use Policy. Evidera may make new applications, features or functionality for the Services available from time to time, the use of which may be contingent upon Customer's agreement to additional terms.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">2.2</p>
  <p class="remove-vertical-margin justify flexAuto">
  Restrictions on Use.  Unless Evidera specifically agrees in writing, Customer will not, and will use commercially reasonable efforts to make sure a third party does not: (a) sell, resell, lease, or the functional equivalent, the Services to a third party (unless expressly authorized in this Agreement); (b) attempt to reverse engineer the Services or any component; (c) attempt to create a substitute or similar service through use of, or access to, the Services; (d) use the Services for High Risk Activities; or (e) use the Services to store or transfer any End User Data that is controlled for export under Export Control Laws. End User is solely responsible for any applicable compliance with HIPAA.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">2.3</p>
  <p class="remove-vertical-margin justify flexAuto">
  Usage Policies and Limits.  End User is permitted 1 End User Account. End User shall comply with any additional usage policies and limits concerning use of the Service as requested by Evidera from time to time.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">2.4</p>
  <p class="remove-vertical-margin justify flexAuto">
  Privacy. End User will only report de-identified individual patient level information coming from medical records. The information data End User reports will be securely stored and will be maintained by Evidera and such information and related results may  be licensed or otherwise commercialized by Evidera and its affiliate companies, to external parties for commercial purposes (including commercial research purposes), in an aggregated anonymous format; and further, for the avoidance of any doubt, such aggregated anonymous information and related results will belong to and be owned by Evidera.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">3.</p>
  <p class="remove-vertical-margin justify flexAuto">
  Technical Support Services. provide support by phone, by email and with LifeChat. The frequency is Monday to Friday except public holidays in the countries handling the support. The support is provided by both fieldwork teams (Brazil and Philippines) and by Agents based in Ireland, Spain and Germany. The hours are the business hours from the team supporting the following languages: Spanish to cover Latin America and Spain (from Spain & Brazil), Portuguese to cover Brazil and Portugal (from Brazil), German for Germany from Germany and the rest of world in English from the Philippines and Ireland.
  <br/>
  The hours are the business hours from the team supporting the following languages: Spanish to cover Latin America and Spain (from Spain & Brazil), Portuguese to cover Brazil and Portugal (from Brazil), German for Germany from Germany and the rest of world in English from the Philippines and Ireland.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">4.</p>
  <p class="remove-vertical-margin"><strong>Suspension.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">4.1</p>
  <p class="remove-vertical-margin justify flexAuto">
  Of End User Accounts by Evidera.  If Evidera becomes aware of an End User's violation of the Agreement, then Evidera may Suspend the applicable End User Account. The duration of any Suspension will be until the applicable End User has cured the breach which caused the Suspension.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">4.2</p>
  <p class="remove-vertical-margin justify flexAuto">
  Emergency Security Issues.  Notwithstanding the foregoing, if there is an Emergency Security Issue, then Evidera may automatically Suspend the offending use. Suspension will be to the minimum extent and of the minimum duration required to prevent or terminate the Emergency Security Issue.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">5.</p>
  <p class="remove-vertical-margin"><strong>Confidential Information.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">5.1</p>
  <p class="remove-vertical-margin justify flexAuto">
  Obligations.  Each party will: (a) protect the other party's Confidential Information with the same standard of care it uses to protect its own Confidential Information; and (b) not disclose the Confidential Information, except to Affiliates, employees and agents who need to know it and who have agreed in writing to keep it confidential. Each party (and any Affiliates, employees and agents to whom it has disclosed Confidential Information) may use Confidential Information only to exercise rights and fulfill its obligations under this Agreement, while using reasonable care to protect it. Each party is responsible for any actions of its Affiliates, employees and agents in violation of this Section.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">5.2</p>
  <p class="remove-vertical-margin justify flexAuto">
  Exceptions.  Confidential Information does not include information that: (a) the recipient of the Confidential Information already knew; (b) becomes public through no fault of the recipient; (c) was independently developed by the recipient; or (d) was rightfully given to the recipient by another party.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">5.3</p>
  <p class="remove-vertical-margin justify flexAuto">
  Required Disclosure.  Each party may disclose the other party's Confidential Information when required by law but only after it, if legally permissible: (a) uses commercially reasonable efforts to notify the other party; and (b) gives the other party the chance to challenge the disclosure.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">6.</p>
  <p class="remove-vertical-margin"><strong>Intellectual Property Rights; Brand Features.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">6.1</p>
  <p class="remove-vertical-margin justify flexAuto">
  Intellectual Property Rights.  Except as expressly set forth herein, this Agreement does not grant either party any rights, implied or otherwise, to the other's content or any of the other's intellectual property.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">7.</p>
  <p class="remove-vertical-margin"><strong>Representations, Warranties and Disclaimers.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">7.1</p>
  <p class="remove-vertical-margin justify flexAuto">
  Representations and Warranties.  Each party represents that it has full power and authority to enter into the Agreement. Each party warrants that it will comply with all laws and regulations applicable to its provision, or use, of the Services, as applicable (including applicable security breach notification law).
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">7.2</p>
  <p class="remove-vertical-margin justify flexAuto">
  End User shall refrain from distributing any content which would be liable for criminal prosecution and from violating any other rights of third parties (particularly intellectual property rights), competition law or general personal rights, as well as data protection regulations. The End User is responsible for compliance with all applicable laws and regulations relating to electronic commerce.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">7.3</p>
  <p class="remove-vertical-margin justify flexAuto">
  Disclaimers.  TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, EXCEPT AS EXPRESSLY PROVIDED FOR HEREIN, NEITHER PARTY MAKES ANY OTHER WARRANTY OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING WITHOUT LIMITATION WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR USE AND NONINFRINGEMENT.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">8.</p>
  <p class="remove-vertical-margin"><strong>Termination.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">8.1</p>
  <p class="remove-vertical-margin justify flexAuto">
  By End User.  End User may discontinue use of the Service at any time.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">8.2</p>
  <p class="remove-vertical-margin justify flexAuto">
  By Evidera.  End User agrees that Evidera may at any time and for any reason terminate this Agreement and/or terminate the provision of all or any portion of the Service. Notwithstanding the foregoing, Evidera will provide at least thirty (30) days’ notice to Customer prior to terminating or suspending the Service; provided that the Service may be terminated immediately if (i) Customer has breached this Agreement or (ii) Evidera reasonably determines that it is commercially impractical to continue providing the Service in light of applicable laws.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">8.3</p>
  <p class="remove-vertical-margin justify flexAuto">
  Effects of Termination.  If this Agreement terminates, then the rights granted by one party to the other will cease immediately.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">9.</p>
  <p class="remove-vertical-margin"><strong>Indemnification.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">9.1</p>
  <p class="remove-vertical-margin justify flexAuto">
  End User will indemnify, defend, and hold harmless Evidera from and against all liabilities, damages, and costs (including settlement costs and reasonable attorneys' fees) arising out of a third party claim or regarding End User's use of the Services in violation of the Acceptable Use Policy. The party seeking indemnification will promptly notify the other party of the claim and cooperate with the other party in defending the claim. The indemnifying party has full control and authority over the defense, except that: (a) any settlement requiring the party seeking indemnification to admit liability or to pay any money will require that party's prior written consent, such consent not to be unreasonably withheld or delayed; and (b) the other party may join in the defense with its own counsel at its own expense.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">10.</p>
  <p class="remove-vertical-margin"><strong>Limitation of Liability.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">10.1</p>
  <p class="remove-vertical-margin justify flexAuto">
  Limitation on Indirect Liability.  NEITHER PARTY WILL BE LIABLE UNDER THIS AGREEMENT FOR LOST REVENUES OR INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES, EVEN IF THE PARTY KNEW OR SHOULD HAVE KNOWN THAT SUCH DAMAGES WERE POSSIBLE AND EVEN IF DIRECT DAMAGES DO NOT SATISFY A REMEDY.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">10.2</p>
  <p class="remove-vertical-margin justify flexAuto">
  Limitation on Amount of Liability.  NEITHER PARTY MAY BE HELD LIABLE UNDER THIS AGREEMENT FOR MORE THAN ONE THOUSAND DOLLARS ($1000 USD).
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">10.3</p>
  <p class="remove-vertical-margin justify flexAuto">
  Exceptions to Limitations.  These limitations of liability apply to the fullest extent permitted by applicable law but do not apply to breaches of confidentiality obligations, violations of a party's Intellectual Property Rights by the other party, or indemnification obligations.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">11.</p>
  <p class="remove-vertical-margin"><strong>Miscellaneous.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.1</p>
  <p class="remove-vertical-margin justify flexAuto">
  Notices.  Unless specified otherwise herein: (a) all notices must be in writing and addressed to the attention of the other party's legal department and primary point of contact; and (b) notice will be deemed given: (i) when verified by written receipt if sent by personal courier, overnight courier, or when received if sent by mail without verification of receipt; or (ii) when verified by automated receipt or electronic logs if sent by facsimile or email.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.2</p>
  <p class="remove-vertical-margin justify flexAuto">
  Assignment.  Neither party may assign or transfer any part of this Agreement without the written consent of the other party, except to an Affiliate, but only if: (a) the assignee agrees in writing to be bound by the terms of this Agreement; and (b) the assigning party remains liable for obligations incurred under the Agreement prior to the assignment. Any other attempt to transfer or assign is void.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.3</p>
  <p class="remove-vertical-margin justify flexAuto">
  Change of Control.  Upon a change of control (for example, through a stock purchase or sale, merger, or other form of corporate transaction): (a) the party experiencing the change of control will provide written notice to the other party within thirty days after the change of control; and (b) the other party may immediately terminate this Agreement any time between the change of control and thirty days after it receives the written notice in subsection (a).
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.4</p>
  <p class="remove-vertical-margin justify flexAuto">
  Force Majeure.  Neither party will be liable for inadequate performance to the extent caused by a condition (for example, natural disaster, act of war or terrorism, riot, labor condition/strike, governmental action, and Internet disturbance) that was beyond the party's reasonable control.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.5</p>
  <p class="remove-vertical-margin justify flexAuto">
  No Waiver.  Failure to enforce any provision of this Agreement will not constitute a waiver.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.6</p>
  <p class="remove-vertical-margin justify flexAuto">
  Severability.  If any provision of this Agreement is found unenforceable, the balance of the Agreement will remain in full force and effect.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.7</p>
  <p class="remove-vertical-margin justify flexAuto">
  No Agency.  The parties are independent contractors, and this Agreement does not create an agency, partnership or joint venture.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.8</p>
  <p class="remove-vertical-margin justify flexAuto">
  No Third-Party Beneficiaries.  There are no third-party beneficiaries to this Agreement.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.9</p>
  <p class="remove-vertical-margin justify flexAuto">
  Equitable Relief.  Nothing in this Agreement will limit either party's ability to seek equitable relief.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.10</p>
  <p class="remove-vertical-margin justify flexAuto">
  Governing Law.  This Agreement is governed by North Carolina law, excluding that state's choice of law rules. FOR ANY DISPUTE ARISING OUT OF OR RELATING TO THIS AGREEMENT, THE PARTIES CONSENT TO PERSONAL JURISDICTION IN THE STATE OF NORTH CAROLINA.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.11</p>
  <p class="remove-vertical-margin justify flexAuto">
  Amendments.  Any amendment must be in writing and expressly state that it is amending this Agreement.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.12</p>
  <p class="remove-vertical-margin justify flexAuto">
  Survival.  The following sections will survive expiration or termination of this Agreement: Sections 6 (Confidentiality), 7 (Intellectual Property), 9 (Representations, Warranties and Disclaimers), 10 (Termination), 11 (Indemnification), 12 (Limitation of Liability), and 13 (Miscellaneous).
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.13</p>
  <p class="remove-vertical-margin justify flexAuto">
  Entire Agreement.  This Agreement, and all documents referenced herein, is the parties' entire agreement relating to its subject and supersedes any prior or contemporaneous agreements on that subject. The terms located at a URL and referenced in this Agreement are hereby incorporated by this reference.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.14</p>
  <p class="remove-vertical-margin justify flexAuto">
  Interpretation of Conflicting Terms.  If there is a conflict between the documents that make up this Agreement, the documents will control in the following order: the Agreement and followed by the terms located at any URL.https://medimix.net/termsandconditions/.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">12.</p>
  <p class="remove-vertical-margin"><strong>Definitions.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  "Acceptable Use Policy".  means the acceptable use policy for the Services available in Appendix A of this document
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  "Administrators".  mean the Customer-designated technical personnel who administer the Services to End Users.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  "Affiliate".  means any entity that directly or indirectly controls, is controlled by, or is under common control with a party.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  "Confidential Information".  means information disclosed by a party to the other party under this Agreement that is marked as confidential or would normally be considered confidential under the circumstances. Customer Data is Customer's Confidential Information.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  "End User Data".  means data, including email address, provided, generated, transmitted or displayed via the Services by End Users.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  "Emergency Security Issue".  means either: (a) End User's use of the Services in violation of the Acceptable Use Policy, which could disrupt: (i) the Services; or (ii) other End User’s use of the Services; or (iii) unauthorized third party access to the Services.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  End Users".  means the individuals Evidera permits to use the Services.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  "End User Account".  means an Evidera-hosted account established by End User for purposes of utilizing the Services.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  "Export Control Laws".  means all applicable export and reexport control laws and regulations, including the Export Administration Regulations ("EAR") maintained by the U.S. Department of Commerce, trade and economic sanctions maintained by the Treasury Department's Office of Foreign Assets Control, and the International Traffic in Arms Regulations ("ITAR") maintained by the Department of State.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  "HIPAA".  means the Health Insurance Portability and Accountability Act of 1996, as may be amended from time to time, and any regulations issued thereunder.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  "Intellectual Property Rights".  means current and future worldwide rights under patent law, copyright law, trade secret law, trademark law, moral rights law, and other similar rights.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  "Services". means “Evidera HCP Portal“ which is a one stop application for registered physicians to facilitate regular participations to Evidera online studies.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 4px;">
  <p class="remove-vertical-margin justify flexAuto">
  This application is available in 3 different platforms:
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  iOS (iPhone & iPad) – Apple Store
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  Android (Smartphones, tablets & Chromebook) – Google Play
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  Web (Windows or Mac)
  </p>
</div>

<div class="flex flex-row" style="margin-top: 4px;">
  <p class="remove-vertical-margin justify flexAuto">
  Notifications:
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  End User will receive notification as soon as a new study is available.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="remove-vertical-margin justify flexAuto">
  Participations:
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  Review new and pending participations.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="remove-vertical-margin justify flexAuto">
  Start Survey:
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  Start or resume End User participations.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="remove-vertical-margin justify flexAuto">
  Personal Data:
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  Review End User personal data and submit changes.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="remove-vertical-margin justify flexAuto">
  Payments:
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  Review End User past completions and follow payments.
  </p>
</div>
<br/>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="remove-vertical-margin justify flexAuto">
  Have a question or an issue?
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  Get support through live chat or with Evidera’s most frequently asked questions section.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  "Suspend".  means the immediate disabling of access to the Services, or components of the Services, as applicable, to prevent further use of the Services.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  "Term".  means the term of the Agreement, which will begin on the Effective Date and continue until the earlier of: (i) the end of the last Services Term or (ii) the Agreement is terminated as set forth herein.
  </p>
</div>


<h3 style="text-align: center; margin-top: 50px; margin-bottom: 0;">APPENDIX A</h3>
<h4 style="text-align: center; margin-bottom: 30px; margin-top: 8px;">Acceptable Use Policy</h4>

<div class="flex flex-row">
  <p class="remove-vertical-margin"><strong>ELIGIBILITY</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">1.</p>
  <p class="remove-vertical-margin justify flexAuto">
  Only one registration per respondent is permitted. Violation may disqualify you from collecting the applicable compensation and result in you being banned from participating in future surveys. If you have previously registered and just need to update to a new email address, please log on and update your email in the Account section.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">2.</p>
  <p class="remove-vertical-margin justify flexAuto">
  Misrepresenting your identity, or any other information when registering or answering surveys, will disqualify you from collecting the applicable compensation and may result in you being banned from participating in future surveys.
  </p>
</div>

<p style="margin-top: 20; margin-bottom: 20;" class="justify">
  <i>HealthCare Professionals:</i> Only currently practicing healthcare professionals are eligible to participate. Misrepresentation of your credentials will disqualify you from collecting the applicable compensation and may result in you being banned from future participation.
</p>

<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">3.</p>
  <p class="remove-vertical-margin justify flexAuto">
  Invitations are personal to you and may not be forwarded to others unless explicitly authorized in the email invitation.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">4.</p>
  <p class="remove-vertical-margin justify flexAuto">
  Evidera has the right to invite or not invite potential participants in its sole discretion.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">5.</p>
  <p class="remove-vertical-margin justify flexAuto">
  All responses are to be true and accurate to the best of your knowledge; providing false or misleading responses will result in forfeiture of compensation and banning from future participation.
  </p>
</div>


<p class="remove-vertical-margin" style="margin-top: 20px;"><strong>COMPENSATION</strong></p>
<p class="remove-vertical-margin justify" style="margin-top: 4px; padding-left: 16px;">
Evidera will provide a list of End User’s previous participations with the amount and donations including End User’s overall balance
</p>

<p class="remove-vertical-margin" style="margin-top: 20px;"><strong>TAXES/LEGAL</strong></p>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">1.</p>
  <p class="remove-vertical-margin justify flexAuto">
  You are solely responsible for declaring any taxes, if due, to your local financial authorities as a result of compensation that you receive. You acknowledge that Evidera may supply information to taxing agencies, or withhold taxes, at the request of those agencies or as Evidera in its sole discretion deems appropriate. Evidera will not be liable for any taxes or for providing any assistance on tax matters.
  </p>
</div>

<p style="margin-top: 20; margin-bottom: 20;" class="justify">
  <i>US Residents: </i>You may be required under federal, state, and/or local laws to pay taxes on the compensation you earn. You acknowledge and agree that you will provide Evidera with any additional personal information via IRS Form W-9, such as social security number or Taxpayer ID number, which Evidera needs to comply with any reporting or withholding obligations. Your failure to provide such information within 30 days of a request sent by Evidera to the primary mailing address registered at that time may result in the delay of payment of your compensation.
</p>

<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">2.</p>
  <p class="remove-vertical-margin justify flexAuto">
  End User acknowledges and agrees that any payment received is fair value for End User’s participation, is made on an arm's length basis and End User explicitly agrees that it is not an inducement to: prescribe any medical treatment, nor promote or deliver any medical product or service, or otherwise modify your behavior in any way related to the delivery of care.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">3.</p>
  <p class="remove-vertical-margin justify flexAuto">
  <i>Healthcare Professionals:</i> As required by disclosure laws and regulations in various jurisdictions, M3 may be obligated to report payments made to you for your participation in our marketing research and other programs. By your participation in these programs, you permit us to disclose your earned compensation and other applicable payments as appropriate to comply with such requirements.
  </p>
</div>

<p style="margin-top: 50px;"></p>
`;