import React from "react";
import {
  StyleSheet,
  KeyboardAvoidingView,
  Platform,
} from "react-native";
// import {authState}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: "white",
  },
  webStyle: {
    backgroundColor: "rgba(50,50,50,0.1)",
    shadowOffset: {
      height: 2,
      width: 2,
    },
    shadowOpacity: 50,
    maxHeight: "100vh",
  },
  image: {
    marginTop: 10,
    marginBottom: 30,
  },
});

export const AuthView = (props: any) => (
  <KeyboardAvoidingView
    behavior={Platform.OS === "ios" ? "padding" : "height"}
    style={[styles.root, Platform.OS === "web" && styles.webStyle]}
  >
    {props.children}
  </KeyboardAvoidingView>
);
