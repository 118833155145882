import React, { useState, useContext, useEffect } from "react";
import { StyleSheet, View, ScrollView, Platform } from "react-native";
import { useTheme, ActivityIndicator, Title } from "react-native-paper";
import { API, graphqlOperation } from "aws-amplify";
import gql from "graphql-tag";
import { UserInfoContext } from "../../../context/userInfo";
import { ProfileInterestItem } from "./profile.interest.item";
import { ColorsEvidera } from "../../../theme/variables";
import { translate } from "../../../components/Localei18n/languagesFunctions";
import { sendAnalyticsEvent } from "../../../components/Analytics/analytics";

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
});

export const ProfileInterest = (props) => {
  // const ma: any = useContext(UserContext);
  const { managedUserInfo } = useContext(UserInfoContext);

  const theme = useTheme();

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);

  const getContacts = async () => {
    setLoading(true);
    try {
      const qry = gql`
        query($panelist_id: Int!) {
          getMiniSurvey(panelist_id: $panelist_id) {
            question_id
            question
            response
          }
        }
      `;
      const response: any = await API.graphql(
        graphqlOperation(qry, {
          panelist_id: managedUserInfo.panelist_id,
        })
      );

      setLoading(false);

      const filteredItems = response.data.getMiniSurvey.filter((item) => item.response === "" || item.response === null);

      setData(filteredItems);
    } catch (e) {
      console.log(["profilecontects->interest->error", e]);

      setLoading(false);
    }
  };

  useEffect(() => {
    getContacts();
  }, []);

  useEffect(() => {
    sendAnalyticsEvent("Opportunities");
  }, []);

  return (
    <ScrollView style={styles.root}>
      {!loading ? (
        <View style={{ paddingTop: 24 }}>
          <View />
          <Title
            style={{
              paddingLeft: 15,
              color: ColorsEvidera.additionalSecondary,
              marginBottom: 20,
            }}
          >
            {data
            && data.length >= 1
              ? translate(
                "Please confirm if you are interested in knowing more about:"
              )
              : translate(
                "There is no new opportunity available at that time."
              )}
          </Title>

          {data
            && data.length >= 1
            && data.map((e, index) => (
              <ProfileInterestItem
                qnum={index + 1}
                panelist_id={managedUserInfo.panelist_id}
                key={index.toString()}
                {...e}
              />
            ))}
        </View>
      ) : (
        <View style={{ paddingTop: 30 }}>
          <ActivityIndicator size={40} color={theme.colors.text} />
        </View>
      )}
    </ScrollView>
  );
};
