import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  root: {
    marginBottom: 8,
    paddingTop: 18,
    // paddingBottom: 18,
  },
  container: {
    flexDirection: "row",
    paddingRight: 15,
  },
  leftColumn: {
    width: 70,
    alignItems: "center",
  },
  rightColumn: {
    flex: 1,
    justifyContent: "flex-start",
    alignItems: "baseline",
  },
  dot: {
    fontSize: 3,
  },
  caption: {
    fontWeight: "700", lineHeight: 16, fontSize: 14, marginVertical: 3,
  },
  content: {
    // borderTopWidth: 1,
    paddingTop: 17,
    paddingHorizontal: 15,
    paddingBottom: 18,
  },
  actionContainer: {
    // paddingTop: 10,
    paddingHorizontal: 15,
    flexDirection: "row",
    justifyContent: "center",
    // marginBottom: -5,
    // marginRight: -10,
  },
  actionButton: {
    // margin: 0,
    flexDirection: "row",
    paddingHorizontal: 8,
    paddingVertical: 12,
  },
  actionButtonText: {
    fontSize: 14,
    marginLeft: 5,
    textTransform: "capitalize",
  },
  contentAmount: {
    lineHeight: 21,
    fontSize: 14,
    paddingLeft: 15,
    paddingRight: 8,
  },
});

export default styles;
