import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import Constants from "expo-constants";

const app = initializeApp(Constants.manifest.web.config.firebase);
const webAnalytics = getAnalytics(app);

export async function sendAnalyticsEvent(log) {
  await logEvent(webAnalytics, log);
}
