export const TermsDE =  `
<h3 style="text-align: center; margin-top: 25px; margin-bottom: 0;">ENDBENUTZER</h3>
<h4 style="text-align: center; margin-bottom: 40px; margin-top: 8px;">GESCHÄFTSBEDINGUNGEN</h4>

<div class="agreement">
  <p class="justify">
  Diese Vereinbarung (die "Vereinbarung") wird von und zwischen Evidera, Ltd., einem britischen Unternehmen mit Sitz in The Ark 201 Talgarth Rd, Hammersmith, London W6 8BJ ("Evidera") und dem Unternehmen, das den hierin enthaltenen Bedingungen zustimmt ("Endbenutzer") geschlossen. Diese Vereinbarung regelt den Zugang und die Nutzung der Dienste durch den Endbenutzer und gilt ab dem Datum des Inkrafttretens. Diese Vereinbarung gilt ab dem Datum, an dem der Endbenutzer auf die Schaltfläche "Ich akzeptiere" unten klickt (das "Datum des Inkrafttretens"). Wenn Sie im Namen des Endbenutzers akzeptieren, versichern Sie und garantieren, dass: (i) Sie die volle rechtliche Befugnis haben, Ihren Arbeitgeber oder das betreffende Unternehmen an diese Geschäftsbedingungen zu binden; (ii) Sie diese Vereinbarung gelesen und verstanden haben; und (iii) im Namen der Partei, die Sie vertreten, dieser Vereinbarung zustimmen. Wenn Sie nicht die gesetzliche Berechtigung zum Verpflichten des Endbenutzers haben, klicken Sie unten nicht auf die Schaltfläche "Ich akzeptiere". Diese Vereinbarung regelt den Zugang und die Nutzung der Dienste durch den Endbenutzer.
  </p>
</div>

<div class="flex flex-row">
  <p class="order remove-vertical-margin">1.</p>
  <p class="remove-vertical-margin"><strong>Dienstleistungen.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">1.1</p>
  <p class="remove-vertical-margin justify flexAuto">
  Einrichtungen und Datenübertragung. Alle Einrichtungen, in denen Endbenutzerdaten gespeichert und verarbeitet werden, erfüllen angemessene Sicherheitsstandards und entsprechen allen geltenden Datenschutzgesetzen und -bestimmungen, einschließlich, aber nicht beschränkt auf nationale Gesetze zur Umsetzung der Allgemeinen Datenschutzverordnung („Verordnung“) der Europäischen Union („EU“), des Gesetzes über die Portabilität und Rechenschaftspflicht von Krankenversicherungen („ HIPAA“) und der Gesetze gegen Sicherheitsverletzungen in den USA, Datenschutzgesetze, die von immer mehr anderen Gerichtsbarkeiten weltweit verabschiedet wurden, sowie der Datenschutz- und Vertraulichkeitsanforderungen der ICH-Leitlinie zur Guten Klinischen Praxis („GCP“, Good Clinical Practice).
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">1.2</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Änderungen.</strong>
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub2">a.</p>
  <p class="remove-vertical-margin justify flexAuto">
  An den Diensten. Evidera hat das Recht, jeden Teil des Dienstes jederzeit ohne vorherige Ankündigung zu ändern, auszusetzen oder einzustellen. Wenn Evidera wesentliche Änderungen an den Diensten vornimmt, informiert Evidera den Endbenutzer.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub2">b.</p>
  <p class="remove-vertical-margin justify flexAuto">
  An dieser Vereinbarung. Evidera behält sich das Recht vor, die in dieser Vereinbarung enthaltenen Bedingungen oder die für den Service geltenden Richtlinien  jederzeit zu ändern oder zu modifizieren, indem die neue Vereinbarung unter https://medimix.net/termsandconditions/ oder einer von Evidera veröffentlichten URL  zur Verfügung gestellt wird. Evidera benachrichtigt den Endbenutzer über Aktualisierungen dieser Vereinbarung. Änderungen oder Modifikationen dieser Vereinbarung werden verbindlich (i) durch die Online-Akzeptanz der aktualisierten Bedingungen durch den Endbenutzer oder (ii) durch die fortgesetzte Nutzung des Dienstes durch den Endbenutzer, nachdem diese Bedingungen von Evidera aktualisiert wurden.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">2.</p>
  <p class="remove-vertical-margin"><strong>Pflichten des Endbenutzers.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">2.1</p>
  <p class="remove-vertical-margin justify flexAuto">
  Einhaltung. Der Kunde wird die Dienste gemäß den Richtlinien zur akzeptablen Nutzung nutzen. Evidera kann von Zeit zu Zeit neue Anwendungen, Features oder Funktionen für die Dienste zur Verfügung stellen, deren Nutzung von der Zustimmung des Kunden zu zusätzlichen Bedingungen abhängig gemacht werden kann.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">2.2</p>
  <p class="remove-vertical-margin justify flexAuto">
  Nutzungsbeschränkungen. Sofern Evidera nicht ausdrücklich schriftlich zustimmt, wird der Kunde keine wirtschaftlich angemessenen Anstrengungen unternehmen, um sicherzustellen, dass ein Dritter nicht: (a) die Dienste an einen Dritten verkauft, weiterverkauft, verleast oder das funktionale Äquivalent (sofern nicht ausdrücklich in dieser Vereinbarung genehmigt); (b) versucht, die Dienste oder eine Komponente zurückzuentwickeln; (c) versucht, durch Nutzung oder Zugang zu den Diensten einen Ersatzdienst oder einen ähnlichen Dienst einzurichten; (d) die Dienste für Aktivitäten mit hohem Risiko nutzt; oder (e) die Dienste zum Speichern oder Übertragen von Endbenutzerdaten verwendet, die gemäß den Exportkontrollgesetzen für den Export kontrolliert werden. Der Endbenutzer ist allein verantwortlich für die Einhaltung der HIPAA.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">2.3</p>
  <p class="remove-vertical-margin justify flexAuto">
  Verwendungsrichtlinien und -beschränkungen. Dem Endbenutzer steht 1 Endbenutzerkonto zu. Der Endbenutzer hat alle von Evidera von Zeit zu Zeit angeforderten zusätzlichen Nutzungsrichtlinien und -beschränkungen für die Nutzung des Dienstes einzuhalten.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">2.4</p>
  <p class="remove-vertical-margin justify flexAuto">
  Datenschutz. Der Endbenutzer meldet nur nicht identifizierte Informationen auf Patientenebene, die aus medizinischen Unterlagen stammen. Die Informationsdaten der Endbenutzerberichte werden sicher gespeichert und von Evidera verwaltet. Diese Informationen und damit verbundenen Ergebnisse können von Evidera und ihren verbundenen Unternehmen für kommerzielle Zwecke (einschließlich kommerzieller Forschungszwecke) an externe Parteien lizenziert oder anderweitig in aggregierter anonymer Form vermarktet werden; um jeden Zweifel auszuschließen, werden solche aggregierten anonymen Informationen und damit verbundenen Ergebnisse Evidera gehören und Eigentum von Evidera sein.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">3.</p>
  <p class="remove-vertical-margin justify flexAuto">
  Technischer Support. Support per Telefon, E-Mail und per Live-Chat: Montag bis Freitag, außer an Feiertagen in den Ländern, in denen der Support erfolgt. Der Support wird sowohl von Feldarbeitsteams (Brasilien und den Philippinen) als auch von Support-Mitarbeitern in Irland, Spanien und Deutschland bereitgestellt. Der Support erfolgt zu den Geschäftszeiten des Teams, das die folgenden Sprachen unterstützt: Spanisch für Lateinamerika und Spanien (aus Spanien und Brasilien), Portugiesisch für Brasilien und Portugal (aus Brasilien), Deutsch für Deutschland aus Deutschland sowie für den Rest der Welt Englisch von den Philippinen und Irland.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">4.</p>
  <p class="remove-vertical-margin"><strong>Aussetzung.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">4.1</p>
  <p class="remove-vertical-margin justify flexAuto">
  der Endbenutzerkonten von Evidera. Wenn Evidera Kenntnis von einem Verstoß des Endbenutzers gegen die Vereinbarung erlangt, kann Evidera das entsprechende Endbenutzerkonto sperren. Die Dauer einer Aussetzung gilt so lange, bis der betreffende Endbenutzer den Verstoß behoben hat, der zur Aussetzung führte.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">4.2</p>
  <p class="remove-vertical-margin justify flexAuto">
  Sicherheitsfragen im Notfall. Ungeachtet des Vorstehenden kann Evidera die missbräuchliche Verwendung automatisch aussetzen, wenn ein Notfall-Sicherheitsproblem vorliegt. Die Aussetzung erfolgt in dem Mindestmaß und der Mindestdauer, die erforderlich sind, um das Problem der Notsicherheit zu verhindern oder zu beenden.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">5.</p>
  <p class="remove-vertical-margin"><strong>Vertrauliche Informationen.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">5.1</p>
  <p class="remove-vertical-margin justify flexAuto">
  Verpflichtungen. Jede Partei wird: (a) die vertraulichen Informationen der anderen Partei mit der gleichen Sorgfalt schützen, mit der sie ihre eigenen vertraulichen Informationen schützt; und (b) die vertraulichen Informationen nicht weitergeben, außer an verbundene Unternehmen, Mitarbeiter und Vertreter, die sie kennen müssen und die schriftlich zugestimmt haben, sie vertraulich zu behandeln. Jede Partei (und alle verbundenen Unternehmen, Mitarbeiter und Vertreter, denen sie vertrauliche Informationen mitgeteilt hat) darf vertrauliche Informationen nur zur Ausübung von Rechten und zur Erfüllung ihrer Verpflichtungen aus dieser Vereinbarung verwenden, wobei sie mit angemessener Sorgfalt geschützt werden. Jede Partei ist für alle Handlungen ihrer verbundenen Unternehmen, Mitarbeiter und Vertreter verantwortlich, die gegen diesen Abschnitt verstoßen.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">5.2</p>
  <p class="remove-vertical-margin justify flexAuto">
  Ausnahmen. Vertrauliche Informationen enthalten keine Informationen, die: (a) dem Empfänger der vertraulichen Informationen bereits bekannt waren; (b) ohne Verschulden des Empfängers öffentlich werden; (c) vom Empfänger unabhängig entwickelt wurden; oder (d) dem Empfänger zu Recht von einer anderen Partei gegeben wurden.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">5.3</p>
  <p class="remove-vertical-margin justify flexAuto">
  Erforderliche Angaben. Jede Partei kann die vertraulichen Informationen der anderen Partei offenlegen, wenn dies gesetzlich vorgeschrieben ist; jedoch erst dann, falls dies gesetzlich zulässig ist: (a) wenn sie wirtschaftlich angemessene Anstrengungen unternommen hat, um die andere Partei zu benachrichtigen; und (b) der anderen Partei die Möglichkeit gegeben hat, die Offenlegung anzufechten.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">6.</p>
  <p class="remove-vertical-margin"><strong> Rechte an geistigem Eigentum; Markenmerkmale.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">6.1</p>
  <p class="remove-vertical-margin justify flexAuto">
  Rechte an geistigem Eigentum. Sofern hierin nicht ausdrücklich dargelegt, gewährt diese Vereinbarung keiner Partei implizite oder sonstige Rechte am Inhalt der anderen Partei oder am geistigen Eigentum der anderen Partei.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">7.</p>
  <p class="remove-vertical-margin"><strong>Zusicherungen, Gewährleistungen und Haftungsausschlüsse.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">7.1</p>
  <p class="remove-vertical-margin justify flexAuto">
  Zusicherungen und Gewährleistungen. Jede Partei erklärt, dass sie die Vollmacht und Befugnis zum Abschluss des Vertrags besitzt. Jede Partei garantiert, dass sie alle Gesetze und Vorschriften einhält, die für die Bereitstellung oder Nutzung der Dienste gelten (einschließlich des geltenden Gesetzes zur Benachrichtigung über Sicherheitsverletzungen).
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">7.2</p>
  <p class="remove-vertical-margin justify flexAuto">
  Der Endbenutzer darf keine Inhalte verbreiten, die strafrechtlich verfolgt werden könnten, und keine anderen Rechte Dritter (insbesondere Rechte an geistigem Eigentum), das Wettbewerbsrecht oder allgemeine Persönlichkeitsrechte sowie Datenschutzbestimmungen verletzen. Der Endbenutzer ist für die Einhaltung aller geltenden Gesetze und Vorschriften in Bezug auf den elektronischen Geschäftsverkehr verantwortlich.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">7.3</p>
  <p class="remove-vertical-margin justify flexAuto">
  Haftungsausschluss. SOWEIT DIES NACH GELTENDEM RECHT ZULÄSSIG IST, ÜBERNIMMT KEINE PARTEI, AUSSER WIE AUSDRÜCKLICH HIERIN VORGESEHEN, EINE ANDERE GARANTIE JEGLICHER ART, UNABHÄNGIG DAVON, OB SIE AUSDRÜCKLICH, STILLSCHWEIGEND, GESETZLICH ODER ANDERWEITIG GELTEND GEMACHT WIRD.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">8.</p>
  <p class="remove-vertical-margin"><strong>Kündigung.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">8.1</p>
  <p class="remove-vertical-margin justify flexAuto">
  Durch Endbenutzer. Der Endbenutzer kann die Nutzung des Dienstes jederzeit einstellen.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">8.2</p>
  <p class="remove-vertical-margin justify flexAuto">
  Durch Evidera. Der Endbenutzer erklärt sich damit einverstanden, dass Evidera diese Vereinbarung jederzeit und aus irgendeinem Grund kündigen und/oder die Bereitstellung des gesamten oder eines Teils des Dienstes beenden kann. Ungeachtet des Vorstehenden wird Evidera dem Kunden mindestens dreißig (30) Tage vor Beendigung oder Aussetzung des Dienstes im Voraus Bescheid geben; vorausgesetzt, der Service kann sofort beendet werden, wenn (i) der Kunde gegen diese Vereinbarung verstoßen hat oder (ii) Evidera vernünftigerweise feststellt, dass es wirtschaftlich unpraktisch ist, den Dienst angesichts der geltenden Gesetze weiter bereitzustellen.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">8.3</p>
  <p class="remove-vertical-margin justify flexAuto">
  Auswirkungen der Kündigung. Bei Endigung des Vertrags erlöschen die Rechte, die eine Partei der anderen Partei gewährt, sofort.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">9.</p>
  <p class="remove-vertical-margin"><strong>Entschädigung.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">9.1</p>
  <p class="remove-vertical-margin justify flexAuto">
  Der Endbenutzer stellt Evidera von allen Verbindlichkeiten, Schäden und Kosten (einschließlich Abrechnungskosten und angemessener Anwaltskosten) frei, die sich aus Ansprüchen Dritter ergeben oder die Nutzung der Dienste durch den Endbenutzer unter Verstoß gegen die Bestimmungen der Richtlinien zur akzeptablen Nutzung betreffen. Die Partei, die eine Entschädigung beantragt, wird die andere Partei unverzüglich über den Anspruch informieren und mit der anderen Partei bei der Abwehr des Anspruchs zusammenarbeiten. Die entschädigende Partei hat die volle Kontrolle und Autorität über die Verteidigung, mit der Ausnahme, dass: (a) jeder Vergleich, bei dem die Partei, die eine Entschädigung beantragt, eine Haftung zulässt oder Geld zahlt, die vorherige schriftliche Zustimmung dieser Partei erfordert, wobei diese Zustimmung nicht unangemessen zurückgehalten oder verzögert werden darf, und (b) die andere Partei sich auf eigene Kosten mit ihrem eigenen Anwalt an der Verteidigung beteiligen kann.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">10.</p>
  <p class="remove-vertical-margin"><strong>Haftungsbeschränkung.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">10.1</p>
  <p class="remove-vertical-margin justify flexAuto">
  Beschränkung der indirekten Haftung. KEINE PARTEI HAFTET IM RAHMEN DIESER VEREINBARUNG FÜR ENTGANGENE EINNAHMEN ODER INDIREKTE, SPEZIELLE, NEBEN-, FOLGE-, EXEMPLARISCHEE ODER BUSSGELDSCHÄDEN, AUCH WENN DIE PARTEI WUSSTE ODER HÄTTE WISSEN MÜSSEN, DASS SOLCHE SCHÄDEN MÖGLICH SIND ODER SOGAR, WENN DIREKTE SCHÄDEN EIN RECHTSMITTEL NICHT ERFÜLLEN.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">10.2</p>
  <p class="remove-vertical-margin justify flexAuto">
  Höchstbetrag der Haftungsbeschränkung. KEINE PARTEI KANN IM RAHMEN DIESER VEREINBARUNG FÜR MEHR ALS TAUSEND DOLLAR (1000 USD) HAFTBAR GEMACHT WERDEN.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">10.3</p>
  <p class="remove-vertical-margin justify flexAuto">
  Ausnahmen von Einschränkungen. Diese Haftungsbeschränkungen gelten im gesetzlich zulässigen Umfang, jedoch nicht für Verstöße gegen Vertraulichkeitsverpflichtungen, Verstöße gegen die Rechte an geistigem Eigentum einer Partei durch die andere Partei oder für Freistellungs-verpflichtungen.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">11.</p>
  <p class="remove-vertical-margin"><strong>Verschiedenes.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.1</p>
  <p class="remove-vertical-margin justify flexAuto">
  Hinweise. Sofern hierin nicht anders angegeben: (a) müssen alle Mitteilungen schriftlich erfolgen und an die Rechtsabteilung und den Hauptansprechpartner der anderen Partei gerichtet sein;  (b) eine Benachrichtigung gilt als gegeben: (i) wenn sie durch schriftliche Empfangsbestätigung verifiziert wird, wenn sie von einem persönlichen Kurier oder Nachtkurier gesendet wird, oder wenn sie per Post ohne Empfangsbestätigung gesendet wird; oder (ii) wenn sie beim Versenden als Fax oder E-Mail durch automatisierten Empfang oder elektronische Protokolle verifiziert wird.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.2</p>
  <p class="remove-vertical-margin justify flexAuto">
  Abtretung. Keine Partei darf einen Teil dieser Vereinbarung ohne die schriftliche Zustimmung der anderen Partei abtreten oder übertragen, außer an ein verbundenes Unternehmen, jedoch nur, wenn: (a) der Abtretungsempfänger schriftlich zustimmt, an die Bestimmungen dieser Vereinbarung gebunden zu sein; und (b) die abtretende Partei für Verpflichtungen haftbar bleibt, die sich aus der Vereinbarung vor der Abtretung ergeben. Jeder andere Versuch einer Übertragung oder Abtretung ist nichtig.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.3</p>
  <p class="remove-vertical-margin justify flexAuto">
  Kontrollwechsel. Bei einem Wechsel in der Kontrolle eines Unternehmens (z. B. durch Aktienkauf oder -verkauf, Fusion oder eine andere Form der Unternehmenstransaktion) wird (a) die Partei, die den Kontrollwechsel erfährt, die andere Partei innerhalb von 30 Tagen nach dem Wechsel der Kontrolle schriftlich benachrichtigen; und (b) die andere Partei kann diesen Vertrag jederzeit zwischen dem Kontrollwechsel und dreißig Tagen nach Erhalt der schriftlichen Mitteilung in Unterabschnitt (a) sofort kündigen.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.4</p>
  <p class="remove-vertical-margin justify flexAuto">
  Höhere Gewalt. Keine Partei haftet für unzureichende Leistungen, die durch Bedingungen verursacht wurden, die außerhalb ihrer angemessenen Kontrolle lagen (z. B. Naturkatastrophe, Kriegs- oder Terrorakt, Aufruhr, Arbeitsbedingungen/Streik, staatliche Maßnahmen und Internetstörungen).
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.5</p>
  <p class="remove-vertical-margin justify flexAuto">
  Kein Verzicht. Die Nichtdurchsetzung einer Bestimmung dieser Vereinbarung bedeutet keinen Verzicht.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.6</p>
  <p class="remove-vertical-margin justify flexAuto">
  Salvatorische Klausel. Sollte sich herausstellen, dass eine Bestimmung dieser Vereinbarung nicht durchsetzbar ist, bleiben die übrigen Bestimmungen dieser Vereinbarung in vollem Umfang in Kraft und wirksam.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.7</p>
  <p class="remove-vertical-margin justify flexAuto">
  Keine Agentur. Die Parteien sind unabhängige Auftragnehmer und diese Vereinbarung begründet keine Agentur, Partnerschaft oder ein Joint Venture.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.8</p>
  <p class="remove-vertical-margin justify flexAuto">
  Keine Drittbegünstigten. Es gibt keine Drittbegünstigten dieser Vereinbarung.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.9</p>
  <p class="remove-vertical-margin justify flexAuto">
  Billigkeitsrechtlicher Rechtsbehelf. Nichts in dieser Vereinbarung wird die Befähigung einer Partei einschränken, eine gerechte Entlastung zu suchen.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.10</p>
  <p class="remove-vertical-margin justify flexAuto">
  Geltendes Recht. Diese Vereinbarung unterliegt dem Recht von North Carolina und schließt die Rechtswahlregeln dieses Staates aus. FÜR STREITIGKEITEN, DIE SICH AUS DIESER VEREINBARUNG ERGEBEN ODER DAMIT IN ZUSAMMENHANG STEHEN, STIMMEN DIE PARTEIEN DER PERSÖNLICHEN GERICHTSBARKEIT IM BUNDESSTAAT NORTH CAROLINA ZU.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.11</p>
  <p class="remove-vertical-margin justify flexAuto">
  Änderungen. Jede Änderung muss schriftlich erfolgen und ausdrücklich darauf hinweisen, dass diese Vereinbarung geändert wird.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.12</p>
  <p class="remove-vertical-margin justify flexAuto">
  Fortbestand von Paragrafen. Die folgenden Paragrafen gelten auch nach Ablauf oder Beendigung dieser Vereinbarung: Abschnitt 6 (Vertraulichkeit), 7 (Geistiges Eigentum), 9 (Zusicherungen, Gewährleistungen und Haftungsausschlüsse), 10 (Kündigung), 11 (Freistellung), 12 (Haftungsbeschränkung) und 13 (Verschiedenes).
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.13</p>
  <p class="remove-vertical-margin justify flexAuto">
  Gesamte Vereinbarung. Diese Vereinbarung und alle hierin genannten Dokumente stellen die gesamte Vereinbarung der Parteien zum Vertragsgegenstand dar und ersetzen alle vorherigen oder gleichzeitigen Vereinbarungen dazu. Die Bedingungen, die sich unter einer URL befinden und auf die in dieser Vereinbarung verwiesen wird, werden hiermit durch diesen Verweis aufgenommen.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.14</p>
  <p class="remove-vertical-margin justify flexAuto">
  Interpretation widersprüchlicher Begriffe. Wenn es einen Konflikt zwischen den Dokumenten gibt, aus denen diese Vereinbarung besteht, gilt folgende Reihenfolge der Dokumente: die Vereinbarung, gefolgt von den Bestimmungen, die sich unter https://medimix.net/termsandconditions/ befinden.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">12.</p>
  <p class="remove-vertical-margin"><strong>Definitionen.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  "Richtlinien zur akzeptablen Nutzung" bezeichnet die akzeptable Nutzungsrichtlinie für die in Anhang A dieses Dokuments verfügbaren Dienste
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  "Administratoren“ bezeichnet das vom Kunden benannte technische Personal, das die Dienste für Endbenutzer verwaltet.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  "Verbundenes Unternehmen" bezeichnet jedes Unternehmen, das direkt oder indirekt eine Partei kontrolliert, von dieser kontrolliert wird oder unter gemeinsamer Kontrolle mit einer Partei steht.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  "Vertrauliche Informationen" bezeichnet Informationen, die von einer Partei gegenüber der anderen Partei im Rahmen dieser Vereinbarung offengelegt werden und als vertraulich gekennzeichnet sind oder unter den gegebenen Umständen normalerweise als vertraulich angesehen werden. Kundendaten sind vertrauliche Informationen des Kunden.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  "Endbenutzerdaten" bezeichnet Daten, einschließlich E-Mail-Adressen, die von Endbenutzern über die Dienste bereitgestellt, generiert, übertragen oder angezeigt werden.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  "Notfall-Sicherheitsproblem" bedeutet entweder: (a) die Nutzung der Dienste durch den Endbenutzer unter Verstoß gegen die Richtlinie zur akzeptablen Nutzung, die Folgendes unterbrechen könnte: (i) die Dienste oder (ii) Nutzung der Dienste durch andere Endbenutzer; oder (b) unbefugter Zugriff Dritter auf die Dienste.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  "Endverbraucher" bezeichnet die Personen, denen Evidera die Nutzung der Dienste gestattet.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  "Endbenutzerkonto" bezeichnet ein von Evidera gehostetes Konto, das vom Endbenutzer zum Zwecke der Nutzung der Dienste eingerichtet wurde.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  "Exportkontrollgesetze" bezeichnet alle geltenden Gesetze und Vorschriften zur Export- und Wiederausfuhrkontrolle, einschließlich der Exportverwaltungsbestimmungen ("EAR") des US-Handelsministeriums, Handels- und Wirtschaftssanktionen des Amtes für ausländische Vermögenskontrolle des Finanzministeriums und der Regeln des Internationalen Waffenverkehrs ("ITAR"), die vom Außenministerium unterhalten werden.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  "HIPAA" bezeichnet das Gesetz über die Portabilität und Rechenschaftspflicht von Krankenversicherungen von 1996 in der jeweils gültigen Fassung sowie alle darin erlassenen Vorschriften.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  "Rechte an geistigem Eigentum" bedeutet gegenwärtige und zukünftige weltweite Rechte nach Patentrecht, Urheberrecht, Geschäftsgeheimnisrecht, Markenrecht, Urheberpersönlichkeitsrecht und anderen ähnlichen Rechten.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  "Dienste" bedeutet „Evidera-Portal für Gesundheitsdienstleister“, eine One-Stop-Anwendung für registrierte Ärzte zur einfacheren, regelmäßigen Teilnahme an den Online-Studien von Evidera.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 4px;">
  <p class="remove-vertical-margin justify flexAuto">
  Diese Anwendung ist auf 3 verschiedenen Plattformen verfügbar:
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  iOS (iPhone & iPad) - Apple Store
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  Android (Smartphones, Tablets und Chrome-Notebooks) - Google Play
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  Web (Windows oder Mac)
  </p>
</div>

<div class="flex flex-row" style="margin-top: 4px;">
  <p class="remove-vertical-margin justify flexAuto">
  Benachrichtigungen:
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  Der Endbenutzer erhält eine Benachrichtigung, sobald eine neue Studie verfügbar ist.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="remove-vertical-margin justify flexAuto">
  Teilnahmen:
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  Überprüfen neuer und ausstehender Teilnahmen.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="remove-vertical-margin justify flexAuto">
  Umfrage starten:
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  Starten oder Fortsetzen der Teilnahme von Endbenutzern.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="remove-vertical-margin justify flexAuto">
  Persönliche Daten:
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  Überprüfen der persönlichen Daten des Endbenutzers und Abschicken von Änderungen.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="remove-vertical-margin justify flexAuto">
  Zahlungen:
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  Überprüfen des vollständigen Ausfüllens von Umfragen durch Endbenutzer und Verfolgen der Zahlungen.
  </p>
</div>
<br/>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="remove-vertical-margin justify flexAuto">
  Haben Sie eine Frage oder ein Problem?
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  Erhalten Sie Unterstützung im Live-Chat oder im Bereich mit den am häufigsten gestellten Fragen (FAQs).
  </p>
</div>

<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  "Einstellen" bezeichnet die sofortige Deaktivierung des Zugriffs auf die Dienste oder Komponenten der Dienste, um eine weitere Nutzung der Dienste zu verhindern.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
  "Laufzeit" bezeichnet die Laufzeit des Vertrags, die am Datum des Inkrafttretens beginnt und entweder andauert bis: (i) zum Ende der letzten Laufzeit des Dienstes oder (ii) zur Kündigung des Vertrags, wie hierin dargelegt.
  </p>
</div>


<h3 style="text-align: center; margin-top: 50px; margin-bottom: 0;">ANHANG A</h3>
<h4 style="text-align: center; margin-bottom: 30px; margin-top: 8px;">Richtlinien zur akzeptablen Nutzung</h4>

<div class="flex flex-row">
  <p class="remove-vertical-margin"><strong>Teilnahmeberechtigung</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">1.</p>
  <p class="remove-vertical-margin justify flexAuto">
  Pro Befragten ist nur eine Registrierung zulässig. Bei Verstößen können Sie vom Anspruch auf die entsprechende Entschädigung ausgeschlossen werden, was dazu führen kann, dass Ihnen die Teilnahme an zukünftigen Umfragen untersagt wird. Wenn Sie sich bereits registriert haben und nur auf eine neue E-Mail-Adresse aktualisieren müssen, melden Sie sich an und aktualisieren Sie Ihre E-Mail-Adresse im Bereich Konto.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">2.</p>
  <p class="remove-vertical-margin justify flexAuto">
  Wenn Sie Ihre Identität oder andere Informationen bei der Registrierung oder Beantwortung von Umfragen falsch darstellen, werden Sie von der Auszahlung der entsprechenden Vergütung ausgeschlossen und somit möglicherweise von der Teilnahme an zukünftigen Umfragen.
  </p>
</div>

<p style="margin-top: 20; margin-bottom: 20;" class="justify">
  <i>Medizinische Fachkräfte: </i>Teilnahmeberechtigt sind nur derzeit praktizierende Angehörige der Gesundheitsberufe. Eine falsche Darstellung Ihrer Anmeldeinformationen führt zum Ausschluss der geltenden Entschädigung und kann dazu führen, dass Sie von der zukünftigen Teilnahme ausgeschlossen werden.
</p>

<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">3.</p>
  <p class="remove-vertical-margin justify flexAuto">
  Einladungen sind für Sie persönlich gerichtet und dürfen nicht an andere weitergeleitet werden, es sei denn, dies wurde ausdrücklich in der E-Mail-Einladung genehmigt.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">4.</p>
  <p class="remove-vertical-margin justify flexAuto">
  Evidera hat das Recht, potenzielle Teilnehmer nach eigenem Ermessen einzuladen oder nicht einzuladen.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">5.</p>
  <p class="remove-vertical-margin justify flexAuto">
  Alle Antworten müssen nach bestem Wissen und Gewissen wahr und genau sein. Falsche oder irreführende Antworten führen zum Verlust der Entschädigung und zum Verbot der künftigen Teilnahme.
  </p>
</div>


<p class="remove-vertical-margin" style="margin-top: 20px;"><strong>VERGÜTUNG</strong></p>
<p class="remove-vertical-margin justify" style="margin-top: 4px; padding-left: 16px;">
Evidera wird eine Liste der früheren Teilnahmen des Endbenutzers mit Betrag- und Spendenangaben einschließlich des Gesamtsaldos des Endbenutzers bereitstellen.
</p>

<p class="remove-vertical-margin" style="margin-top: 20px;"><strong>RECHTLICHES</strong></p>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">1.</p>
  <p class="remove-vertical-margin justify flexAuto">
  Sie sind allein dafür verantwortlich, Steuern an Ihre örtlichen Finanzbehörden zu melden, falls diese aufgrund einer Entschädigung, die Sie erhalten, anfallen. Sie erkennen an, dass Evidera auf Ersuchen dieser Behörden oder nach eigenem Ermessen von Evidera Informationen an Steuerbehörden übermitteln oder Steuern einbehalten kann. Evidera haftet nicht für Steuern oder für die Unterstützung in Steuerfragen.
  </p>
</div>

<p style="margin-top: 20; margin-bottom: 20;" class="justify">
  <i>US-Bürger: Nach Bundes-, Landes- und/oder örtlichen Gesetzen müssen Sie möglicherweise Steuern auf die von Ihnen verdiente Entschädigung zahlen. Sie erkennen an und erklären sich damit einverstanden, dass Sie Evidera über das IRS-Formular W-9 zusätzliche persönliche Informationen wie die Sozialversicherungsnummer oder die Steuerzahler-ID-Nummer zur Verfügung stellen, die Evidera zur Erfüllung von Melde- oder Quellensteuerpflichten benötigt. Wenn Sie diese Informationen nicht innerhalb von 30 Tagen nach einer von Evidera an die zu diesem Zeitpunkt registrierte primäre Postanschrift gesendeten Anfrage bereitstellen, kann dies zu einer Verzögerung der Zahlung Ihrer Entschädigung führen. </i>
</p>

<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">2.</p>
  <p class="remove-vertical-margin justify flexAuto">
  Der Endbenutzer erkennt an und stimmt zu, dass jede erhaltene Zahlung den Zeitwert für die Teilnahme des Endbenutzers widerspiegelt, zu marktüblichen Bedingungen erfolgt und er ferner ausdrücklich zustimmt, dass dies kein Anreiz darstellt, eine medizinische Behandlung zu verschreiben, ein medizinisches Produkt zu bewerben oder zu liefern oder sonstwie den Service oder das eigene Verhalten in irgendeiner Weise im Zusammenhang mit der Erbringung von Pflege zu ändern.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">3.</p>
  <p class="remove-vertical-margin justify flexAuto">
  Angehörige von Gesundheitsberufen: Gemäß den Offenlegungsgesetzen und -bestimmungen in verschiedenen Ländern ist M3 möglicherweise verpflichtet, Zahlungen an Sie für Ihre Teilnahme an unserer Marktforschung und anderen Programmen zu melden. Durch Ihre Teilnahme an diesen Programmen gestatten Sie uns, Ihre verdiente Vergütung und andere betreffende Zahlungen offenzulegen, um diesen Anforderungen gerecht zu werden.
  </p>
</div>

<p style="margin-top: 50px;"></p>
`;