export const TermsES = `
<h3 style="text-align: center; margin-top: 25px; margin-bottom: 0;">TÉRMINOS Y CONDICIONES</h3>
<h4 style="text-align: center; margin-bottom: 40px; margin-top: 8px;">RELATIVAS AL USUARIO FINAL</h4>

<div class="agreement">
  <p class="justify">
    El presente Acuerdo (en lo sucesivo, el “Acuerdo”) se celebra por y entre Evidera, Ltd., sociedad del Reino Unido, con oficinas en The Ark 201 Talgarth Rd. Hammersmith, Londres W6 8BJ (en adelante, “Evidera”) y la entidad que acepta las condiciones del presente (en lo sucesivo, el “Usuario Final”). El presente Acuerdo rige el acceso del Usuario Final a los Servicios, así como su uso por el mismo y será efectivo en la Fecha de Entrada en Vigor. Este Acuerdo entrará en vigor en el momento en el cual el Usuario Final haga clic en el botón “Acepto” a continuación (en adelante, la “Fecha de Entrada en Vigor”). Si acepta en nombre del Usuario Final, usted declara y garantiza que: (i) dispone de la plena autoridad jurídica a los efectos de vincular a su empleador o la entidad correspondiente a cumplir con estos términos y condiciones; (ii) ha leído y entendido este Acuerdo; y (iii) conviene, en nombre de la parte que representa, en este Acuerdo. Si no dispone de la autoridad legal para vincular al Usuario Final, por favor, no haga clic en el botón “Acepto” a continuación. El presente Acuerdo rige el acceso del Usuario Final a los Servicios, así como su uso por el mismo.
  </p>
</div>

<div class="flex flex-row">
  <p class="order remove-vertical-margin">1.</p>
  <p class="remove-vertical-margin"><strong>Servicios.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">1.1</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Instalaciones y Transferencia de Datos.</strong>
    Instalaciones y Transferencia de Datos. Todas las instalaciones utilizadas para el almacenamiento y procesado de datos del Usuario Final cumplirán con todas las normativas razonables de seguridad y respetarán las leyes y normativas aplicables a la confidencialidad de los datos, incluyendo sin limitarse a ello, las leyes nacionales que transponen el Reglamento General sobre Protección de Datos (en lo sucesivo, el “RGPD”) de la Unión Europea (en adelante, la “UE”), la Ley relativa a la Portabilidad y Responsabilidad del Seguro Médico (en lo sucesivo, “HIPAA”) y las leyes relativas a la violación de la seguridad del Estado en Estados Unidos, la legislación relativa a la protección de datos adoptada por un número ascendente de otros fueros a escala global, así como los requisitos relativos a la privacidad y la confidencialidad, exigidos por las Buenas Prácticas Clínicas (en adelante, “GCP”) del ICH (Consejo Internacional de armonización de los requisitos técnicos para el registro de medicamentos de uso humano).
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">1.2</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Modificaciones.</strong>
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub2">a.</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>A los servicios.</strong>
    Evidera se reserva el derecho de cambiar, suspender o interrumpir cualquier aspecto de todo Servicio en cualquier momento, sin previo aviso. Si Evidera modifica materialmente los Servicios, deberá informar al Usuario Final.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub2">b.</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Al presente Acuerdo.</strong>
    Evidera se reserva el derecho de cambiar o modificar cualquiera de las condiciones incluidas en este Acuerdo o toda política aplicable al Servicio, en cualquier momento, publicando el nuevo acuerdo en https://medimix.net/termsandconditions/ o cualquier URL que Evidera considere poner a disposición. Evidera notificará al Usuario Final cualquier actualización de este Acuerdo. Cualquier cambio o modificación a este Acuerdo será vinculante (i) en el momento de la aceptación online por el Usuario Final de las condiciones actualizadas, o (ii) tras el uso continuado del Servicio por el Usuario Final tras la actualización de dichas condiciones por Evidera.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">2.</p>
  <p class="remove-vertical-margin"><strong>Obligaciones del Usuario Final.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">2.1</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Conformidad.</strong>
    El Cliente utilizará los Servicios conforme a la Política relativa a un Uso Adecuado. Evidera podrá desarrollar nuevas aplicaciones, características o funcionalidades para los Servicios disponibles, de forma puntual, cuyo uso podría quedar sujeto a la aceptación de las condiciones adicionales por el Cliente.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">2.2</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Restricciones de Uso.</strong>
    Salvo consentimiento específico y por escrito de Evidera, el Cliente no podrá y se esforzará, comercialmente y razonablemente, a los efectos que los terceros: (a) no puedan vender, ni revender, alquilar o equivalente funcional, los Servicios a un tercero (salvo que el presente Acuerdo lo autorice expresamente) ; (b) no intenten retro diseñar el Servicio o cualquiera de sus componentes; (c) no intenten crear un servicio similar o substitutivo mediante el uso o el acceso a los Servicios; (d) no utilicen los Servicios en el marco de Actividades de Alto Riesgo; o (e) utilicen los Servicios con el fin de almacenar o transferir cualquier Dato del Usuario Final cuya exportación esté controlada al amparo de las Leyes relativas al Control de las Exportaciones. El Usuario Final será el único responsable de toda conformidad aplicable a la HIPAA.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">2.3</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Políticas y Límites de Uso.</strong>
    El Usuario Final tendrá derecho a 1 Cuenta de Usuario Final. EL Usuario Final deberá cumplir con cualquier política y límite adicional de utilización relativo al uso del Servicio, tal y como lo requiere Evidera de forma puntual.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">2.4</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Privacidad.</strong>
    El Usuario Final sólo dará cuenta de las informaciones individuales y desidentificadas del paciente que provengan de los historiales médicos. La información de la cual el Usuario Final deberá dar cuenta será almacenada y conservada de forma segura por Evidera, dicha información, así como los resultados vinculados a ella, podrán ser acreditados o de otro modo comercializados por Evidera y sus filiales a partes externas y fines comerciales (incluyendo fines de investigación comercial), en un formato anónimo agrupado; y, además, para que no quepa duda, dicha información y dichos resultados vinculados agrupados de forma anónima pertenecerán a Evidera quien será su propietario.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">3.</p>
  <p class="remove-vertical-margin justify flexAuto">
    Servicios de Soporte Técnico. Se proveerán mediante teléfono, email y LifeChat, de lunes a viernes, salvo festivos en los países desde los cuales se preste el servicio. El soporte es provisto por equipos de campo (Brasil y Filipinas) y por Agentes residentes en Irlanda, España y Alemania. El horario de asistencia coincide con el horario laboral de los equipos que hablan los idiomas siguientes: español para cubrir Latinoamérica y España (desde España y Brasil), portugués para cubrir Brasil y Portugal (desde Brasil), alemán para Alemania desde Alemania y el resto del mundo en inglés, desde Filipinas e Irlanda.
    <br/>
    El horario de asistencia coincide con el horario laboral de los equipos que hablan los idiomas siguientes: español para cubrir Latinoamérica y España (desde España y Brasil), portugués para cubrir Brasil y Portugal (desde Brasil), alemán para Alemania desde Alemania y el resto del mundo en inglés, desde Filipinas e Irlanda.
    </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">4.</p>
  <p class="remove-vertical-margin"><strong>Suspensión.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">4.1</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>De cuentas de Usuario Final por Evidera.</strong>
    Si Evidera se entera de la violación del Acuerdo por parte de un Usuario Final, podrá Suspender la Cuenta de Usuario Final correspondiente. El plazo de cualquier Suspensión vencerá cuando dicho Usuario corrija la violación que causó la Suspensión.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">4.2</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Problemas Prioritarios de Seguridad.</strong>
    No obstante lo anterior, en caso de Problema Prioritario de Seguridad, Evidera podrá Suspender automáticamente todo uso infractor. La dimensión de la envergadura y del plazo de la Suspensión se reducirá al máximo exigido para prevenir o poner fin al Problema Prioritario de Seguridad.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">5.</p>
  <p class="remove-vertical-margin"><strong>Información Confidencial.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">5.1</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Obligaciones.</strong>
    Cada parte deberá: (a) proteger la Información Confidencial de la otra parte con el mismo cuidado con el cual protege su propia Información Condicional; y (b) no divulgará dicha Información Confidencial, salvo a Filiales, empleados y agentes que necesiten conocerla y aquellos que se hayan comprometido por escrito a mantenerla como confidencial. Cada parte (sí como cualquier Filial, empleado o agente a quien dicha Información Confidencial haya sido divulgada) puede utilizar esta Información Confidencial a los efectos exclusivos de ejercer sus derechos y respetar sus obligaciones al amparo del presente Acuerdo, a la vez que toma las medidas necesarias para protegerla. Cada parte es responsable de cualquier actuación de sus Filiales, empleados y agentes que violen esta Sección.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">5.2</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Excepciones.</strong>
    La Información Confidencial no incluye información que: (a) el destinatario ya conozca; (b) sea del dominio público sin error del destinatario; (c) ha sido desarrollada independientemente por el destinatario; o (d) ha sido puesta de forma legítima a disposición del destinatario por otra parte.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">5.3</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Divulgación Requerida.</strong>
    Cada parte podrá divulgar la Información Confidencial de la otra parte cuando sea requerida de por ley, salvo, si es legalmente aceptable: (a) se esfuerza comercialmente en notificar a la otra parte; y (b) da la posibilidad a la otra parte de cuestionar dicha divulgación.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">6.</p>
  <p class="remove-vertical-margin"><strong>Derechos de Propiedad Intelectual; Características de la Marca.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">6.1</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Derechos de Propiedad Intelectual.</strong>
    Salvo expresamente estipulado en el presente Acuerdo, el mismo no otorga ningún derecho a las partes, ya sea implícito u otro, respecto del contenido o de la propiedad intelectual de la otra parte.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">7.</p>
  <p class="remove-vertical-margin"><strong>Declaraciones, Garantías y Descargos de Responsabilidad.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">7.1</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Declaraciones y Garantías.</strong>
    Cada parte certifica disponer de los plenos poderes, así como de la autoridad, a los efectos de celebrar el presente Acuerdo. Cada parte garantiza que cumplirá con las leyes y normativas aplicables a la puesta a disposición o el uso de los Servicios, en su caso (incluyendo la ley aplicable a las notificaciones de violación de la seguridad).
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">7.2</p>
  <p class="remove-vertical-margin justify flexAuto">
    El Usuario Final se abstendrá de distribuir cualquier contenido pasible de actuaciones penales, así como de violar cualquier otro derecho perteneciente a terceros (derechos de propiedad intelectual en particular), la ley de competencia o los de las personas de forma general, así como la normativa relativa a la protección de datos. El Usuario Final es responsable de cumplir con toda ley y normativa aplicable en el ámbito del comercio electrónico.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">7.3</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Descargos de Responsabilidad.</strong>
    EN TODA LA MEDIDA PERMITIDA O AUTORIZADA DE POR LEY, SALVO DISPOSICIONES EXPRESAS INCLUIDAS EN EL PRESENTE, NINGUNA DE LAS PARTES CONSTITUIRÁ UNA GARANTÍA, CUALQUIERA QUE SEA SU NATURALEZA, EN BENEFICIO DE LA OTRA PARTE, YA SEA EXPRESA, IMPLÍCITA, ESTATUTARIA U OTRA INCLUYENDO SIN LIMITACIÓN, TODA GARANTÍA DE CONFORMIDAD, IDONEIDAD PARA UN USO PARTICULAR Y NO VIOLACIÓN.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">8.</p>
  <p class="remove-vertical-margin"><strong>Resolución.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">8.1</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Por el Usuario Final.</strong>
    El Usuario Final podrá interrumpir el uso del Servicio en todo momento.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">8.2</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Por Evidera.</strong>
    El Usuario Final conviene que Evidera podrá, en todo momento y por cualquier motivo, resolver el presente Acuerdo y/o resolver la puesta a disposición total o parcial del Servicio. No obstante lo que antecede, Evidera notificará la resolución o interrupción del Servicio al Cliente en un plazo previo de al menos treinta (30) días; salvo que el Servicio pueda resolverse inmediatamente si (i) el Cliente ha violado el presente Acuerdo o si (ii) Evidera determina, de forma razonable, que no es comercialmente factible proseguir con la prestación del Servicio a la luz de la legislación aplicable.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">8.3</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Efectos de la Resolución.</strong>
    En caso de resolución del presente Acuerdo, los derechos otorgados por una parte a la otra parte se extinguirán inmediatamente.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">9.</p>
  <p class="remove-vertical-margin"><strong>Indemnización.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">9.1</p>
  <p class="remove-vertical-margin justify flexAuto">
    El Usuario Final indemnizará, defenderá y eximirá a Evidera de toda responsabilidad, todo daño y gasto (incluyendo las costas y los honorarios razonables de los letrados) derivados de cualquier reclamación procedente de un tercero o relativa al uso de los Servicios por el Usuario Final en violación de la Política relativa a un Uso Adecuado. La parte que solicite la indemnización notificará la reclamación a la otra parte a la mayor brevedad posible y cooperará con la misma a los efectos de defender dicha reclamación. La parte indemnizadora dispondrá del pleno control y de la plena autoridad de la defensa, salvo: (a) todo acuerdo que requiera de la parte que solicite la indemnización admitir la responsabilidad o abonar todo importe, lo que exigirá su consentimiento previo y por escrito, consentimiento que no podrá descartarse o retrasarse de forma no razonable; y (b) la otra parte podrá intervenir en la defensa con su propio letrado y a su cargo.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">10.</p>
  <p class="remove-vertical-margin"><strong>Limitación de Responsabilidad.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">10.1</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Limitación de Responsabilidad Indirecta.</strong>
    NINGUNA DE LAS PARTES SERÁ RESPONSABLE CONFORME AL PRESENTE ACUERDO POR LUCRO CESANTE O DAÑOS INDIRECTOS, ESPECIALES, ACCIDENTALES, CONSECUTIVOS, EJEMPLARES O PUNITIVOS, AUNQUE LA PARTE SUPIERA O DEBERÍA HABER SABIDO QUE DICHOS DAÑOS ERAN FACTIBLES, Y AUNQUE LOS DAÑOS DIRECTOS NO RESUELVAN UN RECURSO.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">10.2</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Limitación del importe de la responsabilidad.</strong>
    NINGUNA DE LAS PARTES SERÁ RESPONSABLE, CONFORME AL PRESENTE ACUERDO, MÁS ALLÁ DE UN IMPORTE DE MIL DÓLARES (1.000 $).
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">10.3</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Excepciones a estas limitaciones.</strong>
    Las limitaciones de responsabilidad se aplicarán en toda la medida permitida por la ley aplicable, aunque no se aplicarán a la violación de las obligaciones de confidencialidad, violaciones de los Derechos de Propriedad Intelectual de una parte por la otra parte, u obligaciones de indemnizar.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">11.</p>
  <p class="remove-vertical-margin"><strong>Miscelánea.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.1</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Notificaciones.</strong>
    Salvo que se especifique de otra forma en el presente: (a) toda notificación deberá realizarse por escrito y dirigirse a la atención del departamento jurídico y persona de contacto principal de la otra parte; y (b) las notificaciones se considerarán como entregadas: (i) cuando se compruebe su recepción por escrito si han sido expedidas mediante mensajero personal, entregadas de forma inmediata, o cuando se han recibido tras su envío mediante email sin comprobación de su recepción; o (ii) cuando han sido comprobadas mediante recepción automatizada o registro electrónico si han sido enviadas mediante fax o email.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.2</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Cesión.</strong>
    Ninguna de las partes podrá ceder o transferir cualquier parte del presente Acuerdo sin el consentimiento por escrito de la otra parte, salvo a una Filial, solo si: (a) el cesionario consiente por escrito a comprometerse en virtud de las condiciones del presente Acuerdo; y (b) si la parte cedente permanece responsable de las obligaciones asumidas conforme al Acuerdo previamente a dicha cesión. Todo intento de transmisión o cesión se considerará nulo y sin efecto.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.3</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Cambio de Control.</strong>
    Tras un cambio de control (por ejemplo, mediante una adquisición o venta de acciones, fusión o toda otra forma de transacción corporativa): (a) la parte cuyo control haya cambiado lo notificará por escrito a la otra parte en un plazo de treinta días a contar del cambio de control; y (b) la otra parte podrá resolver inmediatamente el presente Acuerdo, en todo momento entre el cambio de control y un plazo de treinta días a partir de la notificación por escrito arriba mencionada en la subsección (a).
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.4</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Fuerza Mayor.</strong>
     Ninguna de las partes será responsable por el inadecuado rendimiento causado por situaciones (por ejemplo: desastres naturales, actos bélicos o terrorismo, disturbios, problemas laborales/huelga, acciones gubernamentales y problemas de Internet) que queden fuera del control razonable de dicha parte.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.5</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Ausencia de Renuncia.</strong>
    El no cumplir con la ejecución de cualquiera de las disposiciones del presente Acuerdo no se considerará como una renuncia.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.6</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Nulidad Parcial.</strong>
    Si cualquier disposición del presente Acuerdo se volviese inaplicable, el resto del mismo permanecerá en vigor y plenamente efectivo.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.7</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Ausencia de Mediación.</strong>
    Ausencia de Mediación. Las partes son contratantes independientes y el presente Acuerdo no crea ninguna mediación, asociación o joint-venture.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.8</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Ausencia de Terceros Beneficiarios.</strong>
    No existe ningún tercero beneficiario en el presente Acuerdo.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.9</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Compensación Equitativa.</strong>
    Ninguna disposición del presente Acuerdo restringirá el derecho de cada una de las partes a solicitar una compensación equitativa.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.10</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Ley Aplicable.</strong>
    El presente Acuerdo se regirá por la ley aplicable en Carolina del Norte, excluyendo el conflicto de leyes. EN CASO DE DISCREPANCIA DERIVADA O RELATIVA AL PRESENTE ACUERDO, LAS PARTES CONVIENEN RECURRIR AL FUERO DEL ESTADO DE CAROLINA DEL NORTE.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.11</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Adenda.</strong>
    Toda adenda deberá redactarse por escrito y confirmar expresamente que modifica el presente Acuerdo.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.12</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Supervivencia.</strong>
    Las secciones a continuación sobrevivirán tras la expiración o resolución del presente Acuerdo: Secciones 6 (Confidencialidad), 7 (Propiedad Intelectual), 9 (Declaraciones, Garantías y Descargos de Responsabilidad), 10 (Resolución), 11 (Indemnización), 12 (Limitación de Responsabilidad), y 13 (Miscelánea).
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.13</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Acuerdo en su Integralidad.</strong>
    El presente Acuerdo, así como toda la documentación a la cual el presente hace referencia, constituyen el acuerdo de las partes en su integralidad y relativo a su objeto, y anulan y substituyen todo acuerdo previo o actual en este ámbito. Las condiciones establecidas en una URL, a las cuales se refiera el presente Acuerdo, se incorporan al presente mediante dicha referencia.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">11.14</p>
  <p class="remove-vertical-margin justify flexAuto">
    <strong>Interpretación de Términos fuente de conflicto.</strong>
    En caso de producirse un conflicto por los documentos que conforman este Acuerdo, los documentos regirán en el siguiente orden: el Acuerdo, seguido por los términos que se encuentran en cualquier URL. https://medimix.net/termsandconditions/.
  </p>
</div>

<div class="flex flex-row" style="margin-top: 16px;">
  <p class="order remove-vertical-margin">12.</p>
  <p class="remove-vertical-margin"><strong>Definiciones.</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
    “Política relativa a un Uso Adecuado” significa toda política relativa a un uso adecuado de los Servicios disponibles en el Anexo A al presente documento.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
    “Administradores” significa todo personal técnico al servicio del Cliente que administre los Servicios para los Usuarios Finales.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
    “Filial” significa toda entidad que controle directamente o indirectamente, esté controlada, o bajo el control común de una parte.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
    “Información Confidencial” significa toda información divulgada por una parte a la otra parte al amparo del presente Acuerdo, considerada como confidencial o que debería ser normalmente considerada como confidencial dadas las circunstancias. Los Datos del Cliente son considerados como Información Confidencial.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
    "Datos de Usuario Final" son los datos, incluida la dirección de correo electrónico, facilitados, generados, transmitidos o mostrados a través de los Servicios por los Usuarios Finales.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
    "Problema de Seguridad de Emergencia". Se entiende como tal: (a) El uso de los Servicios por parte de un Usuario Final que infringe la Política de Uso Aceptable pudiendo afectar a: (i) los Servicios; o (ii) el uso de los Servicios por parte de otro Usuario Final; o (iiib) el acceso a los Servicios por parte de terceros no autorizados.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
    “Usuarios Finales” significa las personas a quienes Evidera permite utilizar los Servicios.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
    “Cuenta de Usuario Final” significa cualquier cuenta alojada por Evidera y creada por un Usuario Final a los efectos de utilizar los Servicios.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
    “Leyes sobre el Control de las Exportaciones” significa toda ley y normativa relativa a exportaciones y reexportaciones, Normativas Administrativas sobre Exportaciones incluidas (en adelante, “EAR”) que dependen del Departamento de Comercio de los Estados Unidos, las sanciones comerciales y económicas que dependen del Organismo de Control de Activos Extranjeros de la Hacienda de Estados Unido, así como la Normativa Americana sobre el Tráfico de Armas a Nivel Internacional (en lo sucesivo, “ITAR”) que depende del Departamento de Estado.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
    “HIPAA” significa la Ley relativa a la Portabilidad y Responsabilidad del Seguro Médico de 1996, modificada de manera puntual, así como toda normativa publicada a continuación.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
    “Derechos de Propriedad Intelectual” significa cualquier derecho actual y futuro a escala mundial amparado por la ley sobre patentes, ley sobre derechos de autor, ley sobre el secreto comercial, ley sobre marcas patentadas, ley sobre derechos morales, así como todo otro derecho similar.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub" style="margin-right: 0;">•</p>
  <p class="remove-vertical-margin justify flexAuto">
    “Servicios” significa el “Portal HCP de Evidera”, aplicación todo en uno para los médicos dados de alta, a los efectos de facilitar su participación regular en los estudios online de Evidera.
  </p>
</div>


<p class="remove-vertical-margin justify" style="margin-top: 48px;">
  <strong><i>Notificaciones:</i></strong>
  <span style="padding-left: 4px;">El Usuario Final recibirá una notificación en cuanto un nuevo estudio esté disponible.</span>
</p>

<p class="remove-vertical-margin justify" style="margin-top: 16px;">
    <strong><i>Participaciones:</i></strong>
    <span style="padding-left: 4px;">Revisa las participaciones nuevas y pendientes.</span>
</p>

<p class="remove-vertical-margin justify" style="margin-top: 16px;">
    <strong><i>Inicio del Estudio:</i></strong>
    <span style="padding-left: 4px;">Inicia o retoma las participaciones de los Usuarios Finales.</span>
</p>

<p class="remove-vertical-margin justify" style="margin-top: 16px;">
    <strong><i>Datos Personales:</i></strong>
    <span style="padding-left: 4px;">Revisa los datos personales de los Usuarios Finales y envía modificaciones.</span>
</p>

<p class="remove-vertical-margin justify" style="margin-top: 16px;">
    <strong><i>Pagos:</i></strong>
    <span style="padding-left: 4px;">Revisa las conclusiones pasadas de los Usuarios Finales y realiza los pagos.</span>
</p>

<p class="remove-vertical-margin justify" style="margin-top: 16px;">
    <strong><i>¿Preguntas o problemas?</i></strong>
    <span style="padding-left: 4px;">Obtenga soporte mediante chat en directo o la sección de FAQ de Evidera.</span>
</p>


<h3 style="text-align: center; margin-top: 50px; margin-bottom: 0;">ANEXO A</h3>
<h4 style="text-align: center; margin-bottom: 30px; margin-top: 8px;">Política relativa a un Uso Adecuado</h4>

<div class="flex flex-row">
  <p class="remove-vertical-margin"><strong>IDONEIDAD</strong></p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">1.1</p>
  <p class="remove-vertical-margin justify flexAuto">
    Solo se permite una inscripción por persona encuestada. Toda violación le inhabilitará a los efectos de recibir la remuneración aplicable y conllevará su exclusión de futuros estudios. Si se ha previamente dado de alta y solo necesita actualizar una nueva dirección de email, conéctese y actualice su email en la sección Cuenta.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">2.2</p>
  <p class="remove-vertical-margin justify flexAuto">
    Toda desvirtuación de su identidad o de toda otra información a la hora de darse de alta o de responder a los estudios le inhabilitará a los efectos de recibir la remuneración aplicable y conllevará su exclusión de futuros estudios.
  </p>
</div>

<p style="margin-top: 20; margin-bottom: 20;" class="justify">
  <i>Profesionales de la Salud:</i>Solo los profesionales de la salud actualmente en activo podrán participar. Toda desvirtuación de sus credenciales le inhabilitará a recibir la remuneración aplicable y conllevará su exclusión de futuras participaciones.
</p>

<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">1.3</p>
  <p class="remove-vertical-margin justify flexAuto">
    Las invitaciones son nominativas y no podrán enviarse a otras personas salvo si la invitación mediante email lo autoriza expresamente.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">2.4</p>
  <p class="remove-vertical-margin justify flexAuto">
    Evidera tiene el derecho de invitar o no invitar a participantes potenciales a su entera discreción.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">3.5</p>
  <p class="remove-vertical-margin justify flexAuto">
    Todas las respuestas deberán ser verídicas y precisas, de buena fe; facilitar respuestas falsas o erróneas conllevará la pérdida de la remuneración y la prohibición de participar en un futuro.
  </p>
</div>


<p class="remove-vertical-margin" style="margin-top: 20px;"><strong>REMUNERACIÓN</strong></p>
<p class="remove-vertical-margin justify" style="margin-top: 4px; padding-left: 16px;">
  Evidera facilitará una lista de participaciones previas al Usuario Final, con el importe de la remuneración y las donaciones, incluyendo el saldo del Usuario Final en su conjunto.
</p>

<p class="remove-vertical-margin" style="margin-top: 20px;"><strong>TASAS/LEGAL</strong></p>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">1.1</p>
  <p class="remove-vertical-margin justify flexAuto">
    Usted es el único responsable de declarar cualquier tasa, en su caso, ante sus autoridades financieras locales, por la remuneración que recibe. Reconoce que Evidera puede facilitar información a las agencias tributarias, o retener tasas a requerimiento de dichas agencias o si Evidera lo considera apropiado, a su entera discreción. Evidera no será responsable de ninguna tasa ni de no prestar asistencia en materia fiscal.
  </p>
</div>

<p style="margin-top: 20; margin-bottom: 20;" class="justify">
  <i>Residentes en los Estados Unidos:</i> Se le puede requerir, conforme a toda ley federal, de estado y/o local, liquidar tasas sobre la remuneración percibida. Reconoce y acepta que facilitará a Evidera toda información personal adicional mediante el Formulario IRS W-9, como el número de seguridad social o identificación fiscal, con la cual Evidera debe cumplir con cualquier otra obligación de informar o retener. En caso de no facilitar dicha información en un plazo de 30 días a contar de la solicitud remitida por Evidera a la dirección de email principal introducida en este momento, el abono de su remuneración podría retrasarse.
</p>

<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">1.2</p>
  <p class="remove-vertical-margin justify flexAuto">
    El Usuario Final reconoce y acepta que todo abono que reciba constituye el valor justo de su participación, se abona por voluntad propria y el Usuario Final conviene expresamente que no se trata de una incitación a: prescribir cualquier tratamiento médico, ni promover o administrar cualquier producto o servicio médico, o de otro modo modificar su comportamiento de cualquier forma respecto a la administración del tratamiento.
  </p>
</div>
<div class="flex flex-row" style="margin-top: 4px;">
  <p class="order remove-vertical-margin sub">2.3</p>
  <p class="remove-vertical-margin justify flexAuto">
    Profesionales de la Salud: Tal y como lo requieren las leyes y normativas sobre la divulgación en varios fueros, M3 puede verse obligado a postergar los pagos realizados en su favor, en concepto de su participación a nuestra investigación marketing y otros programas. Al participar en dichos programas, nos autoriza a divulgar la remuneración que percibe y otros abonos aplicables, si es necesario a los efectos de cumplir con tales requerimientos.
  </p>
</div>

<p style="margin-top: 50px;"></p>
`;