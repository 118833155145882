import React, { useState, useCallback, useEffect, useContext } from "react";
import { View, StyleSheet, RefreshControl, SafeAreaView, TouchableWithoutFeedback } from "react-native";
import { useTheme, Snackbar, Portal, Dialog, Paragraph, Searchbar, IconButton, ActivityIndicator } from "react-native-paper";
import ContentLoader from "react-native-easy-content-loader";
import gql from "graphql-tag";
import { API, graphqlOperation } from "aws-amplify";
import { FlatList } from "react-native-gesture-handler";
import { StackNavigationProp } from "@react-navigation/stack";
import Animated from "react-native-reanimated";
import BottomSheet from "reanimated-bottom-sheet";
import color from "color";
import ZeroRequestState from "../../components/zero.request.states";
import { UserInfoContext } from "../../context/userInfo";
import { SearchUicItem } from "./search.uic.item";
import { StackNavigatorParamlist } from "../../navigation/types";
import { translate } from "../../components/Localei18n/languagesFunctions";
import UicInfoComponent from "./uic.info.component";

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    alignItems: "center",
  },
  searchText: {
    borderRadius: 5,
    paddingHorizontal: 10,
    marginRight: 10,
    height: 36,
    flex: 1,
    backgroundColor: "rgba(0,0,0,0.2)",
  },
});

type Props = {
  navigation?: StackNavigationProp<StackNavigatorParamlist>;
};

type SearchUicItemProps = React.ComponentProps<typeof SearchUicItem>;

const renderItem = ({ item }: { item: SearchUicItemProps }) => <SearchUicItem {...item} />;

const keyExtractor = (item: SearchUicItemProps) => (item.panelist_id ? item.panelist_id.toString() : (Math.random() * 100).toString());

function wait(timeout) {
  return new Promise((resolve) => {
    setTimeout(resolve, timeout);
  });
}

export const SearchUIC = (props: Props) => {
  const theme = useTheme();
  const [UserData, setUserData] = useState<Omit<SearchUicItemProps | null, "onPress">[]>(null);
  const [loading, setLoading] = useState(false);
  const [uicLoading, setUicLoading] = useState(true);
  const [searchkey, setSearchKey] = useState("");
  const [refreshing, setRefreshing] = React.useState(false);
  const [snackbarVisible, setSnackbarVisible] = useState<string | boolean>(false);
  const [dialogStatus, setDialogStatus] = useState(false);
  const { updateInfo } = useContext(UserInfoContext);
  const sheetRef = React.useRef(null);
  const [btnSheetOpen, setBtnSheetOpen] = useState(false);
  const [btnSheetData, setBtnSheetData] = useState({});

  const [UicStatus, setUicStatus] = useState({});

  const fetchUsers = async (skey) => {
    if (skey.length <= 0) return;
    setLoading(true);
    setUserData([]);

    const qry = gql`
      query listContacts($searchkey: String!){
          listContactInfo(searchkey: $searchkey){
            first_name last_name
            panelist_id uic country_code level1_specialty_name email
            hcpportal_registration_status hcp_registration_date hcp_registration_approver
            verification_status verification_datetime verified_by contact_status hcpportal_last_login
          }
        }
      `;

    // eslint-disable-next-line no-unused-vars
    try {
      const response: any = await API.graphql(graphqlOperation(qry, {
        searchkey: skey,
      }));

      setUserData(response.data.listContactInfo);
    } catch (e) {
      // console.log(e);
    }
    setLoading(false);
  };

  // eslint-disable-next-line camelcase
  const getContactStatus = async (panelist_id) => {
    setUicLoading(true);

    const qry = gql`
      query getUicStatus($panelist_id: Int){
          getUicStatus(panelist_id: $panelist_id){
            hcpportal_registration_status hcp_registration_date hcp_registration_approver verification_status verification_datetime verified_by contact_status
          }
        }
      `;
    try {
      const response: any = await API.graphql(graphqlOperation(qry, {
        panelist_id,
      }));
      setUicStatus(response.data.getUicStatus);

      setUicLoading(false);
      sheetRef.current.snapTo(0);
    } catch (e) {
      console.log("failed to fetch status", e);
    }
  };

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    fetchUsers(searchkey);
    setRefreshing(false);
  }, []);

  useEffect(() => {
    // fetchUsers();
  }, []);

  const renderEmptyComponent = () => (
    <View>
      {loading ? (
        <View style={{ paddingTop: 10, marginBottom: 20 }}>
          <ContentLoader aSize={36} animationDuration={500} avatar active pRows={2} listSize={1} />
        </View>
      ) : ((UserData === null || UserData.length === 0)
        && (
          <ZeroRequestState
            style={{ paddingTop: 30 }}
            title={UserData === null ? translate("Search for UIC to load") : translate("No UIC found.")}
          />
        )
      )}
    </View>
  );

  const handleUpdateManagedUser = (data) => {
    setDialogStatus(true);
    updateInfo(data);
    setSnackbarVisible(`Panelist: ${data.last_name}, ${data.first_name} selected`);
    wait(1000).then(() => {
      // return false;
      props.navigation.goBack();
    });
  };

  const handleOpenBottomSheet = async (data, index) => {
    sheetRef.current.snapTo(1);
    await getContactStatus(data.panelist_id);
    setBtnSheetData(data);
    setBtnSheetOpen(index !== 2);
  };

  const renderUicComponent = () => (
    <View
      style={{
        backgroundColor: "white",
        padding: 20,
        height: 450,
        elevation: 2,
      }}
    >
      {uicLoading ? (
        <View style={{ paddingVertical: 20 }}>
          <ActivityIndicator size={40} />
        </View>
      ) : (
        <UicInfoComponent
          status={UicStatus}
          loadUic={() => handleUpdateManagedUser(btnSheetData)}
          {...btnSheetData}
        />
      )}
    </View>

  );
  const data = UserData && UserData.map((userDataProps: any) => ({
    ...userDataProps,
    openBottomSheet: () => handleOpenBottomSheet(userDataProps, 0),
    onPress: handleUpdateManagedUser,
  }));

  const separatorColor = color(theme.colors.text)
    .alpha(0.11)
    .rgb()
    .string();

  const fall = new Animated.Value(1);
  const animatedShadowOpacity = Animated.interpolateNode(fall, {
    inputRange: [0, 1],
    outputRange: [0.5, 0],
  });
  const AnimatedTouchable = Animated.createAnimatedComponent(TouchableWithoutFeedback);

  return (
    <>


      <SafeAreaView style={{ flex: 1, overflow: "hidden", backgroundColor: theme.colors.background }}>


        <View style={{ padding: 8, backgroundColor: theme.colors.background }}>
          <View style={styles.header}>

            <IconButton
              icon="chevron-left"
              color={theme.colors.primary}
              size={36}
              onPress={() => props.navigation.goBack()}
              style={{ height: 36, width: 36 }}
            />

            <Searchbar
              style={{
                flex: 1, paddingVertical: 0, elevation: 1, height: 46,
              }}
              placeholder="Search"
              onChangeText={(text) => {
                setSearchKey(text);
                // autoSearch(text);
              }}
              onSubmitEditing={() => fetchUsers(searchkey)}
              value={searchkey}
            />
            {/* <TextInput
              style={[styles.searchText, { color: theme.colors.text }]}
              placeholderTextColor={theme.colors.text}
              value={searchkey}
              placeholder={translate("Search UIC or Name")}
              onChangeText={(text) => {
                setSearchKey(text);
                //   autoSearch(text);
              }}
            /> */}
            {/* <Button mode="contained" onPress={() => fetchUsers(searchkey)}>Search</Button> */}
            {/* <TouchableRipple style={{ cursor: "pointer" }} onPress={() => fetchUsers(searchkey)}>
            <MaterialCommunityIcons name="magnify" size={24} color={theme.colors.text} />
          </TouchableRipple> */}
          </View>
        </View>

        {/* <View style={{ height: StyleSheet.hairlineWidth, backgroundColor: separatorColor }} /> */}

        <FlatList
          keyboardShouldPersistTaps="never"
          contentContainerStyle={{ backgroundColor: theme.colors.background, paddingBottom: 18, paddingTop: 8 }}
          style={{ backgroundColor: theme.colors.background }}
          data={data}
          scrollEventThrottle={16}
          renderItem={renderItem}
          keyExtractor={keyExtractor}
          refreshing={loading}
          refreshControl={
            <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
          }
          ListEmptyComponent={renderEmptyComponent}
          ItemSeparatorComponent={() => (<View style={{ height: StyleSheet.hairlineWidth }} />)}
        />
        <Snackbar
          visible={snackbarVisible !== false}
          onDismiss={() => setSnackbarVisible(false)}
          duration={3000}
          action={{
            label: "Hide",
            onPress: () => {
              setSnackbarVisible(false);
            },
          }}
        >
          {snackbarVisible}
        </Snackbar>

        <Portal>

          <Dialog
            dismissable={false}
            visible={dialogStatus}
          >
            <Dialog.Content>
              <Paragraph>{translate("Loading UIC")}</Paragraph>
            </Dialog.Content>
          </Dialog>
        </Portal>
      </SafeAreaView>
      <BottomSheet
        callbackNode={fall}
        enabledContentTapInteraction
        ref={sheetRef}
        snapPoints={[450, 300, 0]}
        borderRadius={10}
        initialSnap={2}
        renderContent={() => renderUicComponent()}
        onCloseEnd={() => {
          setBtnSheetOpen(false);
          setBtnSheetData({});
          setUicStatus({});
        }}
      />
      <AnimatedTouchable
        onPress={() => sheetRef.current.snapTo(2)}
        style={[
          {
            opacity: animatedShadowOpacity,
          },
        ]}
      >
        {/* <TouchableWithoutFeedback style={{ flex: 1 }}> */}
        <View
          pointerEvents={btnSheetOpen ? "box-only" : "box-none"}
          style={{
            ...StyleSheet.absoluteFillObject,
            backgroundColor: "#000",
          }}
        />
        {/* </TouchableWithoutFeedback> */}
      </AnimatedTouchable>
    </>
  );
};
