import { I18n } from "@aws-amplify/core";
import AsyncStorage from "@react-native-async-storage/async-storage";
import gql from "graphql-tag";
import { API, graphqlOperation } from "aws-amplify";
import * as SecureStore from "expo-secure-store";
import { Platform } from "react-native";
import secureStoreOptionsKeychain from "../../data/secure-store-keychain";
import ExpoReleaseEnvironments from "../../ExpoReleaseEnvironments";
import { getDataListLangages } from ".";

const secureStoreOptions = {
  keychainService: secureStoreOptionsKeychain,
  keychainAccessible: SecureStore.ALWAYS, // iOS only
};

export const getTranslations = async (lang) => {
  const qryListTranslation = gql`
    query listTranslations {
      listTranslations(language_code: "${lang.language}") {
        label
        translation
      }
    }
    `;

  try {
    const translations = {};
    const result = await API.graphql(graphqlOperation(qryListTranslation));
    translations[lang.language] = {};
    result.data.listTranslations.forEach((item) => {
      translations[lang.language][item.label] = item.translation;
    });

    I18n.setLanguage(lang.language);
    I18n.putVocabularies(translations);

    window.currentLanguage = lang.language;
    if (Platform.OS === "web") {
      AsyncStorage.setItem("currentLanguage", lang.language);
    } else {
      SecureStore.setItemAsync("currentLanguage", lang.language, secureStoreOptions);
      SecureStore.setItemAsync("language", JSON.stringify(lang), secureStoreOptions);
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log("error: ", err);
  }
};

const createMissingTranslation = async (text) => {
  let currentLanguage = "";

  if (Platform.OS === "web") {
    currentLanguage = await AsyncStorage.getItem("currentLanguage");
  } else {
    currentLanguage = await SecureStore.getItemAsync("currentLanguage", secureStoreOptions);
  }

  const list = await getDataListLangages();
  const simpleList = [];

  if (list && list.length > 0) {
    list.forEach((lng) => {
      simpleList.push(lng.language_code);
    });
  }

  if (simpleList.indexOf(currentLanguage) !== -1) {
    if ((currentLanguage === "en" && text.includes("LNG_")) || currentLanguage !== "en") {
      const createTranslation = gql`
        mutation createTranslation($label: String, $language_code: String) {
          createTranslation(label: $label, language_code: $language_code)
        }
        `;

      try {
        API.graphql(graphqlOperation(createTranslation, {
          label: text.replace(/'/g, "\\'"), language_code: currentLanguage,
        }));
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log("error: ", err);
      }
    }
  }
};

export const translate = (text) => {
  if (text && text.length > 0) {
    let textTranslated = "";

    if (text.length > 250) {
      textTranslated = I18n.get(text.substring(0, 250), "MISSING-TRANSLATION");
    } else {
      textTranslated = I18n.get(text, "MISSING-TRANSLATION");
    }

    if (textTranslated === "MISSING-TRANSLATION") {
      textTranslated = text;

      if (ExpoReleaseEnvironments().envName === "DEVELOPMENT") {
        createMissingTranslation(text);
      }

      if (text.includes("LNG_")) {
        textTranslated = text.replace("LNG_", "").replace(/_/g, " ");
      }

      if (window.environment === "MAINTENANCE" && ((window.currentLanguage === "en" && text.includes("LNG_")) || window.currentLanguage !== "en") && window.viewTag) {
        textTranslated += " - MISSING TRANSLATION";
      } else if (window.environment === "MAINTENANCE" && ((window.currentLanguage === "en" && !text.includes("LNG_"))) && window.viewTag) {
        textTranslated = `* ${textTranslated}`;
      }
    } else if (window.environment === "MAINTENANCE" && window.viewTag) {
      textTranslated = `* ${textTranslated}`;
    }
    return textTranslated;
  }
  return text;
};
