import React from "react";
import { View } from "react-native";
import {
  useTheme,
  Portal,
  Dialog,
  Button,
  Paragraph,
} from "react-native-paper";

const ComfirmDialog = (props) => {
  const theme = useTheme();
  return (
    <Portal>

      <Dialog
        style={[{ alignSelf: "center" }, props.style]}
        visible={props.visible}
        onDismiss={props.cancelEvt}
      >
        <Dialog.Title>{props.title}</Dialog.Title>
        <Dialog.Content>
          <Paragraph style={{ fontSize: 18 }}>{props.message}</Paragraph>
        </Dialog.Content>
        <Dialog.Actions>
          <Button onPress={props.cancelEvt} color={theme.colors.error}>Cancel</Button>
          <Button color={theme.colors.primary} onPress={props.confirmEvt}>
            Yes
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
};

export default ComfirmDialog;
