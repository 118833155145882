import React, { useState, useCallback, useEffect } from "react";
import { View, KeyboardAvoidingView, Platform, ScrollView } from "react-native";
import { Picker } from "@react-native-picker/picker";
import { useForm, Controller } from "react-hook-form";
import { TextInput, useTheme, Title, Subheading, Text, ActivityIndicator } from "react-native-paper";
import debounce from "lodash/debounce";
import { API, graphqlOperation } from "aws-amplify";
import gql from "graphql-tag";
import { styles } from "./forms.style";
import { Payment } from "../types"; // eslint-disable-line no-unused-vars

import { translate } from "../../../components/Localei18n/languagesFunctions";
import FormField from "../../../components/FormValidator/FormField";
import { PickerCustom } from "../../../components/picker.custom";

const EditPayment = (props) => {
  const theme = useTheme();
  const [unmounted, setUnmounted] = useState(false);
  const {
    control, getValues, setValue, register, trigger, formState: { errors },
  } = useForm({ mode: "onChange", reValidateMode: "onChange" });
  const [availableMartket, setAvailableMarket] = useState([]);
  const [inputData, setInputData] = useState<Payment>(props.route.params); // eslint-disable-line react/prop-types
  const [paypal_active, setPaypalActive] = useState(!props.route.params?.IsAmazon ? 1 : props.route.params.paypal_active);

  const [loading, setLoading] = useState(true);
  const paymentOption = [{ text: "Paypal", value: 1 }, { text: "Amazon", value: 2 }];
  const update = useCallback(debounce(async (data: object) => {
    const mutation = gql`
      mutation updateFormsContacts($formsContactsInput: FormsContactsInput! , $panelist_id: Int!){
        insertIgnoreFormsContacts(panelist_id: $panelist_id)
        updateFormsContacts(formsContactsInput: $formsContactsInput)
      }
    `;

    try {
      // const response = await API.graphql(graphqlOperation(mutation, {
      const response = await API.graphql(graphqlOperation(mutation, {
        formsContactsInput: { ...data, panelist_id: inputData.panelist_id },
        panelist_id: inputData.panelist_id,
      }));

      // props.route.params.reload();
      // if (response.data.insertIgnoreFormsContacts && response.data.updateFormsContacts) {
      //   updateManageUser({ ...managedUserInfo, paypal_active: inputData.paypal_active });
      // }
    } catch (err) {
      console.log(["failed to update contacts", err, data]);
    }
  },
  1000, { leading: false }), []);

  const autoSave = async () => {
    await trigger();
    if (Object.keys(errors).length === 0) {
      update(getValues());
      // Analytics.logEvent("PaymentInfoUpdated");
    }
  };


  const onInit = async () => {
    try {
      const qry = gql`
      query getAmazonMarket($country_code: String){
        getAmazonMarket(country_code: $country_code){
          market market_name
        }
      }
    `;
      const response: any = await API.graphql(graphqlOperation(qry, {
        country_code: inputData.country_code && inputData.country_code != null ? inputData.country_code : "",
      }));

      if (unmounted) return false;
      setAvailableMarket(response.data.getAmazonMarket);
    } catch (e) {
      console.log(e);
      if (unmounted) return false;
      setAvailableMarket(e.data.getAmazonMarket);
    }

    console.log("stop loading");
    setLoading(false);
  };

  useEffect(() => {
    register("panelist_id", { value: props.route.params.panelist_id });
    register("uic", { value: props.route.params.uic });
    if (!props.route.params?.IsAmazon) {
      register("paypal_active", { value: 1 });
      register("amazon_market", { value: "" });
    }

    onInit();

    return () => {
      setUnmounted(true);
    };
  }, []);

  useEffect(() => {
    trigger();
  }, [paypal_active]);

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={[
        styles.root,
        { backgroundColor: theme.colors.background },
      ]}
    >
      <ScrollView style={styles.scrollWrapper}>
        <View style={styles.scrollInner}>
          <View style={styles.titleWRapper}>
            <Title style={styles.title}>{translate("Payment Preference")}</Title>
            <Subheading style={styles.subheadingRegular}>
              {translate("Update your preferred rewards. Remember that changes to your payment preferences is subject for verification.")}
            </Subheading>
          </View>
          {
            !loading

              ? (
                <>
                  {props.route.params?.IsAmazon
                  && (
                  <FormField errors={errors?.paypal_active}>
                    <Controller
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <PickerCustom
                          style={{ flex: 1 }}
                          value={value}
                          label={translate("Preferred Payment")}
                          mode="outlined"
                          display="default"
                          required
                          onChange={(itemValue) => {
                            onChange(itemValue);
                            setPaypalActive(itemValue);
                            if (itemValue !== "") autoSave();
                          }}
                        >
                          <Picker.Item label={translate("Select Payment Method")} value="" />
                          {paymentOption.map((e) => (
                            <Picker.Item
                              key={e.value.toString()}
                              label={e.text}
                              value={e.value}
                            />
                          ))}

                        </PickerCustom>

                      )}
                      name="paypal_active"
                      defaultValue={props.route.params?.paypal_active || ""}
                      rules={{
                        required: {
                          value: true,
                          message: translate("Please select payment method"),
                        },
                      }}
                    />
                  </FormField>
                  )}

                  <View style={{ marginTop: 10 }}>
                    <FormField
                      errors={errors?.amazon_market}
                      style={[((paypal_active ? paypal_active.toString() : paypal_active) !== "2" && styles.hidden)]}
                    >
                      <Controller
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <PickerCustom
                            style={{ flex: 1 }}
                            value={value}
                            label={translate("Select Amazon Market")}
                            mode="outlined"
                            display="default"
                            required
                            onChange={(itemValue) => {
                              onChange(itemValue);
                              // const newInputData = inputData;
                              // setInputData({ ...newInputData, amazon_market: itemValue });
                              setValue("amazon_market", itemValue);
                              autoSave();
                            }}
                          >
                            <Picker.Item label={translate("Select Country")} value="" />
                            { availableMartket.length
                            && availableMartket.map((item, index) => (
                              <Picker.Item
                                key={index.toString()}
                                label={`${item.market.toUpperCase()} - ${translate(item.market_name)}`}
                                value={item.market.toString()}
                              />
                            )) }

                          </PickerCustom>
                        )}
                        name="amazon_market"
                        defaultValue={inputData.amazon_market}
                      />
                    </FormField>
                  </View>


                  {parseInt(paypal_active, 10) > 0
                  && (
                  <View style={styles.formField}>
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <TextInput
                          returnKeyType="next"
                          mode="outlined"
                          style={styles.formInput}
                          onBlur={onBlur}
                          onChangeText={(ev) => {
                            onChange(ev);
                            autoSave();
                          }}
                          {...(paypal_active.toString() === "1")
                            && { placeholder: translate("Enter Paypal Email address") }
                          }
                          value={value}
                          label={paypal_active.toString() === "1" ? "Paypal ID" : (paypal_active.toString() === "2" && "Amazon ID" || "")}
                        />
                      )}
                      name="paypal_id"
                      defaultValue={inputData.paypal_id}
                      rules={{
                        // eslint-disable-next-line no-nested-ternary
                        required: {
                          value: true,
                          message: `${translate("Please enter your")} ${paypal_active.toString() === "1" ? "Paypal ID" : (paypal_active.toString() === "2" ? "Amazon ID" : "")}.`,
                        },
                        pattern: paypal_active.toString() === "1" ? {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: translate("Invalid Paypal ID!"),
                        } : {
                          value: /^[a-zA-Z0-9_.-]*$/i,
                          message: translate("Invalid Amazon ID!"),
                        },
                      }}
                    />

                    {errors.paypal_id?.message?.length > 0
                      && (<Text style={{ color: "red", marginTop: 4 }}>{errors.paypal_id?.message}</Text>)}

                  </View>
                  )}
                </>
              ) : (
                <View style={{ paddingTop: 30 }}>
                  <ActivityIndicator size={40} color={theme.colors.primary} />
                </View>
              )
      }
          <View style={styles.formActionField}>
            {/* <Button onPress={handleSubmit(onSubmit)} contentStyle={[styles.button]} dark mode="contained">
              Update
            </Button> */}
          </View>
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

export default EditPayment;
