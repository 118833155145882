
import { Dimensions, Platform, StyleSheet } from "react-native";

const dimension = Dimensions.get("window");

export const styles = StyleSheet.create({
  root: {
    flex: 1,
    width: "100%",
  },
  scrollWrapper: {
    flex: 1,
    width: "100%",
    minWidth: "100%",
  },
  scrollInnerResponsive: {
    ...(Platform.OS === "web" ? {
      width: dimension.width >= 601 ? 500 : "100%",
    }
      : {
        width: "100%",
        minWidth: "100%",
      }),
    alignSelf: "center",
    flex: 1,
    paddingHorizontal: 40,
    paddingTop: 24,
    paddingBottom: 18,
  },
  scrollInner: {
    ...(Platform.OS === "web" ? {
      width: dimension.width >= 601 ? 500 : "100%",
    }
      : {
        width: "100%",
        minWidth: "100%",
      }),
    flex: 1,
    paddingHorizontal: 40,
    paddingTop: 24,
    paddingBottom: 18,
  },
  scrollInnerSm: {
    flex: 1,
    paddingHorizontal: 15,
    paddingTop: 24,
    paddingBottom: 18,
  },
  formField: {
    flexDirection: "column",
    marginBottom: 24,
  },

  formFieldRow: {
    flexDirection: "row",
    marginBottom: 24,
    alignItems: "flex-end",
  },

  formActionField: {
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingVertical: 24,
    marginBottom: 40,
  },
  formInput: {
    width: "100%",
  },
  formMessageWrapper: {
    fontSize: 12,
  },
  button: {
    paddingVertical: 4,
    minWidth: 100,
  },
  titleWRapper: {
    paddingBottom: 20,
  },
  title: {
    fontWeight: "500",
  },
  subheadingRegular: {
    marginTop: 10,
  },
  subheadingSmall: {
    fontSize: 14,
    letterSpacing: 1,
    marginVertical: 8,
  },

  pickerWrapper: {
    backgroundColor: "#fff",
    padding: 10,
  },
  pickerLabel: {
    fontSize: 16,
  },
  pickerAndroidStyle: {
    color: "white",
    borderRadius: 4,
    borderColor: "grey",
    borderWidth: 1,
  },
  pickerAndroidLabel: {
    color: "grey",
    fontSize: 14,
    letterSpacing: 1,
    marginBottom: 5,
  },
  pickerAndroidWrapper: {
    borderRadius: 5,
    borderColor: "grey",
    borderWidth: 1,
    paddingHorizontal: 4,
  },
  pickerLabelWrapper: {
    flexDirection: "row",
    alignItems: "center",
  },
  pickerActionClose: {
    paddingHorizontal: 8,
    paddingVertical: 5,
    borderColor: "grey",
    borderRadius: 3,
    borderWidth: 1,
  },
  pickerActionText: {
    fontWeight: "600",
  },
  hidden: {
    display: "none",
  },
  show: {
    display: "flex",
  },
  pickerWebWrapper: {
    borderRadius: 4,
    borderWidth: 1,
    borderColor: "#858585",
    paddingHorizontal: 14,
  },
  pickerWebStyle: {
    borderWidth: 0,
    height: 56,
    paddingHorizontal: 14,
    fontSize: 16,
  },
});
