import Constants from "expo-constants";

const includeInArray = (arr, obj) => (arr.indexOf(obj) != -1);

const ExpoReleaseEnvironments = () => {
  const release = Constants?.manifest?.releaseChannel;

  const prodEnv = {
    envName: "PRODUCTION",
    urlDomain: "https://medimix.net",
    getEligibleSurveyURL: "https://mmx.medimix.biz/backoffice/public/api/hcp/status/",
    bugsnagKey: "59640578f23b56c145ecf6e28575e4d5",
    LOG_LEVEL: "INFO",
    backofficeUrl: "https://mmx.medimix.biz/backoffice/public/",
    userPoolId: "us-east-1_5tfPENom9",
  };
  const devEnv = {
    envName: "DEVELOPMENT",
    urlDomain: "https://dev.medimix.net",
    getEligibleSurveyURL: "https://devlms.medimix.biz/backoffice/public/api/hcp/status/",
    bugsnagKey: "c5aeeab625969f93dd44f9582e06176d",
    LOG_LEVEL: "DEBUG",
    backofficeUrl: "https://devint.medimix.biz/backoffice/public/",
    userPoolId: "us-east-1_5tfPENom9",
  };
  const productionDomains = ["medimix.net", "www.medimix.net", "beta.medimix.net"];
  const developmentDomains = ["dev.medimix.net", "localhost"];

  let url = null;
  if (window && window.location && window.location.href) {
    url = new URL(window.location.href);
  }
  if (url && includeInArray(productionDomains, url.hostname)) {
    return prodEnv;
  } if (url && includeInArray(developmentDomains, url.hostname)) {
    return devEnv;
  }
  // dev env settings
  if (release === undefined) {
    // no releaseChannel (is undefined) in dev
    return devEnv;
  }
  return prodEnv;
};

export default ExpoReleaseEnvironments;
