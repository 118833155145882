import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  root: {
    marginBottom: 15,
    // paddingBottom: 18,
    borderRadius: 3,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: "rgba(127,127,127,0.2)",
  },
  container: {
    flexDirection: "row",
    padding: 15,
  },
  // rightColumn: {
  //   flex: 1,
  //   justifyContent: "flex-start",
  //   alignItems: "baseline",
  // },
  // dot: {
  //   fontSize: 3,
  // },
  // caption: {
  //   fontWeight: "700", lineHeight: 16, fontSize: 14, marginVertical: 3,
  // },
  // content: {
  //   // borderTopWidth: 1,
  //   paddingTop: 17,
  //   paddingHorizontal: 15,
  //   paddingBottom: 18,
  // },
  actionContainer: {
    paddingVertical: 6,
    paddingHorizontal: 15,
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap",
    // marginBottom: -5,
    // marginRight: -10,
  },
  actionButton: {
    // margin: 0,
    flexDirection: "row",
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderRadius: 15,
    minWidth: 70,
    justifyContent: "center",
    alignItems: "center",
  },
  actionButtonText: {
    fontSize: 13,
    marginLeft: 5,
    textTransform: "capitalize",
    marginVertical: 5,
  },
  // contentAmount: {
  //   lineHeight: 21,
  //   fontSize: 14,
  //   paddingLeft: 15,
  //   paddingRight: 8,
  // },
});

export default styles;
