import React, { useState, useEffect, useContext } from "react";
import { View } from "react-native";
import { useForm, Controller } from "react-hook-form";
import { TextInput, useTheme, Title, Subheading, Text, Button } from "react-native-paper";
import { API, graphqlOperation } from "aws-amplify";
import gql from "graphql-tag";
import Auth from "@aws-amplify/auth";
// eslint-disable-next-line no-unused-vars
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import color from "color";
import { styles } from "./forms.style";
import { translate } from "../../../components/Localei18n/languagesFunctions";
import FormField from "../../../components/FormValidator/FormField";
import UserContext from "../../../context/UserContext";


type Props = {
  data: any;
  goBack: () => void;
  sendCode: () => Promise<boolean>;
  SendingCode: boolean;
  message: String;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  setOpenVerify: React.Dispatch<React.SetStateAction<boolean>>;
  openSnackbar: (msg: string) => void;
};

const VerifyNumber = (props: Props) => {
  const {
    control, errors, trigger, getValues,
  } = useForm({ mode: "onSubmit", reValidateMode: "onSubmit" });
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  // const [message, setMessage] = useState("");
  const [Submitting, setSubmitting] = useState(false);
  // const [SendingCode, setSendingCode] = useState(false);

  const [phone] = useState(`${props.data.number}`);
  // const user: any = useContext(UserContext);
  const theme = useTheme();
  console.log(props.data);


  const onSubmit = async () => {
    await trigger();

    try {
      setSubmitting(true);
      const mutation = gql`
          mutation insert($number_id: Int!,$panelist_id: Int!, $formValue: FormContactsNumbersInput) {
            updateFormNumber(formValue: $formValue)
            addFormNumberToContactsNumber(number_id: $number_id, panelist_id: $panelist_id)
          }
        `;

      const response = await API.graphql(graphqlOperation(mutation, {
        number_id: props.data.number_id,
        panelist_id: props.data.panelist_id,
        formValue: {
          primary_number: 1, number_id: props.data.number_id, panelist_id: props.data.panelist_id, number_status_id: 2,
        },
      }));

      if (response.data.addFormNumberToContactsNumber === true) {
        props.openSnackbar(translate("Contact number updated."));
        props.goBack();
      }
    } catch (err) {
      console.log(["failed to add contacts", err]);
    }
    setSubmitting(false);
  };

  // const submit = async () => {
  //   await trigger();
  //   if (Object.keys(errors).length === 0) {
  //     onSubmit();
  //   }
  // };

  const verify = async () => {
    await trigger();
    setSubmitting(true);

    if (Object.keys(errors).length === 0) {
      /* onSubmit();
      return;
      */
      const codeCheck: any = await Auth.verifyCurrentUserAttributeSubmit(
        "phone_number",
        getValues("code")
      );

      if (codeCheck.code !== "CodeMismatchException") {
        onSubmit();
      } else {
        props.setMessage(codeCheck.message);
      }
    }
    return true;
  };

  const mutedContentColor = color(theme.colors.text)
    .alpha(0.6)
    .rgb()
    .string();

  useEffect(() => {
  }, []);

  return (
    <>
      <View style={[styles.scrollInnerSm]}>
        <View style={[styles.titleWRapper, { paddingBottom: 10 }]}>
          <Title style={styles.title}>{translate("Confirm Phone Number")}</Title>
          <Subheading style={styles.subheadingRegular}>
            {(props.message)}
          </Subheading>
        </View>

        <View style={{ paddingBottom: 15, flexDirection: "row" }}>
          <Text style={{ fontSize: 16, color: mutedContentColor }}>
            {translate("Not your number? ")}
          </Text>
          <TouchableWithoutFeedback
            disabled={props.SendingCode || Submitting}
            onPress={() => props.setOpenVerify(false)}
            style={{ alignSelf: "center" }}
          >
            <Text style={{
              color: theme.colors.primary, fontSize: 16,
            }}
            >
              {translate("Change")}
            </Text>

          </TouchableWithoutFeedback>
        </View>

        <Text style={styles.subheadingSmall}>{translate("Verification Code")}</Text>

        <FormField errors={errors?.code}>
          <Controller
            control={control}
            render={({ field: { onChange } }) => (
              <TextInput
                maxLength={6}
                onChangeText={onChange}
                keyboardType="number-pad"
                mode="outlined"
                style={{ textAlign: "center", fontSize: 20 }}
              />
            )}
            name="code"
            defaultValue=""
            rules={{
              required: { value: true, message: translate("Please enter verification code") },
            }}
          />
        </FormField>
        <View style={{ paddingBottom: 15, flexDirection: "row" }}>
          <Text style={{ fontSize: 16 }}>
            {translate("Did not receive a code? ")}
            {" "}
          </Text>
          <TouchableWithoutFeedback
            disabled={props.SendingCode || Submitting}
            onPress={() => props.sendCode()}
            style={{ alignSelf: "center" }}
          >
            <Text style={{
              color: theme.colors.primary, fontSize: 16,
            }}
            >
              {translate("Resend")}
            </Text>

          </TouchableWithoutFeedback>
        </View>

        <View style={styles.formActionField}>

          <Button
            loading={Submitting}
            disabled={props.SendingCode || Submitting}
            onPress={() => verify()}
            contentStyle={[styles.button]}
            dark
            mode="contained"
          >
            {translate("Verify")}
          </Button>
        </View>

      </View>

    </>
  );
};

export default VerifyNumber;
