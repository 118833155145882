import { Picker, PickerIOS } from "@react-native-picker/picker";

import React, { useEffect, useRef, useState } from "react";
import { Platform, StyleProp, StyleSheet, TextStyle, TouchableOpacity, View } from "react-native";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import { Portal, Text, TextInput, useTheme } from "react-native-paper";
import { translate } from "./Localei18n/languagesFunctions";

type Props = {
  height?: number | string;
  value?: any; // eslint-disable-line react/require-default-props
  // textCaption?: string | (() => any); // eslint-disable-line react/require-default-props
  label?: any; // eslint-disable-line react/require-default-props
  onChange: (itemValue: any, itemIndex: number) => void; // eslint-disable-line react/require-default-props
  children: any; // eslint-disable-line react/require-default-props
  placeHolder?: any; // eslint-disable-line react/require-default-props
  style?: any; // eslint-disable-line react/require-default-props
  required?: boolean; // eslint-disable-line react/require-default-props
  mode?: "outlined" | "flat"; // eslint-disable-line react/require-default-props
  display?: "default" | "inline"; // eslint-disable-line react/require-default-props
  itemStyle?: StyleProp<TextStyle>; // eslint-disable-line react/require-default-props
  disabled?: boolean;
  withTheme?: boolean;
  iosTurnOffTouchableFlex?: boolean;
  forAndroidDialogStyle?: any;
}

const styles = StyleSheet.create({
  subheadingSmall: {
    fontSize: 14,
    letterSpacing: 1,
    marginVertical: 4,
    paddingHorizontal: 10,
  },
  pickerWrapper: {
    backgroundColor: "#fff",
  },
  pickerLabel: {
    fontSize: 16,
    lineHeight: 36,
    height: 36,
    color: "black",
  },
  pickerWrapperWebStyle: {

    flexDirection: "column",
    flex: 1,
    padding: 0,
  },
  pickerWebStyle: {
    height: 36,
    fontSize: 16,
  },
  pickerWebWrapperStyleInline: {
    // padding: "7px 4px";
    height: 56,
    paddingTop: 16,
    paddingBottom: 4,
    paddingHorizontal: 10,
    backgroundColor: "transparent",
  },
  pickerWebWrapperStyle: {
    // padding: "7px 4px";
    height: 56,
    // paddingTop: 24,
    // paddingBottom: 4,
    paddingHorizontal: 10,
    backgroundColor: "transparent",
  },
  pickerWebWrapperFlatStyle: {
    // padding: "7px 4px";
    paddingTop: 24,
    paddingBottom: 4,
    paddingHorizontal: 10,
    height: 64,
  },
  pickerAndroidStyleOutlined: {
    borderRadius: 4,
    borderWidth: 1,
  },
  pickerAndroidStyleFlat: {
    borderBottomWidth: 1,
  },
  pickerAndroidLabel: {
    color: "grey",
    fontSize: 14,
    letterSpacing: 1,
    marginBottom: 5,
  },
  pickerAndroidWrapper: {
    paddingHorizontal: 10,
  },
  pickerLabelWrapper: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 10,
    paddingVertical: 4,
  },
  pickerActionText: {
    fontWeight: "600",
    color: "black",
  },
  label: {
    fontSize: 18,
  },
  inlineLabel: {
    fontSize: 16,
    paddingHorizontal: 12,
    flex: 1,
  },
  pickerActionClose: {
    paddingHorizontal: 20,
    height: 36,
  },
  hidden: {
    display: "none",
  },
  show: {
    display: "flex",
  },
  formInput: {
    flex: 1,
    // backgroundColor: "transparent",
  },
  formFieldBottomBorder: {
    backgroundColor: "rgba(0, 0, 0, 0.26)",
    height: 2,
    width: "100%",
    transform: [{ scaleY: 0.5 }],
  },
  labelTransform: {
    transform: [{ translateX: -7 }, { translateY: -16 }, { scale: 0.75 }],
  },
  labelTransformAndroid: {
    transform: [{ translateY: -22 }, { scale: 0.75 }],
  },
});

export const PickerCustom = (props: Props) => {
  const {
    value,
    label,
    placeHolder,
    onChange,
    children,
    style,
    required,
    mode,
    display,
    disabled,
    withTheme = true,
    iosTurnOffTouchableFlex = false,
    forAndroidDialogStyle = {
      containerStyle: {},
      placeholderStyle: {},
    }
  } = props;
  const theme = useTheme();
  const [selectOpen, setSelectOpen] = useState(false);
  const borderColor = theme.colors.disabled;
  const isMac = Platform.OS === "web" && window.navigator.userAgent.includes("Macintosh") && window.navigator.userAgent.includes("Safari");
  const [rootWidth, setRootWidth] = useState(0);
  const [textCaption, setTextCaption] = useState("");
  const enabled = disabled !== undefined ? disabled : true;
  const pickerRef = useRef<any>();
  const checkLabel = (c) => !(c[0].props.value === value && c[0].props.label === "");
  const applyCaption = (items) => {
    const xCaption = (items.find((e) => e.props?.value === value));
    setTextCaption(xCaption?.props?.label || "");
  };
  useEffect(() => {
    let unmounted = false;

    applyCaption([].concat(...children));

    return () => {
      unmounted = true;
    };
  }, [value, children]);

  if (Platform.OS === "ios") {
    return (
      <>
        <TouchableWithoutFeedback
          onPress={() => {
            if (disabled) return;
            setSelectOpen(true);
          }}
          {...!iosTurnOffTouchableFlex && {style: {
            flex: 1
          }} }
        >
          <View
            pointerEvents="auto"
            {...!iosTurnOffTouchableFlex && {style: [
              {flex: 1},
            ]} }>
            <TextInput
              right={<TextInput.Icon size={30} color={disabled ? theme.colors.disabled : theme.colors.text} icon="menu-down" />}
              onFocus={() => {
                setSelectOpen(true);
              }}
              disabled={disabled}
              editable={false}
              mode={mode}
              // style={[styles.formInput, style]}
              {...!iosTurnOffTouchableFlex && {style: [styles.formInput, style]} }
              // eslint-disable-next-line no-nested-ternary
              value={textCaption}
              placeholder={placeHolder}
              label={`${label}  ${(required === true) ? "*" : ""}`}
            />
          </View>
        </TouchableWithoutFeedback>

        <Portal>
          {selectOpen && (
            <View
              style={{
                flex: 1,
                backgroundColor: "rgba(0,0,0,0.31)",
                justifyContent: "flex-end",
              }}
            >
              <View style={styles.pickerWrapper}>
                <View style={styles.pickerLabelWrapper}>
                  <Text style={styles.pickerLabel}>{translate("Select")}</Text>
                  <View style={{ flex: 1 }} />
                  <TouchableOpacity
                    style={styles.pickerActionClose}
                    onPress={() => setSelectOpen(!selectOpen)}
                  >
                    <Text style={{
                      fontSize: 16, lineHeight: 36, fontWeight: "800", color: "rgb(0, 112, 215)",
                    }}
                    >
                      {translate("Done")}
                    </Text>
                  </TouchableOpacity>
                </View>
                <PickerIOS
                  selectedValue={value}
                  onValueChange={(itemValue, itemIndex) => {
                    const text = children[1][itemIndex - 1]; // .props?.label || "";
                    setTextCaption(text?.props.label || "");
                    onChange(itemValue, itemIndex);
                  }}
                  style={{
                    width: "100%",
                    backgroundColor: "rgba(120,120,120, 0.1)",
                  }}
                >
                  {children}
                </PickerIOS>
              </View>
            </View>
          )}
        </Portal>
      </>
    );
  }
  // WEB ********************************************************************************************
  if (Platform.OS === "web") {
    const pickerBackgroundColor = !withTheme ? "#ffffff" : theme.colors.background;

    return (
      <View style={[styles.pickerWrapperWebStyle,
        ((disabled) && { opacity: 0.5 }),
        (mode === "flat" && { borderWidth: 0 })]}
      >
        {(display !== "inline") && (
          <View style={[
            (mode === "flat" && isMac === false) && {
              backgroundColor: "transparent",
              position: "absolute",
              top: 10,
              left: 10,
              zIndex: 1,
            }, (mode !== "flat" && isMac === false) && {
              backgroundColor: theme.colors.background,
              position: "absolute",
              top: -6,
              left: 10,
              paddingHorizontal: 2,
              zIndex: 1,
            },
            (isMac === true) && {
              marginLeft: 12,
            },
          ]}
          >
            <Text style={[{
              color: theme.colors.placeholder,
              fontWeight: "500",
              fontSize: 13,
              paddingBottom: 3,
              transform: [{ translateX: 0 }, { translateY: 0 }, { scale: 1 }],
            }]}
            >
              {`${label} ${(required === true) ? "*" : ""}`}
            </Text>
          </View>
        )}
        <View style={[(display === "inline" && { flexDirection: "row", alignItems: "baseline" })]}>

          {display === "inline" && (
            <Text style={[{
              marginLeft: 12, fontSize: 16, color: theme.colors.placeholder, flex: 1, fontWeight: "500",
            },
            ]}
            >
              {`${label} ${(required === true) ? "*" : ""}`}
            </Text>
          )}

          <Picker
            itemStyle={{ color: "black", fontSize: 14 }}
            enabled={!disabled}
            mode="dropdown"
            style={[
              // eslint-disable-next-line no-nested-ternary
              display === "inline" ? styles.pickerWebWrapperStyleInline : mode === "flat" && isMac === false ? styles.pickerWebWrapperFlatStyle : styles.pickerWebWrapperStyle,
              {
                borderRadius: 4,
                width: display === "inline" ? "auto" : "100%",
                color: theme.colors.text,
                backgroundColor: mode === "flat" ? "transparent" : theme.colors.background,
                fontSize: 16,
                paddingLeft: 6,
              },
              mode === "outlined" ? { borderWidth: 1, borderColor: theme.colors.placeholder } : { borderWidth: 0 },
            ]}
            selectedValue={value}
            onValueChange={onChange}
          >
            {[].concat(...children).map((child) => React.cloneElement(child, {
              color: theme.dark ? "white" : "black",
              key: `PC-${child.key}-${props.value}`,
            }))}
          </Picker>
          {/* </View> */}
        </View>
        <View style={mode === "flat" && styles.formFieldBottomBorder} />
      </View>
    );
  }
  // ANDROID
  return (
    <View style={{
      flexDirection: "column",
      ...(Object.keys(forAndroidDialogStyle?.containerStyle).length > 0) ?
      forAndroidDialogStyle?.containerStyle : {
        flex: 1
      }
    }}
    >
      {(display !== "inline" && label !== undefined) && (

        <Text
          style={[{
            ...(Object.keys(forAndroidDialogStyle?.placeholderStyle).length > 0) &&
              forAndroidDialogStyle?.placeholderStyle,
            fontWeight: "500",
            position: "absolute",
            top: 33,
            paddingLeft: 12,
            fontSize: 16,
            zIndex: 1,
            transform: [{ translateX: 0 }, { translateY: 0 }, { scale: 1 },],
          },
          (checkLabel(children)
            && { transform: [...styles.labelTransformAndroid.transform, { translateX: rootWidth }] }),
          ]}
          onLayout={(event) => {
            const {
              x, width,
            } = event.nativeEvent.layout;

            const calculatedWidth = (width - (width * 0.75)) / -2;
            setRootWidth(calculatedWidth);
          }}
        >
          {`${label ? label.toUpperCase() : label} ${(required === true) ? "*" : ""}`}
        </Text>
      )}
      <View style={[label !== undefined ? { paddingTop: 26 } : {},
        (display === "inline" && { flexDirection: "row", alignItems: "center", height: 66 }),
        (mode === "flat" ? { ...styles.pickerAndroidStyleFlat, borderColor } : { ...styles.pickerAndroidStyleOutlined, borderColor })]}
      >
        {(display === "inline" && label !== undefined) && (
          <Text
            style={[styles.inlineLabel, { color: theme.colors.placeholder }]}
          >
            {`${label} ${(required === true) ? "*" : ""}`}

          </Text>
        )}

        <Picker
          enabled={disabled !== true}
          mode="dialog"
          style={
            [{
              color: disabled ? theme.colors.disabled : theme.colors.text,
              marginHorizontal: 5,
              height: label !== undefined ? 40 : 60,
              marginBottom: 8,
            }, style]
          }
          selectedValue={value}
          onValueChange={onChange}
        >
          {children}
        </Picker>
      </View>
    </View>
  );
};
