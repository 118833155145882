/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  forwardRef,
  useRef,
} from "react";
import {
  StyleSheet,
  View,
  Platform,
  RefreshControl,
  TouchableWithoutFeedback,
} from "react-native";
import {
  DefaultTheme,
  TextInput,
  Text,
  useTheme,
  ProgressBar,
  Surface,
  Title,
  TouchableRipple,
  ActivityIndicator,
  Snackbar,
  Button,
} from "react-native-paper";
import Auth from "@aws-amplify/auth";
import { API, graphqlOperation } from "aws-amplify";
import { Logger } from "@aws-amplify/core";
import gql from "graphql-tag";
import { ScrollView } from "react-native-gesture-handler";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import PhoneInput from "../../../components/PhoneInput";
import { HCPPortalTheme } from "../../../components";
import { ReferenceDataContext } from "./ReferenceDataContext";
import ErrorComponent from "../../../components/ErrorComponent";
import { styles as formStyles } from "./styles";
import { scaleDisplay } from "../../../helpers/image/applyScale";
import { PersonalInfoTitle } from "./StageTitle";
import { StageLoader } from "./StageLoader";
import FormField, { Message } from "../../../components/FormValidator/FormField";
import { translate } from "../../../components/Localei18n/languagesFunctions";
import LanguageSessionSelector from "../../../components/languageSessionSelector";
import { CountryPicker } from "../components/CountryPicker";

const logger = new Logger("PersonalInfo:Stage2");

const styles = StyleSheet.create({
  container: {
    marginTop: 20,
    width: scaleDisplay(460),
    padding: 35,
    borderRadius: 6,
    shadowColor: "black",
    shadowOpacity: 0.15,
    shadowOffset: { width: 0, height: 3 },
    shadowRadius: 6,
  },
  disabled_font: {
    color: "gray",
  },
  disabled_background: {
    backgroundColor: "lightgray",
  },
  picker: {
    // flex: 1,
    width: "100%",
    height: 44,
  },
  pickerItem: {
    height: 44,
  },
});

type Props = {
  goTo: () => void;
  backTo: () => void;
  handleSignout: () => void;
  user: any;
  userStatus: any;
  updateUserAttributes: (user: any, attributes: any) => void;
  verifyCurrentUserAttributeSubmit: (phone_number: string, code: string) => any;
  startStage: number;
};

export const Stage2 = forwardRef((props: Props, ref) => {
  let unmounted = false;
  const [loading, setLoading] = useState(true);
  const [isDirty, setIsDirty] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const theme = useTheme();
  const [numberId, setNumberId] = useState(null);
  const [numberCountryCode, setNumberCountryCode] = useState(0);
  const [number, setNumber] = useState("");
  const [formattedNumber, setFormattedNumber] = useState(props.user.attributes.phone_number);
  const [numberStatusId, setNumberStatusId] = useState(1);
  const [code, setCode] = useState("");
  const [isConfirming, setIsConfirming] = useState(false);
  const [timeLeft, setTimeLeft] = useState(30);
  const timer = null;

  const [enabledConfirm, setEnabledConfirm] = useState(false);
  const [numberConfirmed, setNumberConfirmed] = useState(false);

  const [phoneCodeList, setPhoneCodeList] = useState([]);

  const [errors, setErrors] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  const [currentLanguage, setCurrentLanguage] = useState("");

  const { personalInfoData, setPersonalInfoData } = useContext(
    ReferenceDataContext
  );

  const snackbarErrorColorConfig = { backgroundColor: "#D32F2F", color: "#ffffff" };
  const [resendCodeTriggered, setResendCodeTriggered] = useState(false);
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [snackBarConfig, setSnackBarConfig] = useState({ message: "", style: {} });

  const [sendingCode, setSendingCode] = useState(false);

  const phone = useRef<PhoneInput>(null);
  const countryData = phone.current?.getPickerData();

  const [countryPickerIsVisible, setCountryPickerIsVisible] = useState(false);

  let interval;
  const timerTrigger = null;

  const loadContactsStage2 = async () => {
    try {
      const qry = gql`
        query($number_id: Int, $contact_id: Int!) {
          getContactsStage2(number_id: $number_id, contact_id: $contact_id) {
            number_id
            number
            country_phone_code
            number_status_id
          }
          getPhonesCodes {
            country_name
            country_phone_code
          }
        }
      `;
      const response: any = await API.graphql(
        graphqlOperation(qry, {
          number_id: numberId,
          contact_id: personalInfoData?.contact_id,
        })
      );
      setPhoneCodeList(response.data.getPhonesCodes);

      if (
        response.data.getContactsStage2
        && response.data.getContactsStage2[0]
      ) {
        if (!numberId) {
          setNumberId(response.data.getContactsStage2[0].number_id);
        }

        if (!number) {
          setNumber(response.data.getContactsStage2[0].number);
        }

        if (!numberCountryCode) {
          setNumberCountryCode(
            response.data.getContactsStage2[0].country_phone_code
          );
        }

        if (!numberStatusId) {
          setNumberStatusId(
            response.data.getContactsStage2[0].number_status_id
          );
        }
        setFormattedNumber(`+${response.data.getContactsStage2[0].country_phone_code}${response.data.getContactsStage2[0].number}`);
      }

      logger.debug(
        "phone_number_verified",
        props.user.attributes.phone_number_verified
      );
      if (props.user.attributes.phone_number_verified) {
        setNumberConfirmed(true);
        logger.debug("phone_number_verified", true);
      }
    } catch (e) {
      return new Promise((resolve) => resolve(false));
    }
    return new Promise((resolve) => resolve(true));
  };

  const updateContactsStage2 = async () => {
    try {
      setErrors([]);
      const currentErrors = [];
      if (!numberCountryCode) {
        currentErrors.push("numberCountryCode");
      }
      if (!number) {
        currentErrors.push("number");
      }
      // if (!numberConfirmed) {
      //   currentErrors.push("code");
      // }
      if (currentErrors.length > 0) {
        setErrors(currentErrors);
        return false;
      }

      const completeNumber = (`+${numberCountryCode}${number}`).replace(/[\- ]+/g, "");

      if (props.user.attributes.phone_number !== completeNumber) {
        await props.updateUserAttributes(props.user, {
          phone_number: completeNumber,
        });
      }

      setSubmitting(true);
      const mutationreplaceContactsStage2 = gql`
        mutation replaceContactsStage2(
          $formValue: replaceContactsStage2Input!
        ) {
          replaceContactsStage2(formValue: $formValue)
        }
      `;

      // 1 Not Verified / 2 Valid
      await API.graphql(
        graphqlOperation(mutationreplaceContactsStage2, {
          formValue: {
            number_id: numberId,
            contact_id: personalInfoData?.contact_id,
            panelist_id: personalInfoData?.panelist_id,
            number,
            country_phone_code: numberCountryCode,
            number_status_id: numberStatusId,
          },
        })
      );
      const stage2Datas = {
        number,
        numberCountryCode,
        numberStatusId,
        phoneCodeList,
      };
      setPersonalInfoData({ ...personalInfoData, ...stage2Datas });
    } catch (e) {
      setSubmitting(false);
      return false;
    }
    return true;
  };

  const getError = () => {
    if (errors.includes("number")) {
      setValidationErrors({ message: translate("Phone number is mandatory.") });
      return;
    }
    if (!phone.current?.isValidNumber()) {
      setValidationErrors({ message: translate("Phone number is invalid") });
      return;
    }
    setValidationErrors({});
  };

  const sendCode = async (isResend = false) => {
    setErrors([]);
    const currentErrors = [];

    if (!numberCountryCode) {
      currentErrors.push("numberCountryCode");
    }
    if (!number) {
      currentErrors.push("number");
    }
    if (currentErrors.length > 0) {
      setErrors(currentErrors);
      return;
    }

    if (!isResend) {
      if (!phone.current?.isValidNumber()) return;
      setSendingCode(true);
    }

    const completeNumber = (`+${numberCountryCode}${number}`).replace(/[\- ]+/g, "");

    if (props.user.attributes.phone_number === completeNumber) {
      logger.debug("verifyCurrentUserAttribute", completeNumber);
      Auth.verifyCurrentUserAttribute("phone_number")
        .then(() => {
          logger.debug("verifyCurrentUserAttribute: ok", completeNumber);
          setEnabledConfirm(true);
          setNumberConfirmed(false);

          if (isResend) {
            setResendCodeTriggered(false);
            setSnackBarConfig({ ...snackBarConfig, message: "Code successfully resent.", style: {} });
            setSnackbarVisible(true);
          }

          setSendingCode(false);
          start();
          console.log("SMS sent 1");
        })
        .catch((err) => {
          logger.debug("verifyCurrentUserAttribute", err);
          currentErrors.push("sendCodeError");
          setErrorMessage(err.message);
          setSnackBarConfig({ ...snackBarConfig, message: err.message, style: snackbarErrorColorConfig });
          setSnackbarVisible(true);
          setSendingCode(false);
          setResendCodeTriggered(false);
        });
    } else {
      // We need to wait before to verify the number, do not remove await (jjb)
      await props.updateUserAttributes(props.user, {
        phone_number: completeNumber,
      });
      Auth.verifyCurrentUserAttribute("phone_number")
        .then(() => {
          logger.debug("verifyCurrentUserAttribute", "phone_number");
          setEnabledConfirm(true);
          setNumberConfirmed(false);

          if (isResend) {
            setResendCodeTriggered(false);
            setSnackBarConfig({ ...snackBarConfig, message: "Code successfully resent.", style: {} });
            setSnackbarVisible(true);
          }

          setSendingCode(false);
          start();
          console.log("SMS sent 2");
        })
        .catch((err) => {
          logger.debug("verifyCurrentUserAttribute", err);
          currentErrors.push("sendCodeError");
          setErrorMessage(err.message);
          setSnackBarConfig({ ...snackBarConfig, message: err.message, style: snackbarErrorColorConfig });
          setSnackbarVisible(true);
          setSendingCode(false);
          setResendCodeTriggered(false);
        });
    }

    if (currentErrors.length > 0) {
      setErrors(currentErrors);
    }

    console.log(snackbarVisible);
  };

  const verifyCode = async () => {
    const currentErrors = [];
    logger.debug("verifyCode", code);
    if (code !== "") {
      setIsConfirming(true);
      const codeCheck = await props.verifyCurrentUserAttributeSubmit(
        "phone_number",
        code
      );
      logger.debug("verifyCode:codeCheck", codeCheck);
      if (codeCheck.code !== "CodeMismatchException") {
        setNumberConfirmed(true);
        setNumberStatusId(2); // Verified successfully
        updateContactsStage2(); // Update the database if ok
      } else {
        currentErrors.push("code");
        setErrors(currentErrors);
      }

      setIsConfirming(false);
    } else {
      currentErrors.push("code");
    }
    setErrors(currentErrors);
  };

  const changePhoneNumber = () => {
    setEnabledConfirm(false);
    setNumberConfirmed(false);
  };

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    loadContactsStage2().then(() => {
      setRefreshing(false);
    });
  }, []);

  const setRef = (element) => {
    if (element !== null) {
      setNumberCountryCode(element?.getCountryCode());
      // if (props.user?.attributes.phone_number) {
      //   setNumber(props.user?.attributes.phone_number.replace(`+${element?.getCountryCode()}`, ""));
      // }
    }

    phone.current = element;
  };

  useEffect(() => {
    setErrors([]);
    loadContactsStage2().then(() => {
      if (!unmounted) {
        setLoading(false);
      }
    });

    if (props.user?.attributes.phone_number_verified) {
      setNumberConfirmed(true);
      setEnabledConfirm(!enabledConfirm);
      setNumberStatusId(2); // Verified sucessfully
    }

    // setSnackBarConfig({ ...snackBarConfig, message: "err.message,", style: snackbarErrorColorConfig });
    // setSnackbarVisible(true);

    return () => {
      clearTimeout(timer);
      unmounted = true;
    };
  }, [props.userStatus, props.user, currentLanguage]);

  const selectCountryHandler = (country) => {
    phone.current?.selectCountry(country.iso2);

    setTimeout(() => {
      setCountryPickerIsVisible(false);
    }, 300);
  };

  const dismissCountryPicker = () => {
    setCountryPickerIsVisible(false);
  };

  const backButton = (enabledConfirm && !numberConfirmed) ? (
    <TouchableRipple
      disabled={submitting}
      onPress={() => {
        setResendCodeTriggered(false);
        changePhoneNumber();
        setCode("");
      }}
      style={[
        formStyles.formButton,
        formStyles.formButtonDisabled,
      ]}
    >
      <Text
        theme={DefaultTheme}
        style={{ color: theme.colors.primary }}
      >
        {translate("Change number")}
      </Text>
    </TouchableRipple>
  ) : (
    <TouchableRipple
      disabled={submitting}
      onPress={() => props.backTo()}
      style={[
        formStyles.formButton,
        formStyles.formButtonDisabled,
      ]}
    >
      <Text
        theme={DefaultTheme}
        style={{ color: theme.colors.primary }}
      >
        {translate("Back")}
      </Text>
    </TouchableRipple>
  );

  const start = () => {
    setTimeLeft(30);
    interval = setInterval(() => {
      setTimeLeft((lastTimerCount) => {
        lastTimerCount <= 1 && clearInterval(interval);
        return lastTimerCount - 1;
      });
    }, 1000);
  };

  return (
    <>
      <PersonalInfoTitle style={formStyles.fluid} />

      <ScrollView
        style={[
          formStyles.contentWrapper,
          Platform.OS === "web" && formStyles.contentWrapperWeb,
        ]}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }
        contentContainerStyle={{ paddingHorizontal: 10 }}
      >
        <Surface
          style={[
            formStyles.root,
            formStyles.boxShadow,
            Platform.OS === "web" && formStyles.rootWeb,
          ]}
        >

          {submitting && (
            <View style={formStyles.mask}>
              <ProgressBar indeterminate color={theme.colors.accent} />
            </View>
          )}

          {!loading && (
            <>
              {(true)
                ? (
                  <View>
                    <Title style={[formStyles.title, { color: theme.colors.primary }]}>
                      {translate("Contact Number")}
                    </Title>
                    <View style={{ marginTop: 16 }}>
                      <FormField
                        errors={validationErrors}
                        style={{ paddingHorizontal: 12 }}
                      >
                        <Text style={{ color: "grey" }}>
                          {translate("Enter Your Phone Number")}
                          {" "}
                          *
                        </Text>

                        <PhoneInput
                          ref={setRef}
                          style={{
                            borderBottom: "2px solid #d0d0d0",
                            paddingTop: 6,
                            paddingHorizontal: 12,
                            paddingBottom: 0,
                          }}
                          textStyle={{
                            height: 36,
                            fontSize: 16,
                            ...(Platform.OS === "web" && { outline: "none" }),
                          }}
                          textProps={{
                            placeholder: "e.g. +10987654321",
                            value: formattedNumber,
                          }}
                          value={formattedNumber}
                          onChangePhoneNumber={(val: string) => {
                            const phoneInputNumber = val.replace(/[^+,0-9]/g, "");
                            setNumber(phoneInputNumber.replace(`+${phone.current.getCountryCode()}`, ""));
                            setFormattedNumber(phoneInputNumber);
                            setNumberCountryCode(phone.current?.getCountryCode());
                            getError();
                          }}
                          autoFormat
                          allowZeroAfterCountryCode
                          withDarkTheme
                          onSelectCountry={(iso2) => {
                            setNumberCountryCode(phone.current?.getCountryCode());
                            setFormattedNumber(`+${phone.current?.getCountryCode()}${number}`);
                            getError();
                          }}
                          disabled={false}
                          {...(Platform.OS !== "ios" ? { onPressFlag: () => setCountryPickerIsVisible(true) } : {})}
                        />

                        {(Platform.OS !== "ios" && countryPickerIsVisible)
                          && (
                            <CountryPicker
                              countries={countryData}
                              dismissModal={dismissCountryPicker}
                              setCountry={selectCountryHandler}
                              currentCountryCode={props.user.attributes["custom:country"]}
                            />
                          )}
                      </FormField>
                    </View>
                    <View
                      style={{
                        borderBottomColor: "rgba(200,200,200,0.5)",
                        ...!numberConfirmed && { marginBottom: 50 },
                      }}
                    >
                      {errors.includes("sendCodeError") && <Message>{errorMessage}</Message>}
                      <View style={{
                        paddingHorizontal: 10,
                        marginBottom: 8,
                        marginTop: 8,
                        display: "none",
                      }}
                      >
                        <Button
                          mode="contained"
                          onPress={() => {
                            sendCode();
                          }}
                          color={theme.colors.primary}
                          loading={sendingCode}
                          disabled={sendingCode || enabledConfirm}
                          dark
                          uppercase={false}
                        >
                          {sendingCode ? translate("Sending") : translate("Send Code")}
                        </Button>
                      </View>
                    </View>
                    {false && (
                      <View style={{
                        flexDirection: "row",
                        justifyContent: "center",
                        marginBottom: 40,
                        marginTop: 12,
                      }}
                      >
                        <Text style={{ fontSize: 14, color: "#424141" }}>
                          {translate("Not your number? ")}
                          {" "}
                        </Text>
                        <TouchableWithoutFeedback
                          disabled={resendCodeTriggered}
                          onPress={() => {
                            setResendCodeTriggered(false);
                            changePhoneNumber();
                          }}
                        >
                          <Text style={{
                            color: resendCodeTriggered ? "grey" : theme.colors.primary,
                            fontSize: 14,
                          }}
                          >
                            {translate("Change")}
                          </Text>

                        </TouchableWithoutFeedback>
                      </View>
                    )}
                  </View>
                ) : (
                  <View>
                    <Title
                      style={[formStyles.title, { color: theme.colors.primary, paddingBottom: 10 }]}
                    >
                      {translate("Verify Contact")}
                    </Title>

                    <View style={{
                      paddingHorizontal: 10,
                      marginBottom: 16,
                      marginTop: 16,
                    }}
                    >
                      <Text style={{ color: "#444444" }}>
                        {translate("Please enter the verification code that was sent to your phone number:")}
                        {" "}
                        {formattedNumber}
                        .
                      </Text>
                    </View>

                    <FormField
                      errors={
                        errors.includes("code") && {
                          message: translate("Invalid verification code provided."),
                        }
                      }
                    >
                      <TextInput
                        style={[
                          {
                            flex: 1,
                            marginHorizontal: 10,
                            textAlign: "center",
                            letterSpacing: 20,
                            backgroundColor: "transparent",
                          },
                          enabledConfirm && !numberConfirmed
                            ? null
                            : styles.disabled_background,
                        ]}
                        placeholder={translate("Verification Code")}
                        autoCapitalize="none"
                        mode="flat"
                        autoCorrect={false}
                        onChangeText={(text) => setCode(text)}
                        value={code}
                        editable={enabledConfirm && !numberConfirmed}
                        autoFocus
                        error={errors.includes("code") && !numberConfirmed}
                        dense
                      />
                    </FormField>

                    <View style={{
                      flexDirection: "row",
                      paddingHorizontal: 10,
                      justifyContent: "space-between",
                      marginTop: 10,
                      marginBottom: 8,
                    }}
                    >
                      <Button
                        mode="contained"
                        onPress={() => verifyCode()}
                        uppercase={false}
                        style={{
                          flex: 1,
                        }}
                        disabled={!enabledConfirm || numberConfirmed || isConfirming}
                        color={theme.colors.primary}
                        loading={isConfirming}
                      >
                        {translate(isConfirming ? "Verifying" : "Verify")}
                      </Button>
                    </View>

                    <View style={{
                      flexDirection: "row",
                      justifyContent: "center",
                      marginBottom: 30,
                      marginTop: 20,
                    }}
                    >
                      {timeLeft <= 0 ? (
                        <>
                          <TouchableWithoutFeedback
                            disabled={resendCodeTriggered}
                            onPress={() => {
                              setResendCodeTriggered(true);
                              sendCode(true);
                            }}
                          >
                            <Text style={{
                              color: resendCodeTriggered ? "grey" : theme.colors.primary,
                              fontSize: 14,
                              fontWeight: "bold",
                            }}
                            >
                              {translate("Resend code")}
                            </Text>

                          </TouchableWithoutFeedback>
                        </>
                      )
                        : (
                          <>
                            <Text style={{ color: "grey" }}>{translate("Resend code")}</Text>
                            <Text style={{ fontWeight: "bold" }}>
                              (
                              {timeLeft}
                              s)
                            </Text>
                          </>
                        )}
                    </View>
                  </View>
                )}

              {numberConfirmed && (
                <View style={{ alignItems: "center" }}>
                  <Text
                    style={[
                      HCPPortalTheme.sectionFooterLink,
                      {
                        display: "flex",
                        color: "green",
                        marginBottom: 16,
                        textAlign: "center",
                      },
                    ]}
                  >
                    {translate("Number confirmed")}
                  </Text>
                </View>
              )}

              <ErrorComponent />

              <View style={[formStyles.formActionSection, { marginTop: 15 }]}>
                <TouchableRipple
                  disabled={submitting}
                  onPress={async () => {
                    if (phone.current?.isValidNumber()) {
                      await updateContactsStage2();
                      props.goTo();
                    }
                  }}
                  style={[
                    formStyles.formButton,
                    {
                      backgroundColor: (Object.keys(errors).length === 0 && phone.current?.isValidNumber() ? theme.colors.accent : theme.colors.disabled),
                    },
                  ]}
                >
                  <View style={{ flexDirection: "row" }}>
                    {submitting && (
                      <ActivityIndicator
                        size={14}
                        color="white"
                        style={{ marginRight: 10 }}
                      />
                    )}
                    <Text style={{ color: "white" }}>{translate("Next")}</Text>
                  </View>
                </TouchableRipple>

                {props.startStage !== 2 ? backButton
                  : (
                    <TouchableRipple
                      disabled={submitting}
                      onPress={() => props.handleSignout()}
                      style={[styles.formButton, styles.formButtonDisabled]}
                    >
                      <Text
                        theme={DefaultTheme}
                        style={{ color: theme.colors.primary }}
                      >
                        {translate("Logout")}
                      </Text>
                    </TouchableRipple>
                  )}
              </View>
            </>
          )}
          {loading && <StageLoader style={{ paddingTop: 10 }} />}
        </Surface>
        <View style={[
          Platform.OS === "web" && formStyles.rootLangSelector,
          { marginTop: Platform.OS === "web" ? -10 : 0, marginLeft: Platform.OS === "web" ? 0 : -20, paddingBottom: 30 }]}
        >
          <LanguageSessionSelector style={{ margin: 0 }} />
        </View>
      </ScrollView>

      <Snackbar
        visible={snackbarVisible}
        onDismiss={() => setSnackbarVisible(false)}
        duration={5000}
        action={{
          label: <MaterialCommunityIcons name="close" size={24} color="#fff" />,
          onPress: () => {
            setSnackbarVisible(false);
          },
        }}
        wrapperStyle={{ alignItems: "center" }}
        style={[snackBarConfig.style, {
          ...Platform.OS === "web" && {
            maxWidth: 500,
            width: "100%",
          },
          padding: 10,
        }]}
      >
        {snackBarConfig.message}
      </Snackbar>
    </>
  );
});

export default Stage2;
